import { gql } from "@apollo/client";

const GET_ALL_PHYSICIANS = gql`
  query GetAllPhysicians($limit: Int!, $page: Int!) {
    getAllPhysicians(limit: $limit, page: $page) {
      Status
      Message
      Data {
        ID
        DocumentId
        ProvidersId
        Name
        Speciality
        Code
        Role
        License
        StatusId
        NphiesId
        SpecialityItemIndex
      }
      Pages
    }
  }
`;

const GET_PHYSICIAN_BY_ID = gql`
  query getPhysician($id: Int!) {
    getPhysician(id: $id) {
      status
      message
      data {
        id
        document_id
        providers_id
        name
        speciality
        speciality_item_index
        role
        license
        status_id
        code
        created_at
      }
    }
  }
`;

const LIST_ALL_PHYSICIANS = gql`
  query ListAllPhysicians($limit: Int!, $page: Int!) {
    listPhysicians(limit: $limit, page: $page) {
      status
      message
      data {
        id
        document_id
        providers_id
        name
        speciality
        speciality_item_index
        code
        role
        license
        status_id
        nphies_id
      }
      pages
      total
    }
  }
`;

const CREATE_PHYSICIAN = gql`
  mutation registerPhysician($input: RegisterPhysicianInput!) {
    registerPhysician(input: $input) {
      message
      status
      data {
        id
        document_id
        providers_id
        name
        speciality
        code
        speciality_item_index
        role
        license
        nphies_id
        status_id
      }
    }
  }
`;

const DELETE_PHYSICIAN = gql`
  mutation deletePhysician($input: DeletePhysicianInput!) {
    deletePhysician(input: $input) {
      message
      status
      data {
        id
        document_id
        providers_id
        name
        speciality
        role
        license
        status_id
        created_at
      }
    }
  }
`;

// const CREATE_PHYSICIAN = gql`
//   mutation physiciianRegistrationMutation($input: Physician!) {
//     physicianRegistrationMutation(input: $input) {
//       Message
//       Status
//       Data {
//         ID
//         DocumentId
//         ProvidersId
//         Name
//         Speciality
//         Role
//         License
//         StatusId
//         CreatedAt
//         SpecialityItemIndex
//       }
//     }
//   }
// `;

const UPDATE_PHYSICIAN = gql`
  mutation updatePhysician($input: UpdatePhysicianInput!) {
    updatePhysician(input: $input) {
      message
      status
      data {
        id
        document_id
        name
        speciality
        speciality_item_index
        code
        role
        license
      }
    }
  }
`;

// const UPDATE_PHYSICIAN = gql`
//   mutation physicianUpdate($input: UpdatePhysician!) {
//     physicianUpdate(input: $input) {
//       Message
//       Status
//       Data {
//         ID
//         DocumentId
//         ProvidersId
//         Name
//         Speciality
//         Role
//         License
//         StatusId
//         CreatedAt
//         UpdatedAt
//         SpecialityItemIndex
//       }
//     }
//   }
// `;

// const DELETE_PHYSICIAN = gql`
//   mutation deletePhysician($input: DeletePhysician!) {
//     deletePhysician(input: $input) {
//       Message
//       Status
//       Data {
//         ID
//         DocumentId
//         ProvidersId
//         Name
//         Speciality
//         Role
//         License
//         StatusId
//         CreatedAt
//       }
//     }
//   }
// `;

const GET_SEARCHED_PHYSICIANS = gql`
  query GetSearchedPhysicians($q: String!) {
    searchPhysicians(search: $q) {
      status
      message
      data {
        id
        document_id
        providers_id
        name
        speciality
        speciality_item_index
        code
        role
        license
        status_id
        nphies_id
      }
    }
  }
`;

const GET_PHYSICIAN_SPECIALITY = gql`
  query {
    getPhysicianSpeciality {
      status
      message
      data {
        id
        code
        display
      }
    }
  }
`;

export default {
  GET_ALL_PHYSICIANS,
  CREATE_PHYSICIAN,
  UPDATE_PHYSICIAN,
  DELETE_PHYSICIAN,
  GET_PHYSICIAN_SPECIALITY,
  GET_SEARCHED_PHYSICIANS,
  LIST_ALL_PHYSICIANS,
  GET_PHYSICIAN_BY_ID,
};
