import { gql } from "@apollo/client";

const GET_ALL_PAYMENTS = gql`
  query listPayments($input: PaymentFiltersInput!) {
    listPayments(filters: $input) {
      message
      status
      data {
        id
        transaction_id
        timestamp
        payment_start_date
        payment_end_date
        payment_amount
        payment_currency
        payment_note_date
        confirmation_status
        payment_status
        providers_name_en
        payer_name_en
      }
      pages
      total
    }
  }
`;

const PAYMENT_NOTICE = gql`
  mutation paymentNoticeRequest($id: String!) {
    paymentNoticeRequest(paymentId: $id) {
      status
      message
      nphies_err {
        code
        value_string
        display
      }
    }
  }
`;

const PAYMENT_POLL_REQ = gql`
  query {
    pollRequest(request_name: "payments") {
      status
      message
      data
    }
  }
`;

export default {
  GET_ALL_PAYMENTS,
  PAYMENT_NOTICE,
  PAYMENT_POLL_REQ,
};
