import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import CommunicationModal from "./CommunicationModal";
import CommuniModal from "./CommuiModal";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { XMarkIcon, ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import gqlPreAuth from "../../../gql/preAuth";

import "../styles/pre-auth.css";
import AppModal from "../../../components/AppModal";
import { MdAttachFile, MdDownload } from "react-icons/md";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import toast from "react-hot-toast";
import { LuReply } from "react-icons/lu";
import { useParams } from "react-router-dom";

const CommunicationDetails = ({ item, currentItem }) => {
  const [showCommunicationModal, setshowCommunicationModal] = useState(false);
  const [communicationId, setCommunicationId] = useState("");
  const [dataArray, setdataArray] = useState([]);
  const { id } = useParams();

  const {
    loading: communicationLoading,
    error: communicationError,
    data: communicationData,
    refetch: communicationRefetch,
  } = useQuery(gqlPreAuth.GET_TRANSACTION_COMMUNICATIONS, {
    variables: {
      input: {
        transaction_id: currentItem?.transaction_id,
        request_name: "preAuth",
      },
    },
    fetchPolicy: "network-only",
  });

  function downloadBase64File(base64Data, fileName, fileType) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  const findSequence = (seq) => {
    const findSeq = item?.getPreAuthTransaction?.data?.items?.find(
      (i) => i.sequence_no === seq
    );
    return findSeq
      ? `${findSeq?.type} - (${findSeq?.standard_code} - ${findSeq?.standard_description})`
      : "___";
  };

  const {
    loading: pollLoading,
    error: pollError,
    data: pollData,
    refetch: refetchPoll,
  } = useQuery(gqlPreAuth.PRE_AUTH_COMMUNICATION_POLL, {
    skip: true, // Skip initial fetching
  });

  const pollClicked = () => {
    toast.loading("Requesting!");
    refetchPoll().then(async (res) => {
      toast.success(res?.data?.pollRequest?.message || "Request Successfully!");
      await communicationRefetch({
        input: {
          transaction_id: currentItem?.transaction_id,
          request_name: "preAuth",
        },
      });
    });
  };
  return (
    <>
      <div class="pre_auth_communication_page">
        <div class="add_communication !p-0 !border-none !shadow-none">
          {/* <div class="add_communication_header">
            <div
              class="add_communication_header_1"
              onClick={() => setshowCommunicationModal(true)}
            >
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="34" height="34" rx="8" fill="#F4F6F8" />
                <path
                  d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
                  fill="#F5793B"
                />
                <path
                  d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
                  fill="#F5793B"
                />
              </svg>
              Add Communication
            </div>
            <div
              class="add_communication_header_2"
              id="add_communication_header_2"
              onClick={() => setshowCommunicationModal(!showCommunicationModal)}
            >
              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="19" cy="19" r="19" fill="#F5793B" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M26 18H20V12C20 11.447 19.552 11 19 11C18.448 11 18 11.447 18 12V18H12C11.448 18 11 18.447 11 19C11 19.553 11.448 20 12 20H18V26C18 26.553 18.448 27 19 27C19.552 27 20 26.553 20 26V20H26C26.552 20 27 19.553 27 19C27 18.447 26.552 18 26 18Z"
                  fill="white"
                />
                <mask
                  id="mask0_90_11610"
                  maskUnits="userSpaceOnUse"
                  x="11"
                  y="11"
                  width="16"
                  height="16"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M26 18H20V12C20 11.447 19.552 11 19 11C18.448 11 18 11.447 18 12V18H12C11.448 18 11 18.447 11 19C11 19.553 11.448 20 12 20H18V26C18 26.553 18.448 27 19 27C19.552 27 20 26.553 20 26V20H26C26.552 20 27 19.553 27 19C27 18.447 26.552 18 26 18Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_90_11610)"></g>
              </svg>
              Add Communication
            </div>
          </div> */}
          <div className="flex justify-end gap-3 mb-3">
            {/* <Button
              variant="contained"
              size="small"
              onClick={() => pollClicked()}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<ImportExportIcon />}
            >
              Poll
            </Button> */}
            <Button
              variant="contained"
              size="small"
              onClick={() => setshowCommunicationModal(!showCommunicationModal)}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<AddIcon />}
            >
              Add Communication
            </Button>
          </div>
          <div class="pre_auth_communication_table">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Comment/File</th>
                  <th>Item Seq.</th>
                  <th>Reply</th>
                </tr>
              </thead>
              <tbody>
                {communicationData?.listCommunicationsByTransactionId?.data?.map(
                  (mainPayloadItem, index) => {
                    return (
                      <>
                        {mainPayloadItem?.payload?.map((el, itemIndex) => {
                          return (
                            <tr>
                              <td>
                                {new Date(
                                  mainPayloadItem?.timestamp
                                )?.toLocaleDateString("en-IN")}{" "}
                                {new Date(
                                  mainPayloadItem?.timestamp
                                )?.toLocaleTimeString("en-IN")}
                              </td>
                              <td>{mainPayloadItem?.type}</td>
                              <td>
                                {el?.payload_comment ? (
                                  el?.payload_comment
                                ) : (
                                  <div className="flex gap-2 ">
                                    <MdAttachFile />
                                    <div>
                                      {
                                        el?.payload_attachment
                                          ?.payload_attachment_title
                                      }
                                    </div>
                                    <MdDownload
                                      className="ml-5 cursor-pointer"
                                      onClick={() =>
                                        downloadBase64File(
                                          el?.payload_attachment
                                            ?.payload_attachment_data,
                                          el?.payload_attachment
                                            ?.payload_attachment_title,
                                          el?.payload_attachment
                                            ?.payload_attachment_content_type
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                              <td>{findSequence(el?.item_sequence)}</td>
                              <td>
                                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    onClick={() => {
                                      setCommunicationId(mainPayloadItem.id);
                                      setshowCommunicationModal(true);
                                    }}
                                  >
                                    <path
                                      d="M12 0C5.388 0 0 5.388 0 12C0 18.612 5.388 24 12 24C18.612 24 24 18.612 24 12C24 5.388 18.612 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM15.108 7.908L16.8 9.6L12 14.4L7.2 9.6L8.892 7.908L10.8 9.804V4.8H13.2V9.804L15.108 7.908ZM18 18H6V15.6H18V18Z"
                                      fill="#242429"
                                    />
                                  </svg> */}
                                {mainPayloadItem?.creator == "payer" && (
                                  <Tooltip title="Reply">
                                    <div
                                      onClick={() => {
                                        setCommunicationId(mainPayloadItem.id);
                                        setshowCommunicationModal(true);
                                      }}
                                      className="text-2xl cursor-pointer"
                                    >
                                      <LuReply />
                                    </div>
                                  </Tooltip>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
        <CommuniModal
          dataArray={dataArray}
          item={item}
          currentItem={currentItem}
          setdataArray={setdataArray}
          showCommunicationModal={showCommunicationModal}
          setshowCommunicationModal={setshowCommunicationModal}
          communicationId={communicationId}
          communicationRefetch={communicationRefetch}
        />
        <AppModal isOpen={communicationLoading} />
      </div>
    </>
  );
  return (
    <div class="add_communication">
      <div class="add_communication_header">
        <div class="add_communication_header_1">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="34" height="34" rx="8" fill="#F4F6F8" />
            <path
              d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
              fill="#F5793B"
            />
            <path
              d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
              fill="#F5793B"
            />
          </svg>
          Add Communication
        </div>
        <div
          class="add_communication_header_2"
          id="add_communication_header_2"
          onClick={() => setshowCommunicationModal(true)}
        >
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="19" cy="19" r="19" fill="#F5793B" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M26 18H20V12C20 11.447 19.552 11 19 11C18.448 11 18 11.447 18 12V18H12C11.448 18 11 18.447 11 19C11 19.553 11.448 20 12 20H18V26C18 26.553 18.448 27 19 27C19.552 27 20 26.553 20 26V20H26C26.552 20 27 19.553 27 19C27 18.447 26.552 18 26 18Z"
              fill="white"
            />
            <mask
              id="mask0_90_11610"
              // style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="11"
              y="11"
              width="16"
              height="16"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26 18H20V12C20 11.447 19.552 11 19 11C18.448 11 18 11.447 18 12V18H12C11.448 18 11 18.447 11 19C11 19.553 11.448 20 12 20H18V26C18 26.553 18.448 27 19 27C19.552 27 20 26.553 20 26V20H26C26.552 20 27 19.553 27 19C27 18.447 26.552 18 26 18Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_90_11610)"></g>
          </svg>
          Add Communication
        </div>
        <CommuniModal
          dataArray={dataArray}
          item={item}
          currentItem={currentItem}
          setdataArray={setdataArray}
          showCommunicationModal={showCommunicationModal}
          setshowCommunicationModal={setshowCommunicationModal}
          communicationId={communicationId}
          communicationRefetch={communicationRefetch}
        />
      </div>
    </div>
  );
  return (
    <>
      <div>
        <div className="text-right py-1 flex justify-between">
          <p></p>
          <div className=" flex items-center gap-3 py-3 text-right">
            <PlusCircleIcon
              className="h-5 w-5 text-gray-900 hover:cursor-pointer "
              aria-hidden="true"
              onClick={() => setshowCommunicationModal(true)}
            />

            <h3
              className="cursor-pointer text-cyan-700"
              onClick={() => setshowCommunicationModal(!showCommunicationModal)}
            >
              Add Communication
            </h3>
          </div>
        </div>
        {communicationData?.listCommunicationsByTransactionId?.data?.map(
          (mainPayloadItem, index) => {
            return (
              <>
                {mainPayloadItem?.Payload?.map((item, itemIndex) => {
                  return (
                    <div className="flex justify-between ">
                      <div
                        className={`flex flex-col gap-2 ${
                          mainPayloadItem?.creator == "payer"
                            ? "bg-cyan-100"
                            : "bg-gray-100"
                        } my-2 py-2 px-2 flex-1 rounded-md`}
                      >
                        {/* <div>
                          {item?.payloadAttachment ? (
                            <div>
                              <img
                                src={
                                  item?.payloadAttachment?.payloadAttachmentData
                                }
                                alt="no file "
                                className="w-12 h-12 rounded-full"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div> */}
                        <div className="flex items-center gap-4">
                          <h3>{item?.payload_comment}</h3>
                        </div>

                        <div className="flex items-center gap-4">
                          <h3 className="flex text-cyan-800">
                            Communication Id: {mainPayloadItem?.id}
                          </h3>
                          <h3 className="flex text-cyan-800">
                            Type: {mainPayloadItem?.type}
                          </h3>
                          {/* <span>{item?.itemSequence}</span> */}
                        </div>
                      </div>
                      {mainPayloadItem?.creator == "payer" && (
                        <div className="py-2">
                          <button
                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            //   value={"submit"}
                            onClick={() => {
                              setCommunicationId(mainPayloadItem.id);
                              setshowCommunicationModal(true);
                            }}
                          >
                            <ArrowUturnLeftIcon
                              className="h-4 w-4 text-white-900 text-xl hover:cursor-pointer  "
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}{" "}
              </>
            );
          }
        )}
      </div>
      <CommuniModal
        dataArray={dataArray}
        item={item}
        currentItem={currentItem}
        setdataArray={setdataArray}
        showCommunicationModal={showCommunicationModal}
        setshowCommunicationModal={setshowCommunicationModal}
        communicationId={communicationId}
        communicationRefetch={communicationRefetch}
      />
    </>
  );
};

export default CommunicationDetails;
