import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import claimsGQL from "../../gql/claims";
import "./styles/pre-auth.css";
import "../Beneficiary/styles/BeneficiaryList.css";
import AppModal from "../../components/AppModal";
import { Divider, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import ClaimHistoryFilter from "./ClaimHistoryFilter";

function ClaimHistoryList(props) {
  const currentDate = new Date();
  const currentYearMonth = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, "0")}`;

  function getFirstAndLastDateOfMonth(yearMonth) {
    const [year, month] = yearMonth.split("-").map(Number);
    const firstDate = new Date(year, month - 1, 1);
    const lastDate = new Date(year, month, 0);

    const firstDateString = `${firstDate.getFullYear()}-${(
      firstDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${firstDate.getDate().toString().padStart(2, "0")}`;
    const lastDateString = `${lastDate.getFullYear()}-${(
      lastDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${lastDate.getDate().toString().padStart(2, "0")}`;

    return {
      firstDate: firstDateString,
      lastDate: lastDateString,
    };
  }

  const initialDates = getFirstAndLastDateOfMonth(currentYearMonth);
  const [showFilter, setShowFilter] = useState(false);
  const [fromDate, setFromDate] = useState(initialDates.firstDate);
  const [toDate, setToDate] = useState(initialDates.lastDate);

  const {
    loading: dataLoading,
    error,
    data,
    refetch,
  } = useQuery(claimsGQL.CLAIMS_STATS_HISTORY, {
    variables: {
      input: {
        from_date: fromDate,
        to_date: toDate,
      },
    },
  });

  useEffect(() => {
    if (error?.graphQLErrors[0]?.message) {
      toast.error(error?.graphQLErrors[0]?.message);
    }
  }, [error]);

  const getLastNMonths = (n) => {
    const months = [];
    for (let i = 0; i < n; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      months.push(date);
    }
    return months;
  };

  const handleMonthClick = (date) => {
    const yearMonth = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}`;
    const { firstDate, lastDate } = getFirstAndLastDateOfMonth(yearMonth);

    setFromDate(firstDate);
    setToDate(lastDate);

    refetch({
      input: {
        from_date: firstDate,
        to_date: lastDate,
      },
    });
  };

  const monthButtons = getLastNMonths(13).map((date, index) => (
    <Button
      key={index}
      variant="outlined"
      size="small"
      onClick={() => handleMonthClick(date)}
      className="!border-primary !text-primary hover:!bg-primary/10 mb-2"
    >
      {date.toLocaleString("default", { month: "short", year: "numeric" })}
    </Button>
  ));

  return (
    <>
      <div className="pre_auth_container !p-0">
        <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
          <div className="">
            <Tooltip
              placement="right"
              title="Keep track of your Claims History"
              className="my-auto !mt-2"
            >
              <span className="font-semibold text-black/70 text-xl ">
                CLAIMS HISTORY
                <span className="italic text-lg ml-1">
                  ({data?.claimsStats?.data?.length || 0}{" "}
                  <span className="text-xs font-bold italic">Entries</span>)
                </span>
              </span>
            </Tooltip>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 mb-4 ">{monthButtons}</div>

        {data?.claimsStats?.data &&
          data?.claimsStats?.data?.map((stats) => {
            const monthDate = new Date(stats.month);
            const monthName = monthDate.toLocaleString("default", {
              month: "long",
            });
            const year = monthDate.getFullYear();

            return (
              <div key={stats.month} className="mt-6">
                <h3 className="py-2 font-bold text-xl border-y-2">
                  {monthName}, {year}
                </h3>

                <div className="grid grid-cols-3 gap-3 my-6">
                  {stats.claims.map((claim) => (
                    <div
                      key={claim.payer_id}
                      className="card shadow-md p-2 rounded-md grid grid-cols-4"
                    >
                      <div className="eligibility_details_user_data col-span-4 flex justify-between gap-4">
                        <div className="data_head !text-center w-28">
                          <span>Payer Name</span>
                        </div>
                        <div className="data_cont !text-center !text-gray-600">
                          <span className="data_cont !text-sm !font-semibold ">
                            {claim?.payer_name || "___"}
                          </span>
                        </div>
                      </div>

                      <div className="eligibility_details_user_data col-span-4 flex justify-between gap-4 py-1">
                        <div className="data_head !text-center">
                          <span>TPA</span>
                        </div>
                        <div className="data_cont !text-center">
                          <div className="data_cont !text-sm !text-gray-600 !font-semibold">
                            {claim?.tpa_name_en || "___"}
                          </div>
                        </div>
                      </div>

                      <div className="eligibility_details_user_data col-span-4 flex justify-between gap-4">
                        <div className="data_head !text-center">
                          <span>Batch ID</span>
                        </div>
                        <div className="data_cont !text-center">
                          <div className="data_cont !text-sm !text-gray-600 !font-semibold">
                            {claim?.batch_id || "___"}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-4">
                        <Divider />
                      </div>
                      <div className="eligibility_details_user_data py-2 col-span-2 bg-gray-50">
                        <div className="data_head !text-center text-gray-500">
                          <span>Total</span>
                        </div>
                        <div className="data_cont !text-center text-gray-500">
                          <span className="data_cont !text-base !font-semibold">
                            {claim?.total_claims}
                          </span>
                        </div>
                      </div>
                      <div className="eligibility_details_user_data py-2 bg-green-50">
                        <div className="data_head !text-center !text-green-500">
                          <span>Approved</span>
                        </div>
                        <div className="data_cont !text-center">
                          <span className="data_cont !text-base !font-semibold !text-green-500">
                            {claim?.approved_claims}
                          </span>
                        </div>
                      </div>
                      <div className="eligibility_details_user_data py-2 bg-lime-50">
                        <div className="data_head !text-center text-lime-500">
                          <span>Partial</span>
                        </div>
                        <div className="data_cont !text-center text-lime-500">
                          <span className="data_cont !text-base !font-semibold">
                            {claim?.partial_claims}
                          </span>
                        </div>
                      </div>
                      <div className="eligibility_details_user_data py-2 bg-cyan-50">
                        <div className="data_head !text-center text-cyan-500">
                          <span>Pending</span>
                        </div>
                        <div className="data_cont !text-center text-cyan-500">
                          <span className="data_cont !text-base !font-semibold">
                            {claim?.pending_claims}
                          </span>
                        </div>
                      </div>
                      <div className="eligibility_details_user_data py-2 bg-cyan-50">
                        <div className="data_head !text-center text-teal-500">
                          <span>Pended</span>
                        </div>
                        <div className="data_cont !text-center text-teal-500">
                          <span className="data_cont !text-base !font-semibold">
                            {claim?.pended_claims}
                          </span>
                        </div>
                      </div>
                      <div className="eligibility_details_user_data py-2 bg-blue-50">
                        <div className="data_head !text-center text-blue-500">
                          <span>Ready</span>
                        </div>
                        <div className="data_cont !text-center text-blue-500">
                          <span className="data_cont !text-base !font-semibold">
                            {claim?.ready_claims}
                          </span>
                        </div>
                      </div>
                      <div className="eligibility_details_user_data py-2 bg-violet-50">
                        <div className="data_head !text-center text-violet-500">
                          <span>Abnormal</span>
                        </div>
                        <div className="data_cont !text-center text-violet-500">
                          <span className="data_cont !text-base !font-semibold">
                            {claim?.abnormal_claims}
                          </span>
                        </div>
                      </div>
                      <div className="eligibility_details_user_data py-2 bg-orange-50">
                        <div className="data_head !text-center text-orange-500">
                          <span>Cancelled</span>
                        </div>
                        <div className="data_cont !text-center text-orange-500">
                          <span className="data_cont !text-base !font-semibold">
                            {claim?.cancelled_claims}
                          </span>
                        </div>
                      </div>
                      <div className="eligibility_details_user_data py-2 bg-red-50">
                        <div className="data_head !text-center text-red-500">
                          <span>Errored</span>
                        </div>
                        <div className="data_cont !text-center text-red-500">
                          <span className="data_cont !text-base !font-semibold">
                            {claim?.errored_claims}
                          </span>
                        </div>
                      </div>
                      <div className="eligibility_details_user_data py-2 bg-rose-50">
                        <div className="data_head !text-center text-rose-500">
                          <span>Rejected</span>
                        </div>
                        <div className="data_cont !text-center text-rose-500">
                          <span className="data_cont !text-base !font-semibold">
                            {claim?.rejected_claims}
                          </span>
                        </div>
                      </div>
                      <div className="eligibility_details_user_data py-2 bg-amber-50">
                        <div className="data_head !text-center text-amber-500">
                          <span>Validation Error</span>
                        </div>
                        <div className="data_cont !text-center text-amber-500">
                          <span className="data_cont !text-base !font-semibold">
                            {claim?.validation_errors_claims}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </div>

      <ClaimHistoryFilter
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        refetch={refetch}
      />

      <AppModal isOpen={dataLoading} />
    </>
  );
}

export default ClaimHistoryList;
