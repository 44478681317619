import React from "react";

function ResponseDetailsComponent({ item }) {
  const checkEligibility = (el) => {
    if (el?.site_eligibility === "eligible") {
      return "Eligible";
    } else if (el?.site_eligibility === "not-active") {
      return "When HCP sends an eligibility request for an expired or canceled member’s policy on the eligibility service date.";
    } else if (el?.site_eligibility === "not-covered") {
      return "When HCP sends an eligibility request mentioning a department that offers services out of patient policy coverage.";
    } else if (el?.site_eligibility === "not-direct-billing") {
      return "When HCP sends an eligibility request for insured member who is covered on patient reimbursement basis, so even the member has an active policy, but he will pay the medical fees out of his pocket and reimburse the claims. (e.g.: members covered under visit visa insurance policy)";
    } else if (el?.site_eligibility === "out-network") {
      return "Facility is outside the member covered policy network.";
    } else if (el?.site_eligibility === "limit-exhausted") {
      return "When member consumed the annual or benefit limit, benefit limit can be indicated from the department in the eligibility request or from the provider type, e.g. request from received from optical shop for a member consumed the optical benefit.";
    } else if (el?.site_eligibility === "coverage-suspended") {
      return "Insurance coverage can be suspended for any reason (regulation, financial, legal, etc.)";
    } else if (el?.site_eligibility === "provider-contract-suspended") {
      return "Provider can be suspended for any reason (regulation, contractual, legal… etc.)";
    } else {
      // Default case
      return "Unknown eligibility status";
    }
  };

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
      {item?.errors?.map((el, index) => {
        return (
          <div className="sm:col-span-6">
            <div class="p-4 text-sm text-red-800 rounded-lg bg-red-50 dark:text-red-400">
              <span class="font-medium">Error {el?.error_code} : </span>
              {el?.error_message}
            </div>
          </div>
        );
      })}
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Site Eligibility</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.site_eligibility}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Beneficiary Name</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.patient_name}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Document</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.document_id}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Insurer</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.payer_name}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">TPA Name</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.tpa_name}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">outcome</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.outcome}</dd>
      </div>
      {/* MY WORK */}
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Service Date</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.service_date}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">purpose</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.purpose}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Is Referral</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {item?.is_referral ? "Yes" : "No"}
        </dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">
          Eligibility purpose Identifier (Value)
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          {item?.eligibility_response_identifier_value}
        </dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">
          Eligibility purpose Identifier (Url)
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          <a href="">{item?.eligibility_response_identifier_url}</a>
        </dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Is New Born ?</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {item?.is_new_born ? "Yes" : "No"}
        </dd>
      </div>
    </dl>
  );
}

export default ResponseDetailsComponent;
