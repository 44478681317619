import { Button, Chip, Divider, Tooltip } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import SyncOutlined from "@mui/icons-material/SyncOutlined";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import toast from "react-hot-toast";
import { LuTrash2 } from "react-icons/lu";
import AppModal from "../../components/AppModal";
import UploadClaimAttachmentModal from "./components/UploadClaimAttachmentModal";
import UploadClaimModal from "./UploadClaimModal";
import { FileUpload } from "@mui/icons-material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ConformationModal from "../../components/ConformationModal";
import { BiError } from "react-icons/bi";
import ClaimUploadErrorModal from "./ClaimUploadErrorModal";

export default function ClaimUploadList() {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSeelectedItem] = useState(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentReference, setCurrentReference] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  const [showUploadAttachmentModal, setShowUploadAttachmentModal] =
    useState(false);
  const [showUploadClaimModal, setShowUploadClaimModal] = useState(false);
  const [currentUploadData, setCurrentUploadData] = useState(null);

  const getUploadClaimHistory = async () => {
    // Retrieve the token from local storage
    const token = localStorage.getItem("token");
    const tokenWithoutQuotes = token.replace(/"/g, "");

    if (!token) {
      console.error("Token not found in local storage.");
      return;
    }

    try {
      setLoadingFile(true);
      const response = await axios.get(
        "https://api.super-git.com/api/tasks?type=excel upload",
        {
          headers: {
            Authorization: `Bearer ${tokenWithoutQuotes}`,
          },
        }
      );
      setLoadingFile(false);
      setUploadData(response?.data?.data);
      // Handle success response
    } catch (error) {
      setLoadingFile(false);
      console.log(error);
      toast.error(error?.response?.data?.error);
      // Handle error response
    }
  };

  useEffect(() => {
    getUploadClaimHistory();
  }, []);

  const handleprocess = async (claimRefference) => {
    // Retrieve the token from local storage
    const token = localStorage.getItem("token");
    const tokenWithoutQuotes = token.replace(/"/g, "");

    if (!token) {
      console.error("Token not found in local storage.");
      return;
    }

    try {
      setLoadingFile(true);
      const response = await axios.post(
        `https://api.super-git.com/api/claim/excel/process?excel_id=${claimRefference}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokenWithoutQuotes}`,
          },
        }
      );
      setLoadingFile(false);
      toast.success(response?.data?.message);
      // Handle success response
    } catch (error) {
      setLoadingFile(false);
      console.log(error);
      toast.error(error?.response?.data?.error);
      // Handle error response
    }
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("token");
    const tokenWithoutQuotes = token ? token.replace(/"/g, "") : null;

    if (!tokenWithoutQuotes) {
      console.error("Token not found in local storage.");
      toast.error("Authentication token not found.");
      return;
    }

    try {
      setLoadingFile(true);
      const response = await axios.delete(
        `https://api.super-git.com/api/claim/excel/delete?excel_id=${currentReference}`,
        {
          headers: {
            Authorization: `Bearer ${tokenWithoutQuotes}`,
          },
        }
      );
      setShowConfirmModal(false);
      setLoadingFile(false);
      getUploadClaimHistory();
      toast.success(response?.data?.message);
    } catch (error) {
      setLoadingFile(false);
      console.error(error);
      toast.error(error?.response?.data?.error || "An error occurred.");
    }
  };

  const handleOpenModal = (reference) => {
    setCurrentReference(reference);
    setShowConfirmModal(true);
  };

  const handleDownloadClick = () => {
    const fileUrl = "/claimSample.xlsx";

    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "claimSample.xlsx";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // upload Error Modal
  // const handleOpenErrorModal = (reference) => {
  //   setSeelectedItem(reference);
  //   setOpen(true);
  // };
  const handleOpenErrorModal = (el) => {
    setSeelectedItem(el);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="pre_auth_container !p-0">
        <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
          <div className="">
            <Tooltip
              placement="right"
              title="Keep track of your upload Claims"
              className="my-auto !mt-2"
            >
              <span className="font-semibold text-black/70 text-xl ">
                Upload HISTORY
                <span className="italic text-lg ml-1">
                  ({uploadData?.length || 0}{" "}
                  <span className="text-xs font-bold italic">Entries</span>)
                </span>
              </span>
            </Tooltip>
          </div>

          <div className="flex gap-3 flex-col lg:flex-row">
            <Button
              variant="outlined"
              size="small"
              onClick={handleDownloadClick}
              disabled={loadingFile}
              className="!border-primary !text-primary hover:!bg-primary/10"
              startIcon={<FileDownloadOutlinedIcon />}
            >
              Download Sample File
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => setShowUploadClaimModal(true)}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<FileUpload />}
            >
              Upload Excel
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-3">
          {uploadData &&
            uploadData?.map((el) => (
              <div
                key={el.Id}
                className="card shadow-md p-2 rounded-md grid grid-cols-7"
              >
                <div className="eligibility_details_user_data ">
                  <div className="mb-2 pt-1 bg-secondry !rounded-tl-md">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>Care Team</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm !font-semibold">
                        {el?.Stats?.CareTeam || "___"}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>Task Type</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm !font-semibold">
                        {el?.TaskType || "___"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="eligibility_details_user_data ">
                  <div className="mb-2 pt-1 bg-secondry">
                    <div
                      div
                      className="data_head  !text-xs !font-semibold !text-gray-500 !text-center"
                    >
                      <span>Claim Info</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm !font-semibold">
                        {el?.Stats?.ClaimInfo || "___"}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>Reference No</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-base !font-semibold">
                        {el?.ReferenceNo || "___"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="eligibility_details_user_data ">
                  <div className="mb-2 pt-1 bg-secondry">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>Diagnosis</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm !font-semibold">
                        {el?.Stats?.Diagnosis || "___"}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>Created at</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-base !font-semibold">
                        {/* {new Date(el?.CreatedAt)?.toLocaleDateString("sv-SE") || "___"} */}
                        {el?.CreatedAt?.length > 16
                          ? el?.CreatedAt.substring(0, 16).replace("T", " ")
                          : el?.CreatedAt}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="eligibility_details_user_data ">
                  <div className="mb-2 pt-1 bg-secondry">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>Item</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm !font-semibold">
                        {el?.Stats?.Item || "___"}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>Total</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-base !font-semibold">
                        {el?.Total || "0"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="eligibility_details_user_data ">
                  <div className="mb-2 pt-1 bg-secondry">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>PreAuth Details</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm !font-semibold">
                        {el?.Stats?.PreAuthDetails || "___"}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>Completed</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-base !font-semibold">
                        {el?.Completed || "0"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="eligibility_details_user_data ">
                  <div className="mb-2 pt-1 bg-secondry">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>Supporting Info</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm !font-semibold">
                        {el?.Stats?.SupportingInfo || "___"}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>Status</span>
                    </div>
                    {/* <div className="data_cont !text-center">
                      <span className="data_cont !text-base !font-semibold">
                        <Chip
                          label={el?.IsCompleted ? "Complete" : "Incomplete"}
                          color={el?.IsCompleted ? "success" : "error"}
                          size="small"
                          className="uppercase text-xs !text-white"
                        />
                      </span>
                    </div> */}
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-base !font-semibold">
                        <Chip
                          label={
                            el?.Total !== 0 &&
                            el?.Total !== null &&
                            el?.Total === el?.Completed
                              ? "Complete"
                              : "Incomplete"
                          }
                          color={
                            el?.Total !== 0 &&
                            el?.Total !== null &&
                            el?.Total === el?.Completed
                              ? "success"
                              : "error"
                          }
                          size="small"
                          className="uppercase text-xs !text-white"
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="eligibility_details_user_data">
                  <div className="mb-2 pt-1 bg-secondry !rounded-tr-md">
                    <div className="data_head  !text-xs !font-semibold !text-gray-500 !text-center">
                      <span>Attachments</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm !font-semibold">
                        {el?.Attachments || "___"}
                      </span>
                    </div>
                  </div>

                  {/* <div className="flex gap-3 !text-center justify-center items-center pt-1">
                    <Tooltip title="Process">
                      <div
                        onClick={() => handleprocess(el?.ReferenceNo)}
                        className="text-2xl cursor-pointer"
                      >
                        <SyncOutlined />
                      </div>
                    </Tooltip>

                    <Tooltip title="Attachment">
                      <div
                        className="text-2xl cursor-pointer"
                        onClick={() => {
                          setCurrentUploadData(el);
                          setShowUploadAttachmentModal(true);
                        }}
                      >
                        <AttachmentOutlinedIcon />
                      </div>
                    </Tooltip>

                    <Tooltip title="Errors">
                      <div
                        className="text-2xl cursor-pointer !my-auto"
                        // onClick={() => handleOpenModal(el?.ReferenceNo)}    
                      >
                        <BiError />
                      </div>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <div
                        className="text-2xl cursor-pointer !my-auto"
                        onClick={() => handleOpenModal(el?.ReferenceNo)}    
                      >
                        <LuTrash2 />
                      </div>
                    </Tooltip>
                  </div> */}
                  <div className="flex gap-3 !text-center justify-center items-center pt-1">
                    {el.Total !== 0 &&
                    el.Total !== null &&
                    el.Total === el.Completed ? (
                      <>
                        <Tooltip title="Process">
                          <div
                            onClick={() => handleprocess(el?.ReferenceNo)}
                            className="text-2xl cursor-pointer"
                          >
                            <SyncOutlined />
                          </div>
                        </Tooltip>

                        <Tooltip title="Attachment">
                          <div
                            className="text-2xl cursor-pointer"
                            onClick={() => {
                              setCurrentUploadData(el);
                              setShowUploadAttachmentModal(true);
                            }}
                          >
                            <AttachmentOutlinedIcon />
                          </div>
                        </Tooltip>
                      </>
                    ) : null}

                    <Tooltip title="Error Logs">
                      <div
                        className="text-2xl cursor-pointer !my-auto"
                        // onClick={() => handleOpenErrorModal(el?.ReferenceNo)}
                        onClick={() => handleOpenErrorModal(el)}
                      >
                        <BiError />
                      </div>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <div
                        className="text-2xl cursor-pointer !my-auto"
                        onClick={() => handleOpenModal(el?.ReferenceNo)}
                      >
                        <LuTrash2 />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <ConformationModal
        open={showConfirmModal}
        setOpen={setShowConfirmModal}
        onDelete={handleDelete}
        text={"Delete"}
      />

      <UploadClaimAttachmentModal
        showUploadAttachmentModal={showUploadAttachmentModal}
        setShowUploadAttachmentModal={setShowUploadAttachmentModal}
        currentUploadData={currentUploadData}
        setCurrentUploadData={setCurrentUploadData}
      />

      <UploadClaimModal
        showUploadClaimModal={showUploadClaimModal}
        setShowUploadClaimModal={setShowUploadClaimModal}
        getUploadClaimHistory={getUploadClaimHistory}
      />

      <ClaimUploadErrorModal
        setOpen={setOpen}
        open={open}
        handleClose={handleClose}
        selectedItem={selectedItem}
      />

      <AppModal isOpen={loadingFile} />
    </>
  );
}
