import { Tooltip } from "@mui/material";
import React from "react";

function DiagnosisList({ item }) {
  const tableHeaders = [
    { name: "Type" },
    { name: "Code" },
    { name: "Description" },
    { name: "On Admission" },
  ];
  return (
    // <div class="pre_auth_detail_section">
    //   <div class="pre_auth-detail_section_head">
    //     <span>Diagnosis</span>
    //   </div>
    //   <div class="pre_auth-detail_section_cont">
    //     <div class="pre_auth_communication_table">
    //       <table>
    //         <thead>
    //           <tr>
    //             {tableHeaders.map((el, index) => {
    //               return <th key={"tableHeaders_" + index}>{el.name}</th>;
    //             })}
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {item?.getClaimTransaction?.data?.diagnosis?.map((item) => (
    //             <tr key={item.id}>
    //               <td>{item.type}</td>
    //               <td>{item.code}</td>
    //               <td>{item.description}</td>
    //               <td>{item?.on_admission ? "Yes" : "No"}</td>
    //             </tr>
    //           ))}
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>
    // </div>
    <div class="eligibility_details_section !p=0">
      <div className="eligibility_details_body_cont !p-0 !border-none !shadow-none">
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 !p-0 !border-none !shadow-none">
            {item?.getClaimTransaction?.data?.diagnosis?.map((item) => (
              <div
                className="shadow-md rounded-lg p-3 gap-3 flex flex-col border border-black/20"
                key={item.id}
              >
                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    Type
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end capitalize">
                    {item?.type || "___"}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    Code
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                    {item?.code || "___"}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    Description
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                    {item?.description?.length > 100 ? (
                      <Tooltip title={item?.description}>
                        <span>{item?.description?.slice(0, 100)}...</span>
                      </Tooltip>
                    ) : (
                      item?.description
                    )}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    On Admission
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end capitalize">
                    {item?.on_admission ? "Yes" : "No"}
                  </dd>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-cyan-600">
        <tr>
          {tableHeaders.map((el, index) => {
            return (
              <th
                scope="col"
                className="whitespace-nowrap py-1 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-2"
                key={"tableHeaders_" + index}
              >
                {el.name}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {item?.getClaimTransaction?.data?.Diagnosis?.map((item) => (
          <tr key={item.id}>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
              {item.type}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
              {item.code}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
              {item.description}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
              {item?.on_admission ? "Yes" : "No"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DiagnosisList;
