import React, { useEffect, useState } from "react";
import AccordianBenefitDetails from "./AccordianBenefitDetails";

function Accordians({ mainItem }) {
  const [expanded, setExpanded] = useState(false);

  const expandedSVG = (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* SVG content for expanded state */}
    </svg>
  );

  const closedSVG = (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* SVG content for closed state */}
    </svg>
  );

  useEffect(() => {
    const accordions = document.querySelectorAll(".items_data_accordians");

    accordions.forEach((accordion) => {
      const accordionContent = accordion.querySelector(".accordion-content");
      const summary = accordion.querySelector(".custom-summary");

      accordion.addEventListener("click", () => {
        accordionContent.classList.toggle("expanded");
        setExpanded(!expanded);
      });
    });
  }, []);

  return (
    <div class="eligibility_details_section accordians">
      <div class="">
        <div class="eligibility_details_body_cont !p-0 !border-none !shadow-none">
          {mainItem?.coverages?.items?.map((item, key) => {
            return (
              <div class="items_data_accordians">
                <details>
                  <summary
                    class="custom-summary"
                    data-summary="Health Benefit Plan Coverage ( Policy Maximum Annual Limit and Approval limit)"
                  >
                    <span>
                      {item?.category_name ? item?.category_name : ""}(
                      {item?.description ? item?.description : ""})
                    </span>
                    <svg
                      class="expand-icon"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="19" cy="19" r="19" fill="#F5793B" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M26 18H20V12C20 11.447 19.552 11 19 11C18.448 11 18 11.447 18 12V18H12C11.448 18 11 18.447 11 19C11 19.553 11.448 20 12 20H18V26C18 26.553 18.448 27 19 27C19.552 27 20 26.553 20 26V20H26C26.552 20 27 19.553 27 19C27 18.447 26.552 18 26 18Z"
                        fill="white"
                      />
                      <mask
                        id="mask0_90_11610"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="11"
                        y="11"
                        width="16"
                        height="16"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26 18H20V12C20 11.447 19.552 11 19 11C18.448 11 18 11.447 18 12V18H12C11.448 18 11 18.447 11 19C11 19.553 11.448 20 12 20H18V26C18 26.553 18.448 27 19 27C19.552 27 20 26.553 20 26V20H26C26.552 20 27 19.553 27 19C27 18.447 26.552 18 26 18Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_90_11610)"></g>
                    </svg>
                  </summary>
                  <div class="accordion-content !grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
                    <div class="eligibility_details_user_data   px-3 py-6">
                      <div class="data_head !text-center">
                        <span>Item Name</span>
                      </div>
                      <div class="data_cont !text-center">
                        <span className="!text-base !font-semibold">
                          {item?.category_name || "___"}
                        </span>
                      </div>
                    </div>

                    <div class="eligibility_details_user_data   px-3 py-6">
                      <div class="data_head !text-center">
                        <span>Description</span>
                      </div>
                      <div class="data_cont !text-center">
                        <span className="!text-base !font-semibold">
                          {item?.description || "___"}
                        </span>
                      </div>
                    </div>

                    <div class="eligibility_details_user_data   px-3 py-6">
                      <div class="data_head !text-center">
                        <span>Network</span>
                      </div>
                      <div class="data_cont !text-center">
                        <span className="!text-base !font-semibold">
                          {item?.network || "___"}
                        </span>
                      </div>
                    </div>

                    <div class="eligibility_details_user_data   px-3 py-6">
                      <div class="data_head !text-center">
                        <span>Unit</span>
                      </div>
                      <div class="data_cont !text-center">
                        <span className="!text-base !font-semibold">
                          {item?.unit || "___"}
                        </span>
                      </div>
                    </div>

                    <div class="eligibility_details_user_data   px-3 py-6">
                      <div class="data_head !text-center">
                        <span>Term</span>
                      </div>
                      <div class="data_cont !text-center">
                        <span className="!text-base !font-semibold">
                          {item?.term || "___"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="benefits-head text-lg font-semibold">
                    Benefits
                  </div>
                  <AccordianBenefitDetails
                    item={item?.benefits[0] && JSON.parse(item?.benefits[0])}
                  />
                </details>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Accordians;
