import React from "react";
import Modal from "react-modal";
import { useQuery } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";

import BeneficiaryDetailTable from "./components/BeneficiaryDetailTable";
import InsurancePlanTable from "./components/InsurancePlanTable";
import beneficiary from "../../gql/beneficiary";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "95%",
    maxHeight: "90%",
  },
};

function BeneficiaryDetails(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state?.item ? location.state.item : null;

  const { loading, error, data, refetch } = useQuery(
    beneficiary.GET_PATIENT_BY_ID,
    {
      variables: { id: item?._id },
      fetchPolicy: "network-only",
    }
  );

  const onClickEdit = (el) => {
    navigate("/beneficiary/edit/form", { state: { item: item } });
  };

  return (
    <div class="patient-info">
      <div class="patient-top">
        <div class="back" onClick={() => navigate(-1)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
              fill="#4D4D53"
            />
          </svg>
        </div>
        <div>
          <div class="patient-title">
            <span>Patient Information</span>
          </div>
          <div class="patient-content">
            <span>View Patient Information</span>
          </div>
        </div>
      </div>
      <div class="patient-details">
        <div class="patient-details-header">
          <div>
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="34" height="34" rx="8" fill="#F4F6F8" />
              <path
                d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                fill="#F5793B"
              />
              <path
                d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                fill="#F5793B"
              />
              <path
                d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                fill="#F5793B"
              />
            </svg>
          </div>
          <div class="patient-details-heading">
            <span>Patient Details</span>
          </div>
        </div>
        <div class="patient-table-data">
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M16 10C17.1 10 18 10.9 18 12C18 13.1 17.1 14 16 14C14.9 14 14 13.1 14 12C14 10.9 14.9 10 16 10ZM16 20C18.7 20 21.8 21.29 22 22H10C10.23 21.28 13.31 20 16 20ZM16 8C13.79 8 12 9.79 12 12C12 14.21 13.79 16 16 16C18.21 16 20 14.21 20 12C20 9.79 18.21 8 16 8ZM16 18C13.33 18 8 19.34 8 22V24H24V22C24 19.34 18.67 18 16 18Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">First Name</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.first_name
                  ? data?.getPatient?.Data?.first_name
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M16 10C17.1 10 18 10.9 18 12C18 13.1 17.1 14 16 14C14.9 14 14 13.1 14 12C14 10.9 14.9 10 16 10ZM16 20C18.7 20 21.8 21.29 22 22H10C10.23 21.28 13.31 20 16 20ZM16 8C13.79 8 12 9.79 12 12C12 14.21 13.79 16 16 16C18.21 16 20 14.21 20 12C20 9.79 18.21 8 16 8ZM16 18C13.33 18 8 19.34 8 22V24H24V22C24 19.34 18.67 18 16 18Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Second Name</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.middle_name
                  ? data?.getPatient?.Data?.middle_name
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M16 10C17.1 10 18 10.9 18 12C18 13.1 17.1 14 16 14C14.9 14 14 13.1 14 12C14 10.9 14.9 10 16 10ZM16 20C18.7 20 21.8 21.29 22 22H10C10.23 21.28 13.31 20 16 20ZM16 8C13.79 8 12 9.79 12 12C12 14.21 13.79 16 16 16C18.21 16 20 14.21 20 12C20 9.79 18.21 8 16 8ZM16 18C13.33 18 8 19.34 8 22V24H24V22C24 19.34 18.67 18 16 18Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Third Name</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.last_name
                  ? data?.getPatient?.Data?.last_name
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M16 10C17.1 10 18 10.9 18 12C18 13.1 17.1 14 16 14C14.9 14 14 13.1 14 12C14 10.9 14.9 10 16 10ZM16 20C18.7 20 21.8 21.29 22 22H10C10.23 21.28 13.31 20 16 20ZM16 8C13.79 8 12 9.79 12 12C12 14.21 13.79 16 16 16C18.21 16 20 14.21 20 12C20 9.79 18.21 8 16 8ZM16 18C13.33 18 8 19.34 8 22V24H24V22C24 19.34 18.67 18 16 18Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Family Name</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.family_name
                  ? data?.getPatient?.Data?.family_name
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M23 9V23H9V9H23ZM23 7H9C7.89 7 7 7.9 7 9V23C7 24.1 7.89 25 9 25H23C24.1 25 25 24.1 25 23V9C25 7.9 24.1 7 23 7ZM16 16C14.35 16 13 14.65 13 13C13 11.35 14.35 10 16 10C17.65 10 19 11.35 19 13C19 14.65 17.65 16 16 16ZM16 12C15.45 12 15 12.45 15 13C15 13.55 15.45 14 16 14C16.55 14 17 13.55 17 13C17 12.45 16.55 12 16 12ZM22 22H10V20.47C10 17.97 13.97 16.89 16 16.89C18.03 16.89 22 17.97 22 20.47V22ZM12.31 20H19.69C19 19.44 17.31 18.88 16 18.88C14.69 18.88 12.99 19.44 12.31 20Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Full Name</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.full_name
                  ? data?.getPatient?.Data?.full_name
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M25 9H27V23H25V9ZM21 9H23V23H21V9ZM18 9H6C5.45 9 5 9.45 5 10V22C5 22.55 5.45 23 6 23H18C18.55 23 19 22.55 19 22V10C19 9.45 18.55 9 18 9ZM17 21H7V11H17V21Z"
                  fill="#387EE7"
                />
                <path
                  d="M12 15.89C13.077 15.89 13.95 15.017 13.95 13.94C13.95 12.863 13.077 11.99 12 11.99C10.923 11.99 10.05 12.863 10.05 13.94C10.05 15.017 10.923 15.89 12 15.89Z"
                  fill="#387EE7"
                />
                <path
                  d="M15.89 19.35C15.89 18.05 13.3 17.4 12 17.4C10.7 17.4 8.11 18.05 8.11 19.35V20H15.89V19.35Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Beneficiary File Id</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.beneficiary_file_id
                  ? data?.getPatient?.Data?.beneficiary_file_id
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M23 8H22V6H20V8H12V6H10V8H9C7.89 8 7.01 8.9 7.01 10L7 24C7 25.1 7.89 26 9 26H23C24.1 26 25 25.1 25 24V10C25 8.9 24.1 8 23 8ZM23 24H9V14H23V24ZM23 12H9V10H23V12ZM16 17H21V22H16V17Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">DOB</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.dob
                  ? data?.getPatient?.Data?.dob
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M25 6.5H7C5.9 6.5 5 7.4 5 8.5V16.5H7V8.5H25V24.5C26.1 24.5 27 23.6 27 22.5V8.5C27 7.4 26.1 6.5 25 6.5Z"
                  fill="#387EE7"
                />
                <path
                  d="M17 13.5C17 11.29 15.21 9.5 13 9.5C10.79 9.5 9 11.29 9 13.5C9 15.71 10.79 17.5 13 17.5C15.21 17.5 17 15.71 17 13.5ZM11 13.5C11 12.4 11.9 11.5 13 11.5C14.1 11.5 15 12.4 15 13.5C15 14.6 14.1 15.5 13 15.5C11.9 15.5 11 14.6 11 13.5Z"
                  fill="#387EE7"
                />
                <path
                  d="M19.39 20.06C17.71 19.2 15.53 18.5 13 18.5C10.47 18.5 8.29 19.2 6.61 20.06C5.61 20.57 5 21.6 5 22.72V25.5H21V22.72C21 21.6 20.39 20.57 19.39 20.06ZM19 23.5H7C7 22.78 6.9 22.16 7.52 21.84C8.71 21.23 10.63 20.5 13 20.5C15.37 20.5 17.29 21.23 18.48 21.84C19.11 22.16 19 22.79 19 23.5Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">E-Health-Id</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.eHealth_id
                  ? data?.getPatient?.Data?.eHealth_id
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M26 7H6C4.9 7 4 7.9 4 9V23C4 24.1 4.9 25 6 25H26C27.1 25 27.99 24.1 27.99 23L28 9C28 7.9 27.1 7 26 7ZM26 23H6V9H26V23ZM25 10H18V15H25V10ZM24 12L21.5 13.75L19 12V11L21.5 12.75L24 11V12ZM13 16C14.65 16 16 14.65 16 13C16 11.35 14.65 10 13 10C11.35 10 10 11.35 10 13C10 14.65 11.35 16 13 16ZM13 12C13.55 12 14 12.45 14 13C14 13.55 13.55 14 13 14C12.45 14 12 13.55 12 13C12 12.45 12.45 12 13 12ZM19 20.59C19 18.09 15.03 17.01 13 17.01C10.97 17.01 7 18.09 7 20.59V22H19V20.59ZM9.48 20C10.22 19.5 11.7 19 13 19C14.3 19 15.77 19.49 16.52 20H9.48Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Nationality</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.nationality
                  ? data?.getPatient?.Data?.nationality
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M24 8H8C6.9 8 6 8.9 6 10V22C6 23.1 6.9 24 8 24H24C25.1 24 26 23.1 26 22V10C26 8.9 25.1 8 24 8ZM24 22H8V10H24V22ZM8 4H24V6H8V4ZM8 26H24V28H8V26ZM16 16C17.38 16 18.5 14.88 18.5 13.5C18.5 12.12 17.38 11 16 11C14.62 11 13.5 12.12 13.5 13.5C13.5 14.88 14.62 16 16 16ZM16 12.5C16.55 12.5 17 12.95 17 13.5C17 14.05 16.55 14.5 16 14.5C15.45 14.5 15 14.05 15 13.5C15 12.95 15.45 12.5 16 12.5ZM21 19.99C21 17.9 17.69 17 16 17C14.31 17 11 17.9 11 19.99V21H21V19.99ZM12.81 19.5C13.42 18.98 14.84 18.5 16 18.5C17.17 18.5 18.59 18.98 19.2 19.5H12.81Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Residency Type</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.residency_type
                  ? data?.getPatient?.Data?.residency_type
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M26 10C26 8.9 25.1 8 24 8H8C6.9 8 6 8.9 6 10V22C6 23.1 6.9 24 8 24H24C25.1 24 26 23.1 26 22V10ZM24 10L16 15L8 10H24ZM24 22H8V12L16 17L24 12V22Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Email</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.email
                  ? data?.getPatient?.Data?.email
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M23 5.5H9C7.89 5.5 7 6.4 7 7.5V21.5C7 22.6 7.89 23.5 9 23.5H13L16 26.5L19 23.5H23C24.1 23.5 25 22.6 25 21.5V7.5C25 6.4 24.1 5.5 23 5.5ZM23 21.5H18.17L16 23.67L13.83 21.5H9V7.5H23V21.5ZM16 14.5C17.65 14.5 19 13.15 19 11.5C19 9.85 17.65 8.5 16 8.5C14.35 8.5 13 9.85 13 11.5C13 13.15 14.35 14.5 16 14.5ZM16 10.5C16.55 10.5 17 10.95 17 11.5C17 12.05 16.55 12.5 16 12.5C15.45 12.5 15 12.05 15 11.5C15 10.95 15.45 10.5 16 10.5ZM22 19.08C22 16.58 18.03 15.5 16 15.5C13.97 15.5 10 16.58 10 19.08V20.5H22V19.08ZM12.48 18.5C13.22 17.99 14.71 17.5 16 17.5C17.29 17.5 18.78 17.99 19.52 18.5H12.48Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Nphies Id</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.nphies_id
                  ? data?.getPatient?.Data?.nphies_id
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M18.17 9L23 13.83V23H9V9H18.17ZM18.17 7H9C7.9 7 7 7.9 7 9V23C7 24.1 7.9 25 9 25H23C24.1 25 25 24.1 25 23V13.83C25 13.3 24.79 12.79 24.41 12.42L19.58 7.59C19.21 7.21 18.7 7 18.17 7ZM11 19H21V21H11V19ZM11 15H21V17H11V15ZM11 11H18V13H11V11Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Document Type</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.document_type
                  ? data?.getPatient?.Data?.document_type
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M23 8H18.82C18.4 6.84 17.3 6 16 6C14.7 6 13.6 6.84 13.18 8H9C7.9 8 7 8.9 7 10V24C7 25.1 7.9 26 9 26H23C24.1 26 25 25.1 25 24V10C25 8.9 24.1 8 23 8ZM16 7.75C16.22 7.75 16.41 7.85 16.55 8C16.67 8.13 16.75 8.31 16.75 8.5C16.75 8.91 16.41 9.25 16 9.25C15.59 9.25 15.25 8.91 15.25 8.5C15.25 8.31 15.33 8.13 15.45 8C15.59 7.85 15.78 7.75 16 7.75ZM23 24H9V10H23V24ZM16 11C14.35 11 13 12.35 13 14C13 15.65 14.35 17 16 17C17.65 17 19 15.65 19 14C19 12.35 17.65 11 16 11ZM16 15C15.45 15 15 14.55 15 14C15 13.45 15.45 13 16 13C16.55 13 17 13.45 17 14C17 14.55 16.55 15 16 15ZM10 21.47V23H22V21.47C22 18.97 18.03 17.89 16 17.89C13.97 17.89 10 18.96 10 21.47ZM12.31 21C13 20.44 14.69 19.88 16 19.88C17.31 19.88 19.01 20.44 19.69 21H12.31Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Document ID</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.document_id
                  ? data?.getPatient?.Data?.document_id
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M10.54 9C10.6 9.89 10.75 10.76 10.99 11.59L9.79 12.79C9.38 11.59 9.12 10.32 9.03 9H10.54ZM20.4 21.02C21.25 21.26 22.12 21.41 23 21.47V22.96C21.68 22.87 20.41 22.61 19.2 22.21L20.4 21.02ZM11.5 7H8C7.45 7 7 7.45 7 8C7 17.39 14.61 25 24 25C24.55 25 25 24.55 25 24V20.51C25 19.96 24.55 19.51 24 19.51C22.76 19.51 21.55 19.31 20.43 18.94C20.33 18.9 20.22 18.89 20.12 18.89C19.86 18.89 19.61 18.99 19.41 19.18L17.21 21.38C14.38 19.93 12.06 17.62 10.62 14.79L12.82 12.59C13.1 12.31 13.18 11.92 13.07 11.57C12.7 10.45 12.5 9.25 12.5 8C12.5 7.45 12.05 7 11.5 7Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Contact Number</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.contact_number
                  ? data?.getPatient?.Data?.contact_number
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M16 12C17.93 12 19.5 13.57 19.5 15.5C19.5 17.43 17.93 19 16 19C14.07 19 12.5 17.43 12.5 15.5C12.5 13.57 14.07 12 16 12ZM20.53 12.38L24.5 8.42V11H26.5V5H20.5V7H23.08L19.11 10.97C18.23 10.36 17.16 10 16 10C14.84 10 13.77 10.36 12.89 10.97L12.24 10.32L13.65 8.91L12.24 7.49L10.82 8.9L8.92 7H11.5V5H5.5V11H7.5V8.42L9.41 10.32L7.99 11.74L9.4 13.15L10.81 11.74L11.46 12.39C10.86 13.27 10.5 14.34 10.5 15.5C10.5 18.2 12.44 20.44 15 20.91V23H13V25H15V27H17V25H19V23H17V20.91C19.56 20.44 21.5 18.2 21.5 15.5C21.5 14.34 21.14 13.27 20.53 12.38Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Marital Status</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.martial_status
                  ? data?.getPatient?.Data?.martial_status
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M16 12C17.93 12 19.5 13.57 19.5 15.5C19.5 17.43 17.93 19 16 19C14.07 19 12.5 17.43 12.5 15.5C12.5 13.57 14.07 12 16 12ZM20.53 12.38L24.5 8.42V11H26.5V5H20.5V7H23.08L19.11 10.97C18.23 10.36 17.16 10 16 10C14.84 10 13.77 10.36 12.89 10.97L12.24 10.32L13.65 8.91L12.24 7.49L10.82 8.9L8.92 7H11.5V5H5.5V11H7.5V8.42L9.41 10.32L7.99 11.74L9.4 13.15L10.81 11.74L11.46 12.39C10.86 13.27 10.5 14.34 10.5 15.5C10.5 18.2 12.44 20.44 15 20.91V23H13V25H15V27H17V25H19V23H17V20.91C19.56 20.44 21.5 18.2 21.5 15.5C21.5 14.34 21.14 13.27 20.53 12.38Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Gender</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.gender
                  ? data?.getPatient?.Data?.gender
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path d="M19 20H13V22H19V20Z" fill="#387EE7" />
                <path
                  d="M17 13H15V15H13V17H15V19H17V17H19V15H17V13Z"
                  fill="#387EE7"
                />
                <path
                  d="M16 6C10.67 10.55 8 14.48 8 17.8C8 22.78 11.8 26 16 26C20.2 26 24 22.78 24 17.8C24 14.48 21.33 10.55 16 6ZM16 24C12.65 24 10 21.43 10 17.8C10 15.46 11.95 12.36 16 8.66C20.05 12.36 22 15.45 22 17.8C22 21.43 19.35 24 16 24Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Blood Group</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.blood_group
                  ? data?.getPatient?.Data?.blood_group
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M10.54 9C10.6 9.89 10.75 10.76 10.99 11.59L9.79 12.79C9.38 11.59 9.12 10.32 9.03 9H10.54ZM20.4 21.02C21.25 21.26 22.12 21.41 23 21.47V22.96C21.68 22.87 20.41 22.61 19.2 22.21L20.4 21.02ZM11.5 7H8C7.45 7 7 7.45 7 8C7 17.39 14.61 25 24 25C24.55 25 25 24.55 25 24V20.51C25 19.96 24.55 19.51 24 19.51C22.76 19.51 21.55 19.31 20.43 18.94C20.33 18.9 20.22 18.89 20.12 18.89C19.86 18.89 19.61 18.99 19.41 19.18L17.21 21.38C14.38 19.93 12.06 17.62 10.62 14.79L12.82 12.59C13.1 12.31 13.18 11.92 13.07 11.57C12.7 10.45 12.5 9.25 12.5 8C12.5 7.45 12.05 7 11.5 7Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Emergency Ph No</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.emergency_number
                  ? data?.getPatient?.Data?.emergency_number
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="patient-address-details">
        <div class="patient-address-details-header">
          <div>
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="34" height="34" rx="8" fill="#F4F6F8" />
              <path
                d="M17 17C15.9 17 15 16.1 15 15C15 13.9 15.9 13 17 13C18.1 13 19 13.9 19 15C19 16.1 18.1 17 17 17ZM23 15.2C23 11.57 20.35 9 17 9C13.65 9 11 11.57 11 15.2C11 17.54 12.95 20.64 17 24.34C21.05 20.64 23 17.54 23 15.2ZM17 7C21.2 7 25 10.22 25 15.2C25 18.52 22.33 22.45 17 27C11.67 22.45 9 18.52 9 15.2C9 10.22 12.8 7 17 7Z"
                fill="#F5793B"
              />
            </svg>
          </div>
          <div class="patient-address-details-heading">
            <span>Address</span>
          </div>
        </div>
        <div class="patient-address-table-data">
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M17 22.5H15V20.5H17V22.5ZM19 18.5H13V24.5H19V18.5ZM23 13.8V8.5H20V11.1L16 7.5L6 16.5H9L16 10.19L23 16.5H26L23 13.8Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Address Line</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.address_line
                  ? data?.getPatient?.Data?.address_line
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M17 14H22L25 11L22 8H17V6H15V8H8V14H15V16H10L7 19L10 22H15V26H17V22H24V16H17V14ZM10 10H21.17L22.17 11L21.17 12H10V10ZM22 20H10.83L9.83 19L10.83 18H22V20Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Street Line</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.street_line
                  ? data?.getPatient?.Data?.street_line
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M21 15V7H11V11H7V25H15V21H17V25H25V15H21ZM11 23H9V21H11V23ZM11 19H9V17H11V19ZM11 15H9V13H11V15ZM15 19H13V17H15V19ZM15 15H13V13H15V15ZM15 11H13V9H15V11ZM19 19H17V17H19V19ZM19 15H17V13H19V15ZM19 11H17V9H19V11ZM23 23H21V21H23V23ZM23 19H21V17H23V19Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">City</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.city
                  ? data?.getPatient?.Data?.city
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M21 19H23V21H21V19ZM21 15H23V17H21V15ZM21 11H23V13H21V11ZM17.74 11L19 11.84V11H17.74Z"
                  fill="#387EE7"
                />
                <path
                  d="M14 7V8.51L16 9.84V9H25V23H21V25H27V7H14Z"
                  fill="#387EE7"
                />
                <path
                  d="M12.17 9.7L19 14.25V25H5V14.48L12.17 9.7ZM14 23H17V15.16L12.17 12.09L7 15.38V23H10V17H14V23Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">State</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.state
                  ? data?.getPatient?.Data?.state
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M15.99 6C10.47 6 6 10.48 6 16C6 21.52 10.47 26 15.99 26C21.52 26 26 21.52 26 16C26 10.48 21.52 6 15.99 6ZM22.92 12H19.97C19.65 10.75 19.19 9.55 18.59 8.44C20.43 9.07 21.96 10.35 22.92 12ZM16 8.04C16.83 9.24 17.48 10.57 17.91 12H14.09C14.52 10.57 15.17 9.24 16 8.04ZM8.26 18C8.1 17.36 8 16.69 8 16C8 15.31 8.1 14.64 8.26 14H11.64C11.56 14.66 11.5 15.32 11.5 16C11.5 16.68 11.56 17.34 11.64 18H8.26ZM9.08 20H12.03C12.35 21.25 12.81 22.45 13.41 23.56C11.57 22.93 10.04 21.66 9.08 20ZM12.03 12H9.08C10.04 10.34 11.57 9.07 13.41 8.44C12.81 9.55 12.35 10.75 12.03 12ZM16 23.96C15.17 22.76 14.52 21.43 14.09 20H17.91C17.48 21.43 16.83 22.76 16 23.96ZM18.34 18H13.66C13.57 17.34 13.5 16.68 13.5 16C13.5 15.32 13.57 14.65 13.66 14H18.34C18.43 14.65 18.5 15.32 18.5 16C18.5 16.68 18.43 17.34 18.34 18ZM18.59 23.56C19.19 22.45 19.65 21.25 19.97 20H22.92C21.96 21.65 20.43 22.93 18.59 23.56ZM20.36 18C20.44 17.34 20.5 16.68 20.5 16C20.5 15.32 20.44 14.66 20.36 14H23.74C23.9 14.64 24 15.31 24 16C24 16.69 23.9 17.36 23.74 18H20.36Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Country</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.country
                  ? data?.getPatient?.Data?.country
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M24 8H8C6.9 8 6 8.9 6 10V22C6 23.1 6.9 24 8 24H24C25.1 24 26 23.1 26 22V10C26 8.9 25.1 8 24 8ZM24 22H8V10H24V22Z"
                  fill="#387EE7"
                />
                <path
                  d="M10.49 14.5V19H11.64V13H10.77L9.01 14.27L9.59 15.16L10.49 14.5Z"
                  fill="#387EE7"
                />
                <path
                  d="M15.47 14.05C15.97 14.05 16.28 14.37 16.28 14.77C16.28 15.14 16.14 15.41 15.74 15.83C15.38 16.21 14.68 16.91 13.61 17.98V19H17.5V18.01H15.13L15.1 17.96C15.78 17.28 16.25 16.82 16.5 16.57C17.11 15.97 17.42 15.35 17.42 14.71C17.42 14.47 17.37 13.67 16.51 13.23C16.04 13 15.25 12.87 14.56 13.2C13.74 13.59 13.57 14.33 13.56 14.35L14.57 14.77C14.67 14.44 14.95 14.05 15.47 14.05Z"
                  fill="#387EE7"
                />
                <path
                  d="M20.99 17.94C20.16 17.94 20 17.18 19.97 17.08L18.94 17.49C19.39 19.08 20.95 19 20.99 19C22.19 19 22.67 18.28 22.75 18.15C23.07 17.66 23.11 16.91 22.74 16.39C22.57 16.15 22.34 15.98 22.06 15.87V15.8C22.26 15.7 22.43 15.54 22.58 15.32C22.84 14.91 22.89 14.25 22.56 13.75C22.48 13.64 22.03 13 20.94 13C19.68 13 19.2 13.9 19.09 14.24L20.08 14.65C20.19 14.33 20.43 14.01 20.93 14.01C21.37 14.01 21.68 14.27 21.68 14.66C21.68 15.24 21.13 15.38 20.8 15.38H20.34V16.38H20.84C21.4 16.38 21.88 16.62 21.88 17.17C21.88 17.66 21.4 17.94 20.99 17.94Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Postal Code</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.postal_code
                  ? data?.getPatient?.Data?.postal_code
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="patient-insurance-details">
        <div class="patient-insurance-details-header">
          <div>
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="34" height="34" rx="8" fill="#F4F6F8" />
              <path
                d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                fill="#F5793B"
              />
              <path
                d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                fill="#F5793B"
              />
              <path
                d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                fill="#F5793B"
              />
            </svg>
          </div>
          <div class="patient-insurance-details-heading">
            <span>Insurance Plan</span>
          </div>
        </div>
        <div class="patient-insurance-table-data">
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M16 10C17.1 10 18 10.9 18 12C18 13.1 17.1 14 16 14C14.9 14 14 13.1 14 12C14 10.9 14.9 10 16 10ZM16 20C18.7 20 21.8 21.29 22 22H10C10.23 21.28 13.31 20 16 20ZM16 8C13.79 8 12 9.79 12 12C12 14.21 13.79 16 16 16C18.21 16 20 14.21 20 12C20 9.79 18.21 8 16 8ZM16 18C13.33 18 8 19.34 8 22V24H24V22C24 19.34 18.67 18 16 18Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Primary</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.insurance_plans?.[0]
                  ? data?.getPatient?.Data?.insurance_plans?.[0]?.is_primary
                    ? "Yes"
                    : "No"
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M14.5 17H12V14H14.5V11.5H17.5V14H20V17H17.5V19.5H14.5V17ZM16 6L8 9V15.09C8 20.14 11.41 24.85 16 26C20.59 24.85 24 20.14 24 15.09V9L16 6ZM22 15.09C22 19.09 19.45 22.79 16 23.92C12.55 22.79 10 19.1 10 15.09V10.39L16 8.14L22 10.39V15.09Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Payer</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.insurance_plans?.[0]?.payer
                  ? data?.getPatient?.Data?.insurance_plans?.[0]?.payer
                      ?.name_en
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M23 8H22V6H20V8H12V6H10V8H9C7.89 8 7.01 8.9 7.01 10L7 24C7 25.1 7.89 26 9 26H23C24.1 26 25 25.1 25 24V10C25 8.9 24.1 8 23 8ZM23 24H9V14H23V24ZM13 18H11V16H13V18ZM17 18H15V16H17V18ZM21 18H19V16H21V18ZM13 22H11V20H13V22ZM17 22H15V20H17V22ZM21 22H19V20H21V22Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Expiry Date</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.insurance_plans?.[0]?.expiry_date
                  ? data?.getPatient?.Data?.insurance_plans?.[0]?.expiry_date
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M25 6.5H7C5.9 6.5 5 7.4 5 8.5V16.5H7V8.5H25V24.5C26.1 24.5 27 23.6 27 22.5V8.5C27 7.4 26.1 6.5 25 6.5Z"
                  fill="#387EE7"
                />
                <path
                  d="M17 13.5C17 11.29 15.21 9.5 13 9.5C10.79 9.5 9 11.29 9 13.5C9 15.71 10.79 17.5 13 17.5C15.21 17.5 17 15.71 17 13.5ZM11 13.5C11 12.4 11.9 11.5 13 11.5C14.1 11.5 15 12.4 15 13.5C15 14.6 14.1 15.5 13 15.5C11.9 15.5 11 14.6 11 13.5Z"
                  fill="#387EE7"
                />
                <path
                  d="M19.39 20.06C17.71 19.2 15.53 18.5 13 18.5C10.47 18.5 8.29 19.2 6.61 20.06C5.61 20.57 5 21.6 5 22.72V25.5H21V22.72C21 21.6 20.39 20.57 19.39 20.06ZM19 23.5H7C7 22.78 6.9 22.16 7.52 21.84C8.71 21.23 10.63 20.5 13 20.5C15.37 20.5 17.29 21.23 18.48 21.84C19.11 22.16 19 22.79 19 23.5Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Member Card ID</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.insurance_plans?.[0]?.member_card_id
                  ? data?.getPatient?.Data?.insurance_plans?.[0]
                      ?.member_card_id
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M23 9V23H9V9H23ZM23 7H9C7.89 7 7 7.9 7 9V23C7 24.1 7.89 25 9 25H23C24.1 25 25 24.1 25 23V9C25 7.9 24.1 7 23 7ZM16 16C14.35 16 13 14.65 13 13C13 11.35 14.35 10 16 10C17.65 10 19 11.35 19 13C19 14.65 17.65 16 16 16ZM16 12C15.45 12 15 12.45 15 13C15 13.55 15.45 14 16 14C16.55 14 17 13.55 17 13C17 12.45 16.55 12 16 12ZM22 22H10V20.47C10 17.97 13.97 16.89 16 16.89C18.03 16.89 22 17.97 22 20.47V22ZM12.31 20H19.69C19 19.44 17.31 18.88 16 18.88C14.69 18.88 12.99 19.44 12.31 20Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Policy Number</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.insurance_plans?.[0]?.policy_number
                  ? data?.getPatient?.Data?.insurance_plans?.[0]
                      ?.policy_number
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M17.5 13H6.5V15H17.5V13ZM17.5 9H6.5V11H17.5V9ZM21.5 17V13H19.5V17H15.5V19H19.5V23H21.5V19H25.5V17H21.5ZM6.5 19H13.5V17H6.5V19Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Patient Share</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.insurance_plans?.[0]?.patient_share
                  ? data?.getPatient?.Data?.insurance_plans?.[0]
                      ?.patient_share
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M17 26H25V19H22V15H17V13H20V6H12V13H15V15H10V19H7V26H15V19H12V17H20V19H17V26ZM14 11V8H18V11H14ZM13 21V24H9V21H13ZM23 21V24H19V21H23Z"
                  fill="#387EE7"
                />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">max_limit</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.insurance_plans?.[0]?.max_limit
                  ? data?.getPatient?.Data?.insurance_plans?.[0]?.max_limit
                  : "-"}
              </div>
            </div>
          </div>
          <div class="data-boxes">
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="4" fill="#AFCBF5" />
                <path
                  d="M24 8H8C6.9 8 6 8.9 6 10V22C6 23.1 6.9 24 8 24H24C25.1 24 26 23.1 26 22V10C26 8.9 25.1 8 24 8ZM24 22H8V10H24V22Z"
                  fill="#387EE7"
                />
                <path d="M15.5 11.5H11.5V15.5H15.5V11.5Z" fill="#387EE7" />
                <path d="M20.5 11.5H16.5V15.5H20.5V11.5Z" fill="#387EE7" />
                <path d="M15.5 16.5H11.5V20.5H15.5V16.5Z" fill="#387EE7" />
                <path d="M20.5 16.5H16.5V20.5H20.5V16.5Z" fill="#387EE7" />
              </svg>
            </div>
            <div class="patient-infoo">
              <div class="data-names">Relation with Subscriber</div>
              <div class="patient-data">
                {" "}
                {data?.getPatient?.Data?.insurance_plans?.[0]
                  ?.relation_with_subscriber
                  ? data?.getPatient?.Data?.insurance_plans?.[0]
                      ?.relation_with_subscriber
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeneficiaryDetails;
