import { Chip } from "@mui/material";
import React from "react";

function ResponseDetailsComponent({ item }) {
  function formatDateTime(data) {
    const date = new Date(data);
    // Get date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding padding if needed
    const day = String(date.getDate()).padStart(2, "0"); // Adding padding if needed

    // Get time components
    const hours = String(date.getHours()).padStart(2, "0"); // Adding padding if needed
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Adding padding if needed
    const seconds = String(date.getSeconds()).padStart(2, "0"); // Adding padding if needed

    // Construct the date and time string in the format "YYYY-MM-DD HH:MM:SS"
    const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return dateTimeString;
  }
  const getColor = (status) => {
    if (status === "rejected") {
      return "error";
    } else if (status === "approved") {
      return "success";
    } else if (status === "pending") {
      return "info";
    } else if (status === "queued") {
      return "warning";
    } else if (status === "complete") {
      return "success";
    } else if (status === "error") {
      return "error";
    } else if (status === "Abnormal Request") {
      return "error";
    } else if (status === "not-required") {
      return "warning";
    } else {
      return "secondary";
    }
  };
  return (
    <div class="eligibility_details_section">
      <div class="eligibility_details_body_cont !grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 !p-0 !border-none !shadow-none">
        <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Req. Bundle Id</span>
          </div>
          <div class="data_cont !text-center">
            <span className="!text-base !font-semibold">
              {item?.request_bundle_id || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Beneficiary Name</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold ">
              {item?.patient_name || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Document</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold ">
              {item?.patient_document_id || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Insurer</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.payer_name || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>TPA Name</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.tpa_name || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Is Referral</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.is_referral ? "Yes" : "No" || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Eligibility Response Identifier (Value)</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.verification_resp_ident_value || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Eligibility Response Identifier (Url)</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.verification_resp_ident_url || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Is New Born ?</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.is_new_born ? "Yes" : "No" || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Transaction Date</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {formatDateTime(item?.transaction_date) || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Date Ordered</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {formatDateTime(item?.date_ordered) || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Type</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.type || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Sub Type</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.subtype || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Pre Auth Ref. No.</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.pre_auth_ref_no || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Pre Auth Res. Identifier</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.pre_auth_ref_value || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Pre Auth Res. Url</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.pre_auth_ref_url || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Pre Auth Period</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.pre_auth_period || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Related Pre Auth Request Identifier</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.related_pre_auth_req_identifier || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Offline Eligibility Id</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.offline_verification_id || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Offline Eligibility Date</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.offline_verification_date || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Disposition</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.disposition || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b  px-1.5 pt-1.5 ">
          <div class="data_head !text-center">
            <span>Cancellation Status</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.calcellation_status || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b  px-1.5 pt-1.5 ">
          <div class="data_head !text-center">
            <span>Cancellation Status Reason</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.calcellation_status_reason || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data px-1.5 pt-1.5 ">
          <div class="data_head !text-center">
            <span>Related Pre Auth Ref. No.</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.related_pre_auth_ref_no || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data px-1.5 pt-1.5 ">
          <div class="data_head !text-center">
            <span>Is Follow Up</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.is_followup_req ? "Yes" : "No" || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data px-1.5 pt-1.5 ">
          <div class="data_head !text-center">
            <span>Ref Transaction ID </span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.followup_ref_transaction_id || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data  px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Outcome</span>
          </div>
          <div class=" !text-center">
            <p class=" !text-base !font-semibold">
              {item?.outcome ? (
                <Chip
                  label={item?.outcome}
                  color={getColor(item?.outcome)}
                  size="small"
                  className="uppercase text-xs "
                />
              ) : (
                "___"
              )}
            </p>
          </div>
        </div>
        <div class="eligibility_details_user_data  px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Status</span>
          </div>
          <div class=" !text-center">
            <p class=" !text-base !font-semibold">
              {item?.status ? (
                <Chip
                  label={item?.status}
                  color={getColor(item?.status)}
                  size="small"
                  className="uppercase text-xs !text-white"
                />
              ) : (
                "___"
              )}
            </p>
          </div>
        </div>
      </div>

      {/* <div class="eligibility_details_head">
        <div class="eligibility_details_head_cont">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
            <path
              d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
              fill="white"
            ></path>
            <path
              d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
              fill="white"
            ></path>
            <path
              d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
              fill="white"
            ></path>
          </svg>
          <span>Details</span>
        </div>
        <div class="eligibility_details_body_cont !grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
          <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>Req. Bundle Id</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.request_bundle_id || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>Beneficiary Name</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold ">
                {item?.patient_name || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>Document</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold ">
                {item?.patient_document_id || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>Insurer</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.payer_name || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>TPA Name</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.tpa_name || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>Outcome</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.outcome || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>Is Referral</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.is_referral ? "Yes" : "No" || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>Eligibility Response Identifier (Value)</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.verification_resp_ident_value || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>Eligibility Response Identifier (Url)</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.verification_resp_ident_url || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>Is New Born ?</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.is_new_born ? "Yes" : "No" || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>Transaction Date</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {formatDateTime(item?.transaction_date) || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
            <div class="data_head !text-center">
              <span>Date Ordered</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {formatDateTime(item?.date_ordered) || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Status</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.status || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Type</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.type || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Sub Type</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.subtype || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Insurer</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.payer_name || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Pre Auth Ref. No.</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.pre_auth_ref_no || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Pre Auth Res. Identifier</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.pre_auth_ref_value || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Pre Auth Res. Url</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.pre_auth_ref_url || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Pre Auth Period</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.pre_auth_period || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Related Pre Auth Request Identifier</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.related_pre_auth_req_identifier || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Offline Eligibility Id</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.offline_verification_id || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Offline Eligibility Date</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.offline_verification_date || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
            <div class="data_head !text-center">
              <span>Disposition</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.disposition || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data  px-1.5 pt-1.5 ">
            <div class="data_head !text-center">
              <span>Cancellation Status</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.calcellation_status || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data  px-1.5 pt-1.5 ">
            <div class="data_head !text-center">
              <span>Cancellation Status Reason</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.calcellation_status_reason || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data px-1.5 pt-1.5 ">
            <div class="data_head !text-center">
              <span>Related Pre Auth Ref. No.</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.related_pre_auth_ref_no || "___"}
              </span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ResponseDetailsComponent;
