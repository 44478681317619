import React, { useState, useEffect, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { useMutation, useQuery } from "@apollo/client";
// import { toast } from "react-toastify";
import toast from "react-hot-toast";

import Filter from "./components/PreAuthFilter";
import PreAuthResponseModal from "./components/PreAuthResponseModal";
import gqlPreAuth from "../../gql/preAuth";
import DownloadExcel from "../../components/DownloadExcel";
import { appContext } from "../../context/ContextProvider";
import Pagination from "../../components/Pagination";
import PollBtn from "../../components/PollBtn";

import "./styles/pre-auth.css";
import "../Beneficiary/styles/BeneficiaryList.css";
import ConformationModal from "../../components/ConformationModal";
import AppModal from "../../components/AppModal";
import PreAuthJsonViewModal from "./PreAuthJsonViewModal";
import { BiSolidFileJson } from "react-icons/bi";
import { Badge, Chip, Tooltip } from "@mui/material";
import { TbEditCircle } from "react-icons/tb";
import { MdArrowCircleUp, MdOutlineRemoveRedEye, MdSync } from "react-icons/md";
import { BsFiletypeJson } from "react-icons/bs";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ConvertPreAuthToClaimModal from "./ConvertPreAuthToClaimModal";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { RiMessage2Line } from "react-icons/ri";
import claims from "../../gql/claims";
import { FaArrowUpFromBracket, FaRegCircleCheck } from "react-icons/fa6";

function PreAuthList(props) {
  const { setShowLoader } = useContext(appContext);
  const navigate = useNavigate();
  const currentDate = new Date();
  const [loading, setLoading] = useState(false);

  const [excelData, setExcelData] = useState([]);

  const [item, setItem] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPreAuthResponseModal, setShowPreAuthResponseModal] =
    useState(false);
  const [showConvertToClaimModal, setShowConvertToClaimModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSeelectedItem] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [fromDate, setFromDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-01`
  );
  const [toDate, setToDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate()}`
  );

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(
    +JSON?.parse(localStorage?.getItem("preAuthPageNo")) || 1
  );

  const [refetchFilterVariables, setRefetchFilterVariables] = useState({
    limit: limit,
    from_date: fromDate,
    to_date: toDate,
  });

  const {
    loading: dataLoading,
    error,
    data,
    refetch,
  } = useQuery(gqlPreAuth.GET_PREAUTH_TRANSACTIONS, {
    variables: {
      input: {
        from_date: fromDate,
        to_date: toDate,
        limit: 10,
        page: pageNo,
      },
    },
  });

  useEffect(() => {
    if (error?.graphQLErrors[0]?.message) {
      toast.error(error?.graphQLErrors[0]?.message);
    }
  }, [error]);

  const {
    loading: excelDataLoading,
    error: excelDataError,
    data: excelDataResponse,
    refetch: refetchExcelDataResponse,
  } = useQuery(gqlPreAuth.GET_PREAUTH_EXCEL_DATA, {
    variables: {
      input: {
        from_date: fromDate,
        to_date: toDate,
      },
    },
    skip: true,
  });

  useEffect(() => {
    if (excelDataResponse) {
      setExcelData(
        JSON.parse(excelDataResponse?.listPreAuthTransactionsExcel?.data)
      );
    }
  }, [excelDataResponse]);

  const tableHeaders = [
    // {
    //   name: "SQ #",
    // },
    {
      name: "Date Orderd",
    },
    {
      name: "Benefeciary Name",
    },
    {
      name: "Payer",
    },
    {
      name: "Type",
    },

    {
      name: "Response Date",
    },
    {
      name: "Is Follow Up",
    },
    {
      name: "Outcome",
    },
    {
      name: "Status",
    },
    {
      name: "Action",
    },
  ];

  // const formatDateTime = (time) => {
  //   const dateTime = new Date(time);
  //   const dateOnly = dateTime.toLocaleDateString();
  //   const timeOnly = dateTime.toLocaleTimeString();
  //   return `${dateOnly} ${timeOnly}`;
  // };
  const formatDateTime = (time) => {
    const dateTime = new Date(time);
    const dateOnly = dateTime.toLocaleDateString("sv-SE");
    const timeOnly = dateTime.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit', hour12: true});
    return `${dateOnly} ${timeOnly}`;
  };

  const onDataRowClicked = (event, item) => {
    // if (
    //   event.target.tagName !== "H6" &&
    //   event.target.tagName !== "I" &&
    //   event.target.tagName !== "BUTTON" &&
    //   !!event.target.closest("td")
    // ) {
    //   setItem(item);
    //   setShowPreAuthResponseModal(true);
    // }
    navigate("/preauth/details", { state: { currentItem: item } });
  };

  const onDataRowClickedCommunication = (event, item) => {
    navigate("/preauth/details", { state: { currentItem: item ,tabToActive:"communications"} });
  };

  const onPageChange = async (currentPage) => {
    const preAuthFilterDate = JSON.parse(
      localStorage.getItem("preAuthFilterDate")
    );
    setPageNo(currentPage);

    // Function to remove empty keys
    const removeEmptyKeys = (obj) => {
      const newObj = {};
      for (const key in obj) {
        if (obj[key] !== "") {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    };

    // Remove empty keys from claimsFilterDate
    const filteredPreAuthFilterDate = removeEmptyKeys(preAuthFilterDate);

    let filterInput = {
      ...filteredPreAuthFilterDate,
      page: currentPage,
      limit: limit,
    };

    try {
      setLoading(true);
      await refetch({
        input: filterInput,
      });
    } finally {
      setLoading(false);
    }
  };

  //cancel request
  const [
    cancelReq,
    { data: cancelData, loading: cancelLoading, error: cancelError },
  ] = useMutation(gqlPreAuth.CANCEL_REQUEST, {
    update(proxy, result) {
      onSuccessfulCancelRequestResponse(result.data);
    },
    onError(error) {
      onCancelResponseError(error.graphQLErrors[0]);
    },
  });

  const onSuccessfulCancelRequestResponse = (data) => {
    if (data.cancelRequest) {
      toast.success("Successfully Cancelled!");
    } else {
    }
  };

  const onCancelResponseError = (error) => {
    toast.error(`${error.message}`);
  };

  const cancelClicked = async () => {
    cancelReq({
      variables: {
        input: {
          transaction_id: selectedItem?.transaction_id,
          request_name: "preAuth",
        },
      },
    }).then(async (res) => {
      const preAuthFilterDate = JSON.parse(
        localStorage.getItem("preAuthFilterDate")
      );

      let filterInput = {
        ...preAuthFilterDate,
        page: pageNo,
        limit: limit,
      };

      // Remove empty keys from claimsFilterDate
      const filteredPreAuthFilterDate = removeEmptyKeys(filterInput);

      try {
        setLoading(true);
        await refetch({
          input: filteredPreAuthFilterDate,
        });
      } finally {
        setLoading(false);
      }
    });
    setShowConfirmModal(false);
    setSeelectedItem(null);
  };
  //end Cancel Request

  function OutcomeComponent({ outcome }) {
    if (outcome == "queued") {
      return <span class="queued">Queued</span>;
    } else if (outcome == "complete") {
      return <span class="outcome-complete">Complete</span>;
    } else if (outcome == "error") {
      return <span class="outcome-error">Error</span>;
    } else if (outcome == "Abnormal Request") {
      return <span class="outcome-error">Abnormal Request</span>;
    }
    // else if (outcome == "error") {
    //   return <label className="badge badge-danger">Error</label>;
    // } else if (outcome == "cancelled") {
    //   return <label className="badge badge-danger">Cancelled</label>;
    // } else if (outcome == "rejected") {
    //   return <label className="badge badge-danger">Rejected</label>;
    // }
    else {
      return <label className="badge badge-danger">Unknown</label>;
    }
  }

  function StatusComponent({ outcome }) {
    if (outcome == "queued") {
      return "Pending";
    } else if (outcome == "complete") {
      return "Approved";
    } else if (outcome == "error") {
      return "Error";
    } else if (outcome == "cancelled") {
      return "Cancelled";
    } else if (outcome == "rejected") {
      return "Rejected";
    } else {
      return "Unknown";
    }
  }

  const getColor = (status) => {
    if (status === "rejected") {
      return "error";
    } else if (status === "approved") {
      return "success";
    } else if (status === "pending") {
      return "info";
    } else if (status === "pended") {
      return "secondary";
    } else if (status === "queued") {
      return "warning";
    } else if (status === "complete") {
      return "success";
    } else if (status === "error") {
      return "error";
    } else if (status === "Abnormal Request") {
      return "error";
    } else if (status === "not-required") {
      return "warning";
    } else {
      return "secondary";
    }
  };

  const {
    loading: pollLoading,
    error: pollError,
    data: pollData,
    refetch: refetchPoll,
  } = useQuery(gqlPreAuth.PRE_AUTH_BULK_POLL_REQ, {
    skip: true, // Skip initial fetching
  });

  const {
    loading: communicationPollLoading,
    error: communicationPollError,
    data: communicationPollData,
    refetch: refetchCommunicationPoll,
  } = useQuery(gqlPreAuth.PRE_AUTH_COMMUNICATION_POLL, {
    skip: true, // Skip initial fetching
  });

  const removeEmptyKeys = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== "") {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const pollClicked = () => {
    toast.loading("Requesting!");
    refetchPoll().then(async (res) => {
      toast.success(res?.data?.pollRequest?.message || "Request Successfully!");

      refetchCommunicationPoll();

      const preAuthFilterDate = JSON.parse(
        localStorage.getItem("preAuthFilterDate")
      );

      let filterInput = {
        ...preAuthFilterDate,
        page: pageNo,
        limit: limit,
      };

      // Remove empty keys from claimsFilterDate
      const filteredPreAuthFilterDate = removeEmptyKeys(filterInput);

      try {
        setLoading(true);
        await refetch({
          input: filteredPreAuthFilterDate,
        });
      } finally {
        setLoading(false);
      }
    });
  };

  //check Status
  const [
    checkReq,
    { data: checkData, loading: checkLoading, error: checkError },
  ] = useMutation(claims.CHECK_STATUS, {
    update(proxy, result) {
      onSuccessfulCheckRequestResponse(result.data);
    },
    onError(error) {
      onCancelResponseError(error.graphQLErrors[0]);
    },
  });

  const onSuccessfulCheckRequestResponse = (data) => {
    if (!data.checkStatusRequest.nphies_err) {
      toast.success(
        data?.checkStatusRequest?.message || "Successfully Requested!"
      );
    } else if (data.checkStatusRequest.nphies_err) {
      // setErrorData(data.checkStatusRequest.nphies_err);
      // setShowErrorAlert(true);
    } else {
      toast.error(`Unknown Error`);
    }
  };

  const checkClicked = (item) => {
    toast.loading("Requesting!");
    checkReq({
      variables: {
        input: {
          transaction_id: item.transaction_id,
          request_name: "preAuth",
        },
      },
    });
  };
  //end status check
  

  return (
    <>
      <div class="pre_auth_container !p-0 ">
        <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
          <div className="">
            <Tooltip
              placement="right"
              title="Keep track of your Preauthorization Transactions"
              className="my-auto !mt-2"
            >
              <span className="font-semibold text-black/70 text-lg ">
                Preauthorization Transactions
                <span className="italic text-lg ml-1">
                  ({data?.listPreAuthTransactions?.total || 0}{" "}
                  <span className="text-xs font-bold italic">Entries</span>)
                </span>
              </span>
            </Tooltip>
          </div>
          <div className="flex gap-3 flex-col md:flex-row">
            <Button
              variant="contained"
              onClick={() => pollClicked()}
              size="small"
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<ImportExportIcon />}
            >
              Poll
            </Button>

            <Button
              variant="contained"
              onClick={() => navigate("/preauth/form")}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<AddIcon />}
              size="small"
            >
              Request Preauthorization
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowFilter(() => !showFilter)}
              className="!border-primary !text-primary hover:!bg-primary/10"
              startIcon={<FilterAltOutlinedIcon />}
              size="small"
            >
              Apply Filter
            </Button>
          </div>
        </div>
        {/* <div class="pre_auth_page">
          <div class="pre_auth_content block lg:flex justify-between">
            <div class="pre_auth_title">
              <span>Preauthorization Transactions</span>
              <div class="track_pre_auth">
                <span>Keep track of your Preauthorization Transactions</span>
              </div>
            </div>
            <div class="create_pre_auth mt-3 lg:mt-0 search-filter">
              <DownloadExcel
                data={excelData}
                fileName={"PreAuthorizationTransactions"}
              />
              <div class="create-btn" onClick={() => pollClicked()}>
                <span>Poll</span>
              </div>
              <div class="create-btn" onClick={() => navigate("/preauth/form")}>
                <span>Request Preauthorization</span>
              </div>
              <div class="apply-filter">
                <div
                  class="btn"
                  onClick={() => setShowFilter(() => !showFilter)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5.83751 4.99992H14.1708L9.99584 10.2499L5.83751 4.99992ZM3.54584 4.67492C5.22918 6.83325 8.33751 10.8333 8.33751 10.8333V15.8333C8.33751 16.2916 8.71251 16.6666 9.17084 16.6666H10.8375C11.2958 16.6666 11.6708 16.2916 11.6708 15.8333V10.8333C11.6708 10.8333 14.7708 6.83325 16.4542 4.67492C16.8792 4.12492 16.4875 3.33325 15.7958 3.33325H4.20418C3.51251 3.33325 3.12084 4.12492 3.54584 4.67492Z"
                      fill="black"
                    />
                  </svg>
                  Apply Filter
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div class="search-filter"> */}
        {/* <div class="search">
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search"
            />
          </div> */}

        <Filter
          refetch={refetch}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setPageNo={setPageNo}
          setLimit={setLimit}
          refetchExcelDataResponse={refetchExcelDataResponse}
          setFromDate={setFromDate}
          setToDate={setToDate}
          setLoading={setLoading}
          setRefetchFilterVariables={setRefetchFilterVariables}
          pageNo={pageNo}
        />
        {/* </div> */}

        <div class="super-git-table">
          <table class="table-super-git">
            <thead className="!p-0">
              <tr className="!p-0">
                {tableHeaders.map((el, index) => {
                  return (
                    <th className="!p-2 !text-sm" key={"tableHeaders_" + index}>
                      {el.name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {/* {(loading || dataLoading) && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>} */}
            <tbody>
              {data?.listPreAuthTransactions?.data ? (
                data?.listPreAuthTransactions?.data?.map((el, index) => {
                  return (
                    <tr
                      key={"beneficiariesData_" + index}
                      // className="cursor-pointer"
                      onClick={() => setSeelectedItem(el)}
                    >
                      {/* <td className="!p-1">
                        {pageNo * limit + index + 1 - limit}
                      </td> */}

                      <td className="!p-1 !text-sm">
                        {el?.date_ordered}
                      </td>
                      {/* <td className="!p-1 !text-sm">
                        {new Date(el?.date_ordered)?.toLocaleDateString()}
                      </td> */}

                      {/* 2 */}
                      <td className="!p-1 !text-sm capitalize text-start">
                        {el?.patient_name?.length > 100 ? (
                          <Tooltip title={el?.patient_name}>
                            <span>{el?.patient_name.slice(0, 100)}...</span>
                          </Tooltip>
                        ) : (
                          el?.patient_name
                        )}
                      </td>

                      {/* 3 */}
                      <td className="!p-1 !text-sm capitalize">
                        {el?.payer_name?.length > 100 ? (
                          <Tooltip title={el?.payer_name}>
                            <span>{el?.payer_name.slice(0, 100)}...</span>
                          </Tooltip>
                        ) : (
                          el?.payer_name
                        )}
                      </td>

                      <td className="!p-1 !text-sm">{el?.type}</td>
                      {/* <td className="!p-1 !text-sm">
                        {el?.response_date?.length > 16
                          ? el?.response_date.substring(0, 16).replace("T", " ")
                          : el?.response_date}
                      </td> */}
                      <td className="!p-1 !text-sm">
                        {el?.response_date?.length > 16
                          ? el?.response_date.substring(0, 16).replace("T", " ")
                          : el?.response_date}

                          {/* {formatDateTime(el?.response_date)} */}
                      </td>

                      <td className="!p-1 !text-sm">
                        {el?.is_followup_req
                          ? (
                              <Tooltip
                                title={`${el?.followup_ref_transaction_id}`}
                              >
                                <div
                                  className="cursor-pointer text-3xl flex justify-center"
                                  onClick={() =>
                                    navigate(
                                      "/preauth/details/" +
                                        el?.followup_ref_transaction_id
                                    )
                                  }
                                >
                                  <MdArrowCircleUp />
                                </div>
                              </Tooltip>
                            ) || "___"
                          : "No" || "___"}
                      </td>
                      <td className="!p-1 !text-sm">
                        <p className=" !text-sm ">
                          {el?.outcome ? (
                            <Chip
                              label={el?.outcome}
                              color={getColor(el?.outcome)}
                              size="small"
                              className="uppercase !text-xs "
                            />
                          ) : (
                            ""
                          )}
                        </p>
                      </td>
                      <td className="!p-1">
                        <p className=" !text-sm ">
                          {el?.status ? (
                            <Chip
                              label={el?.status}
                              color={getColor(el?.status)}
                              size="small"
                              className="uppercase !text-xs "
                            />
                          ) : (
                            ""
                          )}
                        </p>
                      </td>

                      <td className="  !p-1">
                        <div className="flex gap-3 justify-center">
                          <Tooltip
                            title={`${
                              el?.communication_count || 0
                            } Communications`}
                          >
                            <div
                              className="text-xl cursor-pointer flex justify-center"
                              onClick={(event) => onDataRowClickedCommunication(event, el)}
                            >
                              <Badge
                                color="secondary"
                                badgeContent={el?.communication_count || 0}
                                showZero
                              >
                                <RiMessage2Line />
                              </Badge>
                            </div>
                          </Tooltip>
                          <Tooltip title="Convert PreAuth To Claim">
                            <div
                              onClick={() => setShowConvertToClaimModal(true)}
                              className="text-xl cursor-pointer"
                            >
                              <CgArrowsExchangeAlt />
                            </div>
                          </Tooltip>
                          <Tooltip title="View">
                            <div
                              onClick={(event) => onDataRowClicked(event, el)}
                              className="text-xl cursor-pointer"
                            >
                              <MdOutlineRemoveRedEye />
                            </div>
                          </Tooltip>

                          <Tooltip title="Check">
                            <div
                              onClick={() => checkClicked(el)}
                              className="text-xl cursor-pointer"
                            >
                              <FaRegCircleCheck />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="flex gap-3 justify-center mt-1">
                          <Tooltip title="Cancel">
                            <div
                              onClick={() => {
                                setSeelectedItem(el);
                                setShowConfirmModal(true);
                              }}
                              className="text-xl"
                            >
                              <IoMdCloseCircleOutline />
                            </div>
                          </Tooltip>

                          <Tooltip title="Edit">
                            <div
                              onClick={() =>
                                navigate(
                                  "/preauth/edit/form/" + el.transaction_id
                                )
                              }
                              className="text-xl cursor-pointer"
                            >
                              <TbEditCircle />
                            </div>
                          </Tooltip>

                          {el?.is_followup_req && el?.status === "rejected" ? (
                            <Tooltip title="Follow Up">
                              <div
                                onClick={() =>
                                  navigate(
                                    `/preauth/edit/form/${
                                      el?.transaction_id
                                    }/${"follow-up"}`
                                  )
                                }
                                className="text-xl cursor-pointer"
                              >
                                <FaArrowUpFromBracket />
                              </div>
                            </Tooltip>
                          ) : !el?.is_followup_req ? (
                            <Tooltip title="Follow Up">
                              <div
                                onClick={() =>
                                  navigate(
                                    `/preauth/edit/form/${
                                      el?.transaction_id
                                    }/${"follow-up"}`
                                  )
                                }
                                className="text-xl cursor-pointer"
                              >
                                <FaArrowUpFromBracket />
                              </div>
                            </Tooltip>
                          ) : null}

                          <Tooltip title="JSON View">
                            <div
                              onClick={handleClickOpen}
                              className="text-xl cursor-pointer"
                            >
                              <BsFiletypeJson />
                            </div>
                          </Tooltip>

                          {/* <span
                          class="delete"
                          onClick={() => {
                            setItem(el);
                            setShowConfirmModal(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z"
                              fill="#4D4D53"
                            />
                          </svg>
                        </span> */}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colspan="6">
                    <div class="flex justify-center">
                      <h2 class="text-center text-lg">No Data Found</h2>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div>
          <Pagination
            currentPage={pageNo}
            totalPages={data?.listPreAuthTransactions?.pages}
            onPageChange={onPageChange}
          />
        </div>
      </div>

      <ConformationModal
        open={showConfirmModal}
        setOpen={setShowConfirmModal}
        onDelete={() => cancelClicked(item)}
        text={"Cancel"}
      />
      <PreAuthJsonViewModal
        setOpen={setOpen}
        open={open}
        handleClose={handleClose}
        selectedItem={selectedItem}
      />
      <ConvertPreAuthToClaimModal
        showConvertToClaimModal={showConvertToClaimModal}
        setShowConvertToClaimModal={setShowConvertToClaimModal}
        selectedItem={selectedItem}
      />
      <AppModal
        isOpen={
          loading || dataLoading || communicationPollLoading || pollLoading
        }
      />
    </>
  );
}

export default PreAuthList;
