import { useFormikContext } from "formik";
import React from "react";

function ContactForm(props) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const contactUnits = [
    { id: "unit", name: "Unit" },
    { id: "day", name: "Day" },
    { id: "week", name: "Week" },
    { id: "month", name: "Month" },
  ];

  return (
    <div className="w-1/2 mx-auto">
      <div className="flex items-center gap-8 justify-between">
        <div className="sm:col-span-1 ">
          <input
            placeholder="right"
            type="text"
            name="rightPowerContact"
            value={values.rightPowerContact}
            onChange={handleChange}
            onBlur={handleBlur}
            id="non_standard_code"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
        <div>
          <span className="text-gray-700 font-medium">Power</span>
        </div>
        <div className="sm:col-span-1 ">
          <input
            placeholder="Left"
            type="text"
            name="leftPowerContact"
            value={values.leftPowerContact}
            onChange={handleChange}
            onBlur={handleBlur}
            id="non_standard_code"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
      </div>
      {/* SECOND ROW */}
      <div className="flex items-center gap-8 justify-between my-2 ">
        <div className="sm:col-span-1 ">
          <input
            placeholder="Right"
            type="text"
            name="rightBackCurveContact"
            value={values.rightBackCurveContact}
            onChange={handleChange}
            onBlur={handleBlur}
            id="non_standard_code"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
        <div>
          <span className="text-gray-700 font-medium">Back Curve</span>
        </div>
        <div className="sm:col-span-1 ">
          <input
            placeholder="Left"
            type="text"
            name="leftBackCurveContact"
            value={values.leftBackCurveContact}
            onChange={handleChange}
            onBlur={handleBlur}
            id="leftBackCurveContact"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
      </div>
      {/* third row */}
      <div className="flex items-center gap-8 justify-between my-2 ">
        <div className="sm:col-span-1 ">
          <input
            placeholder="Right"
            type="text"
            name="rightDiameterContact"
            value={values.rightDiameterContact}
            onChange={handleChange}
            onBlur={handleBlur}
            id="rightDiameterContact"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
        <div>
          <span className="text-gray-700 font-medium">Diameter</span>
        </div>
        <div className="sm:col-span-1 ">
          <input
            placeholder="Left"
            type="text"
            name="leftDiameterContact"
            value={values.leftDiameterContact}
            onChange={handleChange}
            onBlur={handleBlur}
            id="leftDiameterContact"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
      </div>
      {/* fourth row */}
      <div className="flex items-center gap-8 justify-between my-2 ">
        <div className="sm:col-span-1 ">
          <input
            placeholder="Right"
            type="text"
            name="rightCylinder"
            value={values.rightCylinder}
            onChange={handleChange}
            onBlur={handleBlur}
            id="rightCylinder"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
        <div>
          <span className="text-gray-700 font-medium">Cylinder</span>
        </div>
        <div className="sm:col-span-1 ">
          <input
            placeholder="Left"
            type="text"
            name="leftCylinder"
            value={values.leftCylinder}
            onChange={handleChange}
            onBlur={handleBlur}
            id="leftCylinder"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
      </div>
      {/* five column */}
      <div className="flex items-center gap-8 justify-between my-2 ">
        <div className="sm:col-span-1 ">
          <input
            placeholder="Right"
            type="text"
            name="rightAxis"
            value={values.rightAxis}
            onChange={handleChange}
            onBlur={handleBlur}
            id="rightAxis"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
        <div>
          <span className="text-gray-700 font-medium">Axis</span>
        </div>
        <div className="sm:col-span-1 ">
          <input
            placeholder="Left"
            type="text"
            name="leftAxis"
            value={values.leftAxis}
            onChange={handleChange}
            onBlur={handleBlur}
            id="leftAxis"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
      </div>
      {/* sixth row */}
      <div className="flex items-center gap-8 justify-between my-2 ">
        <div className="sm:col-span-1 ">
          <input
            placeholder="Right"
            type="text"
            name="rightAdd"
            value={values.rightAdd}
            onChange={handleChange}
            onBlur={handleBlur}
            id="rightAdd"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
        <div>
          <span className="text-gray-700 font-medium">Add</span>
        </div>
        <div className="sm:col-span-1 ">
          <input
            placeholder="Left"
            type="text"
            name="leftAdd"
            value={values.leftAdd}
            onChange={handleChange}
            onBlur={handleBlur}
            id="leftAdd"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
      </div>
      {/* seven */}
      <div className="flex items-center gap-8 justify-between my-2 ">
        <div className="sm:col-span-2 ">
          <div className="flex gap-2">
            <select
              id="rightUnitContact"
              name="rightUnitContact"
              onChange={handleChange}
              value={values.rightUnitContact}
              autoComplete="type-name"
              className="block rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            >
              <option value={""}>Please Select</option>
              {contactUnits.map((item) => {
                return (
                  <option value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
            <input
              placeholder="Right"
              type="text"
              name="rightDurationContact"
              value={values.rightDurationContact}
              onChange={handleChange}
              onBlur={handleBlur}
              id="rightDurationContact"
              className="mt-1 block w-1/4  rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <span className="text-gray-700 font-medium">Duration</span>
        </div>
        <div className="sm:col-span-1 ">
          <div className="flex gap-2 items-center justify-center">
            <input
              placeholder="Left"
              type="text"
              name="leftDurationContact"
              value={values.leftDurationContact}
              onChange={handleChange}
              onBlur={handleBlur}
              id="leftDurationContact"
              className="mt-1 block w-1/4  rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            />
            <select
              id="leftUnitContact"
              name="leftUnitContact"
              onChange={handleChange}
              value={values.leftUnitContact}
              autoComplete="type-name"
              className="block rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            >
              <option value={""}>Please Select</option>
              {contactUnits.map((item) => {
                return (
                  <option value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      {/* Eight Row */}
      <div className="sm:col-span-1 ">
        <textarea
          placeholder="Notes here ?"
          type="notes"
          name="notes"
          value={values.notes}
          onChange={handleChange}
          onBlur={handleBlur}
          id="notes"
          className="mt-1 block w-full my-4 rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
        ></textarea>
      </div>
    </div>
  );
}

export default ContactForm;
