import { FileUpload, HistoryOutlined } from "@mui/icons-material";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import AutoModeOutlinedIcon from "@mui/icons-material/AutoModeOutlined";

export const navigation = [
  {
    name: "Dashboard",
    path: "dashboard",
    permission: "view-dashboard",
  },
  {
    name: "Beneficiary",
    path: "beneficiary",
    permission: "view-beneficiary",
  },
  {
    name: "Eligibility",
    path: "eligibility",
    permission: "view-eligibility",
  },
  // {
  //   name: "Providers",
  //   path: "providers",
  //   permission: "view-providers",
  // },
  {
    name: "Pre-Authorization",
    path: "preauth",
    permission: "view-preauth",
  },
  {
    name: "Claims",
    path: "claims",
    permission: "view-claims",
    menu: [
      {
        name: "Transaction",
        path: "claims",
        icon: FormatListBulletedOutlinedIcon,
      },
      {
        name: "Submissions",
        path: "claims/submit",
        icon: PublishedWithChangesOutlinedIcon,
      },
      {
        name: "Bulk Submissions",
        path: "claims/submit-stats",
        icon: AutoModeOutlinedIcon,
      },
      {
        name: "Excel Uploads",
        path: "claims/uploads",
        icon: FileUpload,
      },
      {
        name: "History",
        path: "claims/history",
        icon: HistoryOutlined,
      },
    ],
  },
  {
    name: "Communications",
    path: "communications",
    permission: "view-communications",
  },
  {
    name: "Cancellations",
    path: "cancellation",
    permission: "view-cancellation",
  },
  {
    name: "Payments / Reconciliation",
    path: "payments",
    permission: "view-payments",
  },

  // {
  //   name: "Physicians",
  //   path: "physicians",
  //   permission: "view-physicians",
  // },
  // {
  //   name: "PriceList",
  //   path: "price-list",
  //   permission: "view-priceList",
  // },
  // {
  //   name: "Physicians",
  //   path: "physicians",
  //   permission: "view-physicians",
  // },
  // {
  //   name: "Users",
  //   path: "users",
  //   permission: "view-users",
  // },
  // {
  //   name: "Tob",
  //   path: "tob",
  //   permission: "view-tob",
  // },
  // {
  //   name: "Verification",
  //   path: "verification",
  //   permission: "view-verification",
  // },
];
