import React, { createContext, useState } from "react";

export const appContext = createContext({});

function ContextProvider({ children }) {
  const [showLoader, setShowLoader] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [user, setUser] = useState({
    FullName: "",
    Email: "",
    RoleId: "",
    RoleName: ""
  });
  const [permissions, setPermissions] = useState([
    "add-users",
    "edit-users",
    "delete-users",
    "add-physician",
    "edit-physician",
    "delete-physician",
    "add-provider",
    "edit-provider",
    "delete-provider",
    "create-patient",
    "edit-patient",
    "delete-patient",
    "add-hospital",
    "edit-hospital",
    "delete-hospital",
    "add-clinic",
    "add-payer",
    "edit-payer",
    "delete-payer",
    "add-tpa",
    "add-staff",
    "delete-staff",
    "edit-staff",
    "add-branch",
    "view-dashboard",
    "view-beneficiary",
    "view-providers",
    "view-preauth",
    "view-claims",
    "view-reconciliation",
    "view-priceList",
    "view-physicians",
    "view-users",
    "view-tob",
    "view-verification",
  ]);
  const [token, setToken] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <appContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser,
        permissions,
        setPermissions,
        sidebarOpen,
        setSidebarOpen,
        showLoader,
        setShowLoader,
        showErrorAlert,
        setShowErrorAlert,
      }}
    >
      {children}
    </appContext.Provider>
  );
}

export default ContextProvider;
