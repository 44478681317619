import React from "react";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import payments from "../../../gql/payments";
import { useContext } from "react";
import { appContext } from "../../../context/ContextProvider";
import { useState } from "react";
import AppErrorAlert from "../../../components/AppErrorAlert";
import { Button, IconButton, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "95%",
    maxHeight: "90%",
  },
};

function DetailsModal({ showDetails, setShowDetails, item }) {
  const { showErrorAlert, setShowErrorAlert } = useContext(appContext);

  const [confirmReq, { data, loading, error }] = useMutation(
    payments.PAYMENT_NOTICE,
    {
      update(proxy, result) {
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );
  const [errorData, setErrorData] = useState([]);

  // const { loading, error, data } = useQuery(usersGQL.GET_USER);

  const onSuccessfulRequestResponse = (data) => {
    if (data.paymentNoticeRequest?.nphies_err) {
      setErrorData(data.createClaim.nphies_err);
      setShowErrorAlert(true);
    } else {
      toast.success("Successfully Confirmed!");
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error?.message}`);
  };

  const confirmPayment = () => {
    toast.loading("Requesting!");
    confirmReq({ variables: { id: item.id } });
  };

  return (
    // <Modal
    //   isOpen={showDetails}
    //   onRequestClose={() => setShowDetails(false)}
    //   style={customStyles}
    //   contentLabel="Example Modal"
    // >
    //   <div className="overflow-hidden bg-white shadow sm:rounded-lg">
    //     <div className="flex justify-between px-4 py-2 sm:px-6">
    //       <h3 className="text-xl font-bold leading-6 text-gray-900">
    //         Payment Information
    //       </h3>
    //       <button
    //         onClick={confirmPayment}
    //         type="button"
    //         className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-amber-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
    //       >
    //         Confirm
    //       </button>
    //     </div>
    //     <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
    //       <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">ID</dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.id ? item.id : "-"}
    //           </dd>
    //         </div>
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">
    //             Transaction Id
    //           </dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.transaction_id ? item.transaction_id : "-"}
    //           </dd>
    //         </div>
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">Time Stamp</dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.timestamp ? item.timestamp : "-"}
    //           </dd>
    //         </div>
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">
    //             Payment Note Date
    //           </dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.payment_note_date ? item.payment_note_date : "-"}
    //           </dd>
    //         </div>
    //       </dl>
    //       {/* MY WORK */}
    //       <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">
    //             Payment Start Date
    //           </dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.payment_start_date ? item.payment_start_date : "-"}
    //           </dd>
    //         </div>
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">
    //             Payment End Date
    //           </dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.payment_end_date ? item.payment_end_date : "-"}
    //           </dd>
    //         </div>
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">
    //             Payment Amount
    //           </dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.payment_amount ? item.payment_amount : "-"}
    //           </dd>
    //         </div>
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">
    //             Payment Currency
    //           </dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.payment_currency ? item.payment_currency : "-"}
    //           </dd>
    //         </div>
    //       </dl>
    //       {/* Third ROW */}
    //       <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">
    //             Confirmation Status
    //           </dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.confirmation_status ? item.confirmation_status : "-"}
    //           </dd>
    //         </div>
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">
    //             Payment Status
    //           </dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.payment_status ? item.payment_status : "-"}
    //           </dd>
    //         </div>
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">Provider</dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.providers_name_en ? item.providers_name_en : "-"}
    //           </dd>
    //         </div>
    //         <div className="sm:col-span-1">
    //           <dt className="text-sm font-medium text-gray-500">Payer</dt>
    //           <dd className="mt-1 text-sm text-gray-900">
    //             {item.payer_name_en ? item.payer_name_en : "-"}
    //           </dd>
    //         </div>
    //       </dl>
    //     </div>
    //   </div>

    //   <AppErrorAlert data={errorData} />
    // </Modal>
    <div>
      <BootstrapDialog
        onClose={() => setShowDetails(false)}
        aria-labelledby="customized-dialog-title"
        open={showDetails}
        PaperProps={{
          className:
            "w-full !max-w-[1250px] sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl ",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Payment Information
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setShowDetails(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">ID</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.id ? item.id : "-"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Transaction Id
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.transaction_id ? item.transaction_id : "-"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Time Stamp
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.timestamp ? item.timestamp : "-"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Payment Note Date
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.payment_note_date ? item.payment_note_date : "-"}
                </dd>
              </div>
            </dl>
            {/* MY WORK */}
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Payment Start Date
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.payment_start_date ? item.payment_start_date : "-"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Payment End Date
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.payment_end_date ? item.payment_end_date : "-"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Payment Amount
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.payment_amount ? item.payment_amount : "-"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Payment Currency
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.payment_currency ? item.payment_currency : "-"}
                </dd>
              </div>
            </dl>
            {/* Third ROW */}
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Confirmation Status
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.confirmation_status ? item.confirmation_status : "-"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Payment Status
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.payment_status ? item.payment_status : "-"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Provider</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.providers_name_en ? item.providers_name_en : "-"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Payer</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item.payer_name_en ? item.payer_name_en : "-"}
                </dd>
              </div>
            </dl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            autoFocus
            className="!text-primary hover:!bg-primary/20"
            onClick={confirmPayment}
          >
            Confirm Payment
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default DetailsModal;
