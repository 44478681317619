import { gql } from "@apollo/client";

const UPLOAD_PRICE_LIST = gql`
  mutation uploadPriceListExcel($input: UploadPriceListExcelInput!) {
    uploadPriceListExcel(input: $input) {
      status
      message
    }
  }
`;

const CREATE_SPECIFIC_PRICE_LIST = gql`
  mutation createPriceList($input: CreatePriceListInput!) {
    createPriceList(input: $input) {
      status
      message
      data {
        id
        payer_id
        effective_date
        service_type
        service_code
        service_desc
        non_standard_code
        non_standard_desc
        unit_price
        factor
      }
    }
  }
`;

const GET_SPECIFIC_PRICE_LIST_LIST = gql`
  query listPayerPriceList($input: ListPayerPriceListInput!) {
    listPayerPriceList(input: $input) {
      status
      message
      data {
        id
        service_type
        service_code
        service_desc
        non_standard_code
        non_standard_desc
        unit_price
        factor
      }
      pages
      total
    }
  }
`;

const UPDATE_SPECIFIC_PRICE_LIST = gql`
  mutation updatePriceList($input: UpdatePriceInput!) {
    updatePriceList(input: $input) {
      status
      message
      data {
        id
        service_type
        service_code
        service_desc
        non_standard_code
        non_standard_desc
        unit_price
        factor
      }
    }
  }
`;

const LIST_PRICE_LIST = gql`
  query listPriceList($input: ListPriceListInput!) {
    listPriceList(input: $input) {
      status
      message
      data {
        payer_id
        payer_name
        tpa_id
        tpa_name
        effective_date
        created_at
      }
      pages
      total
    }
  }
`;

const DELETE_PRICE_LIST = gql`
  mutation deletePriceList($input: DeletePriceListInput!) {
    deletePriceList(input: $input) {
      status
      message
    }
  }
`;

const GET_PRICE_LIST_BY_ID = gql`
  query GetPriceListById($id: Int!) {
    getPriceListById(id: $id) {
      status
      message
      data {
        id
        payer_id
        effective_date
        service_type
        service_code
        service_desc
        non_standard_code
        non_standard_desc
        unit_price
        factor
      }
    }
  }
`;

const UPDATE_PRICE_LIST = gql`
  mutation updatePriceList($input: UpdatePriceListInput!) {
    updatePriceList(input: $input) {
      status
      message
      data {
        id
        service_type
        service_code
        service_desc
        non_standard_code
        non_standard_desc
        unit_price
        factor
      }
    }
  }
`;

const SEARCH_PRICE_LIST_CODE = gql`
  query SearchPriceListCode($input: String!, $payerId: String) {
    searchPriceListCode(search: $input, payer_id: $payerId) {
      status
      message
      data {
        id
        payer_id
        effective_date
        service_type
        service_code
        service_desc
        non_standard_code
        non_standard_desc
        unit_price
        factor
      }
    }
  }
`;

export default {
  UPLOAD_PRICE_LIST,
  CREATE_SPECIFIC_PRICE_LIST,
  GET_SPECIFIC_PRICE_LIST_LIST,
  UPDATE_SPECIFIC_PRICE_LIST,
  LIST_PRICE_LIST,
  DELETE_PRICE_LIST,
  GET_PRICE_LIST_BY_ID,
  UPDATE_PRICE_LIST,
  SEARCH_PRICE_LIST_CODE,
};
