import React, { useContext } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import Modal from "react-modal";
import { appContext } from "../context/ContextProvider";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "95%",
  },
};
function AppErrorAlert({ data }) {
  const { showErrorAlert, setShowErrorAlert } = useContext(appContext);

  return (
    <Modal
      isOpen={showErrorAlert}
      onRequestClose={() => setShowErrorAlert(false)}
      style={customStyles}
      contentLabel="Error Modal"
    >
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div
            className="flex-shrink-0"
            onClick={() => setShowErrorAlert(false)}
          >
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              Resolve these errors:
            </h3>
            <div className="mt-2 text-sm text-red-700">
              {data.map((item, index) => {
                return (
                  <>
                    <h3>{index + 1}</h3>
                    <ul role="list" className="list-disc space-y-1 pl-5">
                      {Object.entries(item).map(([key, value]) => {
                        return (
                          <li>
                            {value.toString()}
                            {/* {key} : {value.toString()} */}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AppErrorAlert;
