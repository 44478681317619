import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { appContext } from "../../context/ContextProvider";
// import { ToastContainer, toast } from "react-toastify";
import toast from "react-hot-toast";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Form, Field, Formik } from "formik";

import usersGQL from "../../gql/users";
// import "react-toastify/dist/ReactToastify.css";
import AppLoader from "../../components/AppModal";
import AppModal from "../../components/AppModal";
import { Button, InputAdornment, TextField } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";

function Login(props) {
  const { user, setUser, setToken, setPermissions } = useContext(appContext);

  const [showLoader, setShowLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loginUser, { data, loading, error }] = useMutation(
    usersGQL.USER_LOGIN_MUTATION,
    {
      update(proxy, result) {
        setShowLoader(false);
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        setShowLoader(false);
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  //parse Permissions
  const parsePermissions = (data) => {
    let tempData = [];
    data.map((item, index) => {
      tempData.push(item.PermissionUrl);
    });

    return tempData;
  };

  const onSuccessfulRequestResponse = (data) => {
    if (data?.userSignIn?.data?.jwt) {
      setToken(data.userSignIn.data.jwt);
      localStorage.setItem("token", JSON.stringify(data.userSignIn.data.jwt));

      setPermissions(parsePermissions(data.userSignIn.data.permission));
      localStorage.setItem(
        "permissions",
        JSON.stringify(parsePermissions(data.userSignIn.data.permission))
      );

      let user = {
        FullName: data.userSignIn.data.full_name,
        Email: data.userSignIn.data.email,
        RoleId: data.userSignIn.data.role_id,
        RoleName: data.userSignIn.data.role_name,
      };
      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));
    } else {
    }
  };

  const onResponseError = (error) => {
    toast.error(`Invalid Creds - ${error.message}`);
  };
  ///

  const addData = (values) => {
    setShowLoader(true);
    let data = { input: { email: values.email, password: values.password } };
    loginUser({ variables: data });
  };
  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={(values) => {
          const errors = {};
        }}
        onSubmit={(values, { setSubmitting }) => {
          addData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <div className="w-full">
            <div className="w-full flex flex-row">
              <div className="login-img w-1/2 h-screen hidden lg:block  ">
                <img
                  className=" w-full h-screen"
                  // src="/images/bannerSupergit.png"
                  src="https://i.pinimg.com/originals/88/b0/bc/88b0bca05972efa8f57d793f756c61b4.jpg"
                  alt="Super Git"
                />
              </div>

              <div className="login-form h-screen w-full lg:w-1/2 flex justify-center items-center relative">
                <div className="w-full lg:w-1/2 mx-4 lg:mx-0">
                  <div className="mx-auto flex justify-center mb-4">
                    {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="70"
                        viewBox="0 0 31 53"
                        fill="none"
                      >
                        <path
                          d="M0.0132901 37.5756C0.523851 37.5756 0.972147 37.5756 1.4578 37.5756C1.47025 39.2406 1.71931 40.8187 2.30459 42.3346C4.0106 46.7208 7.11133 49.6781 11.6441 50.9828C19.7633 53.3064 27.87 48.1622 29.3518 39.837C29.3643 39.7376 29.4016 39.6382 29.4266 39.5388C29.6383 38.5199 29.4764 37.7868 28.4926 37.0537C19.1406 30.1451 9.83847 23.1494 0.511398 16.1911C0.112912 15.8929 -0.0116161 15.5947 0.000836598 15.1101C0.299701 8.37538 4.33437 2.87084 10.7475 0.770909C16.3886 -1.08051 22.59 0.447843 26.7617 4.87136C29.1526 7.41861 30.4975 10.438 30.9333 13.8799C30.9956 14.377 30.9458 14.8988 30.9458 15.4456C30.485 15.4456 30.0616 15.4456 29.6507 15.4456C29.0032 8.07717 25.2798 3.23118 17.9452 1.71526C11.943 0.472694 6.43888 3.55425 3.62457 8.05232C3.83627 8.21385 4.06041 8.40023 4.28456 8.56177C13.0139 15.1349 21.7557 21.6956 30.4975 28.2564C30.8462 28.5173 30.9956 28.7782 30.9956 29.2256C30.9707 31.5988 30.9582 33.9846 30.9956 36.3579C31.033 38.7187 30.8462 41.0299 29.9122 43.2292C27.7454 48.3734 23.9225 51.6289 18.4557 52.6975C11.1958 54.1141 4.39664 50.4609 1.30837 43.7138C0.424228 41.7878 0.0257428 39.7376 0.0132901 37.5756ZM2.88986 9.34458C2.08044 10.9599 1.68195 12.6125 1.48271 14.3272C1.42044 14.8864 1.59478 15.2095 2.04308 15.5574C10.9966 22.2424 19.9501 28.9398 28.8911 35.6247C29.0779 35.7614 29.2771 35.8981 29.5511 36.0969C29.5511 33.9721 29.5386 31.9716 29.5635 29.9711C29.5635 29.5362 29.439 29.2628 29.0779 29.0019C24.0345 25.2369 19.0161 21.4471 13.9852 17.6697C10.3117 14.9113 6.62567 12.1403 2.88986 9.34458Z"
                          fill="#F5793B"
                        />
                      </svg> */}
                    <img
                      src="/images/supergit-01.png"
                      alt="logo"
                      className="w-32"
                    />
                  </div>
                  <h2 className="my-2 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
                    Login To Your Account
                  </h2>

                  {/* Form */}
                  <div className="w-full">
                    <div className="bg-white px-1 py-1 sm:rounded-lg sm:px-1">
                      <Form className="space-y-6">
                        <div>
                          <div className="mt-2 relative">
                            <PersonOutlineIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 !text-orange-400" />
                            <Field
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              onChange={handleChange}
                              required
                              placeHolder="Email"
                              className="pl-12 block w-full rounded-xl border-slate-300 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                              style={{
                                boxShadow:
                                  "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                              }}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="relative">
                            <HttpsOutlinedIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 !text-orange-400" />
                            <Field
                              style={{
                                boxShadow:
                                  "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                              }}
                              id="password"
                              name="password"
                              type="password"
                              autoComplete="current-password"
                              onChange={handleChange}
                              required
                              placeHolder="Password"
                              className="pl-12 block w-full rounded-xl border-slate-300 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div>
                          <Button
                            variant="contained"
                            type="submit"
                            size="small"
                            style={{
                              backgroundImage:
                                "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                            }}
                            className="flex w-full justify-center !rounded-xl px-3 py-1.5 text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 !normal-case"
                          >
                            Login
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-0 right-0 m-3">
                  <p className="bg-gray-100 py-1.5 px-3 text-gray-600 text-xs font-semibold rounded-full ">
                    Super Git v2.1.4
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <AppLoader isOpen={showLoader} />
    </>
  );
}

export default Login;
