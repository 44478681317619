import React, { useState, useEffect, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@apollo/client";
import Filter from "./components/Filter";
import gqlPayments from "../../gql/payments";
import gqlPreAuth from "../../gql/preAuth";
import DownloadExcel from "../../components/DownloadExcel";
import { appContext } from "../../context/ContextProvider";
import Pagination from "../../components/Pagination";
import DetailsModal from "./components/DetailsModal";
import PollBtn from "../../components/PollBtn";
// import { toast } from "react-toastify";
import toast from "react-hot-toast";
import AppModal from "../../components/AppModal";
import { Button, Tooltip } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import ImportExportIcon from "@mui/icons-material/ImportExport";

function PaymentList(props) {
  const { setShowLoader } = useContext(appContext);
  const navigate = useNavigate();
  const currentDate = new Date();

  const [excelData, setExcelData] = useState([]);

  const [item, setItem] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [showEligibilityModal, setShowEligibilityModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const [fromDate, setFromDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-01`
  );
  const [toDate, setToDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate()}`
  );

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const tableHeaders = [
    {
      name: "SQ #",
    },
    { name: "Payment Amount" },
    { name: "Payment Currency" },
    { name: "Payment Note Date" },
    { name: "Confirmation Status" },
    { name: "Payment Status" },
    { name: "Provider" },
    { name: "Payer" },
    { name: "Actions" },
  ];

  const [refetchFilterVariables, setRefetchFilterVariables] = useState({
    limit: limit,
    from_date: fromDate,
    to_date: toDate,
  });

  const { loading, error, data, refetch } = useQuery(
    gqlPayments.GET_ALL_PAYMENTS,
    {
      variables: {
        input: {
          from_date: fromDate,
          to_date: toDate,
          limit: 10,
          page: 1,
        },
      },
      fetchPolicy: "network-only",
    }
  );

  const {
    loading: pollLoading,
    error: pollError,
    data: pollData,
    refetch: refetchPoll,
  } = useQuery(gqlPayments.PAYMENT_POLL_REQ, {
    skip: true, // Skip initial fetching
  });

  useEffect(() => {
    if (data && data?.listPayments?.message) {
      toast.success(data?.listPayments?.message);
    }
  }, [data]);

  const pollClicked = () => {
    toast.loading("Requesting!");
    refetchPoll().then(async (res) => {
      await refetch({
        input: {
          from_date: fromDate,
          to_date: toDate,
          limit: 10,
          page: 1,
        },
      });
    });
  };

  // const pollClicked = async () => {
  //   toast.loading("Requesting!");
  //   try {
  //     const res = await refetchPoll();
  //     console.log(res);
  //     toast.dismiss(); // Dismiss the loading toast
  //     refetch({
  //       input: {
  //         from_date: fromDate,
  //         to_date: toDate,
  //         limit: 10,
  //         page: 1,
  //       },
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     toast.dismiss(); // Dismiss the loading toast
  //     toast.error("An error occurred while fetching the data.");
  //   }
  // };

  const onDataRowClicked = (event, item) => {
    setItem(item);
    setShowDetailsModal(true);
    // alert("aa")
  };

  const onPageChange = (currentPage) => {
    console.log(currentPage);
    setPageNo(currentPage);
    let vars = {
      ...refetchFilterVariables,
      limit: limit,
      page: currentPage,
      from_date: fromDate,
      to_date: toDate,
    };
    refetch({ input: vars });
  };

  return (
    <>
      <div class="eligibility_container">
        <div class="eligibility-page">
          {/* eligibility-content block md:flex justify-between */}
          <div class="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
            <div className="">
              <Tooltip
                placement="right"
                title="Keep track of your Payments"
                className="my-auto !mt-2"
              >
                <span className="font-semibold text-black/70 text-lg ">
                  PAYMENTS List
                  <span className="italic text-lg ml-1">
                    ({data?.listPayments?.total || 0}{" "}
                    <span className="text-xs font-bold italic">Entries</span>)
                  </span>
                </span>
              </Tooltip>
            </div>
            {/* create-eligibility mt-3 md:mt-0 search-filter */}
            <div class=" flex gap-3 flex-col md:flex-row">
              {/* <Button
                variant="contained"
                size="small"
                onClick={() => pollClicked()}
                style={{
                  backgroundImage:
                    "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                }}
                startIcon={<ImportExportIcon />}
              >
                Poll
              </Button> */}

              <Button
                variant="outlined"
                size="small"
                onClick={() => setShowFilter(() => !showFilter)}
                className="!border-primary !text-primary hover:!bg-primary/10"
                startIcon={<FilterAltOutlinedIcon />}
              >
                Apply Filter
              </Button>
            </div>
          </div>
        </div>

        {/* search-filter */}
        <div class="">
          {/* <div class="search">
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search"
            />
          </div> */}

          <Filter
            refetch={refetch}
            setPageNo={setPageNo}
            setLimit={setLimit}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            // refetchExcelDataResponse={refetchExcelDataResponse}
            setFromDate={setFromDate}
            setToDate={setToDate}
            setRefetchFilterVariables={setRefetchFilterVariables}
          />
        </div>

        <div class="super-git-table !p-0 !m-0">
          <table class="table-super-git !p-0">
            <thead className="!p-0">
              <tr className="!p-0">
                {tableHeaders.map((el, index) => {
                  return (
                    <th className="!p-2 !text-sm" key={"tableHeaders_" + index}>
                      {el.name}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {data?.listPayments?.data?.map((el, index) => {
                return (
                  <tr key={"paymentsData_" + index}>
                    {/* 1 */}
                    <td className="!p-1 !text-sm">
                      {pageNo * limit + index + 1 - limit}
                    </td>

                    {/* 2 */}
                    <td className="!p-1 !text-sm">{el.payment_amount}</td>

                    {/* 3 */}
                    <td className="!p-1 !text-sm">{el.payment_currency}</td>

                    {/* 4 */}
                    <td className="!p-1 !text-sm">{el.payment_note_date}</td>

                    {/* 5 */}
                    <td className="!p-1 !text-sm">{el.confirmation_status}</td>

                    {/* 6 */}
                    <td className="!p-1 !text-sm">{el.payment_status}</td>

                    {/* 7 */}
                    <td className="!p-1 !text-sm capitalize">
                      {el.providers_name_en.length > 100 ? (
                        <Tooltip title={el?.providers_name_en}>
                          <span>{el?.providers_name_en.slice(0, 100)}...</span>
                        </Tooltip>
                      ) : (
                        el?.providers_name_en
                      )}
                    </td>

                    {/* 8 */}
                    <td className="!p-1 !text-sm capitalize">
                      {el.payer_name_en.length > 100 ? (
                        <Tooltip title={el?.payer_name_en}>
                          <span>{el?.payer_name_en.slice(0, 100)}...</span>
                        </Tooltip>
                      ) : (
                        el?.payer_name_en
                      )}
                    </td>

                    {/* 9 */}
                    <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                      <Tooltip title="View">
                        <div
                          onClick={(event) => onDataRowClicked(event, el)}
                          className="text-xl cursor-pointer"
                        >
                          <MdOutlineRemoveRedEye />
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div>
          <Pagination
            currentPage={pageNo}
            totalPages={data?.listPayments?.pages}
            onPageChange={onPageChange}
          />
        </div>
        <DetailsModal
          showDetails={showDetailsModal}
          setShowDetails={setShowDetailsModal}
          item={item}
        />
      </div>
      <AppModal isOpen={loading || pollLoading} />
    </>
  );

  return (
    <div className="row ">
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card bg-white shadow">
          <div className="card-body rounded-md">
            <div className="pb-2 md:flex md:items-center md:justify-between">
              <h4 className="card-title">Payments</h4>
              <div className="flex space-x-3 md:mt-0 md:ml-4">
                {/* <DownloadExcel
                  data={excelData}
                  fileName={"PreAuthorizationTransactions"}
                />
                <button
                  type="button"
                  onClick={() => setShowFilter(() => !showFilter)}
                  className=" inline-flex justify-center rounded-md border border-transparent bg-cyan-600 
              py-2 px-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-fit"
                >
                  <FunnelIcon
                    className="h-3 w-3 text-white-900 text-xl hover:cursor-pointer  "
                    aria-hidden="true"
                  />
                </button> */}
                <PollBtn />
              </div>
            </div>
            {showFilter && (
              <Filter
                refetch={refetch}
                setPageNo={setPageNo}
                setLimit={setLimit}
                // refetchExcelDataResponse={refetchExcelDataResponse}
                setFromDate={setFromDate}
                setToDate={setToDate}
                setRefetchFilterVariables={setRefetchFilterVariables}
              />
            )}

            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    {tableHeaders.map((el, index) => {
                      return (
                        <th className="py-2" key={"tableHeaders_" + index}>
                          {el.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error.message}</p>}
                <tbody>
                  {data?.getAllPayments?.Data?.map((el, index) => {
                    return (
                      <tr
                        key={"paymentsData_" + index}
                        onClick={(event) => onDataRowClicked(event, el)}
                        className="cursor-pointer"
                      >
                        <td>{el.PaymentAmount}</td>
                        <td>{el.PaymentCurrency}</td>
                        <td>{el.PaymentNoteDate}</td>
                        <td>{el.ConfirmationStatus}</td>
                        <td>{el.PaymentStatus}</td>
                        <td>{el.ProvidersNameEn}</td>
                        <td>{el.PayerNameEn}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {data?.getAllPayments?.Data && (
              <div className="mt-5">
                <Pagination
                  currentPage={pageNo}
                  totalPages={data?.getAllPayments?.Pages}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <DetailsModal
        showDetails={showDetailsModal}
        setShowDetails={setShowDetailsModal}
        item={item}
      />
    </div>
  );
}

export default PaymentList;

// const {
//   loading: excelDataLoading,
//   error: excelDataError,
//   data: excelDataResponse,
//   refetch: refetchExcelDataResponse,
// } = useQuery(gqlPreAuth.GET_PREAUTH_EXCEL_DATA, {
//   variables: {
//     input: {
//       fromDate: currentDate.toISOString().substring(0, 10),
//       toDate: currentDate.toISOString().substring(0, 10),
//     },
//   },
// });
// useEffect(() => {
//   if (excelDataResponse) {
//     setExcelData(
//       JSON.parse(
//         excelDataResponse?.getAllPreAuthTransactionsByFilterInExcel?.Data
//       )
//     );
//   }
// }, [excelDataResponse]);

// {/* <div class="eligibility-title">
//               <span>Payments List</span>
//               <div class="track-eligibility">
//                 <span>Keep track of your Payments</span>
//               </div>
//             </div> */}

//               {/* <div class="create-btn" onClick={() => pollClicked()}>
//                 <span>Poll</span>
//               </div> */}

//             {/* <div class="apply-filter">
//                 <div
//                   class="btn"
//                   onClick={() => setShowFilter(() => !showFilter)}
//                 >
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     width="20"
//                     height="20"
//                     viewBox="0 0 20 20"
//                     fill="none"
//                   >
//                     <path
//                       d="M5.83751 4.99992H14.1708L9.99584 10.2499L5.83751 4.99992ZM3.54584 4.67492C5.22918 6.83325 8.33751 10.8333 8.33751 10.8333V15.8333C8.33751 16.2916 8.71251 16.6666 9.17084 16.6666H10.8375C11.2958 16.6666 11.6708 16.2916 11.6708 15.8333V10.8333C11.6708 10.8333 14.7708 6.83325 16.4542 4.67492C16.8792 4.12492 16.4875 3.33325 15.7958 3.33325H4.20418C3.51251 3.33325 3.12084 4.12492 3.54584 4.67492Z"
//                       fill="black"
//                     />
//                   </svg>
//                   Apply Filter
//                 </div>
//               </div> */}

//                     {/* <div class="super-git-table">
//           <table class="table-super-git">
//             <thead>
//               <tr>
//                 {tableHeaders.map((el, index) => {
//                   return <th key={"tableHeaders_" + index}>{el.name}</th>;
//                 })}
//               </tr>
//             </thead>
//             <tbody>
//               {data?.listPayments?.data?.map((el, index) => {
//                 return (
//                   <tr
//                     key={"paymentsData_" + index}
//                     style={{ cursor: "pointer" }}
//                     onClick={(event) => onDataRowClicked(event, el)}
//                   >
//                     <td>{pageNo * limit + index + 1 - limit}</td>
//                     <td>{el.payment_amount}</td>
//                     <td>{el.payment_currency}</td>
//                     <td>{el.payment_note_date}</td>
//                     <td>{el.confirmation_status}</td>
//                     <td>{el.payment_status}</td>
//                     <td>{el.providers_name_en}</td>
//                     <td>{el.payer_name_en}</td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </div> */}
