import { useMutation, useQuery } from "@apollo/client";
import React, { useState, useContext, useEffect } from "react";
import beneficiary from "../../gql/beneficiary";

// import { ToastContainer, toast } from "react-toastify";
import toast from "react-hot-toast";
import { appContext } from "../../context/ContextProvider";
import { useNavigate, useParams } from "react-router-dom";

import "./style/payerlist.css";
import Pagination from "../../components/Pagination";
import physicians from "../../gql/physicians";
import AppModal from "../../components/AppModal";
import ConformationModal from "../../components/ConformationModal";
// import FilterModal from "./FilterModal";
import ptpa from "../../gql/ptpa";

import ImportPayerModal from "./ImportPayerModal";

import SearchPayerList from "./SearchPayerList";
import AddPriceListServiceModal from "./AddPriceListServiceModal.js";
import priceList from "../../gql/priceList.js";
import { Button, Tooltip } from "@mui/material";
import { LuTrash2 } from "react-icons/lu/index.js";
import { TbEditCircle } from "react-icons/tb/index.js";
import "./style/payerlist.css";
import AddIcon from "@mui/icons-material/Add";
import { BiSearch, BiX } from "react-icons/bi";
import _ from "lodash";

function PriceListSpecificPayer() {
  const navigate = useNavigate();
  const { payerId, effectiveDate, payerName } = useParams();

  const { permissions } = useContext(appContext);
  const [item, setItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [showSearchForm, setShowSearchForm] = useState(false);
  const [showPriceListServiceModal, setShowPriceListServiceModal] =
    useState(false);

  const [showFilter, setShowFilter] = useState(false);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const [delEntry, { loading: delLoading }] = useMutation(
    priceList.DELETE_PRICE_LIST,
    {
      update(proxy, result) {
        onSuccessfulDelRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  const onSuccessfulDelRequestResponse = (data) => {
    if (data?.deletePriceList) {
      toast.success(data?.deletePriceList?.message);
      refetch({
        input: {
          payer_id: payerId,
          effective_date: effectiveDate,
          limit: limit,
          page: pageNo,
        },
      });
      setShowDeleteAlert(false);
    } else {
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error.message}`);
  };

  const { loading, error, data, refetch } = useQuery(
    priceList.GET_SPECIFIC_PRICE_LIST_LIST,
    {
      variables: {
        input: {
          payer_id: payerId,
          effective_date: effectiveDate,
          limit: limit,
          page: pageNo,
        },
      },
    }
  );

  const {
    loading: loadingPriceListByID,
    error: errorriceListByID,
    data: dataPriceListByID,
    refetch: refetchPriceListByID,
  } = useQuery(priceList.GET_PRICE_LIST_BY_ID, {
    variables: {
      id: currentItem?.id,
    },
    skip: true,
  });

  const onPageChange = (currentPage) => {
    setPageNo(currentPage);
    refetch({
      input: {
        payer_id: payerId,
        effective_date: effectiveDate,
        limit: limit,
        page: currentPage,
      },
    });
  };

  const onClickDelete = (item) => {
    setCurrentItem(item);
    setShowDeleteAlert(true);
  };

  const deleteEntry = () => {
    delEntry({
      variables: {
        input: {
          effective_date: "",
          payer_id: "",
          id: currentItem.id,
        },
      },
    });
    setShowDeleteAlert(false);
  };

  const onClickEdit = (el) => {
    navigate("/physicians/edit/form/" + el.id);
  };

  const tableHeaders = [
    { name: "SQ #" },
    { name: "Service Type" },
    { name: "Standard Code" },
    { name: "Standard Description" },
    { name: "Non-Standard Code" },
    { name: "Non-Standard Description" },
    { name: "Unit Price" },
    { name: "Factor" },
    { name: "Actions" },
  ];

  const {
    loading: loadingPayers,
    error: errorPayer,
    data: payersData,
    refetch: refetchPayers,
  } = useQuery(ptpa.GET_ALL_PAYERS);

  const handleEdit = (data) => {
    setCurrentItem(data);

    if (currentItem) {
      refetchPriceListByID({ variables: { id: currentItem?.id } }).then(
        (data) => {
          setItem(data?.data?.getPriceListById?.data);
          setShowPriceListServiceModal(true);
        }
      );
    }
  };

  const {
    loading: loadingSearchPriceListCode,
    error: errorSearchPriceListCode,
    data: dataSearchPriceListCode,
    refetch: refetchSearchPriceListCode,
  } = useQuery(priceList.SEARCH_PRICE_LIST_CODE, {
    variables: {
      input: "",
      payerId: payerId,
    },
    skip: true,
  });

  const handleSearch = (term) => {
    refetchSearchPriceListCode({
      input: term,
      payerId: payerId,
    }).then((data) => {
      setSearchResult(data?.data?.searchPriceListCode?.data || []);
    });
  };

  const debouncedSearch = _.debounce(handleSearch, 1000);

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    if (newSearchTerm.length >= 3) {
      debouncedSearch(newSearchTerm);
    } else {
      setSearchResult([]);
    }
  };

  return (
    <div className="priceList_container">
      <div className="priceList_page">
        <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
          <div className="flex !gap-5">
            <div className="back my-auto" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                  fill="#4D4D53"
                ></path>
              </svg>
            </div>

            <div className="">
              <Tooltip
                placement="right"
                title="Keep track of your pricelists specific payer"
                className="my-auto !mt-2"
              >
                <span className="font-semibold text-black/70 text-lg ">
                  Payer: <span className="font-medium">{payerName || ""}</span>
                  <br />
                  <span className="text-base">
                    Effective Date:{" "}
                    <span className="font-medium">
                      {effectiveDate?.substring(0, 10) || ""}
                    </span>
                  </span>
                  <span className="italic text-base ml-1">
                    ({data?.listPayerPriceList?.total || 0}{" "}
                    <span className="text-xs font-bold italic">Entries</span>)
                  </span>
                </span>
              </Tooltip>
            </div>
          </div>

          <div className="my-auto">
            <div class=" flex gap-3 flex-col md:flex-row">
              <Button
                variant="contained"
                size="small"
                onClick={() => setShowPriceListServiceModal(true)}
                style={{
                  backgroundImage:
                    "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                }}
                startIcon={<AddIcon />}
              >
                Add Price List Service
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* search-filter */}
      <div className="pre_auth_req_page">
        <div className="add_item_fields grid grid-cols-1 lg:grid-cols-2 mb-2">
          <div className="field flex flex-col gap-1 relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
              <BiSearch className="h-5 w-5 text-gray-400" />
            </span>
            <input
              type="text"
              placeholder="Search for item by Service Code or Service Description"
              onChange={handleSearchChange}
              value={searchTerm}
              className="block w-full py-2 !px-10 "
            />
            {searchTerm.length > 0 && (
              <span
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => {
                  setSearchResult([]);
                  setSearchTerm("");
                }}
              >
                <BiX className="h-7 w-7 text-gray-400" />
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="super-git-table">
        <table className="table-super-git">
          <thead className="!p-0">
            <tr className="!p-0">
              {tableHeaders.map((el, index) => {
                return (
                  <th className="!p-2 !text-sm" key={"tableHeaders_" + index}>
                    {el.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {searchTerm.length > 2
              ? searchResult?.map((el, index) => {
                  return (
                    <tr key={"beneficiariesData_" + index}>
                      <td className="!p-1 !text-sm">
                        {pageNo * limit + index + 1 - limit}
                      </td>
                      <td className="!p-1 !text-sm capitalize text-start">
                        {el.service_type.length > 100 ? (
                          <Tooltip title={el?.service_type}>
                            <span>{el?.service_type.slice(0, 100)}...</span>
                          </Tooltip>
                        ) : (
                          el?.service_type
                        )}
                      </td>
                      <td className="!p-1 !text-sm">{el?.service_code}</td>

                      {/* 4 */}
                      <td className="!p-1 !text-sm capitalize">
                        {el.service_desc.length > 100 ? (
                          <Tooltip title={el?.service_desc}>
                            <span>{el?.service_desc.slice(0, 100)}...</span>
                          </Tooltip>
                        ) : (
                          el?.service_desc
                        )}
                      </td>

                      {/* 5 */}
                      <td className="!p-1 !text-sm">{el?.non_standard_code}</td>

                      {/* 6 */}
                      <td className="!p-1 !text-sm capitalize">
                        {el.non_standard_desc.length > 100 ? (
                          <Tooltip title={el?.non_standard_desc}>
                            <span>
                              {el?.non_standard_desc.slice(0, 100)}...
                            </span>
                          </Tooltip>
                        ) : (
                          el?.non_standard_desc
                        )}
                      </td>

                      <td className="!p-1 !text-sm">{el?.unit_price}</td>
                      <td className="!p-1 !text-sm">{el?.factor}</td>

                      <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                        <Tooltip title="Edit">
                          <div
                            onClick={() => handleEdit(el)}
                            className="text-xl"
                          >
                            <TbEditCircle />
                          </div>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <div
                            className="text-xl"
                            onClick={() => {
                              setCurrentItem(el);
                              setShowDeleteAlert(true);
                            }}
                          >
                            <LuTrash2 />
                          </div>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })
              : data?.listPayerPriceList?.data?.map((el, index) => {
                  return (
                    <tr key={"beneficiariesData_" + index}>
                      <td className="!p-2">
                        {pageNo * limit + index + 1 - limit}
                      </td>
                      <td className="!p-2 capitalize text-start">
                        {el.service_type.length > 100 ? (
                          <Tooltip title={el?.service_type}>
                            <span>{el?.service_type.slice(0, 100)}...</span>
                          </Tooltip>
                        ) : (
                          el?.service_type
                        )}
                      </td>
                      <td className="!p-2">{el?.service_code}</td>

                      {/* 4 */}
                      <td className="!p-2 capitalize">
                        {el.service_desc.length > 100 ? (
                          <Tooltip title={el?.service_desc}>
                            <span>{el?.service_desc.slice(0, 100)}...</span>
                          </Tooltip>
                        ) : (
                          el?.service_desc
                        )}
                      </td>

                      {/* 5 */}
                      <td className="!p-2">{el?.non_standard_code}</td>

                      {/* 6 */}
                      <td className="!p-2 capitalize">
                        {el.non_standard_desc.length > 100 ? (
                          <Tooltip title={el?.non_standard_desc}>
                            <span>
                              {el?.non_standard_desc.slice(0, 100)}...
                            </span>
                          </Tooltip>
                        ) : (
                          el?.non_standard_desc
                        )}
                      </td>

                      <td className="!p-2">{el?.unit_price}</td>
                      <td className="!p-2">{el?.factor}</td>

                      <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-0">
                        <Tooltip title="Edit">
                          <div
                            onClick={() => handleEdit(el)}
                            className="text-xl"
                          >
                            <TbEditCircle />
                          </div>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <div
                            className="text-xl"
                            onClick={() => {
                              setCurrentItem(el);
                              setShowDeleteAlert(true);
                            }}
                          >
                            <LuTrash2 />
                          </div>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>

      <div>
        {searchTerm.length > 2 ? null : (
          <Pagination
            currentPage={pageNo}
            totalPages={data?.listPayerPriceList?.pages}
            onPageChange={onPageChange}
          />
        )}
      </div>
      <ConformationModal
        open={showDeleteAlert}
        setOpen={setShowDeleteAlert}
        onDelete={deleteEntry}
      />
      <AddPriceListServiceModal
        showPriceListServiceModal={showPriceListServiceModal}
        setShowPriceListServiceModal={setShowPriceListServiceModal}
        payersData={payersData}
        item={item}
        refetchSpecificPriceList={refetch}
        pageNo={pageNo}
        limit={limit}
      />

      <AppModal
        isOpen={
          loading ||
          delLoading ||
          loadingPriceListByID ||
          loadingSearchPriceListCode
        }
      />
    </div>
  );
}

export default PriceListSpecificPayer;

// return (
//   <div className="superGit_container">
//     <div className="benefeciaries-page">
//       <div className="beneficiaries-content block md:flex justify-between ">
//         <div className="benefeciaries-title flex items-center">
//           <span className="">
//             {payerName || ""} / {effectiveDate || ""}
//           </span>
//         </div>

//         <div className="create-benefeciaries  !mt-0 !mb-0 search-filter">
//           <div
//             className="create-btn"
//             onClick={() => setShowPriceListServiceModal(true)}
//           >
//             <span>Add Price List Service</span>
//           </div>

//           <div className="apply-filter">
//             <div
//               className="btn"
//               onClick={() => setShowFilter(() => !showFilter)}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="20"
//                 height="20"
//                 viewBox="0 0 20 20"
//                 fill="none"
//               >
//                 <path
//                   d="M5.83751 4.99992H14.1708L9.99584 10.2499L5.83751 4.99992ZM3.54584 4.67492C5.22918 6.83325 8.33751 10.8333 8.33751 10.8333V15.8333C8.33751 16.2916 8.71251 16.6666 9.17084 16.6666H10.8375C11.2958 16.6666 11.6708 16.2916 11.6708 15.8333V10.8333C11.6708 10.8333 14.7708 6.83325 16.4542 4.67492C16.8792 4.12492 16.4875 3.33325 15.7958 3.33325H4.20418C3.51251 3.33325 3.12084 4.12492 3.54584 4.67492Z"
//                   fill="black"
//                 />
//               </svg>
//               Apply Filter
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="search-filter">

//        <div className="search"> <input type="search" name="search" id="search" placeholder="Search" /> </div>  ---Comment

//        <FilterModal  ---commentWhole
//         refetch={refetch}
//         showFilter={showFilter}
//         setShowFilter={setShowFilter}
//         setPageNo={setPageNo}
//         setLimit={setLimit}
//         refetchExcelDataResponse={refetchExcelDataResponse} ---comment
//       />

//     </div>
//     <div className="super-git-table">
//       <table className="table-super-git">
//         <thead>
//           <tr>
//             {tableHeaders.map((el, index) => {
//               return (
//                 <th className="" key={"tableHeaders_" + index}>
//                   {el.name}
//                 </th>
//               );
//             })}
//           </tr>
//         </thead>
//         <tbody>
//           {data?.listPayerPriceList?.data?.map((el, index) => {
//             return (
//               <tr key={"beneficiariesData_" + index}>
//                 <td>{pageNo * limit + index + 1 - limit}</td>
//                 <td>{el?.service_type}</td>
//                 <td>{el?.service_code}</td>
//                 <td>{el?.service_desc}</td>
//                 <td>{el?.non_standard_code}</td>
//                 <td>{el?.non_standard_desc}</td>
//                 <td>{el?.unit_price}</td>

//                 <td>{el?.factor}</td>

//                 <td className="flex gap-3">
//                   <Tooltip title="Edit">
//                     <div onClick={() => handleEdit(el)} className="text-xl">
//                       <TbEditCircle />
//                     </div>
//                   </Tooltip>
//                   <Tooltip title="Delete">
//                     <div
//                       className="text-xl"
//                       onClick={() => {
//                         setCurrentItem(el);
//                         setShowDeleteAlert(true);
//                       }}
//                     >
//                       <LuTrash2 />
//                     </div>
//                   </Tooltip>
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     </div>
//     <div>
//       <Pagination
//         currentPage={pageNo}
//         totalPages={data?.listPayerPriceList?.pages}
//         onPageChange={onPageChange}
//       />
//     </div>
//     <ConformationModal
//       open={showDeleteAlert}
//       setOpen={setShowDeleteAlert}
//       onDelete={deleteEntry}
//     />
//     <AddPriceListServiceModal
//       showPriceListServiceModal={showPriceListServiceModal}
//       setShowPriceListServiceModal={setShowPriceListServiceModal}
//       payersData={payersData}
//       item={item}
//       refetchSpecificPriceList={refetch}
//       pageNo={pageNo}
//       limit={limit}
//     />

//     <AppModal isOpen={loading || delLoading || loadingPriceListByID} />
//   </div>
// );
