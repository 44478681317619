import React, { useState } from "react";
import { Formik, useFormikContext } from "formik";
import { Switch } from "@headlessui/react";
import _ from "lodash";
import { useQuery, gql, useMutation } from "@apollo/client";
// import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import BeneficiarySearchNSelect from "../../../Eligibility/components/requestEligibility/BeneficiarySearchNSelect";
import gqlProviders from "../../../../gql/providers";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function PreAuthInfo({ onChange, refId }) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const navigate = useNavigate();
  const [providers, setProviders] = useState([]);
  const [subTypes, setSubTypes] = useState([
    { id: "op", name: "OutPatient" },
    { id: "ip", name: "InPatient" },
    { id: "emr", name: "Emergency" },
  ]);

  const [priorities, setPriorities] = useState([
    { id: "stat", name: "Stat" },
    { id: "normal", name: "Normal" },
    { id: "deferred", name: "Deferred" },
  ]);

  const formatProvidersData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.id,
          name: item.NameEn,
        });
      });
    } else {
    }
    return returnData;
  };

  // const {
  //   loading: loadingProviders,
  //   error: errorProvider,
  //   data: providersData,
  //   refetch,
  // } = useQuery(gqlProviders.GET_ALL_PROVIDERS_WITHOUT_PAGINATION);

  // React.useEffect(() => {
  //   if (providersData) {
  //     setProviders(formatProvidersData(providersData?.getAllProviders?.data));
  //   }
  // }, [providersData]);

  const payers = [
    {
      id: 1,
      nameAR: "شركة بوبا العربية للتأمين التعاوني",
      nameEN: "Bupa Arabia for Cooperative Insurance",
    },
    {
      id: 2,
      nameAR: "شركة بوبا العربية للتأمين التعاوني",
      nameEN: "Cooperative Company for Cooperative Insurance",
    },
    {
      id: 3,
      nameAR: "شركة بوبا العربية للتأمين التعاوني",
      nameEN: "Al-Rajhi Company for Cooperative Insurance",
    },
  ];

  const statuses = [
    { id: 1, name: "Processing Complete" },
    { id: 2, name: "Error" },
  ];

  const types = [
    { id: "institutional", name: "Institutional" },
    { id: "oral", name: "Dental" },
    { id: "pharmacy", name: "Pharmacy" },
    { id: "professional", name: "Professional" },
    { id: "vision", name: "Optical" },
  ];

  const handleSubType = (val) => {
    if (val == "institutional") {
      setFieldValue("subType", "ip");
      setSubTypes([
        { id: "ip", name: "InPatient" },
        { id: "emr", name: "Emergency" },
      ]);
    } else {
      setFieldValue("subType", "op");
      setSubTypes([{ id: "op", name: "OutPatient" }]);
    }
  };

  const addData = (values) => {
    // setShowEligibilityModal(false);
    let data = _.cloneDeep(values);
    console.log("data :: ", data);
    // checkEligibility({ variables: { input: data } });
  };
  const formikRef = React.useRef();
  React.useImperativeHandle(refId, () => ({
    Submit: async () => {
      await formikRef.current.submitForm();
    },
  }));

  function watchForm(data) {
    if (onChange) {
      onChange({
        values: data,
      });
    }
  }
  return (
    <div class="personal-data !p-3 !mb-3 !bg-gray-100">
      <div class="personal-title !mb-3">
        <div class="inventory-svg">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="34" height="34" rx="8" fill="#F4F6F8"></rect>
            <path
              d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
              fill="#F5793B"
            ></path>
            <path
              d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
              fill="#F5793B"
            ></path>
          </svg>
        </div>
        <div class="personal-head">
          <span>PreAuth Info</span>
        </div>
      </div>
      <div class="personal-fields grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
        <div className="flex flex-col gap-1">
          <label htmlFor="dateOrdered">Date Ordered*</label>
          <input
            type="date"
            name="dateOrdered"
            value={values.dateOrdered}
            onChange={handleChange}
            onBlur={handleBlur}
            id="dateOrdered"
          />
          {errors.dateOrdered && touched.dateOrdered ? (
            <p className="max-w-2xl text-sm leading-6 text-red-500">
              {errors.dateOrdered}
            </p>
          ) : null}
        </div>

        <div className="flex flex-col gap-1">
          <label htmlFor="claimType">Claim Type*</label>
          <select
            id="claimType"
            name="claimType"
            onChange={(e) => {
              setFieldValue("claimType", e.target.value);
              handleSubType(e.target.value);
            }}
            value={values.claimType}
            autoComplete="type-name"
            placeholder="Select Type"
          >
            <option value="">Select Type</option>
            {types.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  <p>{item.name}</p>
                </option>
              );
            })}
          </select>
          {errors.claimType && touched.claimType ? (
            <p className="max-w-2xl text-sm leading-6 text-red-500">
              {errors.claimType}
            </p>
          ) : null}
        </div>

        <div className="flex flex-col gap-1">
          <label htmlFor="subType">Sub Type</label>
          <select
            name="subType"
            onChange={handleChange}
            value={values.subType}
            id="sub-type"
          >
            <option value="">Select Sub Type</option>
            {subTypes.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  <p>{item.name}</p>
                </option>
              );
            })}
          </select>
        </div>

        <div className="flex flex-col gap-1">
          <label htmlFor="offlineEligibilityId">Offline Eligibility Id</label>
          <input
            type="text"
            name="offlineEligibilityId"
            value={values.offlineEligibilityId}
            onChange={handleChange}
            onBlur={handleBlur}
            id="offlineEligibilityId"
            placeholder="Offline Eligibility ID"
          />
        </div>
      </div>

      <div className="personal-fields grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 !mt-2.5">
        <div className="col-span-1 sm:col-span-2 lg:col-span-1 flex flex-col gap-1">
          <label htmlFor="offlineEligibilityId">
            Eligibility Response Identifier (Value)
          </label>
          <input
            type="text"
            name="eligibilityResponseIdentifierValue"
            value={values.eligibilityResponseIdentifierValue}
            onChange={handleChange}
            id=""
            placeholder="Eligibility Response Identifier (Value)"
          />
        </div>

        <div className="flex flex-col gap-1">
          <label htmlFor="eligibilityResponseIdentifierUrl">
            Eligibility Response Identifier (URL)
          </label>
          <input
            type="text"
            name="eligibilityResponseIdentifierUrl"
            value={values.eligibilityResponseIdentifierUrl}
            onChange={handleChange}
            onBlur={handleBlur}
            id="eligibilityResponseIdentifierUrl"
            placeholder="Eligibility Response Identifier (URL)"
          />
        </div>

        <div className="flex flex-col gap-1">
          <label htmlFor="offlineEligibilityDate">
            Offline Eligibility Date
          </label>
          <input
            type="date"
            name="offlineEligibilityDate"
            value={values.offlineEligibilityDate}
            onChange={handleChange}
            onBlur={handleBlur}
            id="offlineEligibilityDate"
            placeholder="Offline Eligibility Date"
          />
        </div>
      </div>

      <div className="personal-fields grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 !mt-2.5">
        <div className="col-span-1 sm:col-span-2 lg:col-span-1 flex flex-col gap-1">
          <label htmlFor="relatedPreAuthRefNo">
            Related Preauthorization Reference No(s).
          </label>
          <input
            type="text"
            name="relatedPreAuthRefNo"
            value={values.relatedPreAuthRefNo}
            onChange={handleChange}
            onBlur={handleBlur}
            id="relatedPreAuthRefNo"
            placeholder="Related Preauthorization Reference No(s)."
          />
        </div>

        <div className="flex flex-col gap-1">
          <label htmlFor="referringProvider">Ref. Provider</label>
          <select
            id="referringProvider"
            name="referringProvider"
            onChange={handleChange}
            value={values.referringProvider}
          >
            <option value="">Please Select Ref. Provider</option>
            {providers.map((item) => {
              return (
                <option value={item.id}>
                  <p>{item.name}</p>
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="priority">Priority</label>
          <select
            name="priority"
            onChange={handleChange}
            value={values.priority}
            id="priority"
          >
            <option value="">Please Select Priority</option>
            {priorities.map((item) => {
              return (
                <option value={item.id}>
                  <p>{item.name}</p>
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}

export default PreAuthInfo;
