import React, { useState } from "react";
import eligibility from "../../../../gql/eligibility";
import { useQuery } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";

import "../../styles/eligibility.css";
import "../../../Beneficiary/styles/BeneficiaryList.css";
import Details from "./components/Details";
import Coverages from "./components/Coverages";
import CostToBeneficiary from "./components/CostToBeneficiary";
import Accordians from "./components/Accordians";
import JsonTable from "../JsonTable";
import AppModal from "../../../../components/AppModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Classes from "./components/Classes";

function EligibilityDetails(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state?.item ? location.state.item : null;
  const [currentTab, setCurrentTab] = useState("details");
  const [expanded, setExpanded] = React.useState("detail");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const CustomTabs = (props) => (
    <Tabs
      {...props}
      sx={{
        "& .MuiTabs-indicator": {
          backgroundColor: "#ffab2c", // Customize indicator color
        },
        "& .Mui-selected": {
          color: "#ffab2c", // Customize text color for selected tab
        },
        "& .MuiTabs-flexContainer": {
          "& > *": {
            color: "black", // Set default text color to black
          },
        },
      }}
    />
  );

  const tabs = [{ label: "Details", value: "details" }];

  const { loading, error, data, refetch } = useQuery(
    eligibility.GET_TRANSACTION_DETAILS,
    {
      variables: {
        transactionId: item?.transaction_id,
      },
    }
  );
  return (
    <div class="superGit_container">
      <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
        <div class="flex !gap-5">
          <div class="back my-auto" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                fill="#4D4D53"
              ></path>
            </svg>
          </div>

          <div className="">
            <Tooltip
              placement="right"
              title="Keep track of your eligibility details"
              className="my-auto !mt-2"
            >
              <span className="font-semibold text-black/70 text-lg ">
                Eligibility Detail
                <br />
                <span className="text-base">
                  Transaction:{" "}
                  <span className="font-medium">{item?.transaction_id}</span>
                </span>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div class="pre_auth_details_nav_menu !flex !justify-center flex-col lg:flex-row gap-8 !mt-2">
        <div class="pre_auth_details_nav_menu_">
          <Box
            sx={{ maxWidth: { xs: 320, sm: 480, md: 720, lg: 980, xl: 1260 } }}
          >
            <CustomTabs
              value={currentTab}
              onChange={handleTabChange}
              textColor="inherit"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              {tabs.map((tab, i) => (
                <Tab key={i} value={tab.value} label={tab.label} />
              ))}
            </CustomTabs>
          </Box>
        </div>
      </div>
      <>
        {currentTab == "details" && (
          <>
            <Accordion
              expanded={expanded === "detail"}
              onChange={handleChange("detail")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={`${
                  expanded === "detail" ? "!bg-primary/80" : "!bg-white"
                } !rounded-t-lg !border-t-0`}
              >
                <Typography
                  className=" flex gap-3"
                  sx={{ width: "50%", flexShrink: 0 }}
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
                    <path
                      d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                      fill="white"
                    ></path>
                    <path
                      d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span className="!font-semibold my-auto text-lg">Detail</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Details item={data?.getVerificationTransaction?.data} />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "coverages"}
              onChange={handleChange("coverages")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={`${
                  expanded === "coverages" ? "!bg-primary/80" : "!bg-white"
                } `}
              >
                <Typography
                  className=" flex gap-3"
                  sx={{ width: "50%", flexShrink: 0 }}
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
                    <path
                      d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                      fill="white"
                    ></path>
                    <path
                      d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span className="!font-semibold my-auto text-lg">
                    Coverages
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Coverages
                    item={data?.getVerificationTransaction?.data?.coverages}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "costOfBenefits"}
              onChange={handleChange("costOfBenefits")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={`${
                  expanded === "costOfBenefits" ? "!bg-primary/80" : "!bg-white"
                } `}
              >
                <Typography
                  className=" flex gap-3"
                  sx={{ width: "50%", flexShrink: 0 }}
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
                    <path
                      d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                      fill="white"
                    ></path>
                    <path
                      d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span className="!font-semibold my-auto text-lg">
                    Cost of Benefits
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <CostToBeneficiary
                    item={data?.getVerificationTransaction?.data?.coverages}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "classes"}
              onChange={handleChange("classes")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={`${
                  expanded === "classes" ? "!bg-primary/80" : "!bg-white"
                } `}
              >
                <Typography
                  className=" flex gap-3"
                  sx={{ width: "50%", flexShrink: 0 }}
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
                    <path
                      d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                      fill="white"
                    ></path>
                    <path
                      d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span className="!font-semibold my-auto text-lg">
                    Classes
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Classes
                    item={data?.getVerificationTransaction?.data?.coverages}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "items"}
              onChange={handleChange("items")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={`${
                  expanded === "items" ? "!bg-primary/80" : "!bg-white"
                } `}
              >
                <Typography
                  className=" flex gap-3"
                  sx={{ width: "50%", flexShrink: 0 }}
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
                    <path
                      d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                      fill="white"
                    ></path>
                    <path
                      d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                      fill="white"
                    ></path>
                  </svg>
                  <span className="!font-semibold my-auto text-lg"> Items</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Accordians
                    mainItem={data?.getVerificationTransaction?.data}
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </>
      <AppModal isOpen={loading} />
    </div>
  );
}

export default EligibilityDetails;
