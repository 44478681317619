import React, { useState } from "react";
import toast from "react-hot-toast";
import { JSONTree } from "react-json-tree";

export default function ReactJsonTree({ data }) {
  const theme = {
    scheme: "monokai",
    author: "wimer hazenberg (http://www.monokai.nl)",
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#fd971f",
    base0A: "#f4bf75",
    base0B: "#a6e22e",
    base0C: "#a1efe4",
    base0D: "#66d9ef",
    base0E: "#ae81ff",
    base0F: "#cc6633",
  };

  const copyJSON = () => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    toast.success("JSON Copied Successfully!");
  };

  return (
    <div className="px-4 py-8">
      <div className="flex justify-between gap-4 flex-col md:flex-row items-center mb-4">
        <button
          className="px-4 py-2 bg-primary text-white rounded-md font-semibold"
          onClick={copyJSON}
        >
          Copy JSON
        </button>
        <div className="flex gap-2 text-lg italic">
          <span className="font-bold">Note: </span>
          <span>To Collapse/Expand JSON Click On Blue Arrow</span>
        </div>
      </div>
      <JSONTree
        data={data}
        theme={theme}
        invertTheme
        shouldExpandNodeInitially={() => true}
      />
    </div>
  );
}
