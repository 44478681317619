import { gql } from "@apollo/client";

const GET_ALL_INSURANCE_PLANS = gql`
  query GetAllInsurancePlans($limit: Int!, $page: Int!) {
    getAllInsurancePlans(limit: $limit, page: $page) {
      Status
      Message
      Data {
        _id
        memberCardId
        policyNumber
        expiryDate
        isPrimary
        payerId
        relationWithSubscriber
        coverageType
        patientShare
        maxLimit
        payerNphiesId
        patientId
      }
      Pages
    }
  }
`;

const CREATE_INSURANCE_PLAN = gql`
  mutation insurancePlansRegistration($input: [InsurancePlans]!) {
    insurancePlansRegistration(input: $input) {
      Message
      Status
      Data {
        _id
        memberCardId
        policyNumber
        expiryDate
        isPrimary
        payerId
        relationWithSubscriber
        coverageType
        patientShare
        maxLimit
        payerNphiesId
        patientId
        network
        payer {
          _id
          naphipesId
          licenseId
          nameAr
          nameEn
          tpaId
          url
          type
        }
      }
    }
  }
`;

const UPDATE_INSURANCE_PLAN = gql`
  mutation insurancePlanUpdate($input: UpdateInsurancePlans!) {
    insurancePlanUpdate(input: $input) {
      Message
      Status
      Data {
        _id
        memberCardId
        policyNumber
        expiryDate
        isPrimary
        payerId
        relationWithSubscriber
        coverageType
        patientShare
        maxLimit
        network
        payerNphiesId
        patientId
        payer {
          _id
          naphipesId
          licenseId
          nameAr
          nameEn
          type
          url
          tpaId
        }
      }
    }
  }
`;

const DELETE_INSURANCE_PLAN = gql`
  mutation deleteInsurancePlan($input: DeleteInsurancePlan!) {
    deleteInsurancePlan(input: $input) {
      Message
      Status
      ID
    }
  }
`;

export default {
  GET_ALL_INSURANCE_PLANS,
  CREATE_INSURANCE_PLAN,
  UPDATE_INSURANCE_PLAN,
  DELETE_INSURANCE_PLAN,
};
