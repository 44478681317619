import React, { useEffect, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import "../../PriceList/style/dragndropstyle.css";

const DragNdrop = ({
  width,
  height,
  formik,
  formattedData,
  setFormattedData,
}) => {
  const [files, setFiles] = useState("");

  useEffect(() => {
    if (formik.values?.data.length === 0) {
      formik.resetForm();
      setFiles("");
    }
  }, [formik.values?.data]);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      const newFiles = selectedFiles;
      setFiles(newFiles);
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files[0];
    if (droppedFiles) {
      const newFiles = droppedFiles;
      setFiles(newFiles);
    }
  };

  const handleRemoveFile = (index) => {
    // Create a new array without the element at the specified index
    const newData = formik.values?.data.filter((_, i) => i !== index);
    // Update the state with the new array
    setFormattedData(newData);
    formik.setFieldValue("data", newData);
  };

  useEffect(() => {
    formik.setFieldValue("file", files);
  }, [files]);

  return (
    <section className="drag-drop" style={{ width: width, height: height }}>
      <div
        className={`document-uploader !min-h-[180px] ${
          files.length > 0 ? "upload-box active" : "upload-box"
        }`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <>
          <div className="upload-info">
            <AiOutlineCloudUpload />
            <div>
              <p>Drag and drop your files here</p>
              <p>Limit 10MB per file. Supported file: .zip</p>
            </div>
          </div>
          <input
            type="file"
            accept=".zip"
            hidden
            id="browse"
            onChange={handleFileChange}
          />
          <label htmlFor="browse" className="browse-btn">
            Browse files
          </label>
        </>

        {formik.values?.data && formik.values?.data.length > 0 && (
          <div className="file-list">
            <div className="file-list__container">
              {formik.values?.data?.map((file, i) => (
                <div key={i} className="file-item">
                  <div className="file-info">
                    <p>
                      {file?.claim_no} ({file?.attachment?.filename})
                    </p>
                  </div>
                  <div className="file-actions">
                    <MdClear onClick={() => handleRemoveFile(i)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default DragNdrop;
