import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { XMarkIcon } from "@heroicons/react/20/solid";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import preAuthGQL from "../../../gql/preAuth";
// import { toast } from "react-toastify";
import toast from "react-hot-toast";

import "../styles/pre-auth.css";
import AppModal from "../../../components/AppModal";
import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import * as Yup from "yup";

const CommuniModal = ({
  showCommunicationModal,
  setshowCommunicationModal,
  dataArray,
  setdataArray,
  item,
  currentItem,
  communicationId,
  communicationRefetch,
}) => {
  //   const [file, setFile] = useState(null)
  const [image, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [commentArray, setcommentArray] = useState([]);
  const [selectEmpty, setselectEmpty] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [addCommunication, { data, loading, error }] = useMutation(
    preAuthGQL.ADD_COMMUNICATION,
    {
      update(proxy, result) {
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  const onSuccessfulRequestResponse = (data) => {
    if (data?.communicationRequest?.nphies_err) {
      toast.error(data?.communicationRequest?.message);

      data?.communicationRequest?.nphies_err &&
        data?.communicationRequest?.nphies_err.length > 0 &&
        data?.communicationRequest?.nphies_err?.map((err) =>
          toast.error(`${err?.code} - ${err?.display}`)
        );
    } else {
      toast.success(
        data?.communicationRequest?.message || "Successfully Added!"
      );
    }

    communicationRefetch({
      input: {
        transaction_id: currentItem?.transaction_id,
        request_name: "preAuth",
      },
    });
    setcommentArray([]);
  };

  const onResponseError = (error) => {
    toast.error(`${error?.message}`);
  };

  // let temp = [...dataArray];
  // temp.push(commentArray);
  // setdataArray(temp);

  const [obj, setobj] = useState({
    payload_attachment: null,
    payload_comment: null,
    item_sequence: null,
  });
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minHeight: "45vh",
      maxWidth: "800px",
      maxHeight: "75vh",
      overflowY: "scroll",
    },
  };

  const documentTypes = [
    { id: "Resident Card", name: "Resident Card" },
    { id: "Passport", name: "Passport" },
    { id: "Visa", name: "Visa" },
    { id: "National Card", name: "National Card" },
    { id: "Medical Record Number", name: "Medical Record Number" },
  ];

  const getCurrentDate = () => {
    const currentDate = new Date();

    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear());

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const removeItem = (data, index) => {
    let tempArray = [...commentArray];
    tempArray.splice(index, 1);
    setcommentArray(tempArray);
  };

  const addDataTwo = (e) => {
    e.preventDefault();
    if (obj.payload_attachment === "" && obj.payload_comment === "") {
      setselectEmpty(true);

      return;
    }

    let tempArr = [...commentArray];
    tempArr.push({ ...obj });
    setcommentArray(tempArr);

    setobj({
      payload_attachment: null,
      payload_comment: null,
      item_sequence: null,
    });
    setFile(null);
  };

  const handleChange = (e) => {
    setobj((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const addToCommunication = () => {
    // changeToBase64Data();
    try {
      if (communicationId) {
        addCommunication({
          variables: {
            input: {
              transaction_id: item?.getPreAuthTransaction?.data?.transaction_id,
              type: "solicited",
              request_name: "preAuth",
              communication_id: communicationId,
              payload: [...commentArray],
            },
          },
        });
      } else {
        addCommunication({
          variables: {
            input: {
              transaction_id: item?.getPreAuthTransaction?.data?.transaction_id,
              type: "unsolicited",
              request_name: "preAuth",
              payload: [...commentArray],
            },
          },
        });
      }

      setcommentArray([]);
      formik.resetForm();
    } catch (error) {
      console.log("The mutation error is ", error.message);
    }

    setshowCommunicationModal(false);
  };

  const changeToBase64Data = () => {
    let tempArr = [...commentArray];
    tempArr.map((item, index) => {
      if (tempArr[index].payload_attachment.payload_attachment_data) {
        const base64ImageData =
          tempArr[index].payload_attachment.payload_attachment_data;

        const [data, base64] = base64ImageData.split(",");
        tempArr[index].payload_attachment.payload_attachment_data = base64;
      }
    });
    setcommentArray(tempArr);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const formik = useFormik({
    initialValues: {
      payload_attachment: null,
      payload_comment: null,
      item_sequence: null,
    },
    validationSchema: Yup.object().shape({
      payload_attachment: Yup.object({
        payload_attachment_content_type: Yup.mixed()
          .nullable()
          .label("File Type")
          .test(
            "fileFormat",
            "Invalid file format. Please upload a JPG, JPEG, or PDF file.",
            (value) => {
              if (value) {
                return (
                  value === "image/jpeg" ||
                  value === "image/jpg" ||
                  value === "application/pdf"
                );
              }
              return true;
            }
          ),
        payload_attachment_data: Yup.mixed().nullable().label("File"),
        payload_attachment_title: Yup.string().nullable(),
        payload_attachment_creation_date: Yup.string().nullable(),
      }).nullable(),
      payload_comment: Yup.string()
        .nullable()
        .test(
          "commentOrAttachment",
          "Either attachment or comment is required",
          function (value) {
            const { payload_attachment } = this.parent;
            if (
              !value &&
              (!payload_attachment?.payload_attachment_content_type ||
                !payload_attachment?.payload_attachment_data)
            ) {
              return this.createError({
                path: "payload_comment",
                message: "Either attachment or comment is required",
              });
            }
            return true;
          }
        ),
      item_sequence: Yup.number().nullable(),
    }),

    onSubmit: async (values) => {
      let tempArr = [...commentArray];
      tempArr.push({ ...values });
      setcommentArray(tempArr);
      formik.resetForm();
    },
  });

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFile(URL.createObjectURL(event.target.files[0]));
        setFileType(file.type);

        formik?.setValues((prev) => ({
          ...prev,
          payload_attachment: {
            payload_attachment_content_type: file.type,
            payload_attachment_data: reader.result?.split(",")[1],
            payload_attachment_title: file.name,
            payload_attachment_creation_date: getCurrentDate(),
          },
          // [e.target.name]:e.target.valueobj
        }));
      };
    }
  };

  useEffect(() => {
    if (formik?.values?.payload_attachment) {
      formik?.setFieldValue("payload_comment", null);
    }
  }, [formik?.values?.payload_attachment]);

  useEffect(() => {
    if (formik?.values?.item_sequence) {
      formik?.setFieldValue(
        "item_sequence",
        parseInt(formik?.values?.item_sequence)
      );
    }
  }, [formik?.values?.item_sequence]);

  return (
    <div
      className={`${showCommunicationModal ? "open" : ""} modal`}
      id="search-filter"
    >
      <div class="modal-content">
        <div class="close" onClick={() => setshowCommunicationModal(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#DE5042"
            />
          </svg>
          <div class="bene-text">
            {communicationId ? "Reply" : "Add Communication"}
          </div>
        </div>
        <div class="">
          <div>
            <Button
              component="label"
              size="small"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              className="my-3"
            >
              {formik?.values?.payload_attachment?.payload_attachment_title ||
                "Upload file"}
              <VisuallyHiddenInput type="file" onChange={onImageChange} />
            </Button>
            {formik.errors.payload_attachment?.payload_attachment_data &&
            formik.touched.payload_attachment?.payload_attachment_data ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.payload_attachment.payload_attachment_data}
              </p>
            ) : null}
            {formik.errors.payload_attachment
              ?.payload_attachment_content_type &&
            formik.touched.payload_attachment
              ?.payload_attachment_content_type ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {
                  formik.errors.payload_attachment
                    .payload_attachment_content_type
                }
              </p>
            ) : null}
          </div>

          {!formik?.values?.payload_attachment && (
            <>
              <Divider className="">or</Divider>

              <div className="my-3">
                <input
                  type="text"
                  name="payload_comment"
                  value={formik.payload_comment}
                  onChange={formik.handleChange}
                  placeholder="Enter your comment here"
                  autoComplete="off"
                  className="mb-0"
                />
                {formik.errors.payload_comment &&
                formik.touched.payload_comment ? (
                  <p className=" max-w-2xl text-sm leading-6 text-red-500">
                    {formik.errors.payload_comment}
                  </p>
                ) : null}
              </div>
            </>
          )}

          <div>
            <select
              id="documentType"
              name="item_sequence"
              onChange={formik.handleChange}
              value={formik.item_sequence}
              autoComplete="type-name"
              required
            >
              <option value={null}>Please Select</option>
              {item?.getPreAuthTransaction?.data?.items?.map((item, index) => {
                return (
                  <option key={index} value={item.sequence_no}>
                    {item.type} - {item.standard_code}-
                    {item.standard_description}
                  </option>
                );
              })}
            </select>
            {formik.errors.item_sequence && formik.touched.item_sequence ? (
              <p className=" max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.item_sequence}
              </p>
            ) : null}
          </div>
          <div class="add-btn" onClick={formik.handleSubmit}>
            <span>Add</span>
          </div>
          <div className="mt-11">
            {commentArray
              ? commentArray.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex justify-between bg-cyan-50 my-2 rounded-md"
                    >
                      <div
                        className="flex flex-col gap-2   py-2 px-2 flex-1
                  rounded-md
                  "
                      >
                        <div>
                          {item?.payload_attachment?.payload_attachment_data ? (
                            <div>
                              {item?.payload_attachment
                                ?.payload_attachment_content_type ===
                              "application/pdf" ? (
                                item?.payload_attachment
                                  ?.payload_attachment_title
                              ) : (
                                <img
                                  src={
                                    "data:image/jpeg;base64," +
                                    item.payload_attachment
                                      .payload_attachment_data
                                  }
                                  alt={`${item?.payload_attachment?.payload_attachment_title}`}
                                  className="w-12 h-12 rounded-full"
                                />
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {!item?.payload_attachment?.payload_attachment_data && (
                          <div className="flex items-center gap-2">
                            <h3 className="font-semibold">Comment:</h3>

                            <span className="text-sm text-gray-500">
                              {item?.payload_comment
                                ? item?.payload_comment
                                : "-"}
                            </span>
                          </div>
                        )}

                        <div className="flex items-center gap-4">
                          <span>
                            <span className="font-semibold">Seq:</span>{" "}
                            {item?.item_sequence}
                          </span>
                        </div>
                      </div>
                      <div className="p-2">
                        <XMarkIcon
                          className="h-5 w-5 text-gray-900 hover:cursor-pointer "
                          aria-hidden="true"
                          onClick={() => removeItem(item, index)}
                        />
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
          {commentArray.length > 0 && (
            <div class="search-btn" onClick={addToCommunication}>
              <span>{communicationId ? "Reply" : "Send Communication"}</span>
            </div>
          )}
          <div
            class="reset-btn mb-6"
            onClick={() => setshowCommunicationModal(false)}
          >
            <span>Cancel</span>
          </div>
        </div>
      </div>
      <AppModal isOpen={loading} />
    </div>
  );
  return (
    <Modal
      // isOpen={true}
      isOpen={showCommunicationModal}
      onRequestClose={() => setshowCommunicationModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-2xl text-cyan-800 font-bold">
          {communicationId ? "Reply" : "Add Communication"}
        </h3>
        <XMarkIcon
          className="h-5 w-5 text-gray-900 hover:cursor-pointer "
          aria-hidden="true"
          onClick={() => setshowCommunicationModal(false)}
        />
      </div>
      <hr />

      <div className="-form  " action="" method="PO">
        <form action="#" method="POST">
          <div className="flex items-center my-3">
            <div className="flex- w-[35%] flex items-center">
              <input
                type="file"
                placeholder=""
                accept="image/*"
                onChange={onImageChange}
              />
              <span className="mx-3">OR</span>
            </div>
            <div className=" w-[65%]">
              <div className="sm:col-span-2 col-span-6">
                <input
                  placeholder="Enter your comment here"
                  type="text"
                  name="payload_comment"
                  value={obj.payload_comment}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-100"
                />
              </div>
              {selectEmpty && (
                <span className="text-sm text-red-900">
                  please enter a comments
                </span>
              )}
            </div>
          </div>
          {/* second row */}
          <div className="flex items-center gap-4">
            <div className="sm:col-span-2 col-span-6 required relative flex-1 gap-4">
              <select
                id="documentType"
                name="item_sequence"
                onChange={handleChange}
                value={obj.item_sequence}
                autoComplete="type-name"
                required
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Please Select</option>
                {item?.getPreAuthTransaction?.data?.items?.map(
                  (item, index) => {
                    return (
                      <option value={parseInt(item.sequence_no)}>
                        {item.type} - {item.standard_code}-
                        {item.standard_description}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
            <div>
              <button
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                //   value={"submit"}
                onClick={addDataTwo}
              >
                Add
              </button>
            </div>
          </div>

          <div className="mt-11">
            {commentArray
              ? commentArray.map((item, index) => {
                  return (
                    <div className="flex justify-between ">
                      <div
                        className="flex flex-col gap-2 bg-cyan-100 my-2 py-2 px-2 flex-1
                  rounded-md
                  "
                      >
                        <div>
                          {item?.payload_attachment?.payload_attachment_data ? (
                            <div>
                              <img
                                src={
                                  item.payload_attachment
                                    .payload_attachment_data
                                }
                                alt="no file "
                                className="w-12 h-12 rounded-full"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex items-center gap-4">
                          <h3>Comment:</h3>

                          <span className="text-sm text-gray-500">
                            {item?.payload_comment
                              ? item?.payload_comment
                              : "-"}
                          </span>
                        </div>

                        <div className="flex items-center gap-4">
                          <span>Seq: {item?.item_sequence}</span>
                        </div>
                      </div>
                      <div>
                        <XMarkIcon
                          className="h-5 w-5 text-gray-900 hover:cursor-pointer "
                          aria-hidden="true"
                          onClick={() => removeItem(item, index)}
                        />
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>

          <div className="mt-12 justify-between flex text-right ">
            <h1></h1>
            <div className=" flex gap-4  items-end">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                onClick={() => setshowCommunicationModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                onClick={addToCommunication}
              >
                {communicationId ? "Reply" : "Add Communication"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CommuniModal;

// import { Formik } from "formik";
// import React, { useState } from "react";
// import Modal from "react-modal";
// import { XMarkIcon } from "@heroicons/react/20/solid";
// import _ from "lodash";
// import { useMutation } from "@apollo/client";
// import usersGQL from "../../../gql/preAuth";
// // import { toast } from "react-toastify";
// import toast from "react-hot-toast";

// import "../styles/pre-auth.css";
// import AppModal from "../../../components/AppModal";

// const CommuniModal = ({
//   showCommunicationModal,
//   setshowCommunicationModal,
//   dataArray,
//   setdataArray,
//   item,
//   currentItem,
//   communicationId,
//   communicationRefetch,
// }) => {
//   //   const [file, setFile] = useState(null)
//   const [image, setFile] = useState(null);
//   const [fileType, setFileType] = useState(null);
//   const [commentArray, setcommentArray] = useState([]);
//   const [selectEmpty, setselectEmpty] = useState(false);
//   const [selectedItem, setselectedItem] = useState(null);
//   const [addCommunication, { data, loading, error }] = useMutation(
//     usersGQL.ADD_COMMUNICATION,
//     {
//       update(proxy, result) {
//         onSuccessfulRequestResponse(result.data);
//       },
//       onError(error) {
//         onResponseError(error.graphQLErrors[0]);
//       },
//     }
//   );

//   const onSuccessfulRequestResponse = (data) => {
//     if (data?.communicationRequest?.nphies_status === "fatal-error") {
//       toast.error(data?.communicationRequest?.message);

//       data?.communicationRequest?.nphies_err &&
//         data?.communicationRequest?.nphies_err.length > 0 &&
//         data?.communicationRequest?.nphies_err?.map((err) =>
//           toast.error(`${err?.code} - ${err?.display}`)
//         );
//     } else {
//       toast.success(
//         data?.communicationRequest?.message || "Successfully Added!"
//       );
//     }
//     communicationRefetch({
//       input: {
//         transaction_id: currentItem?.transaction_id,
//         request_name: "preAuth",
//       },
//     });
//     setcommentArray([]);
//   };

//   const onResponseError = (error) => {
//     toast.error(`${error?.message}`);
//   };

//   // let temp = [...dataArray];
//   // temp.push(commentArray);
//   // setdataArray(temp);

//   const [obj, setobj] = useState({
//     payload_attachment: null,
//     payload_comment: null,
//     item_sequence: null,
//   });
//   const customStyles = {
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       marginRight: "-50%",
//       transform: "translate(-50%, -50%)",
//       minHeight: "45vh",
//       maxWidth: "800px",
//       maxHeight: "75vh",
//       overflowY: "scroll",
//     },
//   };

//   const documentTypes = [
//     { id: "Resident Card", name: "Resident Card" },
//     { id: "Passport", name: "Passport" },
//     { id: "Visa", name: "Visa" },
//     { id: "National Card", name: "National Card" },
//     { id: "Medical Record Number", name: "Medical Record Number" },
//   ];

//   const getCurrentDate = () => {
//     const currentDate = new Date();

//     const day = String(currentDate.getDate()).padStart(2, "0");
//     const month = String(currentDate.getMonth() + 1).padStart(2, "0");
//     const year = String(currentDate.getFullYear());

//     const formattedDate = `${year}-${month}-${day}`;
//     return formattedDate;
//   };
//   const onImageChange = (event) => {
//     if (event.target.files && event.target.files[0]) {
//       const file = event.target.files[0];
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onloadend = () => {
//         // setFieldValue(`supportingInfos.${index}.fileName`, file.name);
//         // setFieldValue(`supportingInfos.${index}.fileType`, file.type);
//         // setFieldValue(`supportingInfos.${index}.attachment`, reader.result);

//         setFile(URL.createObjectURL(event.target.files[0]));
//         setFileType(file.type);

//         setobj((prev) => ({
//           ...prev,
//           payload_attachment: {
//             payload_attachment_content_type: file.type,
//             payload_attachment_data: reader.result,
//             payload_attachment_title: file.name,
//             payload_attachment_creation_date: getCurrentDate(),
//           },
//           // [e.target.name]:e.target.valueobj
//         }));
//       };
//     }
//   };

//   const removeItem = (data, index) => {
//     let tempArray = [...commentArray];
//     tempArray.splice(index, 1);
//     setcommentArray(tempArray);
//   };

//   const addDataTwo = (e) => {
//     e.preventDefault();
//     if (obj.payload_attachment === "" && obj.payload_comment === "") {
//       setselectEmpty(true);

//       return;
//     }

//     let tempArr = [...commentArray];
//     tempArr.push({ ...obj });
//     setcommentArray(tempArr);

//     setobj({
//       payload_attachment: null,
//       payload_comment: null,
//       item_sequence: null,
//     });
//     setFile(null);
//   };

//   const handleChange = (e) => {
//     setobj((prev) => ({
//       ...prev,
//       [e.target.name]: e.target.value,
//     }));
//   };

//   const addToCommunication = () => {
//     changeToBase64Data();
//     try {
//       if (communicationId) {
//         addCommunication({
//           variables: {
//             input: {
//               transaction_id: item?.getPreAuthTransaction?.data?.transaction_id,
//               type: "solicited",
//               request_name: "preAuth",
//               communication_id: communicationId,
//               payload: [...commentArray],
//             },
//           },
//         });
//       } else {
//         addCommunication({
//           variables: {
//             input: {
//               transaction_id: item?.getPreAuthTransaction?.data?.transaction_id,
//               type: "unsolicited",
//               request_name: "preAuth",
//               payload: [...commentArray],
//             },
//           },
//         });
//       }
//     } catch (error) {
//       console.log("The mutation error is ", error.message);
//     }

//     setshowCommunicationModal(false);
//   };

//   const changeToBase64Data = () => {
//     let tempArr = [...commentArray];
//     tempArr.map((item, index) => {
//       if (tempArr[index].payload_attachment.payload_attachment_data) {
//         const base64ImageData =
//           tempArr[index].payload_attachment.payload_attachment_data;

//         const [data, base64] = base64ImageData.split(",");
//         tempArr[index].payload_attachment.payload_attachment_data = base64;
//       }
//     });
//     setcommentArray(tempArr);
//   };

//   return (
//     <div
//       className={`${showCommunicationModal ? "open" : ""} modal`}
//       id="search-filter"
//     >
//       <div class="modal-content">
//         <div class="close" onClick={() => setshowCommunicationModal(false)}>
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//             fill="none"
//           >
//             <path
//               d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
//               fill="#DE5042"
//             />
//           </svg>
//           <div class="bene-text">
//             {communicationId ? "Reply" : "Add Communication"}
//           </div>
//         </div>
//         <div class="">
//           <div>
//             {/* <!-- <input type="file" name="fileUpload" id="fileUpload"> --> */}
//             <label for="fileUpload" class="file-label" id="fileLabel">
//               <span id="chooseFileText">Drag and Drop files here</span>
//               <span id="fileNameText">Choose File</span>
//             </label>
//             <input
//               type="file"
//               name="fileUpload"
//               id="fileUpload"
//               onChange={onImageChange}
//             />

//             {/* {obj?.payload_attachment?.payload_attachment_data && (
//             <div>
//               <h4>{obj?.payload_attachment?.payload_attachment_title}</h4>
//               <img
//                 src={obj?.payload_attachment?.payload_attachment_data}
//                 alt="no file "
//                 className="w-12 h-12 rounded-full"
//               />
//             </div>
//           )} */}
//             {obj?.payload_attachment?.payload_attachment_data && (
//               <div id="fileInfo">
//                 <span id="fileName">
//                   {obj?.payload_attachment?.payload_attachment_title}
//                 </span>
//                 <img
//                   src={obj?.payload_attachment?.payload_attachment_data}
//                   alt="no file "
//                   className="w-12 h-12 rounded-full"
//                 />
//               </div>
//             )}
//           </div>
//           <div>
//             <input
//               type="text"
//               name="payload_comment"
//               value={obj.payload_comment}
//               onChange={handleChange}
//               id="comment_here"
//               placeholder="Enter your comment here"
//             />
//             {selectEmpty && (
//               <span className="text-sm text-red-900">
//                 please enter a comments
//               </span>
//             )}
//           </div>
//           <select
//             id="documentType"
//             name="item_sequence"
//             onChange={handleChange}
//             value={obj.item_sequence}
//             autoComplete="type-name"
//             required
//           >
//             <option value="">Please Select</option>
//             {item?.getPreAuthTransaction?.data?.items?.map((item, index) => {
//               return (
//                 <option value={parseInt(item.sequence_no)}>
//                   {item.type} - {item.standard_code}-{item.standard_description}
//                 </option>
//               );
//             })}
//           </select>
//           <div class="add-btn" onClick={addDataTwo}>
//             <span>Add</span>
//           </div>
//           <div className="mt-11">
//             {commentArray
//               ? commentArray.map((item, index) => {
//                   return (
//                     <div className="flex justify-between ">
//                       <div
//                         className="flex flex-col gap-2 bg-cyan-100 my-2 py-2 px-2 flex-1
//                   rounded-md
//                   "
//                       >
//                         <div>
//                           {item?.payload_attachment?.payload_attachment_data ? (
//                             <div>
//                               <img
//                                 src={
//                                   item.payload_attachment
//                                     .payload_attachment_data
//                                 }
//                                 alt="no file "
//                                 className="w-12 h-12 rounded-full"
//                               />
//                             </div>
//                           ) : (
//                             ""
//                           )}
//                         </div>
//                         <div className="flex items-center gap-4">
//                           <h3>Comment:</h3>

//                           <span className="text-sm text-gray-500">
//                             {item?.payload_comment
//                               ? item?.payload_comment
//                               : "-"}
//                           </span>
//                         </div>

//                         <div className="flex items-center gap-4">
//                           <span>Seq: {item?.item_sequence}</span>
//                         </div>
//                       </div>
//                       <div>
//                         <XMarkIcon
//                           className="h-5 w-5 text-gray-900 hover:cursor-pointer "
//                           aria-hidden="true"
//                           onClick={() => removeItem(item, index)}
//                         />
//                       </div>
//                     </div>
//                   );
//                 })
//               : ""}
//           </div>
//           <div class="search-btn" onClick={addToCommunication}>
//             <span>{communicationId ? "Reply" : "Add Communication"}</span>
//           </div>
//           <div
//             class="reset-btn"
//             onClick={() => setshowCommunicationModal(false)}
//           >
//             <span>Cancel</span>
//           </div>
//         </div>
//       </div>
//       <AppModal isOpen={loading} />
//     </div>
//   );
//   return (
//     <Modal
//       // isOpen={true}
//       isOpen={showCommunicationModal}
//       onRequestClose={() => setshowCommunicationModal(false)}
//       style={customStyles}
//       contentLabel="Example Modal"
//     >
//       <div className="flex justify-between items-center mb-2">
//         <h3 className="text-2xl text-cyan-800 font-bold">
//           {communicationId ? "Reply" : "Add Communication"}
//         </h3>
//         <XMarkIcon
//           className="h-5 w-5 text-gray-900 hover:cursor-pointer "
//           aria-hidden="true"
//           onClick={() => setshowCommunicationModal(false)}
//         />
//       </div>
//       <hr />

//       <div className="-form  " action="" method="PO">
//         <form action="#" method="POST">
//           <div className="flex items-center my-3">
//             <div className="flex- w-[35%] flex items-center">
//               <input
//                 type="file"
//                 placeholder=""
//                 accept="image/*"
//                 onChange={onImageChange}
//               />
//               <span className="mx-3">OR</span>
//             </div>
//             <div className=" w-[65%]">
//               <div className="sm:col-span-2 col-span-6">
//                 <input
//                   placeholder="Enter your comment here"
//                   type="text"
//                   name="payload_comment"
//                   value={obj.payload_comment}
//                   onChange={handleChange}
//                   className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm w-100"
//                 />
//               </div>
//               {selectEmpty && (
//                 <span className="text-sm text-red-900">
//                   please enter a comments
//                 </span>
//               )}
//             </div>
//           </div>
//           {/* second row */}
//           <div className="flex items-center gap-4">
//             <div className="sm:col-span-2 col-span-6 required relative flex-1 gap-4">
//               <select
//                 id="documentType"
//                 name="item_sequence"
//                 onChange={handleChange}
//                 value={obj.item_sequence}
//                 autoComplete="type-name"
//                 required
//                 className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
//               >
//                 <option value="">Please Select</option>
//                 {item?.getPreAuthTransaction?.data?.items?.map(
//                   (item, index) => {
//                     return (
//                       <option value={parseInt(item.sequence_no)}>
//                         {item.type} - {item.standard_code}-
//                         {item.standard_description}
//                       </option>
//                     );
//                   }
//                 )}
//               </select>
//             </div>
//             <div>
//               <button
//                 className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
//                 //   value={"submit"}
//                 onClick={addDataTwo}
//               >
//                 Add
//               </button>
//             </div>
//           </div>

//           <div className="mt-11">
//             {commentArray
//               ? commentArray.map((item, index) => {
//                   return (
//                     <div className="flex justify-between ">
//                       <div
//                         className="flex flex-col gap-2 bg-cyan-100 my-2 py-2 px-2 flex-1
//                   rounded-md
//                   "
//                       >
//                         <div>
//                           {item?.payload_attachment?.payload_attachment_data ? (
//                             <div>
//                               <img
//                                 src={
//                                   item.payload_attachment
//                                     .payload_attachment_data
//                                 }
//                                 alt="no file "
//                                 className="w-12 h-12 rounded-full"
//                               />
//                             </div>
//                           ) : (
//                             ""
//                           )}
//                         </div>
//                         <div className="flex items-center gap-4">
//                           <h3>Comment:</h3>

//                           <span className="text-sm text-gray-500">
//                             {item?.payload_comment
//                               ? item?.payload_comment
//                               : "-"}
//                           </span>
//                         </div>

//                         <div className="flex items-center gap-4">
//                           <span>Seq: {item?.item_sequence}</span>
//                         </div>
//                       </div>
//                       <div>
//                         <XMarkIcon
//                           className="h-5 w-5 text-gray-900 hover:cursor-pointer "
//                           aria-hidden="true"
//                           onClick={() => removeItem(item, index)}
//                         />
//                       </div>
//                     </div>
//                   );
//                 })
//               : ""}
//           </div>

//           <div className="mt-12 justify-between flex text-right ">
//             <h1></h1>
//             <div className=" flex gap-4  items-end">
//               <button
//                 type="button"
//                 className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
//                 onClick={() => setshowCommunicationModal(false)}
//               >
//                 Cancel
//               </button>
//               <button
//                 type="button"
//                 className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
//                 onClick={addToCommunication}
//               >
//                 {communicationId ? "Reply" : "Add Communication"}
//               </button>
//             </div>
//           </div>
//         </form>
//       </div>
//     </Modal>
//   );
// };

// export default CommuniModal;
