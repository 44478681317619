import { Tooltip } from "@mui/material";
import React from "react";

function CareTeamsList({ item }) {
  const tableHeaders = [
    { name: "Name" },
    { name: "Practitioner Role" },
    { name: "Care Team Role" },
    { name: "Speciality" },
  ];
  return (
    <div class="eligibility_details_section !p-0">
      {/* <div class="eligibility_details_head_cont">
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
          <path
            d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
            fill="white"
          ></path>
          <path
            d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
            fill="white"
          ></path>
          <path
            d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
            fill="white"
          ></path>
        </svg>
        <span>Care Team</span>
      </div> */}
      <div className="eligibility_details_body_cont !p-0 !shadow-none !border-none">
        {/* <div class="pre_auth_communication_table">
          <table>
            <thead>
              <tr>
                {tableHeaders.map((el, index) => {
                  return <th key={"tableHeaders_" + index}>{el.name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {item?.getPreAuthTransaction?.data?.care_teams?.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.practitioner_role}</td>
                  <td>{item.care_team_role}</td>
                  <td>{item.speciality}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}

        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 !p-0 !shadow-none !border-none">
            {item?.getPreAuthTransaction?.data?.care_teams?.map((item) => (
              <div
                className="shadow-md rounded-lg p-3 gap-3 flex flex-col border border-black/20"
                key={item.id}
              >
                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    Name
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end capitalize">
                    {item?.name || "___"}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    Practitioner Role
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                    {item?.practitioner_role || "___"}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    Care Team Role
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                    {item?.care_team_role?.length > 100 ? (
                      <Tooltip title={item?.care_team_role}>
                        <span>{item?.care_team_role?.slice(0, 100)}...</span>
                      </Tooltip>
                    ) : (
                      item?.care_team_role
                    )}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    Speciality
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end capitalize">
                    {item?.speciality || "___"}
                  </dd>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead className="bg-cyan-600">
        <tr>
          {tableHeaders.map((el, index) => {
            return (
              <th
                scope="col"
                className="whitespace-nowrap py-1 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-2"
                key={"tableHeaders_" + index}
              >
                {el.name}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {item?.getPreAuthTransaction?.data?.care_teams?.map((item) => (
          <tr key={item.id}>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
              {item.name}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
              {item.practitioner_role}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
              {item.care_team_role}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
              {item.qualification}
            </td>
            <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
              {item.speciality}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CareTeamsList;
