import React, { useState } from "react";
import ItemDetailsModal from "./Details/ItemDetailsModal";
import { EyeIcon } from "@heroicons/react/20/solid";
import { Divider, Tooltip } from "@mui/material";
import { MdOutlineRemoveRedEye } from "react-icons/md";

function ItemsList({ item }) {
  const tableHeaders = [
    { name: "No." },
    { name: "Standard Code" },
    { name: "Standard Description" },
    { name: "Net" },
    { name: "Benefit" },
    { name: "Status" },
    { name: "Action" },
  ];

  const [showDetails, setShowDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const openModal = (data) => {
    setShowDetails(!showDetails);
    setCurrentItem(data);
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div class="eligibility_details_section !p-0">
        <div className="eligibility_details_body_cont  !p-0 !shadow-none !border-none">
          <div class="pre_auth-detail_section_cont">
            <div class="pre_auth_communication_table">
              <table>
                <thead>
                  <tr>
                    {tableHeaders.map((el, index) => {
                      return (
                        <th
                          className="!p-2 !bg-secondry"
                          scope="col"
                          key={"itemtableHeaders_" + index}
                        >
                          {el.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {item?.getPreAuthTransaction?.data?.items?.map(
                    (item, index) => (
                      <tr
                        key={item.id}
                        className="cursor-pointer"
                        onClick={() => openModal(item)}
                      >
                        <td className="!p-1.5">{index}</td>
                        <td className="!p-1.5">
                          {item?.standard_code || "___"}
                        </td>
                        <td className="!p-1.5">
                          {item?.standard_description || "___"}
                        </td>
                        <td className="!p-1.5">
                          SAR {item?.net?.toFixed(2) || "___"}
                        </td>
                        <td className="!p-1.5">
                          {item?.benefit_currency}{" "}
                          {item?.benefit_value?.toFixed(2) || "___"}
                        </td>
                        <td className="!p-1.5">{item?.status || "___"}</td>
                        <td className="!p-1.5">
                          <Tooltip title="View">
                            <div
                              onClick={() => {
                                setOpen(true);
                                setCurrentItem(item);
                              }}
                              className="text-2xl cursor-pointer"
                            >
                              <MdOutlineRemoveRedEye />
                            </div>
                          </Tooltip>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 !p-0 !shadow-none !border-none">
              {item?.getPreAuthTransaction?.data?.items?.map((item) => (
                <div
                  className="shadow-md rounded-lg p-3 gap-3 flex flex-col border border-black/20"
                  key={item.id}
                >
                  <div className="flex justify-between">
                    <dt className="text-sm font-semibold text-gray-600 my-auto">
                      Standard Code
                    </dt>
                    <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                      {item?.standard_code || "___"}
                    </dd>
                  </div>

                  <div className="flex justify-between">
                    <dt className="text-sm font-semibold text-gray-600 my-auto">
                      Standard Description
                    </dt>
                    <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                      {item?.standard_description?.length > 100 ? (
                        <Tooltip title={item?.standard_description}>
                          <span>
                            {item?.standard_description.slice(0, 100)}...
                          </span>
                        </Tooltip>
                      ) : (
                        item?.standard_description
                      )}
                    </dd>
                  </div>

                  <div className="flex justify-between">
                    <dt className="text-sm font-semibold text-gray-600 my-auto">
                      Net
                    </dt>
                    <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                      SAR {item?.net?.toFixed(2) || "___"}
                    </dd>
                  </div>

                  <div className="flex justify-between">
                    <dt className="text-sm font-semibold text-gray-600 my-auto">
                      Benefit
                    </dt>
                    <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                      {item?.benefit_currency}{" "}
                      {item?.benefit_value?.toFixed(2) || "___"}
                    </dd>
                  </div>

                  <div className="flex justify-between">
                    <dt className="text-sm font-semibold text-gray-600 my-auto">
                      Status
                    </dt>
                    <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end capitalize">
                      {item?.status || "___"}
                    </dd>
                  </div>

                  <Divider />

                  <div className="flex justify-center gap-3">
                    <Tooltip title="View">
                      <div
                        onClick={() => {
                          setOpen(true);
                          setCurrentItem(item);
                        }}
                        className="text-2xl cursor-pointer"
                      >
                        <MdOutlineRemoveRedEye />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
      <ItemDetailsModal
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        item={currentItem}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        allItem={item}
      />
    </>
  );
  return (
    <div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-cyan-600">
          <tr>
            {tableHeaders.map((el, index) => {
              return (
                <th
                  scope="col"
                  className="whitespace-nowrap py-1 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-2"
                  key={"tableHeaders_" + index}
                >
                  {el.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {item?.getPreAuthTransaction?.data?.items?.map((item) => (
            <tr
              key={item.id}
              className="cursor-pointer"
              onClick={() => openModal(item)}
            >
              <td
                className="whitespace-nowrap px-2 py-2 text-sm text-gray-900
            "
              >
                {item.standard_code}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                {item.standard_description}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                {item.status}
              </td>
              <td>
                <EyeIcon className="w-6 h-6 text-gray-600" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ItemsList;
