const supportingInfoElements = [
  {
    id: 1,
    name: "Attachment",
    object: {
      category: "attachment",
      attachment: "",
      fileName: "",
      fileType: "",
    },
  },
  {
    id: 2,
    name: "Birth Weight",
    object: {
      category: "birth-weight",
      value: "",
      valueUnit: "kg",
    },
  },
  {
    id: 3,
    name: "Chief Complaint",
    object: {
      category: "chief-complaint",
      codeText: "",
      code: "",
      valueUnit: "",
      value: "",
    },
  },
  {
    id: 4,
    name: "Days Supply",
    object: {
      category: "days-supply",
      valueUnit: "d",
      value: "",
    },
  },
  {
    id: 5,
    name: "Employment Impacted",
    object: {
      category: "employment-impacted",
      startDate: "",
      endDate: "",
    },
  },
  {
    id: 6,
    name: "Hospitalized",
    object: {
      category: "hospitalized",
      startDate: "",
      endDate: "",
    },
  },
  {
    id: 7,
    name: "ICU Hours",
    object: {
      category: "icu-hours",
      startDate: "",
      endDate: "",
      value: "",
      valueUnit: "h",
    },
  },
  {
    id: 8,
    name: "Info",
    object: {
      category: "info",
      value: "",
      valueUnit: "",
    },
  },
  {
    id: 9,
    name: "Lab Test",
    object: {
      category: "lab-test",
      startDate: "",
      endDate: "",
      value: "",
      valueUnit: "",
      codeText: "",
      code: "",
    },
  },
  {
    id: 10,
    name: "Last Menstrual Period",
    object: {
      category: "last-menstrual-period",
      startDate: "",
    },
  },
  {
    id: 11,
    name: "Missing Tooth",
    object: {
      category: "missingtooth",
      startDate: "",
      codeText: "",
      code: "",
      reason: "",
    },
  },
  {
    id: 12,
    name: "Onset",
    object: {
      category: "onset",
      startDate: "",
      codeText: "",
      code: "",
    },
  },
  {
    id: 13,
    name: "Oxygen Saturation",
    object: {
      category: "oxygen-saturation",
      startDate: "",
      endDate: "",
      value: "",
      valueUnit: "%",
    },
  },
  {
    id: 14,
    name: "Pulse",
    object: {
      category: "pulse",
      startDate: "",
      endDate: "",
      value: "",
      valueUnit: "/min",
    },
  },
  {
    id: 15,
    name: "Reason For Visit",
    object: {
      category: "reason-for-visit",
      codeText: "",
      code: "",
    },
  },
  {
    id: 16,
    name: "Respiratory Rate",
    object: {
      category: "respiratory-rate",
      startDate: "",
      endDate: "",
      value: "",
      valueUnit: "/min",
    },
  },
  {
    id: 17,
    name: "Temperature",
    object: {
      category: "temperature",
      startDate: "",
      endDate: "",
      value: "",
      valueUnit: "Cel",
    },
  },
  {
    id: 18,
    name: "Ventilation Hours",
    object: {
      category: "ventilation-hours",
      startDate: "",
      endDate: "",
      value: "",
      valueUnit: "h",
    },
  },
  {
    id: 19,
    name: "Vital Sign Diastolic",
    object: {
      category: "vital-sign-diastolic",
      startDate: "",
      endDate: "",
      value: "",
      valueUnit: "mm[Hg]",
    },
  },
  {
    id: 20,
    name: "Vital Sign Height",
    object: {
      category: "vital-sign-height",
      startDate: "",
      endDate: "",
      value: "",
      valueUnit: "cm",
    },
  },
  {
    id: 21,
    name: "Vital Sign Systolic",
    object: {
      category: "vital-sign-systolic",
      startDate: "",
      endDate: "",
      value: "",
      valueUnit: "mm[Hg]",
    },
  },
  {
    id: 22,
    name: "Vital Sign Weight",
    object: {
      category: "vital-sign-weight",
      startDate: "",
      endDate: "",
      value: "",
      valueUnit: "kg",
    },
  },
];

export default supportingInfoElements;
