import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function SearchPayerList({ setShowSearchForm, payersData }) {
  const formik = useFormik({
    initialValues: {
      payer: "",
      effectiveDate: "",
      fromDate: "",
      toDate: "",
    },

    validationSchema: () => {
      return Yup.object().shape({
        payer: Yup.string().required("Payer required"),
        effectiveDate: Yup.string().required("Effective Date required"),
        fromDate: Yup.string().required("From Upload Date required"),
        toDate: Yup.string().required("To Upload Date required"),
      });
    },

    onSubmit: (values) => {
      console.log(values);
      // if (item) {
      //   updatePhysician({ variables: { input: values } });
      // } else {
      //   createPhysician({ variables: { input: values } });
      // }
    },
  });

  return (
    <div>
      <div className="benefeciary-create m-2 mt-4">
        <div className="personal-data flex flex-col gap-4  !shadow-none !border-none">
          <div className="personal-fields grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
            <div className="flex flex-col gap-1">
              <label htmlFor="payer">Payer</label>
              <select
                id="payer"
                name="payer"
                onChange={formik.handleChange}
                value={formik.values.payer}
                autoComplete="payer"
              >
                <option value={""}>Select Payer</option>
                {payersData?.listPayersWithTpa?.data.map((category, index) => (
                  <optgroup
                    label={
                      category?.name_en
                        ? category?.name_en + ` (${category?.license_id})`
                        : ""
                    }
                    key={category.id}
                  >
                    {category.payers.map((insurance, index) => (
                      <option key={index} value={insurance.id}>
                        {insurance.name_en} ({insurance?.license_id})
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
              {formik.errors.payer && formik.touched.payer ? (
                <p className="max-w-2xl text-sm leading-6 text-red-500">
                  {formik.errors.payer}
                </p>
              ) : null}
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="effectiveDate">Effective Date</label>
              <input
                placeholder="Enter Effective Date"
                type="date"
                name="effectiveDate"
                value={formik.values.effectiveDate}
                onChange={formik.handleChange}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                id="effectiveDate"
                className="effectiveDate"
              />

              {formik.errors.effectiveDate && formik.touched.effectiveDate ? (
                <p className=" max-w-2xl text-sm leading-6 text-red-500">
                  {formik.errors.effectiveDate}
                </p>
              ) : null}
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="fromDate">From Upload Date</label>
              <input
                placeholder="Enter From Upload Date"
                type="date"
                name="fromDate"
                value={formik.values.fromDate}
                onChange={formik.handleChange}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                id="fromDate"
                className="fromDate"
              />

              {formik.errors.fromDate && formik.touched.fromDate ? (
                <p className=" max-w-2xl text-sm leading-6 text-red-500">
                  {formik.errors.fromDate}
                </p>
              ) : null}
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="toDate">To Upload Date</label>
              <input
                placeholder="Enter To Upload Date"
                type="date"
                name="toDate"
                value={formik.values.toDate}
                onChange={formik.handleChange}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                id="toDate"
                className="toDate"
              />

              {formik.errors.toDate && formik.touched.toDate ? (
                <p className=" max-w-2xl text-sm leading-6 text-red-500">
                  {formik.errors.toDate}
                </p>
              ) : null}
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <div className="apply-filter px-8 hover:bg-gray-200">
              <div
                className="btn font-semibold"
                onClick={() => setShowSearchForm(false)}
              >
                Close
              </div>
            </div>
            <div
              className="save-benefeciary-btn !w-56 !m-0"
              onClick={formik.handleSubmit}
            >
              <span>Search Price List</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
