import React, { useEffect, useState } from "react";
import { Chip, Tooltip } from "@mui/material";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import claims from "../../../gql/claims";
import { useQuery } from "@apollo/client";
import Pagination from "../../../components/Pagination";
import AppModal from "../../../components/AppModal";
import { useNavigate } from "react-router-dom";

export default function PreAuthCommunicationList({ setTotslList }) {
  const navigate = useNavigate();
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const tableHeaders = [
    { name: "SQ #" },
    { name: "Patient Name" },
    { name: "Transaction ID" },
    { name: "Category" },
    { name: "Status" },
    { name: "Created At" },
    { name: "Actions" },
  ];

  const getColor = (status) => {
    if (status === "active") {
      return "primary";
    } else if (status === "not-active") {
      return "secondary";
    } else if (status === "complete") {
      return "success";
    } else if (status === "error") {
      return "error";
    } else {
      return "secondary";
    }
  };

  const {
    loading: dataLoading,
    error,
    data,
    refetch,
  } = useQuery(claims.CLAIMS_PREAUTH_COMMUNICATIONLIST, {
    variables: {
      input: {
        request_name: "preAuth",
        limit: limit,
        page: pageNo,
      },
    },
  });

  useEffect(() => {
    setTotslList(data?.listAllCommunications?.total);
  }, [data]);

  const onPageChange = (newPage) => {
    setPageNo(newPage);
    refetch({
      input: {
        request_name: "preAuth",
        limit: limit,
        page: newPage,
      },
    });
  };

  const onDataRowClicked = (event, item) => {
    // navigate("/preauth/details", { state: { currentItem: item } });
    navigate("/preauth/details", { state: { currentItem: item ,tabToActive:"communications"} });
  };

  return (
    <div>
      <div class="super-git-table !overflow-y-auto !p-0 !m-0">
        <table class="table-super-git !p-0">
          <thead className="!p-0">
            <tr className="!p-0">
              {tableHeaders.map((el, index) => {
                return (
                  <th className="!p-2 !text-sm" key={"tableHeaders_" + index}>
                    {el.name}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {data?.listAllCommunications?.data?.map((el, index) => {
              return (
                <tr key={"preAuthCommunicationData_" + index}>
                  <td className="!p-1 !text-sm">
                    {pageNo * limit + index + 1 - limit}
                  </td>

                  <td className="!p-1 capitalize text-start !text-sm">
                    {el?.patient_name}
                  </td>

                  <td className="!p-1 !text-sm">{el?.transaction_id}</td>

                  <td className="!p-1 !text-sm capitalize">
                    {el?.category}
                  </td>

                  <td className="!p-1 capitalize !text-sm">
                    {el?.status ? (
                      <Chip
                        label={el?.status}
                        color={getColor(el?.status)}
                        size="small"
                        className="uppercase !text-xs "
                      />
                    ) : (
                      ""
                    )}
                  </td>

                  <td className="!p-1 !text-sm">
                    {el?.created_at?.length > 16
                      ? el?.created_at.substring(0, 16).replace("T", " ")
                      : el?.created_at}
                  </td>

                  <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                    <Tooltip title="View">
                      <div
                        onClick={(event) => onDataRowClicked(event, el)}
                        className="text-xl cursor-pointer"
                      >
                        <MdOutlineRemoveRedEye />
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          currentPage={pageNo}
          totalPages={data?.listAllCommunications?.pages}
          onPageChange={onPageChange}
        />
      </div>

      <AppModal isOpen={loading || dataLoading} />
    </div>
  );
}
