import React, { useEffect, useRef, useState } from "react";
import { Formik, FieldArray, Field, useFormik } from "formik";
import _ from "lodash";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import ptpa from "../../../gql/ptpa";
import { useQuery } from "@apollo/client";
import BeneficiarySearchNSelect from "../../Eligibility/components/requestEligibility/BeneficiarySearchNSelect";
import { useLocation, useNavigate } from "react-router-dom";

function ClaimsFilter({
  showFilter,
  setShowFilter,
  refetch,
  setPageNo,
  setLimit,
  refetchExcelDataResponse,
  setFromDate,
  setToDate,
  setLoading,
  setRefetchFilterVariables,
  pageNo,
  limit,
  activeTab,
  setAllClaims,
  setClaimsPages,
  setClaimsTotal,
}) {
  const currentDate = new Date();

  const modalRef = useRef();

  const location = useLocation();
  const navigate = useNavigate();

  const claimsFilterDate = JSON.parse(localStorage.getItem("claimsFilterDate"));
  const claimsPageNo = JSON.parse(localStorage.getItem("claimsPageNo"));

  const [payers, setPayers] = useState([]);
  const [filterData, setFilterData] = useState({
    from_date: claimsFilterDate?.from_date
      ? claimsFilterDate?.from_date
      : `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}-01`,
    to_date: claimsFilterDate?.to_date
      ? claimsFilterDate?.to_date
      : `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}-${new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate()}`,
    from_date_ordered: claimsFilterDate?.from_date_ordered
      ? claimsFilterDate?.from_date_ordered
      : "",
    to_date_ordered: claimsFilterDate?.to_date_ordered
      ? claimsFilterDate?.to_date_ordered
      : "",
    payer_id: claimsFilterDate?.payer_id ? claimsFilterDate?.payer_id : "",
    type: claimsFilterDate?.type ? claimsFilterDate?.type : "",
    patient_id: claimsFilterDate?.patient_id
      ? claimsFilterDate?.patient_id
      : "",
    batch_id: claimsFilterDate?.batch_id ? claimsFilterDate?.batch_id : "",
    ...(activeTab && { outcome: activeTab }),
    status: claimsFilterDate?.status ? claimsFilterDate?.status : "",
    tp_ref_no: claimsFilterDate?.tp_ref_no ? claimsFilterDate?.tp_ref_no : "",
    request_bundle_id: claimsFilterDate?.request_bundle_id
      ? claimsFilterDate?.request_bundle_id
      : "",
    is_followup_req: claimsFilterDate?.is_followup_req
      ? claimsFilterDate?.is_followup_req
      : false,
   // is_valid: claimsFilterDate?.is_valid ? claimsFilterDate?.is_valid : true,
    limit: 10,
    page: claimsPageNo ? claimsPageNo : 1,
  });

  useEffect(() => {
    if (location?.pathname === "/claims") {
      localStorage.setItem("claimsFilterDate", JSON.stringify(filterData));
      localStorage.setItem("claimsPageNo", JSON.stringify(pageNo));
    }
  }, [location?.pathname, filterData, pageNo]);

  useEffect(() => {
    const claimsFilterDate = JSON.parse(
      localStorage.getItem("claimsFilterDate")
    );
    const claimsPageNo = JSON.parse(localStorage.getItem("claimsPageNo"));

    if (claimsFilterDate && claimsPageNo) {
      const obj = { ...claimsFilterDate, page: claimsPageNo };

      // console.log();
      try {
        setLoading(true);
        refetch({
          input: Object.keys(obj).reduce((acc, key) => {
            const value = obj[key];
            if (value !== "" && value !== null) {
              acc[key] = value;
            }
            return acc;
          }, {}),
        }).then(({ data }) => {
          setAllClaims(data?.listClaimTransactions?.data);
          setClaimsPages(data?.listClaimTransactions?.pages);
          setClaimsTotal(data?.listClaimTransactions?.total);
        });
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const addData = async (values) => {
    let data = _.cloneDeep(values);
    setPageNo(1);
    setFromDate(values.fromDate);
    setToDate(values.toDate);
    let obj = {
      from_date: values.fromDate,
      to_date: values.toDate,
      payer_id: values.payer,
      type: values.type,
      patient_id: values.beneficiary,
      batch_id: values.batchID,
      tp_ref_no: values.tpRefNo,
      outcome: activeTab,
      from_date_ordered: values?.fromDateOrdered,
      to_date_ordered: values?.toDateOrdered,
      // pre_auth_id: values.preAuthorizationID,
      status: values.status,
      // pre_auth_ref_no: values.preAuthRefNo,
      request_bundle_id: values.bundleID,
      is_followup_req: values?.isFollowUp,
      // is_valid: values?.isValid,
      limit: 10,
      page: 1,
    };

    setFilterData(obj);
    try {
      setLoading(true);
      await refetch({
        input: Object.keys(obj).reduce((acc, key) => {
          const value = obj[key];
          if (value !== "" && value !== null) {
            acc[key] = value;
          }
          return acc;
        }, {}),
      }).then(({ data }) => {
        setAllClaims(data?.listClaimTransactions?.data);
        setClaimsPages(data?.listClaimTransactions?.pages);
        setClaimsTotal(data?.listClaimTransactions?.total);
      });
    } finally {
      setLoading(false);
    }

    setRefetchFilterVariables(
      Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value !== "" && value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {})
    );

    delete obj.limit;
    delete obj.page;

    // refetchExcelDataResponse({
    //   input: Object.keys(obj).reduce((acc, key) => {
    //     const value = obj[key];
    //     if (value !== "" && value !== null) {
    //       acc[key] = value;
    //     }
    //     return acc;
    //   }, {}),
    // });
  };

  const formatPayersData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.id,
          naphipesId: item.nphies_id,
          licenseId: item.license_id,
          name_ar: item.name_ar,
          name: item.name_en,
          tpa_id: item.tpa_id,
        });
      });
    } else {
    }
    return returnData;
  };

  // const {
  //   loading: loadingPayers,
  //   error: errorPayer,
  //   data: payersData,
  //   refetch: refetchPayers,
  // } = useQuery(ptpa.GET_ALL_PAYERS);

  // React.useEffect(() => {
  //   if (payersData) {
  //     setPayers(payersData?.listPayersWithTpa?.data);
  //   }
  // }, [payersData]);

  React.useEffect(() => {
    const payersData = JSON.parse(localStorage.getItem("payersData"));

    if (payersData && payersData.length > 0) {
      setPayers(payersData);
    } else {
      navigate("/dashboard");
    }
  }, []);

  const types = [
    { id: "institutional", name: "Institutional" },
    { id: "oral", name: "Dental" },
    { id: "pharmacy", name: "Pharmacy" },
    { id: "professional", name: "Professional" },
    { id: "vision", name: "Optical" },
  ];

  const statuses = [
    { id: "approved", name: "Approved" },
    { id: "partial Approved", name: "Partial Approved" },
    { id: "rejected", name: "Rejected" },
    { id: "pending", name: "Pending" },
    { id: "pended", name: "Pended" },
    { id: "cancelled", name: "Cancelled" }
  ];

  const outcome = [
    { id: "error", name: "Error" },
    { id: "queued", name: "Queued" },
    { id: "complete", name: "Complete" },
  ];

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
    /* and other goodies */
  } = useFormik({
    initialValues: {
      fromDate: claimsFilterDate?.from_date
        ? claimsFilterDate?.from_date
        : `${currentDate.getFullYear()}-${String(
            currentDate.getMonth() + 1
          ).padStart(2, "0")}-01`,
      toDate: claimsFilterDate?.to_date
        ? claimsFilterDate?.to_date
        : `${currentDate.getFullYear()}-${String(
            currentDate.getMonth() + 1
          ).padStart(2, "0")}-${new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0
          ).getDate()}`,
      fromDateOrdered: "",
      toDateOrdered: "",
      payer: "",
      type: "",
      beneficiary: "",
      preAuthorizationID: "",
      status: "",
      preAuthRefNo: "",
      bundleID: "",
      batchID: "",
      tpRefNo: "",
      isFollowUp: false,
      isValid: true,

      // outcome: "error",
    },
    onSubmit: (values, { setSubmitting }) => {
      addData(values);
    },
  });

  // useEffect Claims
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowFilter(false);
      }
    };

    if (showFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilter]);

  return (
    <div className={`${showFilter ? "open" : ""} modal`} id="search-filter">
      <div ref={modalRef} class="modal-content">
        <div class="close" onClick={() => setShowFilter(() => !showFilter)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#DE5042"
            />
          </svg>
          <div class="bene-text">Filters</div>
        </div>
        <div class="search-fields">
          <label>Submission Date</label>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <input
              type="date"
              name="fromDate"
              value={values.fromDate}
              onChange={handleChange}
              onBlur={handleBlur}
              id="date"
              placeholder="From Date"
            />
            <input
              type="date"
              name="toDate"
              value={values.toDate}
              onChange={handleChange}
              onBlur={handleBlur}
              id="date"
              placeholder="To Date"
            />
          </div>
          <label>Ordered Date</label>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <input
              type="text"
              id="fromDateOrdered"
              placeholder="From Order Date"
              name="fromDateOrdered"
              value={values.fromDateOrdered}
              onChange={handleChange}
              onBlur={(e) => (e.target.type = "text")}
              onFocus={(e) => (e.target.type = "date")}
            />

            <input
              type="text"
              id="toDateOrdered"
              placeholder="To Order Date"
              name="toDateOrdered"
              value={values.toDateOrdered}
              onChange={handleChange}
              onBlur={(e) => (e.target.type = "text")}
              onFocus={(e) => (e.target.type = "date")}
            />
          </div>

          <select
            name="payer"
            onChange={handleChange}
            value={values.payer}
            id="prayer"
          >
            <option value="">Select Payer</option>
            {payers.map((category, index) => (
              <optgroup
                label={
                  category?.name_en
                    ? category?.name_en + ` (${category?.license_id})`
                    : ""
                }
                key={category.id}
              >
                {category.payers.map((insurance, index) => (
                  <option key={index} value={insurance.id}>
                    {insurance?.name_en} ({insurance?.license_id})
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
          <select
            name="type"
            onChange={handleChange}
            value={values.type}
            id="type"
          >
            <option value="">Select Type</option>

            {types.map((item) => {
              return (
                <option value={item.id}>
                  <p>{item.name}</p>
                </option>
              );
            })}
          </select>
          <BeneficiarySearchNSelect
            setFieldValue={(val) => {
              setFieldValue("beneficiary", val?.id);
            }}
          />
          {/* <input type="search" placeholder="Select a Beneficiary" /> */}
          {/* <input
            type="text"
            placeholder="Claims ID"
            name="preAuthorizationID"
            value={values.preAuthorizationID}
            onChange={handleChange}
            onBlur={handleBlur}
          /> */}
          <input
            type="text"
            placeholder="Batch ID"
            name="batchID"
            value={values.batchID}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <input
            type="text"
            placeholder="Claim Reference"
            name="tpRefNo"
            value={values.tpRefNo}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <select
            name="status"
            onChange={handleChange}
            value={values.status}
            id="status"
          >
            <option value="">Select Status</option>
            {statuses.map((item) => {
              return <option value={item.id}>{item.name}</option>;
            })}
          </select>

          <input
            type="text"
            placeholder="Request Bundle ID"
            name="bundleID"
            value={values.bundleID}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <div className="flex justify-between items-center p-2  border rounded-lg">
            <div className="text-lg text-gray-600 ml-2">Is Follow Up</div>
            <div class="toggle-button-born flex items-center">
              <input
                type="checkbox"
                id="toggle-born"
                name="isFollowUp"
                value={values.isFollowUp}
                onChange={handleChange}
              />
              <label for="toggle-born"></label>
            </div>
          </div>

          {/* <div className="flex justify-between items-center p-2  border rounded-lg mt-2">
            <div className="text-lg text-gray-600 ml-2">Is Valid</div>
            <div class="toggle-button-born flex items-center">
              <input
                type="checkbox"
                id="toggle-born-1"
                name="isValid"
                defaultChecked={true}
                value={values.isValid}
                onChange={handleChange}
              />
              <label for="toggle-born-1"></label>
            </div>
          </div> */}

          {/* <select
            name="outcome"
            onChange={handleChange}
            value={values.outcome}
            id="outcome"
          >
            <option value="">Select Outcome</option>
            {outcome.map((item) => {
              return <option value={item.id}>{item.name}</option>;
            })}
          </select> */}
          {/* <input
            type="text"
            placeholder="Claims Reference No"
            name="preAuthRefNo"
            value={values.preAuthRefNo}
            onChange={handleChange}
            onBlur={handleBlur}
          />*/}
        </div>
        <div
          className="search-btn mt-2"
          // onClick={handleSubmit}
          onClick={() => {
            handleSubmit();
            setShowFilter(false);
          }}
        >
          <span>Search</span>
        </div>
        <div class="reset-btn" onClick={resetForm}>
          <span>Reset</span>
        </div>
      </div>
    </div>
  );
}

export default ClaimsFilter;
