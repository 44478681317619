import React, { useEffect, useRef, useState } from "react";
import { Formik, useFormik } from "formik";
import _ from "lodash";

function DashboardFilter({ showFilter, setShowFilter, formik }) {
  const currentDate = new Date();
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowFilter(false);
      }
    };

    if (showFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilter]);

  return (
    <div className={`${showFilter ? "open" : ""} modal`} id="myModal">
      <div ref={modalRef} className="modal-content">
        <div className="close" onClick={() => setShowFilter(() => !showFilter)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#DE5042"
            />
          </svg>
          <div className="bene-text">Dashboard</div>
        </div>
        <div className="search-fields">
          <input
            type="text"
            id="from_date"
            placeholder="From Date"
            name="from_date"
            value={formik.values.from_date}
            onChange={formik.handleChange}
            onBlur={(e) => (e.target.type = "text")}
            onFocus={(e) => (e.target.type = "date")}
          />
          <input
            type="text"
            id="to_date"
            placeholder="To Date"
            name="to_date"
            value={formik.values.to_date}
            onChange={formik.handleChange}
            onBlur={(e) => (e.target.type = "text")}
            onFocus={(e) => (e.target.type = "date")}
          />
        </div>
        <div
          className="search-btn"
          onClick={() => {
            formik.handleSubmit();
            setShowFilter(false);
          }}
        >
          {/* <div className="search-btn" onClick={formik.handleSubmit}> */}
          <span>Search</span>
        </div>
        <div className="reset-btn" onClick={formik.resetForm}>
          <span>Reset</span>
        </div>
      </div>
    </div>
  );
}

export default DashboardFilter;
