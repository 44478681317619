import React from "react";
import { useState } from "react";
import ReactJsonView from "./ReactJsonView";

const JsonTable = ({ item }) => {
  const [showJsonView, setShowJsonView] = useState(false);
  const [jsonObject, setJsonObject] = useState(false);

  const showJson = (jsonObj) => {
    setJsonObject(jsonObj);
    setShowJsonView(true);
  };

  const onCloseShowJson = () => {
    setShowJsonView(false);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToJson = (item) => {
    downloadFile({
      data: JSON.stringify(JSON.parse(item.log)),
      fileName: `${item.type}.json`,
      fileType: "text/json",
    });
  };

  const requests = [
    {
      id: 1,
      transactionType: "eligibility-request",
      date: "06/02/23 01:23:22 PM",
      jsonResponse: {
        employees: [
          {
            id: 1,
            first_name: "John",
            last_name: "Doe",
            position: "Manager",
            email: "johndoe@example.com",
            phone_number: "555-555-5555",
            address: {
              street: "123 Main St",
              city: "Anytown",
              state: "CA",
              zip: "12345",
            },
          },
          {
            id: 2,
            first_name: "Jane",
            last_name: "Smith",
            position: "Developer",
            email: "janesmith@example.com",
            phone_number: "555-555-5556",
            address: {
              street: "456 Elm St",
              city: "Anytown",
              state: "NY",
              zip: "67890",
            },
          },
          {
            id: 3,
            first_name: "Bob",
            last_name: "Johnson",
            position: "Designer",
            email: "bobjohnson@example.com",
            phone_number: "555-555-5557",
            address: {
              street: "789 Oak St",
              city: "Anytown",
              state: "TX",
              zip: "11111",
            },
          },
        ],
      },
    },
    {
      id: 2,
      transactionType: "eligibility response",
      date: "06/02/23 01:23:22 PM",
      jsonResponse: {
        employees: [
          {
            id: 1,
            first_name: "John",
            last_name: "Doe 1",
            position: "Manager",
            email: "johndoe@example.com",
            phone_number: "555-555-5555",
            address: {
              street: "123 Main St",
              city: "Anytown",
              state: "CA",
              zip: "12345",
            },
          },
          {
            id: 2,
            first_name: "Jane",
            last_name: "Smith 1",
            position: "Developer",
            email: "janesmith@example.com",
            phone_number: "555-555-5556",
            address: {
              street: "456 Elm St",
              city: "Anytown",
              state: "NY",
              zip: "67890",
            },
          },
          {
            id: 3,
            first_name: "Bob",
            last_name: "Johnson 1",
            position: "Designer",
            email: "bobjohnson@example.com",
            phone_number: "555-555-5557",
            address: {
              street: "789 Oak St",
              city: "Anytown",
              state: "TX",
              zip: "11111",
            },
          },
        ],
      },
    },
  ];

  const formatDateTime = (time) => {
    // const time = "2023-05-15T09:30:00";
    const dateTime = new Date(time);

    return dateTime.toLocaleString();
  };

  return (
    <div class="superGit_container">
      <div class="add_communication">
        <div class="add_communication_header">
          <div class="add_communication_header_1 d-flex fw-bold align-items-center gap-3">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="34" height="34" rx="8" fill="#F4F6F8" />
              <path
                d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
                fill="#F5793B"
              />
              <path
                d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
                fill="#F5793B"
              />
            </svg>
            <span>JSON</span>
          </div>
        </div>
        <div class="pre_auth_communication_table mt-3">
          <table>
            <thead>
              <tr>
                <th>Transaction Types</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {item?.getClaimTransaction?.data?.logs?.map((item, index) => {
                return (
                  <tr key={`requests_${index}`}>
                    <td>{item.type}</td>
                    <td>{formatDateTime(item.timestamp)}</td>
                    <td className="user-action flex gap-2">
                      <span class="download" onClick={() => exportToJson(item)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 0C5.388 0 0 5.388 0 12C0 18.612 5.388 24 12 24C18.612 24 24 18.612 24 12C24 5.388 18.612 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM15.108 7.908L16.8 9.6L12 14.4L7.2 9.6L8.892 7.908L10.8 9.804V4.8H13.2V9.804L15.108 7.908ZM18 18H6V15.6H18V18Z"
                            fill="#242429"
                          />
                        </svg>
                      </span>
                      <span
                        class="create"
                        onClick={() => showJson(JSON.parse(item.log))}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M2.99902 17.25V21H6.74902L17.809 9.94L14.059 6.19L2.99902 17.25ZM5.91902 19H4.99902V18.08L14.059 9.02L14.979 9.94L5.91902 19ZM20.709 5.63L18.369 3.29C18.169 3.09 17.919 3 17.659 3C17.399 3 17.149 3.1 16.959 3.29L15.129 5.12L18.879 8.87L20.709 7.04C21.099 6.65 21.099 6.02 20.709 5.63Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* <ReactJsonView
          showJsonView={showJsonView}
          onCloseShowJson={onCloseShowJson}
          jsonObject={jsonObject}
        /> */}
      </div>
    </div>
  );
  return (
    <>
      <div className="table-responsive  ">
        <table className="table  table-hover table-auto ">
          <thead>
            <tr className=" ">
              <th className="py-2  ">Transaction Types</th>
              <th className="py-2 col-span-2 ">Date</th>
              <th className="py-2 "></th>
            </tr>
          </thead>
          <tbody>
            {item?.getClaimTransaction?.data?.logs?.map((item, index) => {
              return (
                <tr key={`requests_${index}`}>
                  <td>{item.type}</td>
                  <td>{formatDateTime(item.time_stamp)}</td>
                  <td className="flex gap-2">
                    <span
                      className="hover:cursor-pointer text-cyan-700"
                      onClick={() => showJson(JSON.parse(item.log))}
                    >
                      View
                    </span>
                    <span
                      className="hover:cursor-pointer text-cyan-700"
                      onClick={() => exportToJson(item)}
                    >
                      Download
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* <ReactJsonView
        showJsonView={showJsonView}
        onCloseShowJson={onCloseShowJson}
        jsonObject={jsonObject}
      /> */}
    </>
  );
};

export default JsonTable;
