import React from "react";

function AccordianItemBenefits({ item }) {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
      {item?.allowedMoney && (
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Allowed Money</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {item?.allowedMoney?.value ?? ""} -{" "}
            {item?.allowedMoney?.currency ?? ""}
          </dd>
        </div>
      )}
      {item?.type && (
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Code</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {item?.type?.coding?.[0]?.code ?? ""}
          </dd>
        </div>
      )}
      {item?.allowedUnsignedInt && (
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">
            Allowed Unsigned Int
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {item?.allowedUnsignedInt ?? ""}
          </dd>
        </div>
      )}
      {item?.usedMoney && (
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Used Money</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {item?.usedMoney?.value ?? ""} - {item?.usedMoney?.currency ?? ""}
          </dd>
        </div>
      )}
    </dl>
  );
}

export default AccordianItemBenefits;
