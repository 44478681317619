import { gql } from "@apollo/client";

const GET_ALL_TPA = gql`
  query {
    getAllTpa {
      Status
      Message
      Data {
        _id
        naphipesId
        licenseId
        nameAr
        nameEn
        payers {
          _id
          naphipesId
          licenseId
          nameAr
          nameEn
          tpaId
        }
      }
    }
  }
`;

const CREATE_TPA = gql`
  mutation tpaRegistration($input: Tpa!) {
    tpaRegistration(input: $input) {
      Message
      Status
      Data {
        _id
        naphipesId
        licenseId
        nameAr
        nameEn
      }
    }
  }
`;

const CREATE_PAYER = gql`
  mutation insuranceCompanyRegistration($input: InsuranceCompany!) {
    insuranceCompanyRegistration(input: $input) {
      Message
      Status
      Data {
        _id
        naphipesId
        licenseId
        nameAr
        nameEn
        tpaId
        type
        url
      }
    }
  }
`;

const EDIT_PAYER = gql`
  mutation insuranceCompanyUpdate($input: UpdateInsuranceCompany!) {
    insuranceCompanyUpdate(input: $input) {
      Message
      Status
      Data {
        _id
        naphipesId
        licenseId
        nameAr
        nameEn
        tpaId
        type
        url
      }
    }
  }
`;

const DEL_PAYER = gql`
  mutation deleteInsuranceCompany($input: DeleteInsuranceCompany!) {
    deleteInsuranceCompany(input: $input) {
      Message
      Status
      ID
    }
  }
`;

// const GET_ALL_PAYERS = gql`
//   query GetAllPayers($limit: Int!, $page: Int!) {
//     getAllInsuranceCompanies(limit: $limit, page: $page) {
//       Status
//       Message
//       Data {
//         _id
//         naphipesId
//         licenseId
//         nameAr
//         nameEn
//         tpaId
//       }
//       Pages
//     }
//   }
// `;

// const GET_ALL_PAYERS = gql`
//   query {
//     listPayers {
//       status
//       message
//       data {
//         id
//         license_id
//         name_ar
//         name_en
//         tpa_id
//       }
//     }
//   }
// `;

const GET_ALL_PAYERS = gql`
  query {
    listPayersWithTpa {
      status
      message
      data {
        id
        license_id
        name_ar
        name_en
        payers {
          id
          name_ar
          name_en
          license_id
          flag
        }
      }
    }
  }
`;

const DELETE_TPA = gql`
  mutation deleteTpa($input: DeleteTpa!) {
    deleteTpa(input: $input) {
      Message
      Status
      ID
    }
  }
`;
export default {
  GET_ALL_TPA,
  CREATE_TPA,
  CREATE_PAYER,
  GET_ALL_PAYERS,
  DELETE_TPA,
  EDIT_PAYER,
  DEL_PAYER,
};
