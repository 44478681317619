import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import "./styles/pre-auth.css";
import "../Beneficiary/styles/BeneficiaryList.css";
import { Tooltip } from "@mui/material";
import BulkCancellation from "./components/BulkCancellation";
import ManualCancellation from "./components/ManualCancellation";

function CancellationList(props) {
  const navigate = useNavigate();
  const [totalList, setTotalList] = useState(0);
  const [activeTab, setActiveTab] = useState("bulk");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="pre_auth_container !p-0">
        <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
          <div className="">
            <Tooltip
              placement="right"
              title="Keep track of your Cancellations"
              className="my-auto !mt-2"
            >
              <span className="font-semibold text-black/70 text-xl ">
                Cancellations
                {/* <span className="italic text-lg ml-1">
                  ({totalList || 0}{" "}
                  <span className="text-xs font-bold italic">Entries</span>)
                </span> */}
              </span>
            </Tooltip>
          </div>

          {/* <div className="flex gap-3 flex-col lg:flex-row">
            <Button
              variant="contained"
              size="small"
            //   onClick={() => navigate("/claims/form")}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<AddIcon />}
            >
              Request Claim
            </Button>
            <Button
              variant="outlined"
              size="small"
            //   onClick={() => setShowFilter(() => !showFilter)}
              className="!border-primary !text-primary hover:!bg-primary/10"
              startIcon={<FilterAltOutlinedIcon />}
            >
              Apply Filter
            </Button>
          </div> */}
        </div>

        <div className="my-2">
          <ul className="grid grid-flow-col text-center text-gray-500 bg-gray-100 rounded p-1 shadow-inner">
            <li>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === "bulk"
                    ? "gradient-background rounded shadow text-white"
                    : ""
                }`}
                onClick={() => handleTabClick("bulk")}
              >
                Bulk Cancellation
              </a>
            </li>
            <li>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === "manual"
                    ? "gradient-background rounded shadow text-white"
                    : ""
                }`}
                onClick={() => handleTabClick("manual")}
              >
                Manual Cancellation
              </a>
            </li>
          </ul>
        </div>

        <div className="!mt-2.5">
          {activeTab === "bulk" && (
            <BulkCancellation setTotslList={setTotalList} />
          )}
          {activeTab === "manual" && (
            <ManualCancellation setTotslList={setTotalList} />
          )}
        </div>
      </div>
    </>
  );
}

export default CancellationList;
