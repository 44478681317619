import React, { useState } from "react";
import { CheckIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { useQuery } from "@apollo/client";
import preAuth from "../../../../gql/preAuth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ItemCodesSearchNSelect({ setFieldValue, typeName }) {
  const [query, setQuery] = useState("");
  const [selectedPractioner, setSelectedPractioner] = useState(null);

  const [people, setPeople] = useState([]);

  const formatData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.code,
          name: item.description,
          receivedDate: item.receivedDate,
          code: item.code,
          description: item.description,
        });
      });
    } else {
    }
    return returnData;
  };

  const {
    loading: loadingItemCodes,
    error: errorItemCodes,
    data: itemCodesData,
    refetch,
  } = useQuery(preAuth.GET_SEARCHED_ITEM_CODES, {
    variables: { q: "abcdefgh*%^%", fileName: typeName },
  });

  React.useEffect(() => {
    if (itemCodesData) {
      setPeople(formatData(itemCodesData?.getItemsCodeDescription?.data));
    }
  }, [itemCodesData]);

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) => {
          let searched = person.name
            .toLowerCase()
            .includes(query.toLowerCase());
          if (!searched) {
            searched = person.id.toLowerCase().includes(query.toLowerCase());
          }
          return searched;
        });

  const onSearch = (inputText) => {
    setQuery(inputText);
    if (inputText.length > 1) {
      refetch({ q: inputText, fileName: typeName });
    } else {
    }
  };

  return (
    <Combobox
      as="div"
      value={selectedPractioner}
      onChange={(val) => {
        setSelectedPractioner(val);
        setFieldValue(val);
      }}
    >
      {/* <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
              Select Beneficiary
            </Combobox.Label> */}
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
          onChange={(event) => onSearch(event.target.value)}
          // displayValue={(person) => `${person?.name} - ${person?.id}`}
          displayValue={(person) => person?.name}
          placeholder={"Search for Code"}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="w-auto absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-amber-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {person.name} - {person.id}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-amber-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export default ItemCodesSearchNSelect;
