import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import claims from "../../../gql/claims";

const ManualCancellationSchema = Yup.object().shape({
  request_value: Yup.string().required("Request Value is required"),
  request_name: Yup.string().required("Request Name is required"),
  payer_id: Yup.string().required("Payer is required"),
});

export default function ManualCancellation() {
  const [submitEntry, { loading: submitLoading }] = useMutation(
    claims.MANUAL_CANCELLATION,
    {
      update(proxy, result) {
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  const onSuccessfulRequestResponse = (data) => {
    if (data?.cancelRequest2) {
      toast.success(data?.cancelRequest2?.message);
    } else {
      toast.error("An error occurred while cancelling the claims.");
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error?.message}`);
  };

  const handleManualCancellation = async (values, { resetForm }) => {
    try {
      await submitEntry({
        variables: {
          input: {
            request_value: values.request_value,
            request_name: values.request_name,
            payer_id: values.payer_id,
          },
        },
      });
      resetForm();
    } catch (error) {
      console.error("Error submitting manual cancellation:", error);
    }
  };

  const systemTypes = [
    { value: "claims", name: "Claims" },
    { value: "preAuth", name: "PreAuth" },
  ];

  const [payers, setPayers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const payersData = JSON.parse(localStorage.getItem("payersData"));

    if (payersData && payersData.length > 0) {
      setPayers(payersData);
    } else {
      navigate("/dashboard");
    }
  }, []);

  return (
    <Formik
      initialValues={{ request_value: "", request_name: "", payer_id: "" }}
      validationSchema={ManualCancellationSchema}
      onSubmit={handleManualCancellation}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="w-1/2 ml-auto">
            <div className="details-fields grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4 mb-4 mt-8">
              <div className="flex gap-1 flex-col">
                <label className="text-sm">Request Value</label>
                <Field
                  placeholder="Enter Request Value"
                  type="text"
                  name="request_value"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <ErrorMessage
                  name="request_value"
                  component="p"
                  className="max-w-2xl text-sm leading-6 text-red-500"
                />
              </div>

              <div className="flex gap-1 flex-col">
                <label className="text-sm">Request Name</label>
                <Field
                  as="select"
                  id="request_name"
                  name="request_name"
                  style={{ height: "40px" }}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg !placeholder:text-sm"
                >
                  <option value="">Select Request Name</option>
                  {systemTypes.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="request_name"
                  component="p"
                  className="max-w-2xl text-sm leading-6 text-red-500"
                />
              </div>

              <div className="flex gap-1 flex-col">
                <label className="text-sm">Payer</label>
                <Field
                  as="select"
                  id="payer_id"
                  name="payer_id"
                  style={{ height: "40px" }}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg !placeholder:text-sm"
                >
                  <option value="">Select Payer</option>
                  {payers.map((category, index) => (
                    <optgroup
                      label={
                        category?.name_en
                          ? category?.name_en + ` (${category?.license_id})`
                          : ""
                      }
                      key={category.id}
                    >
                      {category.payers.map((insurance, index) => (
                        <option key={index} value={insurance?.id}>
                          {insurance.name_en} ({insurance?.license_id})
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </Field>
                <ErrorMessage
                  name="payer_id"
                  component="p"
                  className="max-w-2xl text-sm leading-6 text-red-500"
                />
              </div>
            </div>
            <div className="flex items-end mb-0.5 justify-end">
              <Button
                variant="contained"
                type="submit"
                style={{
                  backgroundImage:
                    "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                }}
                startIcon={<CloseIcon />}
                size="small"
              >
                {submitLoading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

