import { Divider, Tooltip } from "@mui/material";
import React from "react";
import { MdAttachFile, MdDownload } from "react-icons/md";

const SupportingInfo = ({ item }) => {
  const tableHeaders = [
    { name: "Category" },
    { name: "Code" },
    { name: "Timing" },
    { name: "Value" },
  ];

  function downloadBase64File(base64Data, fileName, fileType) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
  return (
    <div class="eligibility_details_section !p-0 ">
      {/* <div class="eligibility_details_head_cont">
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
          <path
            d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
            fill="white"
          ></path>
          <path
            d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
            fill="white"
          ></path>
          <path
            d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
            fill="white"
          ></path>
        </svg>
        <span>Supporting Info</span>
      </div> */}
      <div className="eligibility_details_body_cont !p-0 !border-none !shadow-none">
        {/* <div class="pre_auth_communication_table">
          <table>
            <thead>
              <tr>
                {tableHeaders.map((el, index) => {
                  return <th key={"tableHeaders_" + index}>{el.name}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {item?.getPreAuthTransaction?.data?.supporting_info?.map(
                (item) => (
                  <tr key={item.id}>
                    <td>{item.category}</td>
                    <td>{item.code}</td>
                    <td>
                      <p>
                        {item.start_date}{" "}
                        {item.end_date && ` - ${item.end_date}`}
                      </p>
                    </td>
                    <td>{item.value}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div> */}

        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 !p-0 !border-none !shadow-none">
            {item?.getPreAuthTransaction?.data?.supporting_info?.map((item) => (
              <div
                className="shadow-md rounded-lg p-3 gap-3 flex flex-col border border-black/20"
                key={item.id}
              >
                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    Category
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end capitalize">
                    {item?.category || "___"}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    Code
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                    {item?.code || "___"}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    Timming
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                    {item?.start_date}{" "}
                    {(item?.end_date && ` - ${item?.end_date}`) || "___"}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt className="text-sm font-semibold text-gray-600 my-auto">
                    Value
                  </dt>
                  <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end capitalize">
                    {item.category === "attachment" ? (
                      <div className="flex gap-2">
                        {item?.image_content_type === "image/jpeg" ||
                        item?.image_content_type === "image/png" ? (
                          <div className="w-12 h-12 my-auto">
                            <img
                              src={`data:${item?.image_content_type};base64,${item?.image_value}`}
                              alt={`${item?.image_title} Image`}
                              className="rounded-md"
                            />
                          </div>
                        ) : null}
                        <Tooltip title="Download Attachment">
                          <label
                            className="flex gap-2 my-auto cursor-pointer"
                            onClick={() =>
                              downloadBase64File(
                                item?.image_value,
                                item?.image_title,
                                item?.image_content_type
                              )
                            }
                          >
                            {!(
                              item?.image_content_type === "image/jpeg" ||
                              item?.image_content_type === "image/png"
                            ) ? (
                              <span className="font-bold text-lg">
                                <MdAttachFile />
                              </span>
                            ) : null}

                            {item?.image_title}
                          </label>
                        </Tooltip>
                      </div>
                    ) : item?.value?.length > 100 ? (
                      <Tooltip title={item?.value}>
                        <span>{item?.value.slice(0, 100)}...</span>
                      </Tooltip>
                    ) : (
                      item?.value
                    )}
                  </dd>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-cyan-600">
          <tr>
            {tableHeaders.map((el, index) => {
              return (
                <th
                  scope="col"
                  className="whitespace-nowrap py-1 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-2"
                  key={"tableHeaders_" + index}
                >
                  {el.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {item?.getPreAuthTransaction?.data?.supporting_info?.map((item) => (
            <tr key={item.id} className="cursor-pointer py-2">
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                {item.category}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                {item.code}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                <p>
                  {item.start_date} {item.end_date && ` - ${item.end_date}`}
                </p>
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 ">
                {item.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SupportingInfo;
