import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import AddPhysicianDetailForm from "./AddPhysicianDetailForm";
import physicians from "../../gql/physicians";
import AppModal from "../../components/AppModal";
import { useQuery } from "@apollo/client";

function PhysiciansForm() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { loading, error, data, refetch } = useQuery(
    physicians.GET_PHYSICIAN_BY_ID,
    {
      variables: { id: id },
      fetchPolicy: "network-only",
      skip: !id,
    }
  );

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  return (
    <div className="superGit_container">
      <div class="benefeciary-create">
        <div class="benefeciary-top">
          <div class="back" onClick={handleGoBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                fill="#4D4D53"
              />
            </svg>
          </div>
          <div>
            <div class="benefeciary-title">
              <span>{id ? "Edit" : "Create New"} Physician</span>
            </div>
            <div class="benefeciary-content">
              <span>
                {id ? "Edit" : "Create New"} physician account details
              </span>
            </div>
          </div>
        </div>
        <AddPhysicianDetailForm item={data?.getPhysician?.data} />
      </div>
      <AppModal isOpen={loading} />
    </div>
  );
}

export default PhysiciansForm;
