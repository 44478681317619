import React, { useState } from "react";
import { CheckIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { useQuery } from "@apollo/client";
import patients from "../../../../gql/beneficiary";

// const people = [
//   { id: 1, name: "Leslie Alexander" },
//   { id: 2, name: "Helen Edwards" },
//   { id: 3, name: "Tony Nguyen" },
//   { id: 4, name: "Brandon Wood" },
//   { id: 5, name: "Maria Hernandez" },
//   { id: 6, name: "Sara Lee" },
//   { id: 7, name: "David Kim" },
//   { id: 8, name: "Karen Lewis" },
//   { id: 9, name: "Michael Rodriguez" },
//   { id: 10, name: "Anna Smith" },
//   { id: 11, name: "Christopher Davis" },
//   { id: 12, name: "Julia Martinez" },
//   { id: 13, name: "James Johnson" },
//   { id: 14, name: "Rachel Wilson" },
//   { id: 15, name: "Andrew Brown" },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function BeneficiarySearchNSelect({ setFieldValue }) {
  const [people, setPeople] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);

  const formatPatientsData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.id,
          firstName: item.first_name,
          documentId: item.document_id,
          middleName: item.middle_name,
          familyName: item.family_name,
          name: item.full_name,
          beneficiaryFileId: item.file_no,
          dob: item.dob,
          eHealthId: item.eHealth_id,
          nationality: item.nationality,
          residencyType: item.residency_type,
          documentType: item.document_type,
          contactNumber: item.contact_number,
          martialStatus: item.martial_status,
          gender: item.gender,
          bloodGroup: item.blood_group,
          preferredLanguage: item.preferred_language,
          emergencyNumber: item.emergency_number,
          email: item.email,
          addressLine: item.address_line,
          insurancePlans: item.insurance_plans,
        });
      });
    } else {
    }
    return returnData;
  };

  const {
    loading: loadingPatients,
    error: errorPatient,
    data: patientsData,
    refetch,
  } = useQuery(patients.GET_SEARCHED_PATIENTS, {
    variables: { q: "" },
    skip: true, // Skip initial fetching
  });

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) => {
          let searched = person.name
            .toLowerCase()
            .includes(query.toLowerCase());
          if (!searched) {
            searched = person.id.toLowerCase().includes(query.toLowerCase());
          }
          return searched;
        });

  const onSearch = async (inputText) => {
    setQuery(inputText);
    if (inputText.length > 2) {
      try {
        const { data: updatedData } = await refetch({ q: inputText });

        // Perform any necessary operations with the updated data
        setPeople(formatPatientsData(updatedData?.searchPatients?.data));
      } catch (error) {
        console.error("Refetch error:", error);
      }
    } else {
    }
  };

  return (
    <Combobox
      as="div"
      value={selectedBeneficiary}
      onChange={(val) => {
        setSelectedBeneficiary(val);
        setFieldValue(val);
      }}
    >
      {/* <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        Select Beneficiary
      </Combobox.Label> */}
      <div className="relative">
        <Combobox.Input
          className="w-full rounded-md border-gray-300 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
          onChange={(event) => onSearch(event.target.value)}
          displayValue={(person) => person?.name}
          placeholder={"Search for Patient"}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {true && (
          <Combobox.Options className="w-auto absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {people.map((person) => (
              <Combobox.Option
                key={person.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-amber-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {person.name + " - " + person.documentId}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-amber-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export default BeneficiarySearchNSelect;
