import React, { useState } from "react";
import Modal from "react-modal";
import { Formik } from "formik";

import { Switch } from "@headlessui/react";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

import { XMarkIcon } from "@heroicons/react/20/solid";
import eligibility from "../../../gql/eligibility";
import { useQuery } from "@apollo/client";
import CommunicationDetails from "./CommunicationDetails";
import ResponseDetailsComponent from "./response/ResponseDetailsComponent";
import ItemClassesAndBenefeciaries from "./response/ItemClassesAndBenefeciaries";
import ResponseDetailsItemsAccordian from "./response/ResponseDetailsItemsAccordian";
import JsonTable from "./response/JsonTable";
import ItemsList from "./response/ItemsList";
import CareTeamsList from "./response/CareTeamsList";
import DiagnosisList from "./response/DiagnosisList";
import UcafForm from "./ucaf/UcafForm";
import gqlPreAuth from "../../../gql/preAuth";
import TotalItem from "./TotalItem";
import SupportingInfo from "./response/SupportingInfo";
import Encounter from "./response/Encounter";
import AccidentInfo from "./response/AccidentInfo";
import VisionPrescription from "./response/VisionPrescription";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minHeight: "95vh",
    minWidth: "85%",
    maxHeight: "75vh",
    overflowY: "scroll",
    paddingTop: "0 !important",
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let subtitle;
const PreAuthResponseModal = ({
  showPreAuthResponseModal,
  setShowPreAuthResponseModal,
  currentItem,
}) => {
  const currentDate = new Date();
  const navigate = useNavigate();

  const [showUCAF, setShowUCAF] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [myFirstName, setmyFirstName] = useState(false);
  const [showInfoItem, setshowInfoItem] = useState(false);

  const {
    loading: loadingDetails,
    error: detailsError,
    data: detailsData,
    refetch: refetchDetails,
  } = useQuery(gqlPreAuth.GET_PREAUTH_TRANSACTION_DETAILS, {
    variables: {
      transactionId: currentItem?.transaction_id,
    },
    skip: !currentItem?.transaction_id,
  });

  function afterOpenModal() {}

  const [currentTab, setCurrentTab] = useState("Details");

  const tabs = [
    { name: "Details", href: "#", current: true },
    { name: "Communications", href: "#", current: false },
    { name: "JSON", href: "#", current: false },
    { name: "Encounter", href: "#", current: false },
    { name: "Accident", href: "#", current: false },
    { name: "VisionPrescription", href: "#", current: false },
  ];

  const types = [
    { id: 1, name: "HCP" },
    { id: 2, name: "Public HCP" },
  ];

  const addData = (values) => {
    setShowPreAuthResponseModal(false);
    let data = _.cloneDeep(values);
  };

  const tableHeaders = [
    { name: "Payer" },
    { name: "Expiry Date" },
    { name: "Member Card ID" },
    { name: "Policy Number" },
    { name: "Relation With Subscriber" },
  ];
  let firstName = "Payer";

  const [eligibilityResponseData, setEligibilityResponseData] = useState([
    {
      id: 1,
      InForce: "Yes",
      BenefitDate: "01/01/2023 - 31/12/2023",
      MemberId: 35444349,
      PolicyNumber: 22001814,
      Type: "Extended HealthCare",
      Status: true,
      RelationWithSubscriber: "self",
      SubscriberMemberId: "",
      Network: "0222",
      Subrogation: false,
      SiteEligibility: "eligible",
      visible: false,
    },
  ]);

  const preAuthItem = {
    payer: "XYZ",
    expiry_date: "23/3/2024",
    member_card_id: "12223",
    policy_number: "12223",
    relationShipWithSubscriber: "ssdd",
  };

  return (
    <Modal
      // isOpen={true}
      isOpen={showPreAuthResponseModal}
      onAfterOpen={afterOpenModal}
      // onRequestClose={() => setShowEligibilityResponseModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="pt-2  ">
        <div className="  sticky pt-2 -top-10  bg-white">
          <div className="flex justify-between items-center py-1  ">
            <div>
              <h2 className="font-bold text-xl text-cyan-600">
                PreAuth -
                {detailsData?.getPreAuthTransaction?.data?.transaction_id}
              </h2>
            </div>
            <div className="flex align-items-baseline gap-4 ">
              <XMarkIcon
                className="h-5 w-5 text-gray-900 hover:cursor-pointer "
                aria-hidden="true"
                onClick={() => setShowPreAuthResponseModal(false)}
              />
            </div>
          </div>
          {/* TABS */}
          <div className="py-1  ">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                onChange={(e) => setCurrentTab(e.target.value)}
                className="block w-full rounded-md border-gray-300 focus:border-cyan-500 focus:ring-cyan-500"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="flex space-x-4" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      currentTab == tab.name
                        ? "bg-cyan-100 text-cyan-600"
                        : "text-gray-500 hover:text-gray-700",
                      "px-3 py-2 font-medium text-sm rounded-md"
                    )}
                    onClick={(e) => setCurrentTab(tab.name)}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <hr />
        {loadingDetails ? (
          <p>Loading...</p>
        ) : (
          <>
            {currentTab == "Details" && (
              <div className="preauth-form  ">
                <form className=" divide-gray-200">
                  <div className=" mt-2">
                    <div className="">
                      <ResponseDetailsComponent
                        item={detailsData?.getPreAuthTransaction?.data}
                      />
                    </div>

                    <hr />
                  </div>

                  <TotalItem item={detailsData?.getPreAuthTransaction?.data} />

                  <div className="pt-5 pb-2">
                    <h4 className="font-bold text-xl text-cyan-600">Items</h4>
                  </div>
                  <hr />

                  <ItemsList item={detailsData} />

                  <div className="pt-5 pb-2">
                    <h4 className="font-bold text-xl text-cyan-600">
                      Care Teams
                    </h4>
                  </div>
                  <hr />
                  <CareTeamsList item={detailsData} />

                  <div className="pt-5 pb-2">
                    <h4 className="font-bold text-xl text-cyan-600">
                      Diagnosis
                    </h4>
                  </div>

                  <hr />

                  <DiagnosisList item={detailsData} />

                  <div className="pt-5 pb-2">
                    <h4 className="font-bold text-xl text-cyan-600">
                      Supporting Info
                    </h4>
                  </div>
                  <hr />
                  <SupportingInfo item={detailsData} />

                  <div className="bottom-list sticky bottom-2 flex justify-end   right-20">
                    <div className="flex justify-end gap-2  my-4 w-fit bg-gray-100 p-2 ">
                      <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        onClick={() => setShowUCAF(true)}
                      >
                        UCAF
                      </button>
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        onClick={() => {
                          setShowPreAuthResponseModal(false);
                          navigate("/pre-auth/form", {
                            state: {
                              item: detailsData?.getPreAuthTransaction?.data,
                            },
                          });
                        }}
                      >
                        Follow Up
                      </button>
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        onClick={() => setShowPreAuthResponseModal(false)}
                      >
                        Close
                      </button>{" "}
                    </div>
                  </div>
                </form>
              </div>
            )}
            {currentTab == "JSON" && (
              <div className="preauth-form ">
                <div>
                  <JsonTable item={detailsData} />
                </div>
              </div>
            )}
            {currentTab == "Communications" && (
              <>
                <CommunicationDetails
                  currentItem={currentItem}
                  item={detailsData}
                />
              </>
            )}
            {currentTab == "Encounter" && (
              <>
                <div className="py-2">
                  <h4 className="font-bold text-xl text-cyan-600">Encounter</h4>
                </div>
                <hr />
                <Encounter
                  item={detailsData?.getPreAuthTransaction?.data?.Encounter}
                />
              </>
            )}
            {currentTab == "VisionPrescription" && (
              <>
                <VisionPrescription
                  item={detailsData?.getPreAuthTransaction?.data}
                />
              </>
            )}
            {currentTab == "Accident" && (
              <>
                <div className="py-2">
                  <h4 className="font-bold text-xl text-cyan-600">
                    Accident Info.
                  </h4>
                </div>
                <hr />
                <AccidentInfo item={detailsData?.getPreAuthTransaction?.data} />
              </>
            )}
          </>
        )}
      </div>
      <UcafForm
        item={detailsData?.getPreAuthTransaction?.data}
        showUCAF={showUCAF}
        setShowUCAF={setShowUCAF}
      />
    </Modal>
  );
};

export default PreAuthResponseModal;
