import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import AccordianItemTable from "./AccordianItemTable";
import AccordianItemBenefits from "./AccordianItemBenefits";
import AccordianItemDescription from "./AccordianItemDescription";

function Accordian({ item }) {
  return (
    <div>
      {item?.coverages?.items?.map((item, key) => {
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                {item?.category_name ? item?.category_name : ""}(
                {item?.description ? item?.description : ""})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <AccordianItemTable item={item} /> */}
              <AccordianItemDescription item={item} />
              <div className="mt-3">
                <h2 className="my-4">Benefits</h2>
                <AccordianItemBenefits item={JSON.parse(item?.benefits[0])} />
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
export default Accordian;
