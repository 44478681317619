import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";

import ContextProvider from "./context/ContextProvider";
import ApolloAppProvider from "./ApolloAppProvider";

function App() {
  return (
    <BrowserRouter>
      <ContextProvider>
        <ApolloAppProvider />
      </ContextProvider>
    </BrowserRouter>
  );
}

export default App;
