import React, { useState } from "react";
import Modal from "react-modal";
import { Formik } from "formik";
// import { Switch } from "@headlessui/react";
import _ from "lodash";
// import { useQuery, gql, useMutation } from "@apollo/client";
// import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";

import ContactForm from "./visionPrescription/ContactForm";
import LensForm from "./visionPrescription/LensForm";
// import DatePicker from "react-datepicker";
// import { ChevronRightIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
// import gqlPreAuth from "../../../../gql/preAuth";
// import ItemCodesSearchNSelect from "./ItemCodesSearchNSelect";
// import PrescriptionSearchNSelect from "./PrescriptionSearchNSelect";

// import {HiOutlineZoomIn} from '@heroicons/react/20/solid/s';
// HiAdjustments
// import {FontAwesomeIcon} from 'fa-font'

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "75%",
    minHeight: "50vh",
  },
};

let subtitle;

function AddVisionPrescriptionModal({
  showViewPrescriptionModal,
  setshowViewPrescriptionModal,
  item,
  formData,
  setFormData,
  setFieldValue: setMainFieldValue,
  formikValues,
  currentItemIndex,
}) {
  const navigate = useNavigate();

  const [enabled, setEnabled] = useState(false);
  const [requiredField, setrequiredField] = useState(false);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  //formula net = ((quantity * unit price) * factor) + tax
  const addData = (values) => {
    let data = _.cloneDeep(values);
    //   if(values.product!=="Lens" |values.product!=="Contact"  ){
    //     setrequiredField(true)
    //     // console.log('Nothing')
    //     return
    //    // setshowViewPrescriptionModal(false)
    //  }
    //   console.log('The products is'+data.product)

    setFormData((prevState) => [...prevState, data]);
    //   setFormData(prevState => [...prevState, ...data])
    //   setFormData(data)

    let tempFormData = [...formikValues.lens_specifications];
    if (item) {
      tempFormData[currentItemIndex] = data;
    } else {
      tempFormData.push(data);
    }
    setMainFieldValue("lens_specifications", tempFormData);

    setshowViewPrescriptionModal(false);

    // let tempFormData = { ...formData };
    // tempFormData.itemsListForm.values.items.push(data);
    // setFormData(tempFormData);
    // alert("hhhh")
    // checkEligibility({ variables: { input: data } });
  };

  const validationSchema = Yup.object().shape({
    product: Yup.string().required("Product is required"),
    // color: Yup.string().required("Color is required"),
    // brand: Yup.string().required("Brand is required"),
    // notes: Yup.string(),
    // rightPowerContact: Yup.string().when("product", {
    //   is: "medication-codes",
    //   then: Yup.string().required("Right Power Contact is required"),
    //   otherwise: Yup.string(),
    // }),
    // leftPowerContact: Yup.string().when("product", {
    //   is: "medication-codes",
    //   then: Yup.string().required("Left Power Contact is required"),
    //   otherwise: Yup.string(),
    // }),
    // rightBackCurveContact: Yup.string(),
    // leftBackCurveContact: Yup.string(),
    // rightDiameterContact: Yup.string(),
    // leftDiameterContact: Yup.string(),
    // rightDurationContact: Yup.string(),
    // leftDurationContact: Yup.string(),
    // rightUnitContact: Yup.string(),
    // leftUnitContact: Yup.string(),
    // rightSphere: Yup.string(),
    // leftSphere: Yup.string(),
    // rightPrismUnit: Yup.string(),
    // leftPrismUnit: Yup.string(),
    // rightPrismVal: Yup.string(),
    // leftPrismVal: Yup.string(),
    // rightCylinder: Yup.string(),
    // leftCylinder: Yup.string(),
    // rightAxis: Yup.string(),
    // leftAxis: Yup.string(),
    // rightAdd: Yup.string(),
    // leftAdd: Yup.string(),
  });

  return (
    <Modal
      isOpen={showViewPrescriptionModal}
      onAfterOpen={afterOpenModal}
      onRequestClose={() => setshowViewPrescriptionModal(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <Formik
        initialValues={{
          product: item?.product ? item?.product : "",
          color: item?.color ? item?.color : "",
          brand: item?.brand ? item?.brand : "",
          notes: item?.notes ? item?.notes : "",
          //vision contact form
          rightPowerContact: item?.rightPowerContact
            ? item?.rightPowerContact
            : "",
          leftPowerContact: item?.leftPowerContact
            ? item?.leftPowerContact
            : "",
          rightBackCurveContact: item?.rightBackCurveContact
            ? item?.rightBackCurveContact
            : "",
          leftBackCurveContact: item?.leftBackCurveContact
            ? item?.leftBackCurveContact
            : "",
          rightDiameterContact: item?.rightDiameterContact
            ? item?.rightDiameterContact
            : "",
          leftDiameterContact: item?.leftDiameterContact
            ? item?.leftDiameterContact
            : "",
          rightDurationContact: item?.rightDurationContact
            ? item?.rightDurationContact
            : "",
          leftDurationContact: item?.leftDurationContact
            ? item?.leftDurationContact
            : "",
          rightUnitContact: item?.rightUnitContact
            ? item?.rightUnitContact
            : "",
          leftUnitContact: item?.leftUnitContact ? item?.leftUnitContact : "",
          //vision lens form
          rightSphere: item?.rightSphere ? item?.rightSphere : "",
          leftSphere: item?.leftSphere ? item?.leftSphere : "",
          rightPrismUnit: item?.rightPrismUnit ? item?.rightPrismUnit : "",
          leftPrismUnit: item?.leftPrismUnit ? item?.leftPrismUnit : "",
          rightPrismVal: item?.rightPrismVal ? item?.rightPrismVal : "",
          leftPrismVal: item?.leftPrismVal ? item?.leftPrismVal : "",
          //common
          rightCylinder: item?.rightCylinder ? item?.rightCylinder : "",
          leftCylinder: item?.leftCylinder ? item?.leftCylinder : "",
          rightAxis: item?.rightAxis ? item?.rightAxis : "",
          leftAxis: item?.leftAxis ? item?.leftAxis : "",
          rightAdd: item?.rightAdd ? item?.rightAdd : "",
          leftAdd: item?.leftAdd ? item?.leftAdd : "",
        }}
        validate={(values) => {
          const errors = {};
        }}
        onSubmit={(values, { setSubmitting }) => {
          // console.log('The current value  are '+values.rightPowerContact)

          addData(values);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          isValid,
          /* and other goodies */
        }) => (
          <div className="preauth-form">
            <div className="pb-2 md:flex md:items-center md:justify-between">
              <h3 className="text-3xl font-bold leading-6 text-gray-900 mb-1">
                Add Vision Lens Specification
              </h3>
            </div>
            <hr />

            <form className="space-y-8 divide-y divide-gray-200 mt-3">
              {/* DATA IS LOST FROM HERE  */}
              <div className="flex gap-3">
                {/* first */}
                <div className="sm:col-span-1 flex-1">
                  <label
                    htmlFor="product"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Product <span className="text-sm text-red-500"> *</span>
                  </label>
                  <select
                    id="product"
                    name="product"
                    onChange={handleChange}
                    required
                    value={values.product}
                    autoComplete="type-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                  >
                    <option value={""}>Please Select</option>
                    <option value={"contact"}>Contact</option>
                    <option value={"lens"}>Lens</option>
                  </select>
                  {requiredField ? (
                    <span className="text-red-800 text-sm">
                      Prouct field is required
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="sm:col-span-1 flex-1">
                  <label
                    htmlFor="color"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Color
                  </label>
                  <input
                    placeholder="Enter Color"
                    type="text"
                    name="color"
                    value={values.color}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="color"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                  />
                </div>
                <div className="sm:col-span-1 flex-1">
                  <label
                    htmlFor="brand"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Brand
                  </label>
                  <input
                    placeholder="Enter brand"
                    type="text"
                    name="brand"
                    value={values.brand}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="brand"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                  />
                </div>
              </div>

              {/* Contact Form */}
              {values.product == "contact" && <ContactForm />}

              {/* Lens Form */}
              {values.product == "lens" && <LensForm />}
              {/* Buttons */}
              <div className="mt-12">
                <div className=" flex gap-4  items-end">
                  <button
                    type="button"
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2"
                    onClick={() => setshowViewPrescriptionModal(false)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className={`cursor-${
                      !isValid ? "not-allowed" : "pointer"
                    } ml-3 inline-flex justify-center rounded-md border border-transparent bg-amber-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2`}
                    onClick={handleSubmit}
                    disabled={!isValid}
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default AddVisionPrescriptionModal;
