import React from "react";

function Classes({ item }) {
  const tableHeaders = [
    {
      name: "Type",
    },
    {
      name: "Name",
    },
    {
      name: "Value",
    },
  ];

  const costBeneficiariesData = [
    {
      type: "plan",
      name: "",
      value: "22001814",
    },
    {
      type: "class",
      name: "1",
      value: "individual",
    },
    {
      type: "class",
      name: "A-1st Class",
      value: "A",
    },
  ];
  return (
    <div className="row p-2">
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card bg-white shadow">
          <div className="card-body rounded-md">
            <h4 className="font-bold text-xl text-cyan-600 py-3">Classes</h4>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.class_name}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Type</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.class_type}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Value</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.class_value}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Group Name
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.class_group_name}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Group Type
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.class_group_type}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Group Value
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.class_group_value}
                </dd>
              </div>
              {/* MY WORK */}
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Plan Name</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.class_plan_name}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Plan Type</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.class_plan_type}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Plan Value
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.class_plan_value}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

function CostToBeneficiaries({ item }) {
  const tableHeaders = [
    {
      name: "Type",
    },
    {
      name: "Quantity",
    },
    {
      name: "Money",
    },
    {
      name: "Exception",
    },
  ];

  const costBeneficiariesData = [
    {
      type: "copaypct",
      quantity: "20",
      money: "",
      exception: "",
    },
    {
      type: "maxcopay",
      quantity: "",
      money: "50",
      exception: "",
    },
  ];
  return (
    <div className="row p-2">
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card bg-white shadow">
          <div className="card-body rounded-md">
            <h4 className="font-bold text-xl text-cyan-600 mb-1 py-3">
              Cost To Beneficiaries
            </h4>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Copay Type
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.cost_to_ben_copay_type}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Copay Code
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.CopayCode}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Copay Value
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.cost_to_ben_copay_value}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Copay Type
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.cost_to_ben_max_copay_type}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Copay Value
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.cost_to_ben_max_copay_value}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

function ItemClassesAndBenefeciaries({ item }) {
  return (
    <>
      <Classes item={item?.coverages} />
      <CostToBeneficiaries item={item?.coverages} />
    </>
  );
}

export default ItemClassesAndBenefeciaries;
