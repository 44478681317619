import { useFormikContext } from "formik";
import React from "react";

function LensForm(props) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const lensUnits = [
    { id: "base", name: "Base" },
    { id: "up", name: "Up" },
    { id: "down", name: "Down" },
    { id: "in", name: "In" },
    { id: "out", name: "Out" },
  ];

  return (
    <div className="w-1/2 mx-auto">
      <div className="flex items-center gap-8 justify-between">
        <div className="sm:col-span-1 ">
          <input
            placeholder="right"
            type="text"
            name="rightSphere"
            value={values.rightSphere}
            onChange={handleChange}
            onBlur={handleBlur}
            id="rightSphere"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
        <div>
          <span className="text-gray-700 font-medium">Sphere</span>
        </div>
        <div className="sm:col-span-1 ">
          <input
            placeholder="Left"
            type="text"
            name="leftSphere"
            value={values.leftSphere}
            onChange={handleChange}
            onBlur={handleBlur}
            id="leftSphere"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
      </div>
      {/* Cyliner */}
      <div className="flex items-center gap-8 justify-between">
        <div className="sm:col-span-1 ">
          <input
            placeholder="right"
            type="text"
            name="rightCylinder"
            value={values.rightCylinder}
            onChange={handleChange}
            onBlur={handleBlur}
            id="rightCylinder"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
        <div>
          <span className="text-gray-700 font-medium">Cylinder</span>
        </div>
        <div className="sm:col-span-1 ">
          <input
            placeholder="Left"
            type="text"
            name="leftCylinder"
            value={values.leftCylinder}
            onChange={handleChange}
            onBlur={handleBlur}
            id="leftCylinder"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
      </div>
      {/* Axis */}
      <div className="flex items-center gap-8 justify-between">
        <div className="sm:col-span-1 ">
          <input
            placeholder="right"
            type="text"
            name="rightAxis"
            value={values.rightAxis}
            onChange={handleChange}
            onBlur={handleBlur}
            id="rightAxis"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
        <div>
          <span className="text-gray-700 font-medium">Axis</span>
        </div>
        <div className="sm:col-span-1 ">
          <input
            placeholder="Left"
            type="text"
            name="leftAxis"
            value={values.leftAxis}
            onChange={handleChange}
            onBlur={handleBlur}
            id="leftAxis"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
      </div>
      {/* Add */}
      <div className="flex items-center gap-8 justify-between">
        <div className="sm:col-span-1 ">
          <input
            placeholder="right"
            type="text"
            name="rightAdd"
            value={values.rightAdd}
            onChange={handleChange}
            onBlur={handleBlur}
            id="rightAdd"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
        <div>
          <span className="text-gray-700 font-medium">Add</span>
        </div>
        <div className="sm:col-span-1 ">
          <input
            placeholder="Left"
            type="text"
            name="leftAdd"
            value={values.leftAdd}
            onChange={handleChange}
            onBlur={handleBlur}
            id="leftAdd"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
          />
        </div>
      </div>
      {/* Prism */}
      <div className="flex items-center gap-8 justify-between my-2 ">
        <div className="sm:col-span-2 ">
          <div className="flex gap-2">
            <select
              id="rightPrismUnit"
              name="rightPrismUnit"
              onChange={handleChange}
              value={values.rightPrismUnit}
              autoComplete="type-name"
              className="block rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            >
              {lensUnits.map((item) => {
                return (
                  <option value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
            <input
              placeholder="Right"
              type="text"
              name="rightPrismVal"
              value={values.rightPrismVal}
              onChange={handleChange}
              onBlur={handleBlur}
              id="rightPrismVal"
              className="mt-1 block w-1/4  rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <span className="text-gray-700 font-medium">Prism</span>
        </div>
        <div className="sm:col-span-1 ">
          <div className="flex gap-2 items-center justify-center">
            <input
              placeholder="Left"
              type="text"
              name="leftPrismVal"
              value={values.leftPrismVal}
              onChange={handleChange}
              onBlur={handleBlur}
              id="leftPrismVal"
              className="mt-1 block w-1/4  rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            />
            <select
              id="leftPrismUnit"
              name="leftPrismUnit"
              onChange={handleChange}
              value={values.leftPrismUnit}
              autoComplete="type-name"
              className="block rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
            >
              {lensUnits.map((item) => {
                return (
                  <option value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      {/* Eight Row */}
      <div className="sm:col-span-1 ">
        <textarea
          placeholder="Notes here ?"
          type="notes"
          name="notes"
          value={values.notes}
          onChange={handleChange}
          onBlur={handleBlur}
          id="notes"
          className="mt-1 block w-full my-4 rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
        ></textarea>
      </div>
    </div>
  );
}

export default LensForm;
