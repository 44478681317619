export const items = [
  { id: "E1", name: "Upper left, eyelid" },
  { id: "E2", name: "Lower left, eyelid" },
  { id: "E3", name: "Upper right, eyelid" },
  { id: "E4", name: "Lower right, eyelid" },
  { id: "F1", name: "Left hand, second digit" },
  { id: "F2", name: "Left hand, third digit" },
  { id: "F3", name: "Left hand, fourth digit" },
  { id: "F4", name: "Left hand, fifth digit" },
  { id: "F5", name: "Right hand, thumb" },
  { id: "F6", name: "Right hand, second digit" },
  { id: "F7", name: "Right hand, third digit" },
  { id: "F8", name: "Right hand, fourth digit" },
  { id: "F9", name: "Right hand, fifth digit" },
  { id: "FA", name: "Left hand, thumb" },
  {
    id: "LC",
    name: "Left circumflex coronary artery",
  },
  {
    id: "LD",
    name: "Left anterior descending coronary artery",
  },
  { id: "LM", name: "Left main coronary artery" },
  {
    id: "LT",
    name: "Left side (used to identify procedures performed on the left side of the body)",
  },
  { id: "RC", name: "Right coronary artery" },
  {
    id: "RI",
    name: "Ramus intermedius coronary artery",
  },
  {
    id: "RT",
    name: "Right side (used to identify procedures performed on the right side of the body)",
  },
  { id: "T1", name: "Left foot, second digit" },
  { id: "T2", name: "Left foot, third digit" },
  { id: "T3", name: "Left foot, fourth digit" },
  { id: "T4", name: "Left foot, fifth digit" },
  { id: "T5", name: "Right foot, great toe" },
  { id: "T6", name: "Right foot, second digit" },
  { id: "T7", name: "Right foot, third digit" },
  { id: "T8", name: "Right foot, fourth digit" },
  { id: "T9", name: "Right foot, fifth digit" },
  { id: "TA", name: "Left foot, great toe" },
  { id: "RIV", name: "right eye" },
  { id: "LIV", name: "left eye" },
];

export const dentalItems = [
  { id: "11", name: "UPPER RIGHT; PERMANENT TEETH # 1" },
  { id: "12", name: "UPPER RIGHT; PERMANENT TEETH # 2" },
  { id: "13", name: "UPPER RIGHT; PERMANENT TEETH # 3" },
  { id: "14", name: "UPPER RIGHT; PERMANENT TEETH # 4" },
  { id: "15", name: "UPPER RIGHT; PERMANENT TEETH # 5" },
  { id: "16", name: "UPPER RIGHT; PERMANENT TEETH # 6" },
  { id: "17", name: "UPPER RIGHT; PERMANENT TEETH # 7" },
  { id: "18", name: "UPPER RIGHT; PERMANENT TEETH # 8" },
  { id: "21", name: "UPPER LEFT; PERMANENT TEETH # 1" },
  { id: "22", name: "UPPER LEFT; PERMANENT TEETH # 2" },
  { id: "23", name: "UPPER LEFT; PERMANENT TEETH # 3" },
  { id: "24", name: "UPPER LEFT; PERMANENT TEETH # 4" },
  { id: "25", name: "UPPER LEFT; PERMANENT TEETH # 5" },
  { id: "26", name: "UPPER LEFT; PERMANENT TEETH # 6" },
  { id: "27", name: "UPPER LEFT; PERMANENT TEETH # 7" },
  { id: "28", name: "UPPER LEFT; PERMANENT TEETH # 8" },
  { id: "31", name: "LOWER LEFT; PERMANENT TEETH # 1" },
  { id: "32", name: "LOWER LEFT; PERMANENT TEETH # 2" },
  { id: "33", name: "LOWER LEFT; PERMANENT TEETH # 3" },
  { id: "34", name: "LOWER LEFT; PERMANENT TEETH # 4" },
  { id: "35", name: "LOWER LEFT; PERMANENT TEETH # 5" },
  { id: "36", name: "LOWER LEFT; PERMANENT TEETH # 6" },
  { id: "37", name: "LOWER LEFT; PERMANENT TEETH # 7" },
  { id: "38", name: "LOWER LEFT; PERMANENT TEETH # 8" },
  { id: "41", name: "LOWER RIGHT; PERMANENT TEETH # 1" },
  { id: "42", name: "LOWER RIGHT; PERMANENT TEETH # 2" },
  { id: "43", name: "LOWER RIGHT; PERMANENT TEETH # 3" },
  { id: "44", name: "LOWER RIGHT; PERMANENT TEETH # 4" },
  { id: "45", name: "LOWER RIGHT; PERMANENT TEETH # 5" },
  { id: "46", name: "LOWER RIGHT; PERMANENT TEETH # 6" },
  { id: "47", name: "LOWER RIGHT; PERMANENT TEETH # 7" },
  { id: "48", name: "LOWER RIGHT; PERMANENT TEETH # 8" },
  { id: "51", name: "UPPER RIGHT; DECIDUOUS TEETH # 1" },
  { id: "52", name: "UPPER RIGHT; DECIDUOUS TEETH # 2" },
  { id: "53", name: "UPPER RIGHT; DECIDUOUS TEETH # 3" },
  { id: "54", name: "UPPER RIGHT; DECIDUOUS TEETH # 4" },
  { id: "55", name: "UPPER RIGHT; DECIDUOUS TEETH # 5" },
  { id: "61", name: "UPPER LEFT; DECIDUOUS TEETH # 1" },
  { id: "62", name: "UPPER LEFT; DECIDUOUS TEETH # 2" },
  { id: "63", name: "UPPER LEFT; DECIDUOUS TEETH # 3" },
  { id: "64", name: "UPPER LEFT; DECIDUOUS TEETH # 4" },
  { id: "65", name: "UPPER LEFT; DECIDUOUS TEETH # 5" },
  { id: "71", name: "LOWER LEFT; DECIDUOUS TEETH # 1" },
  { id: "72", name: "LOWER LEFT; DECIDUOUS TEETH # 2" },
  { id: "73", name: "LOWER LEFT; DECIDUOUS TEETH # 3" },
  { id: "74", name: "LOWER LEFT; DECIDUOUS TEETH # 4" },
  { id: "75", name: "LOWER LEFT; DECIDUOUS TEETH # 5" },
  { id: "81", name: "LOWER RIGHT; DECIDUOUS TEETH # 1" },
  { id: "82", name: "LOWER RIGHT; DECIDUOUS TEETH # 2" },
  { id: "83", name: "LOWER RIGHT; DECIDUOUS TEETH # 3" },
  { id: "84", name: "LOWER RIGHT; DECIDUOUS TEETH # 4" },
  { id: "85", name: "LOWER RIGHT; DECIDUOUS TEETH # 5" },
];
