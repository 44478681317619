import React, { useEffect, useRef, useState } from "react";
import { Formik, useFormik } from "formik";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import ptpa from "../../gql/ptpa";
import { useNavigate } from "react-router-dom";

// import "../../styles/eligibility.css";

function PriceListFilterModal({
  showFilter,
  setShowFilter,
  pageNo,
  limit,
  refetch,
}) {
  const [payers, setPayers] = useState([]);
  const navigate = useNavigate();

  const modalRef = useRef();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  };

  // const {
  //   loading: loadingPayers,
  //   error: errorPayer,
  //   data: payersData,
  //   refetch: refetchPayers,
  // } = useQuery(ptpa.GET_ALL_PAYERS);

  // React.useEffect(() => {
  //   if (payersData?.listPayersWithTpa?.data) {
  //     setPayers(payersData?.listPayersWithTpa?.data);
  //   }
  // }, [payersData]);

  React.useEffect(() => {
    const payersData = JSON.parse(localStorage.getItem("payersData"));

    if (payersData && payersData.length > 0) {
      setPayers(payersData);
    } else {
      navigate("/dashboard");
    }
  }, []);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      from_date: "",
      to_date: "",
      effective_date: "",
      payer_id: "",
      page: pageNo,
      limit: limit,
    },
    onSubmit: (values, { setSubmitting }) => {
      refetch({
        input: Object.keys(values).reduce((acc, key) => {
          const value = values[key];
          if (value !== "" && value !== null) {
            acc[key] = value;
          }
          return acc;
        }, {}),
      });
    },
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowFilter(false);
      }
    };

    if (showFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilter]);

  return (
    <div className={`${showFilter ? "open" : ""} modal`} id="myModal">
      <div ref={modalRef} className="modal-content">
        <div className="close" onClick={() => setShowFilter(() => !showFilter)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#DE5042"
            />
          </svg>
          <div className="bene-text">Price List</div>
        </div>
        <div className="search-fields">
          <input
            type="text"
            id="from_date"
            placeholder="From Date"
            name="from_date"
            value={values.from_date}
            onChange={handleChange}
            onBlur={(e) => (e.target.type = "text")}
            onFocus={(e) => (e.target.type = "date")}
          />
          <input
            type="text"
            id="to_date"
            placeholder="To Date"
            name="to_date"
            value={values.to_date}
            onChange={handleChange}
            onBlur={(e) => (e.target.type = "text")}
            onFocus={(e) => (e.target.type = "date")}
          />

          <input
            type="text"
            id="effective_date"
            placeholder="Effective Date"
            name="effective_date"
            value={values?.effective_date?.substring(0, 10)}
            onChange={(e) =>
              setFieldValue("effective_date", formatDate(e.target.value))
            }
            onBlur={(e) => (e.target.type = "text")}
            onFocus={(e) => (e.target.type = "date")}
          />

          <select
            id="payer_id"
            name="payer_id"
            onChange={handleChange}
            value={values.payer_id}
          >
            <option value="">Select Payer</option>
            {payers.map((category, index) => (
              <optgroup
                label={
                  category?.name_en
                    ? category?.name_en + ` (${category?.license_id})`
                    : ""
                }
                key={category.id}
              >
                {category.payers.map((insurance, index) => (
                  <option key={index} value={insurance?.id}>
                    {insurance?.name_en} ({insurance?.license_id})
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </div>
        <div
          className="search-btn"
          onClick={() => {
            handleSubmit();
            setShowFilter(false);
          }}
        >
          {/* <div className="search-btn" onClick={handleSubmit}> */}
          <span>Search</span>
        </div>
        <div className="reset-btn" onClick={resetForm}>
          <span>Reset</span>
        </div>
      </div>
    </div>
  );
}

export default PriceListFilterModal;
