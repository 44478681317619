import { Alert, Divider, Tooltip } from "@mui/material";
import React from "react";

export default function ItemRejectReason({ item }) {
  return (
    <div class="eligibility_details_section !p-0">
      <div className="eligibility_details_body_cont  !p-0 !shadow-none !border-none">
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 !p-0 !shadow-none !border-none">
            {item?.items
              ?.filter((i) => i?.status === "rejected")
              ?.map((item) => (
                <div
                  className="shadow-md rounded-lg p-3 gap-3 flex flex-col border border-black/20"
                  key={item.id}
                >
                  <div className="flex justify-between">
                    <dt className="text-sm font-semibold text-gray-600 my-auto">
                      Standard Code
                    </dt>
                    <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                      {item?.standard_code || "___"}
                    </dd>
                  </div>

                  <div className="flex justify-between">
                    <dt className="text-sm font-semibold text-gray-600 my-auto">
                      Standard Description
                    </dt>
                    <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                      {item?.standard_description?.length > 100 ? (
                        <Tooltip title={item?.standard_description}>
                          <span>
                            {item?.standard_description.slice(0, 100)}...
                          </span>
                        </Tooltip>
                      ) : (
                        item?.standard_description
                      )}
                    </dd>
                  </div>

                  <div className="flex justify-between">
                    <dt className="text-sm font-semibold text-gray-600 my-auto">
                      Net
                    </dt>
                    <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                      SAR {item?.net?.toFixed(2) || "___"}
                    </dd>
                  </div>

                  <div className="flex justify-between">
                    <dt className="text-sm font-semibold text-gray-600 my-auto">
                      Benefit
                    </dt>
                    <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end">
                      {item?.benefit_currency}{" "}
                      {item?.benefit_value?.toFixed(2) || "___"}
                    </dd>
                  </div>

                  <div className="flex justify-between">
                    <dt className="text-sm font-semibold text-gray-600 my-auto">
                      Status
                    </dt>
                    <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden w-52 text-end capitalize">
                      {item?.status || "___"}
                    </dd>
                  </div>

                  <div className="my-2">
                    {item?.reject_reason_display && (
                      <div className="sm:col-span-6">
                        <Alert severity="error">
                          <span class="font-medium">
                            Reason {item?.reject_reason_code} :{" "}
                          </span>
                          {item?.reject_reason_display}
                        </Alert>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
