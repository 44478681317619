import React from "react";

function Validations({ item }) {
  if (!item?.IsValid) {
    return (
      <>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-8">
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Date</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.validation_errors?.[0]?.error_date}
            </dd>
          </div>
        </dl>
        <h2 className="font-bold text-xl text-amber-600 my-1">Errors</h2>
        <hr />
        {item?.validation_errors?.[0]?.errors?.map((el, index) => {
          return (
            <>
              <div className="my-2">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <dt className="text-sm font-medium text-gray-500">
                      Error Code
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.error_code}
                    </dd>
                  </div>
                  <div className="sm:col-span-3">
                    <dt className="text-sm font-medium text-gray-500">
                      Error Message
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.error_message}
                    </dd>
                  </div>
                  <div className="sm:col-span-3">
                    <dt className="text-sm font-medium text-gray-500">Value</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.value_string}
                    </dd>
                  </div>
                </dl>
              </div>
              <hr />
            </>
          );
        })}
      </>
    );
  } else {
    return null;
  }
}

export default Validations;
