import { XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";
import Modal from "react-modal";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/base";
import Alert from "@mui/material/Alert";
import { Tooltip } from "@mui/material";
import { MdAttachFile, MdDownload } from "react-icons/md";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "95%",
    maxHeight: "90%",
  },
};

const supportingInfoHeaders = [
  { name: "Category" },
  { name: "Code" },
  { name: "Timing" },
  { name: "Value" },
];

const careTeamHeaders = [
  { name: "Name" },
  { name: "Practitioner Role" },
  { name: "Care Team Role" },
  { name: "Speciality" },
];

const diagnosisHeaders = [
  { name: "Type" },
  { name: "Code" },
  { name: "Description" },
  { name: "On Admission" },
];

function ItemDetailsModal({
  showDetails,
  setShowDetails,
  item,
  open,
  setOpen,
  handleClose,
  allItem,
}) {
  function downloadBase64File(base64Data, fileName, fileType) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
  return (
    // <div className={`${showDetails ? "open" : ""} modal`} id="search-filter">
    //   <div class="modal-content">
    //     <div class="close" onClick={() => setShowDetails(() => !showDetails)}>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="24"
    //         height="24"
    //         viewBox="0 0 24 24"
    //         fill="none"
    //       >
    //         <path
    //           d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
    //           fill="#DE5042"
    //         />
    //       </svg>
    //       <div class="bene-text">Item Details</div>
    //     </div>
    //     {item?.reject_reason_display && (
    //       <div className="sm:col-span-6">
    //         <div class="p-4 text-sm text-red-800 rounded-lg bg-red-50 dark:text-red-400">
    //           <span class="font-medium">
    //             Reason {item?.reject_reason_code} :{" "}
    //           </span>
    //           {item?.reject_reason_display}
    //         </div>
    //       </div>
    //     )}
    //     <div class="title">
    //       <span>Adjudication</span>
    //     </div>
    //     <div class="item_detail_boxes">
    //       <div class="item_detail_box_1">
    //         <div class="detail_box_row_1 border_">
    //           <div class="detail_box width_172">
    //             <div class="detail_head">Category</div>
    //             <div class="detail_cont">{item?.category}</div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Approved - Quantity</div>
    //             <div class="detail_cont">
    //               {item?.approved_quantity}{" "}
    //               {item?.approved_currency ? item?.approved_currency : ""}
    //             </div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Benefit</div>
    //             <div class="detail_cont">
    //               {item?.benefit_value}{" "}
    //               {item?.benefit_currency ? item?.benefit_currency : ""}
    //             </div>
    //           </div>
    //         </div>
    //         <div class="detail_box_row_1 border_">
    //           <div class="detail_box width_172">
    //             <div class="detail_head">Copay</div>
    //             <div class="detail_cont">
    //               {item?.copay_value}{" "}
    //               {item?.copay_currency ? item?.copay_currency : ""}
    //             </div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Deductible</div>
    //             <div class="detail_cont">
    //               {item?.deducted_value}{" "}
    //               {item?.deducted_currency ? item?.deducted_currency : ""}
    //             </div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">PatientShare</div>
    //             <div class="detail_cont">{item?.patient_share}</div>
    //           </div>
    //         </div>
    //         <div class="detail_box_row_1 last_">
    //           <div class="detail_box width_172">
    //             <div class="detail_head">Submitted</div>
    //             <div class="detail_cont">
    //               {item?.submitted_value}{" "}
    //               {item?.submitted_currency ? item?.submitted_currency : ""}
    //             </div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Tax</div>
    //             <div class="detail_cont">{item?.tax_amount}</div>
    //           </div>
    //         </div>
    //       </div>
    //       <div class="item_detail_box_2">
    //         <div class="detail_box_row_1 border_">
    //           <div class="detail_box width_172">
    //             <div class="detail_head">Type</div>
    //             <div class="detail_cont">{item?.type ? item?.type : "-"}</div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Standard Code</div>
    //             <div class="detail_cont">
    //               {item?.standard_code ? item?.standard_code : "-"}
    //             </div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Non Standard Code</div>
    //             <div class="detail_cont">
    //               {item?.non_standard_code ? item?.non_standard_code : "-"}
    //             </div>
    //           </div>
    //         </div>
    //         <div class="detail_box_row_1 border_">
    //           <div class="detail_box width_172">
    //             <div class="detail_head">Non Standard Description</div>
    //             <div class="detail_cont">
    //               {item?.non_standard_description
    //                 ? item?.non_standard_description
    //                 : "-"}
    //             </div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Body Site</div>
    //             <div class="detail_cont">
    //               {item?.body_site ? item?.body_site : "-"}
    //             </div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Sub Site</div>
    //             <div class="detail_cont">
    //               {item?.sub_site ? item?.sub_site : "-"}
    //             </div>
    //           </div>
    //         </div>
    //         <div class="detail_box_row_1 border_">
    //           <div class="detail_box width_172">
    //             <div class="detail_head">Service Start Date</div>
    //             <div class="detail_cont">
    //               {item?.service_start_date ? item?.service_start_date : "-"}
    //             </div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Service End Date</div>
    //             <div class="detail_cont">
    //               {item?.service_end_date ? item?.service_end_date : "-"}
    //             </div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Supporting Info</div>
    //             <div class="detail_cont">
    //               {item?.supporting_info ? item?.supporting_info?.length : "-"}
    //             </div>
    //           </div>
    //         </div>
    //         <div class="detail_box_row_1 border_">
    //           <div class="detail_box width_172">
    //             <div class="detail_head">Diagnosis</div>
    //             <div class="detail_cont">
    //               {item?.diagnosis ? item?.diagnosis?.length : "-"}
    //             </div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Care Teams</div>
    //             <div class="detail_cont">
    //               {item?.care_teams ? item?.care_teams?.length : "-"}
    //             </div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Is Package?</div>
    //             <div class="detail_cont">{item?.is_package ? "Yes" : "No"}</div>
    //           </div>
    //         </div>
    //         <div class="detail_box_row_1 border_">
    //           <div class="detail_box width_172">
    //             <div class="detail_head">Quantity</div>
    //             <div class="detail_cont">{item?.quantity}</div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Unit Price</div>
    //             <div class="detail_cont">{item?.unit_price}</div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Tax Amount</div>
    //             <div class="detail_cont">{item?.tax_amount}</div>
    //           </div>
    //         </div>
    //         <div class="detail_box_row_1 last_">
    //           <div class="detail_box width_172">
    //             <div class="detail_head">Factor</div>
    //             <div class="detail_cont">{item?.factor}</div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Discount Amount</div>
    //             <div class="detail_cont">{item?.discount_amount}</div>
    //           </div>
    //           <div class="detail_box width_254">
    //             <div class="detail_head">Patient Share (%)</div>
    //             <div class="detail_cont">{item?.patient_share}</div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          handleClose();
        }}
        TransitionComponent={Transition}
        style={{ background: "#ffab2c" }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar className="" style={{ background: "#ffab2c" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Item Detail
            </Typography>
            <Button
              autoFocus
              size="small"
              color="inherit"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <div className="my-4">
          {item?.reject_reason_display && (
            <div className="sm:col-span-6">
              <Alert severity="error">
                <span class="font-medium">
                  Reason {item?.reject_reason_code} :{" "}
                </span>
                {item?.reject_reason_display}
              </Alert>
            </div>
          )}
        </div>
        {/* <div class="title">
          <span>Adjudication</span>
        </div> */}

        <div class="eligibility_details_section">
          <div class="eligibility_details_body_cont !grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 !p-0 !border-none !shadow-none">
            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Category</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.category || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Approved - Quantity</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.approved_quantity || 0}{" "}
                  {item?.approved_currency ? item?.approved_currency : ""}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Benefit</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.benefit_value || 0}{" "}
                  {item?.benefit_currency ? item?.benefit_currency : ""}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Copay</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.copay_value || 0}{" "}
                  {item?.copay_currency ? item?.copay_currency : ""}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Submitted</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.submitted_value || 0}{" "}
                  {item?.submitted_currency ? item?.submitted_currency : ""}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Tax</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.tax_amount || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Type</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.type || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Standard Code</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.standard_code || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Non Standard Code</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.non_standard_code || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Non Standard Description</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.non_standard_description || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Body Site</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.body_site || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Sub Site</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.sub_site || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Service Start Date</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.service_start_date || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Service End Date</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.service_end_date || "___"}
                </span>
              </div>
            </div>

            {/* <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Supporting Info</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.supporting_info?.length || 0}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Diagnosis</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.diagnosis?.length || 0}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Care Teams</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.care_teams?.length || 0}
                </span>
              </div>
            </div> */}

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Is Package</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.is_package ? "Yes" : "No"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Quantity</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.quantity || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b   px-3 py-6">
              <div class="data_head !text-center">
                <span>Unit Price</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.unit_price || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Tax Amount</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.tax_amount || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Factor</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.factor || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Discount Amount</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.discount_amount || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data px-3 py-6">
              <div class="data_head !text-center">
                <span>Patient Share (%)</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.patient_share || "___"}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Care Team Table */}
        <div class="pre_auth_detail_section !shadow-none !border-none !mb-0 !pb-0">
          <div class="font-bold text-2xl mb-3 mx-2 !text-primary">
            <span>Care Team</span>
          </div>
          <div class="pre_auth-detail_section_cont">
            <div className="super-git-table !p-0 !m-0">
              <table className="table-super-git !p-0 ">
                <thead className="!p-0 ">
                  <tr className="!p-0  ">
                    {careTeamHeaders.map((el, index) => {
                      return (
                        <th className="!p-2" key={"tableHeaders_" + index}>
                          {el.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {allItem?.getPreAuthTransaction?.data?.care_teams?.map(
                    (i, index) =>
                      item?.care_teams?.includes(index + 1) && (
                        <tr
                          className="!p-0  border border-gray-400"
                          key={i?.id}
                        >
                          <td className="!p-2">{i?.name}</td>
                          <td className="!p-2">{i?.practitioner_role}</td>
                          <td className="!p-2">{i?.care_team_role}</td>
                          <td className="!p-2">{i?.speciality}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Diagnosis Table */}
        <div class="pre_auth_detail_section !shadow-none !border-none !mb-0 !pb-0">
          <div class="font-bold text-2xl mb-3 mx-2 !text-primary">
            <span>Diagnosis</span>
          </div>
          <div class="pre_auth-detail_section_cont">
            <div className="super-git-table !p-0 !m-0">
              <table className="table-super-git !p-0 ">
                <thead className="!p-0 ">
                  <tr className="!p-0  ">
                    {diagnosisHeaders.map((el, index) => {
                      return (
                        <th className="!p-2" key={"tableHeaders_" + index}>
                          {el.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {allItem?.getPreAuthTransaction?.data?.diagnosis?.map(
                    (i, index) =>
                      item?.diagnosis?.includes(index + 1) && (
                        <tr className="!p-0  border border-gray-400" key={i.id}>
                          <td className="!p-2">{i?.type}</td>
                          <td className="!p-2">{i?.code}</td>
                          <td className="!p-2">{i?.description}</td>
                          <td className="!p-2">
                            {i?.on_admission ? "Yes" : "No"}
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Supporting Info Table */}
        <div class="pre_auth_detail_section !shadow-none !border-none  ">
          <div class="font-bold text-2xl mb-3 mx-2 !text-primary">
            <span>Supporting Info</span>
          </div>
          <div class="pre_auth-detail_section_cont">
            <div className="super-git-table !p-0 !m-0">
              <table className="table-super-git !p-0 ">
                <thead className="!p-0 ">
                  <tr className="!p-0  ">
                    {supportingInfoHeaders.map((el, index) => {
                      return (
                        <th className="!p-2" key={"tableHeaders_" + index}>
                          {el.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {allItem?.getPreAuthTransaction?.data?.supporting_info?.map(
                    (i, index) =>
                      item?.supporting_info?.includes(index + 1) && (
                        <tr key={i.id} className="border border-gray-400 !p-0">
                          <td className="!p-2">{i.category}</td>
                          <td className="!p-2">{i.code}</td>
                          <td className="!p-2">
                            <p>
                              {i?.start_date}{" "}
                              {i?.end_date && ` - ${i?.end_date}`}
                            </p>
                          </td>
                          <td className="!p-2">
                            {i?.category === "attachment" ? (
                              <div className="flex gap-2 justify-center">
                                {i?.image_content_type === "image/jpeg" ||
                                i?.image_content_type === "image/png" ? (
                                  <div className="w-20 h-20 my-auto">
                                    <img
                                      src={`data:${i?.image_content_type};base64,${i?.image_value}`}
                                      alt={`${i?.image_title} Image`}
                                      className="rounded-md"
                                    />
                                  </div>
                                ) : null}
                                <label className="flex gap-2 my-auto  justify-center">
                                  {!(
                                    i?.image_content_type === "image/jpeg" ||
                                    i?.image_content_type === "image/png"
                                  ) ? (
                                    <span className="font-bold text-lg">
                                      <MdAttachFile />
                                    </span>
                                  ) : null}

                                  {i?.image_title}

                                  <span
                                    className="font-bold text-lg my-auto ml-6"
                                    onClick={() =>
                                      downloadBase64File(
                                        i?.image_value,
                                        i?.image_title,
                                        i?.image_content_type
                                      )
                                    }
                                  >
                                    <MdDownload />
                                  </span>
                                </label>
                              </div>
                            ) : (
                              i?.value
                            )}
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
  return (
    <Modal
      isOpen={showDetails}
      onRequestClose={() => setShowDetails(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="flex justify-between px-4 py-2 sm:px-6">
          <h3 className="text-xl font-bold leading-6 text-gray-900">
            Item Details
          </h3>
          <XMarkIcon
            className="text-gray-700 w-8 h-8 cursor-pointer"
            onClick={() => setShowDetails(false)}
          />
        </div>

        <div className="border-t border-gray-200 px-4 py-2 sm:px-6">
          {item?.reject_reason_display && (
            <div className="sm:col-span-6">
              <div class="p-4 text-sm text-red-800 rounded-lg bg-red-50 dark:text-red-400">
                <span class="font-medium">
                  Reason {item?.reject_reason_code} :{" "}
                </span>
                {item?.reject_reason_display}
              </div>
            </div>
          )}
          {/* AD */}
          <div className=" my-3">
            <h3
              className="text-sm font-bold leading-6
           py-3 text-gray-900"
            >
              Adjudication
            </h3>
            <div className="border-gray-300 rounded-lg border-2 py-3">
              <dl
                className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4
        "
              >
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Category
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {item?.category}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Approved - Quantity
                  </dt>
                  <dd className="mt-1 text-md text-gray-900">
                    {item?.approved_quantity}{" "}
                    <span className="text-sm">
                      {item?.approved_currency ? item?.approved_currency : ""}
                    </span>
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Benefit</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {item?.benefit_value}{" "}
                    <span className="text-sm">
                      {item?.benefit_currency ? item?.benefit_currency : ""}
                    </span>
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Copay</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {item?.copay_value}{" "}
                    <span className="text-sm">
                      {item?.copay_currency ? item?.copay_currency : ""}
                    </span>
                  </dd>
                </div>
              </dl>
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Deductible
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {item?.deducted_value}{" "}
                    <span className="text-sm">
                      {item?.deducted_currency ? item?.deducted_currency : ""}
                    </span>
                  </dd>
                </div>

                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    PatientShare
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {item?.patient_share}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Submitted
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {item?.submitted_value}{" "}
                    <span className="text-sm">
                      {item?.submitted_currency ? item?.submitted_currency : ""}
                    </span>
                  </dd>
                </div>

                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Tax</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {item?.tax_amount}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          {/* DOWN WORK */}
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Type</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.type ? item?.type : "-"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Standard Code
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.standard_code ? item?.standard_code : "-"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Non Standard Code
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.non_standard_code ? item?.non_standard_code : "-"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Non Standard Description
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.non_standard_description
                  ? item?.non_standard_description
                  : "-"}
              </dd>
            </div>
          </dl>
          {/* MY WORK */}
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Body Site</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.body_site ? item?.body_site : "-"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Sub Site</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.sub_site ? item?.sub_site : "-"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Service Start Date{" "}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.service_start_date ? item?.service_start_date : "-"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Service End Date
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.service_end_date ? item?.service_end_date : "-"}
              </dd>
            </div>
          </dl>
          {/* Third ROW */}
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Supporting Info
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.supporting_info ? item?.supporting_info?.length : "-"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Diagnosis</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.diagnosis ? item?.diagnosis?.length : "-"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Care Teams</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.care_teams ? item?.care_teams?.length : "-"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Is Package?</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.is_package ? "Yes" : "NO"}
              </dd>
            </div>
          </dl>
          {/* FOURTH ROW */}
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Quantity</dt>
              <dd className="mt-1 text-sm text-gray-900">{item?.quantity}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Unit Price </dt>
              <dd className="mt-1 text-sm text-gray-900">{item?.unit_price}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Tax Amount</dt>
              <dd className="mt-1 text-sm text-gray-900">{item?.tax_amount}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Factor</dt>
              <dd className="mt-1 text-sm text-gray-900">{item?.factor}</dd>
            </div>
          </dl>
          {/* FIFTH ROW */}
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Discount Amount{" "}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.discount_amount}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Patient Share (%){" "}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {item?.patient_share}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </Modal>
  );
}

export default ItemDetailsModal;
