import * as Yup from "yup";

export const preAuthValidationSchema = Yup.object().shape({
  isFollowUpReq: Yup.boolean(),
  isNewBorn: Yup.boolean(),
  isReferral: Yup.boolean(),
  patientId: Yup.mixed().required("Patient is required"),
  patientName: Yup.string().nullable(),
  subscriberName: Yup.string().nullable(),
  insurancePlanId: Yup.mixed().required("Insurance Plan is required"),
  subscriber: Yup.mixed().nullable(),
  payerId: Yup.mixed().nullable(),
  payerName: Yup.string().nullable(),
  insurancePlanIndex: Yup.string(),
  dateOrdered: Yup.string().required("Date ordered is required"),
  claimType: Yup.string().required("Type is required"),
  subType: Yup.string(),
  eligibilityResponseIdentifierValue: Yup.string(),
  eligibilityResponseIdentifierUrl: Yup.string(),
  offlineEligibilityId: Yup.string(),
  offlineEligibilityDate: Yup.string(),
  priority: Yup.string(),
  payeeType: Yup.string(),
  payee: Yup.string(),
  relatedPreAuthRefNo: Yup.string(),
  referringProvider: Yup.string(),
  careTeamsArr: Yup.array()
    .of(
      Yup.object().shape({
        practitioner: Yup.string().required("Practitioner is required"),
        practitionerName: Yup.string(),
        practitioner_role: Yup.string(),
        care_team_role: Yup.string().required("Care team role is required"),
        qualification: Yup.string().required("Qualification is required"),
      })
    )
    .min(1, "At least 1 care team member is required")
    .required("Care Team array is required"),
  diagnosisArr: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        codeDescription: Yup.string().required("Code Description is required"),
        type: Yup.string().required("Type is required"),
        on_admission: Yup.boolean().required("On Admission is required"),
        codeId: Yup.string().required("Code ID is required"),
      })
    )
    .min(1, "At least 1 diagnose is required")
    .required("Diagnosis array is required"),
  supportingInfos: Yup.array().of(
    Yup.object().shape({
      // Define the schema for supporting info object here
    })
  ),
  items: Yup.array()
    .of(
      Yup.object().shape({
        // Define the schema for supporting info object here
      })
    )
    .min(1, "At least 1 item is required"),
  // Define the schema for the remaining fields here
});

export const claimsValidationSchema = Yup.object().shape({
  refTransactionId: Yup.mixed().nullable(),
  isFollowUpReq: Yup.boolean(),
  isNewBorn: Yup.boolean(),
  isReferral: Yup.boolean(),
  patientId: Yup.mixed().required("Patient is required"),
  patientName: Yup.string().nullable(),
  subscriberName: Yup.string().nullable(),
  insurancePlanId: Yup.mixed().required("Insurance Plan is required"),
  subscriber: Yup.mixed().nullable(),
  payerId: Yup.mixed().nullable(),
  payerName: Yup.string().nullable(),
  insurancePlanIndex: Yup.string(),
  dateOrdered: Yup.string().required("Date Ordered is required"),
  claimType: Yup.string().required("Claim Type is required"),
  subType: Yup.string(),
  eligibilityResponseIdentifierValue: Yup.string()
    .nullable()
    .test(
      "required",
      "Eligibility Res Identifier (URL) is required",
      function (value) {
        const { eligibilityResponseIdentifierUrl } = this.parent;
        return !!eligibilityResponseIdentifierUrl ? !!value : true;
      }
    ),
  eligibilityResponseIdentifierUrl: Yup.string()
    .nullable()
    .test(
      "required",
      "Eligibility Res Identifier (URL) is required",
      function (value) {
        const { eligibilityResponseIdentifierValue } = this.parent;
        return !!eligibilityResponseIdentifierValue ? !!value : true;
      }
    ),
  offlineEligibilityId: Yup.string()
    .nullable()
    .test("required", "Offline Eligibility Id is required", function (value) {
      const { offlineEligibilityDate } = this.parent;
      return !!offlineEligibilityDate ? !!value : true;
    }),
  offlineEligibilityDate: Yup.string()
    .nullable()
    .test("required", "Offline Eligibility Date is required", function (value) {
      const { offlineEligibilityId } = this.parent;
      return !!offlineEligibilityId ? !!value : true;
    }),
  preAuthRespValue: Yup.string()
    .nullable()
    .test("required", "Preauth Res Value is required", function (value) {
      const { preAuthRespUrl } = this.parent;
      return !!preAuthRespUrl ? !!value : true;
    }),
  preAuthRespUrl: Yup.string()
    .nullable()
    .test("required", "Preauth Res URL is required", function (value) {
      const { preAuthRespValue } = this.parent;
      return !!preAuthRespValue ? !!value : true;
    }),

  preAuthRefNo: Yup.string()
    .nullable()
    .test("required", "Preauth Ref No. is required", function (value) {
      const { offlinePreAuthDate } = this.parent;
      return !!offlinePreAuthDate ? !!value : true;
    }),
  offlinePreAuthDate: Yup.string()
    .nullable()
    .test("required", "Preauth Ref Date is required", function (value) {
      const { preAuthRefNo } = this.parent;
      return !!preAuthRefNo ? !!value : true;
    }),

  episodeNo: Yup.string().required("Episode No is required"),
  priority: Yup.string(),
  payeeType: Yup.string(),
  payee: Yup.string(),
  relatedPreAuthRefNo: Yup.string(),
  referringProvider: Yup.string(),
  careTeamsArr: Yup.array()
    .of(
      Yup.object().shape({
        practitioner: Yup.string().required("Practitioner is required"),
        practitionerName: Yup.string(),
        practitioner_role: Yup.string(),
        care_team_role: Yup.string().required("Care team role is required"),
        qualification: Yup.string().required("Qualification is required"),
      })
    )
    .min(1, "At least 1 care team member is required")
    .required("Care Team array is required"),
  // diagnosisArr: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       id: Yup.number(),
  //       codeDescription: Yup.string(),
  //       type: Yup.string(),
  //       on_admission: Yup.boolean(),
  //       codeId: Yup.string(),
  //     })
  //   )
  //   .min(1, "At least 1 diagnose is required"),

  diagnosisArr: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        codeDescription: Yup.string().required("Code Description is required"),
        type: Yup.string().required("Type is required"),
        on_admission: Yup.boolean().required("On Admission is required"),
        codeId: Yup.string().required("Code ID is required"),
      })
    )
    .min(1, "At least 1 diagnose is required")
    .required("Diagnosis array is required"),
  supportingInfos: Yup.array().of(
    Yup.object().shape({
      // Define the schema for supporting info object here
    })
  ),
  items: Yup.array()
    .of(
      Yup.object().shape({
        // Define the schema for supporting info object here
      })
    )
    .min(1, "At least 1 item is required"),
  // Define the schema for the remaining fields here
});
