import { Button, IconButton, Dialog, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AppModal from "../../../components/AppModal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import claims from "../../../gql/claims";
import { useQuery } from "@apollo/client";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import SaveIcon from "@mui/icons-material/Save";
import ptpa from "../../../gql/ptpa";
import { useNavigate } from "react-router-dom";
// import LoadingButton from "@mui/lab/LoadingButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ClaimReportModal({ open, handleClose }) {
  const [loading, setLoading] = useState(false);

  function getCurrentYearMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // JavaScript months are 0-based
    return `${year}-${month}`;
  }

  const [payers, setPayers] = useState([]);
  const [selectMonth, setSelectMonth] = useState(getCurrentYearMonth());
  const navigate = useNavigate();

  // const {
  //   loading: loadingPayers,
  //   error: errorPayer,
  //   data: payersData,
  //   refetch: refetchPayers,
  // } = useQuery(ptpa.GET_ALL_PAYERS);

  // React.useEffect(() => {
  //   if (payersData) {
  //     setPayers(payersData?.listPayersWithTpa?.data);
  //   }
  // }, [payersData]);

  React.useEffect(() => {
    const payersData = JSON.parse(localStorage.getItem("payersData"));

    if (payersData && payersData.length > 0) {
      setPayers(payersData);
    } else {
      navigate("/dashboard");
    }
  }, []);
  const { refetch } = useQuery(claims.CLAIMS_REPORT_EXCEL, {
    variables: {
      input: {
        from_date: "",
        to_date: "",
        payer_id: "",
        batch_id: "",
      },
    },
    skip: true, // Skip initial execution
  });

  const formik = useFormik({
    initialValues: {
      from_date: "",
      to_date: "",
      batch_id: "",
      payer_id: "",
      tp_ref_no: "",
    },
    validationSchema: () => {
      return Yup.object({
        from_date: formik.values.to_date
          ? Yup.string().required("From Date is required")
          : Yup.string(),
        to_date: formik.values.from_date
          ? Yup.string().required("To Date is required")
          : Yup.string(),
        // batch_id: Yup.string().required("Batch ID is required"),
        payer_id: Yup.string().required("Payer ID is required"),
      });
    },

    onSubmit: async (values) => {
      setLoading(true);

      refetch({
        input: {
          from_date: values.from_date,
          to_date: values.to_date,
          batch_id: values.batch_id,
          payer_id: values.payer_id,
          tp_ref_no: values.tp_ref_no,
        },
      })
        .then((res) => {
          setLoading(false);
          handleClose();
          if (res?.data?.getClaimsReportExcel?.data) {
            downloadExcelFile(
              JSON.parse(res?.data?.getClaimsReportExcel?.data),
              `Claims_Report_${values.batch_id}`
            );
            formik.resetForm();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const downloadExcelFile = (data, fileName) => {
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(fileData, `${fileName}.xlsx`);
  };

  function getFirstAndLastDateOfMonth(yearMonth) {
    // Parse the input string to get year and month
    const [year, month] = yearMonth.split("-").map(Number);

    // Create a Date object for the first day of the month
    const firstDate = new Date(year, month - 1, 1);

    // Create a Date object for the last day of the month
    const lastDate = new Date(year, month, 0);

    // Format the dates to `YYYY-MM-DD`
    const firstDateString = `${firstDate.getFullYear()}-${(
      firstDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${firstDate.getDate().toString().padStart(2, "0")}`;
    const lastDateString = `${lastDate.getFullYear()}-${(
      lastDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${lastDate.getDate().toString().padStart(2, "0")}`;

    return {
      firstDate: firstDateString,
      lastDate: lastDateString,
    };
  }

  useEffect(() => {
    if (selectMonth) {
      const { firstDate, lastDate } = getFirstAndLastDateOfMonth(selectMonth);

      formik.setFieldValue("from_date", firstDate);
      formik.setFieldValue("to_date", lastDate);
    }
  }, [selectMonth]);

  return (
    <div>
      {/* <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        TransitionComponent={Transition}
        style={{ background: "#ffab2c" }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar className="" style={{ background: "#ffab2c" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Claim Report
            </Typography>
            <Button
              autoFocus
              size="small"
              color="inherit"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
      </Dialog> */}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          className:
            "w-full !max-w-[750px] sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl !z-40",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Download Claim Report
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div class="pre_auth_container !p-0 ">
            <div className="pre_auth_req_page !p-0">
              <div className="personal-data !shadow-none !border-none !p-0 !mb-0">
                {loading && (
                  <LinearProgress
                    sx={{
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#ffab2c",
                      },
                    }}
                    className="my-2"
                  />
                )}
                <div className="personal-fields  gap-2 grid grid-cols-1 lg:grid-cols-2">
                  <div className="flex flex-col gap-1">
                    <label htmlFor="from_date">Select Submission Month</label>
                    <input
                      type="month"
                      value={selectMonth}
                      onChange={(e) => setSelectMonth(e.target.value)}
                    />

                    {formik.errors.from_date && formik.touched.from_date ? (
                      <p className=" max-w-2xl text-sm leading-6 text-red-500">
                        {formik.errors.from_date}
                      </p>
                    ) : null}
                  </div>

                  {/* <div className="flex flex-col gap-1">
                    <label htmlFor="to_date">To Date</label>
                    <input
                      placeholder="To Date*"
                      type="month"
                      name="to_date"
                      value={formik.values.to_date}
                      onChange={formik.handleChange}
                      onFocus={(e) => (e.target.type = "month")}
                      onBlur={(e) => (e.target.type = "text")}
                      id="to_date"
                      autoComplete="off"
                      className="dob"
                    />

                    {formik.errors.to_date && formik.touched.to_date ? (
                      <p className=" max-w-2xl text-sm leading-6 text-red-500">
                        {formik.errors.to_date}
                      </p>
                    ) : null}
                  </div> */}

                  {/* <div className="flex gap-1 flex-col">
                    <label>Batch ID*</label>
                    <input
                      placeholder="Enter Batch ID"
                      type="text"
                      name="batch_id"
                      value={formik.values.batch_id}
                      onChange={formik.handleChange}
                      id="batch_id"
                      className="w-full"
                    />
                    {formik.errors.batch_id && formik.touched.batch_id ? (
                      <p className="max-w-2xl text-sm leading-6 text-red-500">
                        {formik.errors.batch_id}
                      </p>
                    ) : null}
                  </div> */}

                  <div className="flex flex-col gap-1">
                    <label htmlFor="payer_id">Payer</label>
                    <select
                      id="payer_id"
                      name="payer_id"
                      onChange={formik.handleChange}
                      value={formik.values.payer_id}
                      autoComplete="payer"
                    >
                      <option value={""}>Select Payer</option>
                      {payers?.map((category, index) => (
                        <optgroup
                          label={
                            category?.name_en
                              ? category?.name_en + ` (${category?.license_id})`
                              : ""
                          }
                          key={category.id}
                        >
                          {category.payers.map((insurance, index) => (
                            <option key={index} value={insurance.id}>
                              {insurance.name_en} ({insurance?.license_id})
                            </option>
                          ))}
                        </optgroup>
                      ))}
                    </select>
                    {formik.errors.payer_id && formik.touched.payer_id ? (
                      <p className="max-w-2xl text-sm leading-6 text-red-500">
                        {formik.errors.payer_id}
                      </p>
                    ) : null}
                  </div>

                  {/* <div className="flex gap-1 flex-col">
                    <label>Claim Reference</label>
                    <input
                      placeholder="Enter Claim Reference"
                      type="text"
                      name="tp_ref_no"
                      value={formik.values.tp_ref_no}
                      onChange={formik.handleChange}
                      id="tp_ref_no"
                      className="w-full"
                    />
                    {formik.errors.tp_ref_no && formik.touched.tp_ref_no ? (
                      <p className="max-w-2xl text-sm leading-6 text-red-500">
                        {formik.errors.tp_ref_no}
                      </p>
                    ) : null}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            size="small"
            onClick={() => {
              formik.handleSubmit();
            }}
            className="!text-primary hover:!bg-primary/20"
          >
            {loading ? "..." : "Download"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <AppModal isOpen={loading} />
    </div>
  );
}
