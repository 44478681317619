import React, { useEffect, useState, useRef } from "react";
import { Formik, useFormik } from "formik";
import _ from "lodash";
import { useQuery } from "@apollo/client";

import ptpa from "../../../../gql/ptpa";
import "../../styles/eligibility.css";
import { useNavigate, useLocation } from "react-router-dom";

function FilterModal({
  showFilter,
  setShowFilter,
  refetch,
  setPageNo,
  setLimit,
  // refetchExcelDataResponse,
  setRefetchFilterVariables,
  setFilterDate,
  fromDate,
  toDate,
  pageNo,
}) {
  const [payers, setPayers] = useState([]);
  const currentDate = new Date();
  const location = useLocation();
  const navigate = useNavigate();
  const modalRef = useRef(null);

  const eligibilityFilterDate = JSON.parse(
    localStorage.getItem("eligibilityFilterDate")
  );
  const eligibilityPageNo = JSON.parse(
    localStorage.getItem("eligibilityPageNo")
  );

  const [filterData, setFilterData] = useState({
    from_date: eligibilityFilterDate?.from_date
      ? eligibilityFilterDate?.from_date
      : `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}-01`,
    to_date: eligibilityFilterDate?.to_date
      ? eligibilityFilterDate?.to_date
      : `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}-${new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate()}`,
    payer_id: "",
    eligibility_id: "",
    status: "",
    patient_name: "",
    limit: 10,
    page: eligibilityPageNo ? eligibilityPageNo : 1,
  });

  useEffect(() => {
    if (location?.pathname === "/eligibility") {
      localStorage.setItem("eligibilityFilterDate", JSON.stringify(filterData));
      localStorage.setItem("eligibilityPageNo", JSON.stringify(pageNo));
    }
  }, [location?.pathname, filterData, pageNo]);

  useEffect(() => {
    const eligibilityFilterDate = JSON.parse(
      localStorage.getItem("eligibilityFilterDate")
    );
    const eligibilityPageNo = JSON.parse(
      localStorage.getItem("eligibilityPageNo")
    );

    if (eligibilityFilterDate && eligibilityPageNo) {
      const obj = { ...eligibilityFilterDate, page: eligibilityPageNo };

      // console.log();
      try {
        // setLoading(true);
        refetch({
          input: Object.keys(obj).reduce((acc, key) => {
            const value = obj[key];
            if (value !== "" && value !== null) {
              acc[key] = value;
            }
            return acc;
          }, {}),
        });
      } finally {
        // setLoading(false);
      }
    }
  }, []);

  const addData = (values) => {
    let data = _.cloneDeep(values);
    setPageNo(1);
    let obj = {
      from_date: values.fromDate,
      to_date: values.toDate,
      payer_id: values.payer,
      eligibility_id: values.eligibilityID,
      status: values.status,
      patient_name: values.beneficiary,
      limit: 10,
      page: 1,
    };

    setFilterData(obj);

    refetch({
      input: Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value !== "" && value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {}),
    });

    delete obj.limit;
    delete obj.page;

    // refetchExcelDataResponse({
    //   input: Object.keys(obj).reduce((acc, key) => {
    //     const value = obj[key];
    //     if (value !== "" && value !== null) {
    //       acc[key] = value;
    //     }
    //     return acc;
    //   }, {}),
    // });

    setRefetchFilterVariables(
      Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value !== "" && value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {})
    );
  };

  const formatPayersData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.id,
          naphipesId: item.naphipes_id,
          licenseId: item.license_id,
          nameAr: item.name_ar,
          name: item.name_en,
          tpaId: item.tpa_id,
        });
      });
    }
    return returnData;
  };

  // const {
  //   loading: loadingPayers,
  //   error: errorPayer,
  //   data: payersData,
  //   refetch: refetchPayers,
  // } = useQuery(ptpa.GET_ALL_PAYERS);

  // useEffect(() => {
  //   if (payersData?.listPayersWithTpa?.data) {
  //     setPayers(payersData?.listPayersWithTpa?.data);
  //   }
  // }, [payersData]);

  React.useEffect(() => {
    const payersData = JSON.parse(localStorage.getItem("payersData"));

    if (payersData && payersData.length > 0) {
      setPayers(payersData);
    } else {
      navigate("/dashboard");
    }
  }, []);

  const statuses = [
    { id: "complete", name: "Processing Complete" },
    { id: "error", name: "Error" },
  ];

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      fromDate: eligibilityFilterDate?.from_date
        ? eligibilityFilterDate?.from_date
        : fromDate,
      toDate: eligibilityFilterDate?.to_date
        ? eligibilityFilterDate?.to_date
        : toDate,
      eligibilityID: "",
      payer: "",
      beneficiary: "",
      status: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      addData(values);
    },
  });

  useEffect(() => {
    setFilterDate({
      from: values?.fromDate,
      to: values?.toDate,
    });
  }, [values?.fromDate, values?.toDate]);

  // useEffect
  useEffect(() => {
    // Function to handle click outside of the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside of the modal, so close it
        setShowFilter(false);
      }
    };

    // Attach the event listener when the modal is shown
    if (showFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove the event listener when the modal is hidden
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilter]);

  return (
    <div className={`${showFilter ? "open" : ""} modal`} id="myModal">
      <div ref={modalRef} className="modal-content">
        <div className="close" onClick={() => setShowFilter(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#DE5042"
            />
          </svg>
          <div className="bene-text">Eligibility</div>
        </div>
        <div className="search-fields">
          <input
            type="date"
            id="date"
            placeholder="From Date"
            name="fromDate"
            value={values.fromDate}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <input
            type="date"
            id="date"
            placeholder="To Date"
            name="toDate"
            value={values.toDate}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <input
            placeholder="Eligibility ID"
            type="text"
            name="eligibilityID"
            value={values.eligibilityID}
            onChange={handleChange}
            onBlur={handleBlur}
            id="eligibilityID"
          />
          <select
            id="payer"
            name="payer"
            onChange={handleChange}
            value={values.payer}
            autoComplete="type-name"
          >
            <option value="">Select Payer</option>
            {payers.map((category, index) => (
              <optgroup
                label={
                  category?.name_en
                    ? category?.name_en + ` (${category?.license_id})`
                    : ""
                }
                key={category.id}
              >
                {category.payers.map((insurance, index) => (
                  <option key={index} value={insurance?.id}>
                    {insurance.name_en} ({insurance?.license_id})
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
          <input
            placeholder="Enter Beneficiary Name"
            type="text"
            name="beneficiary"
            value={values.beneficiary}
            onChange={handleChange}
            onBlur={handleBlur}
            id="beneficiary"
          />
          <select
            id="status"
            name="status"
            onChange={handleChange}
            value={values.status}
            autoComplete="type-name"
          >
            <option value="">Select Status</option>
            {statuses.map((item) => {
              return <option value={item.id}>{item.name}</option>;
            })}
          </select>
        </div>

        <div
          className="search-btn"
          onClick={() => {
            handleSubmit();
            setShowFilter(false);
          }}
        >
          {/* <div className="search-btn" onClick={handleSubmit}> */}
          <span>Search</span>
        </div>
        <div className="reset-btn" onClick={resetForm}>
          <span>Reset</span>
        </div>
      </div>
    </div>
  );
}

export default FilterModal;

// import React, { useEffect, useState } from "react";
// import { Formik, useFormik } from "formik";
// import _ from "lodash";
// import { useQuery } from "@apollo/client";

// import ptpa from "../../../../gql/ptpa";
// import "../../styles/eligibility.css";

// function FilterModal({
//   showFilter,
//   setShowFilter,
//   refetch,
//   setPageNo,
//   setLimit,
//   refetchExcelDataResponse,
//   setRefetchFilterVariables,
//   setFilterDate,
// }) {
//   const [payers, setPayers] = useState([]);
//   const currentDate = new Date();

//   const addData = (values) => {
//     let data = _.cloneDeep(values);
//     setPageNo(1);
//     let obj = {
//       from_date: values.fromDate,
//       to_date: values.toDate,
//       payer_name: values.payer,
//       eligibility_id: values.eligibilityID,
//       status: values.status,
//       patient_name: values.beneficiary,
//       limit: 10,
//       page: 1,
//     };

//     refetch({
//       input: Object.keys(obj).reduce((acc, key) => {
//         const value = obj[key];
//         if (value !== "" && value !== null) {
//           acc[key] = value;
//         }
//         return acc;
//       }, {}),
//     });

//     delete obj.limit;
//     delete obj.page;

//     refetchExcelDataResponse({
//       input: Object.keys(obj).reduce((acc, key) => {
//         const value = obj[key];
//         if (value !== "" && value !== null) {
//           acc[key] = value;
//         }
//         return acc;
//       }, {}),
//     });

//     setRefetchFilterVariables(
//       Object.keys(obj).reduce((acc, key) => {
//         const value = obj[key];
//         if (value !== "" && value !== null) {
//           acc[key] = value;
//         }
//         return acc;
//       }, {})
//     );
//   };

//   const formatPayersData = (data) => {
//     let returnData = [];
//     if (data?.length > 0) {
//       data.map((item) => {
//         returnData.push({
//           id: item.id,
//           naphipesId: item.naphipes_id,
//           licenseId: item.license_id,
//           nameAr: item.name_ar,
//           name: item.name_en,
//           tpaId: item.tpa_id,
//         });
//       });
//     } else {
//     }
//     return returnData;
//   };

//   const {
//     loading: loadingPayers,
//     error: errorPayer,
//     data: payersData,
//     refetch: refetchPayers,
//   } = useQuery(ptpa.GET_ALL_PAYERS);

//   React.useEffect(() => {
//     if (payersData?.listPayersWithTpa?.data) {
//       setPayers(payersData?.listPayersWithTpa?.data);
//     }
//   }, [payersData]);

//   const statuses = [
//     { id: "complete", name: "Processing Complete" },
//     { id: "error", name: "Error" },
//   ];

//   const {
//     values,
//     errors,
//     touched,
//     handleChange,
//     handleBlur,
//     handleSubmit,
//     isSubmitting,
//     setFieldValue,
//     resetForm,
//   } = useFormik({
//     initialValues: {
//       fromDate: currentDate.toISOString().substring(0, 10),
//       toDate: currentDate.toISOString().substring(0, 10),
//       eligibilityID: "",
//       payer: "",
//       beneficiary: "",
//       status: "",
//     },
//     onSubmit: (values, { setSubmitting }) => {
//       addData(values);
//     },
//   });

//   useEffect(() => {
//     setFilterDate({
//       from: values?.fromDate,
//       to: values?.toDate,
//     });
//   }, [values?.fromDate, values?.toDate]);

//   return (
//     <div className={`${showFilter ? "open" : ""} modal`} id="myModal">
//       <div className="modal-content">
//         <div className="close" onClick={() => setShowFilter(() => !showFilter)}>
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//             fill="none"
//           >
//             <path
//               d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
//               fill="#DE5042"
//             />
//           </svg>
//           <div className="bene-text">Eligibility</div>
//         </div>
//         <div className="search-fields">
//           <input
//             type="date"
//             id="date"
//             placeholder="From Date"
//             name="fromDate"
//             value={values.fromDate}
//             onChange={handleChange}
//             onBlur={handleBlur}
//           />
//           <input
//             type="date"
//             id="date"
//             placeholder="To Date"
//             name="toDate"
//             value={values.toDate}
//             onChange={handleChange}
//             onBlur={handleBlur}
//           />
//           <input
//             placeholder="Eligibility ID"
//             type="text"
//             name="eligibilityID"
//             value={values.eligibilityID}
//             onChange={handleChange}
//             onBlur={handleBlur}
//             id="eligibilityID"
//           />
//           <select
//             id="payer"
//             name="payer"
//             onChange={handleChange}
//             value={values.payer}
//             autoComplete="type-name"
//           >
//             <option value="">Select Payer</option>
//             {payers.map((category, index) => (
//               <optgroup label={category.name_en} key={category.id}>
//                 {category.payers.map((insurance, index) => (
//                   <option key={index} value={insurance?.name_en}>
//                     {insurance.name_en}
//                   </option>
//                 ))}
//               </optgroup>
//             ))}
//           </select>
//           <input
//             placeholder="Enter Beneficiary Name"
//             type="text"
//             name="beneficiary"
//             value={values.beneficiary}
//             onChange={handleChange}
//             onBlur={handleBlur}
//             id="beneficiary"
//           />
//           <select
//             id="status"
//             name="status"
//             onChange={handleChange}
//             value={values.status}
//             autoComplete="type-name"
//           >
//             <option value="">Select Status</option>
//             {statuses.map((item) => {
//               return <option value={item.id}>{item.name}</option>;
//             })}
//           </select>
//         </div>

//         <div
//           className="search-btn"
//           onClick={() => {
//             handleSubmit();
//             setShowFilter(() => !showFilter);
//           }}
//         >
//           <span>Search</span>
//         </div>
//         <div className="reset-btn" onClick={resetForm}>
//           <span>Reset</span>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default FilterModal;

//------
// delete obj.limit;
// delete obj.page;

// refetchExcelDataResponse({
//   input: Object.keys(obj).reduce((acc, key) => {
//     const value = obj[key];
//     if (value !== "" && value !== null) {
//       acc[key] = value;
//     }
//     return acc;
//   }, {}),
// });
