import React, { useState } from "react";
import ReactJsonView from "./ReactJsonView";
import Accordian from "./response/Accordian";

function ResponseDetailsItemsAccordian({ item }) {
  const [showJsonView, setShowJsonView] = useState(false);
  const [jsonObject, setJsonObject] = useState(false);
  const showJson = (item) => {
    setJsonObject(item);
    setShowJsonView(true);
  };

  const onCloseShowJson = () => {
    setShowJsonView(false);
  };

  // This is the LAST ACCORDIAN DATA
  const tableHeaderTwo = [
    {
      name: "Item Name",
    },
    {
      name: "Description",
    },
    {
      name: "Network",
    },
    {
      name: "Unit",
    },
    {
      name: "Term",
    },
    // {
    //   name: "Benefits",
    // },
  ];
  const tableDataTwo = [
    {
      itemName: "Acquired Heart Diseases",
      description: "Out Mininum Network Clinic",
      network: "In",
      unit: "Indiviual",
      term: "Annual",
    },
    {
      itemName: "Acquired Heart Diseases 2",
      Description: "Out Mininum Network Clinic",
      network: "In",
      unit: "Indiviual",
      term: "Annual",
    },
    {
      itemName: "Acquired Heart Diseases 3",
      Description: "Out Mininum Network Clinic",
      network: "In",
      unit: "Indiviual",
      term: "Annual",
    },
    {
      itemName: "Acquired Heart Diseases 4",
      Description: "Out Mininum Network Clinic",
      network: "In",
      unit: "Indiviual",
      term: "Annual",
    },
  ];
  const accordianItems = [
    {
      id: 1,
      name: "Acquired Valvular Hearth Diseases",
    },
    {
      id: 2,
      name: "Autism Managment",
    },
    {
      id: 3,
      name: "Circumcision of Male newborn",
    },
  ];
  // This is the LAST ACCORDIAN DATA

  return (
    <>
      {item?.coverages?.items?.length > 0 && (
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card bg-white shadow">
              <div className="card-body rounded-md">
                <h4 className="font-bold text-xl text-cyan-600 py-3">items</h4>
                <Accordian item={item} />
              </div>
            </div>
          </div>
          {/* <ReactJsonView
            showJsonView={showJsonView}
            onCloseShowJson={onCloseShowJson}
            jsonObject={jsonObject}
          /> */}
        </div>
      )}
    </>
  );
}

export default ResponseDetailsItemsAccordian;
