import React, { useContext } from "react";
import Modal from "react-modal";
import { Puff } from "react-loader-spinner";
import { appContext } from "../context/ContextProvider";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "50%",
    border: "none",
    background: "none",
  },
};

function AppLoader({ showLoader, setShowLoader, loadingText }) {
  return (
    <Modal isOpen={showLoader} style={customStyles}>
      <div className="items-center justify-center flex">
        <div class="progress"></div>
        {/* <h2 className="py-1">{loadingText ? loadingText : "Loading..."}</h2> */}
      </div>

      {/* <Modal
      isOpen={showLoader}
      style={customStyles}
      contentLabel="Loader Modal"
      className={
        "items-center justify-center flex absolute inset-0 border-none bg-white"
      }
    >
      <div className="items-center justify-center">
        <Puff
          height="60"
          width="60"
          radius={1}
          color="black"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <h2 className="py-1">{loadingText ? loadingText : "Loading..."}</h2>
      </div>
    </Modal> */}
    </Modal>
  );
}

export default AppLoader;
