import React, { useState } from "react";
import { useFormikContext } from "formik";
import { PlusIcon as PlusIcon } from "@heroicons/react/24/solid";
import { Switch } from "@headlessui/react";
import _ from "lodash";

import AddVisionPrescriptionModal from "./AddVisionPrescriptionModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const VisionPrescription = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const [showViewPrescriptionModal, setshowViewPrescriptionModal] =
    useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);

  const [formData, setFormData] = useState([]);
  const rightHeader = [
    {
      name: "No",
    },
    {
      name: "product",
    },
    {
      name: "Sph",
    },
    {
      name: "Power",
    },
    {
      name: "CYL",
    },
    {
      name: "Axis",
    },
    {
      name: "Curve",
    },
    {
      name: "Diameter",
    },
    {
      name: "Duration",
    },
  ];
  const leftHeader = [
    {
      name: "Sph",
    },
    {
      name: "Power",
    },
    {
      name: "CYL",
    },
    {
      name: "Axis",
    },
    {
      name: "Curve",
    },
    {
      name: "Diameter",
    },
    {
      name: "Duration",
    },
  ];
  return (
    <>
      <div className="bg-white px-4 shadow sm:rounded-lg pb-6 my-6">
        <div className="py-2 md:flex md:items-center md:justify-start">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-1 pt-2">
            Vision Prescription
          </h3>
          <div className="flex space-x-3 md:mt-0 md:ml-4 mr-2">
            <Switch
              checked={values.isVisionPrescription}
              onChange={() =>
                setFieldValue(
                  "isVisionPrescription",
                  !values.isVisionPrescription
                )
              }
              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2"
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className="pointer-events-none absolute h-full w-full rounded-md bg-white"
              />
              <span
                aria-hidden="true"
                className={classNames(
                  values.isVisionPrescription ? "bg-amber-600" : "bg-gray-200",
                  "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                )}
              />
              <span
                aria-hidden="true"
                className={classNames(
                  values.isVisionPrescription
                    ? "translate-x-5"
                    : "translate-x-0",
                  "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                )}
              />
            </Switch>
          </div>
        </div>
        <hr />
        {values.isVisionPrescription && (
          <>
            <div className="flex gap-4 my-4 ">
              <div className="sm:col-span-">
                <label
                  htmlFor="visionPrescriptionDateWritten"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date Written <span className="text-sm text-red-500"> *</span>
                </label>
                <input
                  placeholder="Enter Service Start Date"
                  type="date"
                  name="visionPrescriptionDateWritten"
                  value={values.visionPrescriptionDateWritten}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="visionPrescriptionDateWritten"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                />
              </div>
              {/* second */}
              <div className="sm:col-span-">
                <label
                  htmlFor="prescriber_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prescriber <span className="text-sm text-red-500"> *</span>
                </label>
                <select
                  id="prescriber_id"
                  name="prescriber_id"
                  onChange={handleChange}
                  value={values.prescriber_id}
                  autoComplete="type-name"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                >
                  <option value="">Please Select</option>
                  {values?.careTeamsArr?.map((item, index) => {
                    return (
                      <option value={index + 1}>
                        <p>{item.practitionerName}</p>
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {/* BOX  */}
            <div className="b-2 border-gray-800">
              <div className="pb-2 md:flex md:items-center md:justify-between">
                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-1">
                  Lens Specifications{" "}
                  <span className="text-sm text-red-500"> *</span>
                </h3>
                <div className="flex space-x-3 md:mt-0 md:ml-4">
                  <button
                    onClick={() => setshowViewPrescriptionModal(true)}
                    type="button"
                    className="ml-3 inline-flex justify-center rounded-full border px-2 py-2 border-transparent bg-amber-600 text-white"
                  >
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="table-responsive table-width-sets">
                <div className="flex">
                  <table className="table table-hover table-fixed">
                    <thead>
                      <tr>
                        {rightHeader.map((el, index) => {
                          return (
                            <th
                              className="py-2
                      px-2
                      pr-3
                      text-wrap
                      "
                              key={"tableHeaders_" + index}
                            >
                              {el.name}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody
                      className="
              min-w-[100%]
              "
                    >
                      {values.lens_specifications?.map((item, index) => {
                        return (
                          <tr
                            key={"addressform"}
                            className="b-2 border-gray-900
                    kasa

                    w-full"
                            onClick={() => {
                              setCurrentItemIndex(index);
                              setshowViewPrescriptionModal(true);
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{item.product}</td>
                            <td>{item.rightSphere}</td>
                            <td>{item.rightPowerContact}</td>
                            <td>{item.rightCylinder}</td>
                            <td>{item.rightAxis}</td>
                            <td>{item.rightBackCurveContact}</td>
                            <td>{item.rightDiameterContact}</td>
                            <td>{item.rightDurationContact}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <table className="table table-hover table-fixed">
                    <thead>
                      <tr>
                        {leftHeader.map((el, index) => {
                          return (
                            <th
                              className="py-2 px-2 pr-3 text-wrap"
                              key={"tableHeaders_" + index}
                            >
                              {el.name}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody
                      className="
              min-w-[100%]
              "
                    >
                      {values.lens_specifications?.map((item, index) => {
                        return (
                          <tr
                            key={"addressform"}
                            className="b-r-2 border-red-600 kasa w-full"
                            onClick={() => {
                              setCurrentItemIndex(index);
                              setshowViewPrescriptionModal(true);
                            }}
                          >
                            <td>{item.leftSphere}</td>
                            <td>{item.leftPowerContact}</td>
                            <td>{item.leftCylinder}</td>
                            <td>{item.leftAxis}</td>
                            <td>{item.leftBackCurveContact}</td>
                            <td>{item.leftDiameterContact}</td>
                            <td>{item.leftDurationContact}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <AddVisionPrescriptionModal
        showViewPrescriptionModal={showViewPrescriptionModal}
        setshowViewPrescriptionModal={setshowViewPrescriptionModal}
        formData={formData}
        setFormData={setFormData}
        setFieldValue={setFieldValue}
        formikValues={values}
        item={
          currentItemIndex !== null
            ? values.lens_specifications[currentItemIndex]
            : null
        }
        currentItemIndex={currentItemIndex}
      />
    </>
  );
};

export default VisionPrescription;
