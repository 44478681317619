import { useMutation, useQuery } from "@apollo/client";
import React, { useState, useContext, useEffect } from "react";
import beneficiary from "../../gql/beneficiary";

// import { ToastContainer, toast } from "react-toastify";
import toast from "react-hot-toast";
import { appContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";

import "./style/payerlist.css";
import Pagination from "../../components/Pagination";
import physicians from "../../gql/physicians";
import AppModal from "../../components/AppModal";
import ConformationModal from "../../components/ConformationModal";
// import FilterModal from "./FilterModal";
import ptpa from "../../gql/ptpa";

import ImportPayerModal from "./ImportPayerModal";

import SearchPayerList from "./SearchPayerList";
import priceList from "../../gql/priceList";
import { LuTrash2 } from "react-icons/lu";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import PriceListFilterModal from "./PriceListFilterModal";
import { Button, Tooltip } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

function PriceListMain() {
  const navigate = useNavigate();

  const [payers, setPayers] = useState([]);
  const { permissions } = useContext(appContext);
  const [item, setItem] = useState(null);

  const [showSearchForm, setShowSearchForm] = useState(false);
  const [showImportPriceListModal, setShowImportPriceListModal] =
    useState(false);

  const [showFilter, setShowFilter] = useState(false);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const [delEntry, { loading: delLoading }] = useMutation(
    priceList.DELETE_PRICE_LIST,
    {
      update(proxy, result) {
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  const onSuccessfulRequestResponse = (data) => {
    if (data?.deletePriceList) {
      toast.success(data?.deletePriceList?.message);
      refetch({
        input: {
          limit: limit,
          page: pageNo,
        },
      });
    } else {
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error.message}`);
  };

  const { loading, error, data, refetch } = useQuery(
    priceList.LIST_PRICE_LIST,
    {
      variables: {
        input: {
          limit: limit,
          page: pageNo,
        },
      },
    }
  );

  const onPageChange = (currentPage) => {
    setPageNo(currentPage);
    refetch({
      input: {
        limit: limit,
        page: currentPage,
      },
    });
  };

  const onClickDelete = (item) => {
    setCurrentItem(item);
    setShowDeleteAlert(true);
  };

  const deleteEntry = () => {
    delEntry({
      variables: {
        input: {
          effective_date: currentItem.effective_date,
          payer_id: currentItem.payer_id,
          id: 0,
        },
      },
    });
    setShowDeleteAlert(false);
  };

  const onClickEdit = (el) => {
    navigate("/physicians/edit/form/" + el.id);
  };

  const tableHeaders = [
    { name: "SQ #" },
    { name: "Payer" },
    { name: "TPA" },
    // { name: "Document #" },
    { name: "Effective Date" },
    { name: "Upload Date" },
    { name: "Actions" },
  ];

  const handleDownloadClick = () => {
    const fileUrl = "/PriceListSample.xlsx";

    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "priceListSample.xlsx";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const {
  //   loading: loadingPayers,
  //   error: errorPayer,
  //   data: payersData,
  //   refetch: refetchPayers,
  // } = useQuery(ptpa.GET_ALL_PAYERS);

  React.useEffect(() => {
    const payersData = JSON.parse(localStorage.getItem("payersData"));

    if (payersData && payersData.length > 0) {
      setPayers(payersData);
    } else {
      navigate("/dashboard");
    }
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Extract date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Format the date
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  return (
    // superGit_container
    <div className="priceList_container">
      {/* benefeciaries-page */}
      <div className="priceList_page">
        {/* beneficiaries-content block md:flex justify-between  */}
        <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
          {showSearchForm ? (
            <SearchPayerList
              setShowSearchForm={setShowSearchForm}
              payersData={payers}
            />
          ) : (
            <>
              <div className="">
                <Tooltip
                  placement="right"
                  title="Keep track of your price lists"
                  className="my-auto !mt-2"
                >
                  <span className="font-semibold text-black/70 text-lg ">
                    PRICE List
                    <span className="italic text-lg ml-1">
                      ({data?.listPriceList?.total || 0}{" "}
                      <span className="text-xs font-bold italic">Entries</span>)
                    </span>
                  </span>
                </Tooltip>
              </div>

              <div class=" flex gap-3 flex-col md:flex-row">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleDownloadClick}
                  className="!border-primary !text-primary hover:!bg-primary/10"
                  startIcon={<FileDownloadOutlinedIcon />}
                >
                  Download Sample
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setShowImportPriceListModal(true)}
                  style={{
                    backgroundImage:
                      "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                  }}
                  startIcon={<AddIcon />}
                >
                  Import Price List
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setShowFilter(() => !showFilter)}
                  className="!border-primary !text-primary hover:!bg-primary/10"
                  startIcon={<FilterAltOutlinedIcon />}
                >
                  Apply Filter
                </Button>
              </div>
            </>
          )}
        </div>
      </div>

      {/* search-filter */}
      <div className="">
        <PriceListFilterModal
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          pageNo={pageNo}
          limit={limit}
          refetch={refetch}
        />
      </div>

      <div className="super-git-table !p-0 !m-0">
        <table className="table-super-git !p-0">
          <thead className="!p-0">
            <tr className="!p-0">
              {tableHeaders.map((el, index) => {
                return (
                  <th className="!p-2 !text-sm" key={"tableHeaders_" + index}>
                    {el?.name}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {data?.listPriceList?.data?.map((el, index) => {
              return (
                <tr key={"beneficiariesData_" + index}>
                  {/* 1 */}
                  <td className="!p-1 !text-sm">
                    {pageNo * limit + index + 1 - limit}
                  </td>
                  <td className="!p-1 !text-sm capitalize text-start">
                    {el.payer_name.length > 100 ? (
                      <Tooltip title={el?.payer_name}>
                        <span>{el?.payer_name.slice(0, 100)}...</span>
                      </Tooltip>
                    ) : (
                      el?.payer_name
                    )}
                  </td>

                  <td className="!p-1 !text-sm">{el?.tpa_name}</td>
                  {/* 4 */}
                  <td className="!p-1 !text-sm">
                    {el?.effective_date?.substring(0, 10)}
                  </td>
                  <td className="!p-1 !text-sm">
                    {formatDate(el?.created_at)}
                  </td>

                  <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                    <Tooltip title="View">
                      <div
                        className="text-xl cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/price-list/specific/${el?.payer_id}/${el?.effective_date}/${el?.payer_name}`
                          )
                        }
                      >
                        <MdOutlineRemoveRedEye />
                      </div>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <div
                        className="text-xl cursor-pointer"
                        onClick={() => {
                          setCurrentItem(el);
                          setShowDeleteAlert(true);
                        }}
                      >
                        <LuTrash2 />
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          currentPage={pageNo}
          totalPages={data?.listPriceList?.pages}
          onPageChange={onPageChange}
        />
      </div>
      <ConformationModal
        open={showDeleteAlert}
        setOpen={setShowDeleteAlert}
        onDelete={deleteEntry}
      />
      <ImportPayerModal
        showImportPriceListModal={showImportPriceListModal}
        setShowImportPriceListModal={setShowImportPriceListModal}
        payersData={payers}
        refetchPriceList={refetch}
        pageNo={pageNo}
        limit={limit}
      />

      <AppModal isOpen={loading || delLoading} />
    </div>
  );
}

export default PriceListMain;

// {
//   /* <div className="superGit_container">
// <div className="benefeciaries-page">
//   <div className="beneficiaries-content block md:flex justify-between ">
//     {showSearchForm ? (
//       <SearchPayerList
//         setShowSearchForm={setShowSearchForm}
//         payersData={payersData}
//       />
//     ) : (
//       <>
//         <div className="benefeciaries-title">
//           <span>Price List</span>
//           <div className="track-beneficiaries">
//             <span>Keep track of your price lists</span>
//           </div>
//         </div>
//         <div className="create-benefeciaries mt-3 md:mt-0 search-filter">
//           <DownloadExcel data={excelData} fileName={"Beneficiaries"} />  --- comment This
//           <div className="create-btn" onClick={() => setShowSearchForm(true)}> <span>Search Price List</span> </div>  --- comment This

//           <div className="create-btn" onClick={handleDownloadClick}>
//             <span>Download Sample</span>
//           </div>

//           <div
//             className="create-btn"
//             onClick={() => setShowImportPriceListModal(true)}
//           >
//             <span>Import Price List</span>
//           </div>

//           <div className="apply-filter">
//             <div
//               className="btn"
//               onClick={() => setShowFilter(() => !showFilter)}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="20"
//                 height="20"
//                 viewBox="0 0 20 20"
//                 fill="none"
//               >
//                 <path
//                   d="M5.83751 4.99992H14.1708L9.99584 10.2499L5.83751 4.99992ZM3.54584 4.67492C5.22918 6.83325 8.33751 10.8333 8.33751 10.8333V15.8333C8.33751 16.2916 8.71251 16.6666 9.17084 16.6666H10.8375C11.2958 16.6666 11.6708 16.2916 11.6708 15.8333V10.8333C11.6708 10.8333 14.7708 6.83325 16.4542 4.67492C16.8792 4.12492 16.4875 3.33325 15.7958 3.33325H4.20418C3.51251 3.33325 3.12084 4.12492 3.54584 4.67492Z"
//                   fill="black"
//                 />
//               </svg>
//               Apply Filter
//             </div>
//           </div>
//         </div>
//       </>
//     )}
//   </div>
// </div>
// <div className="search-filter">
//   <div className="search"> <input type="search" name="search" id="search" placeholder="Search" /> </div>  --- comment This

//   <PriceListFilterModal
//     showFilter={showFilter}
//     setShowFilter={setShowFilter}
//     pageNo={pageNo}
//     limit={limit}
//     refetch={refetch}
//   />
// </div>
// <div className="super-git-table">
//   <table className="table-super-git">
//     <thead>
//       <tr>
//         {tableHeaders.map((el, index) => {
//           return (
//             <th className="" key={"tableHeaders_" + index}>
//               {el?.name}
//             </th>
//           );
//         })}
//       </tr>
//     </thead>
//     <tbody>
//       {data?.listPriceList?.data?.map((el, index) => {
//         return (
//           <tr key={"beneficiariesData_" + index}>
//             <td>{pageNo * limit + index + 1 - limit}</td>
//             <td>{el?.payer_name}</td>
//             <td>{el?.tpa_name}</td>

//             <td>{el?.effective_date?.substring(0, 10)}</td>
//             <td>{formatDate(el?.created_at)}</td>
//             <td className="flex gap-3">
//               <Tooltip title="View">
//                 <div
//                   onClick={() =>
//                     navigate(
//                       `/price-list/specific/${el?.payer_id}/${el?.effective_date}/${el?.payer_name}`
//                     )
//                   }
//                   className="text-xl"
//                 >
//                   <MdOutlineRemoveRedEye />
//                 </div>
//               </Tooltip>
//               <Tooltip title="Delete">
//                 <div
//                   className="text-xl"
//                   onClick={() => {
//                     setCurrentItem(el);
//                     setShowDeleteAlert(true);
//                   }}
//                 >
//                   <LuTrash2 />
//                 </div>
//               </Tooltip>
//             </td>
//           </tr>
//         );
//       })}
//     </tbody>
//   </table>
// </div>
// <div>
//   <Pagination
//     currentPage={pageNo}
//     totalPages={data?.listPriceList?.pages}
//     onPageChange={onPageChange}
//   />
// </div>
// <ConformationModal
//   open={showDeleteAlert}
//   setOpen={setShowDeleteAlert}
//   onDelete={deleteEntry}
// />
// <ImportPayerModal
//   showImportPriceListModal={showImportPriceListModal}
//   setShowImportPriceListModal={setShowImportPriceListModal}
//   payersData={payersData}
//   refetchPriceList={refetch}
//   pageNo={pageNo}
//   limit={limit}
// />

// <AppModal isOpen={loading || delLoading} />
// </div> */
// }
