import React, { useEffect, useRef, useState } from "react";
import { Formik, useFormik } from "formik";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import ptpa from "../../gql/ptpa";

// import "../../styles/eligibility.css";

function ClaimHistoryFilter({ showFilter, setShowFilter, refetch }) {
  const currentDate = new Date();
  function getCurrentYearMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // JavaScript months are 0-based
    return `${year}-${month}`;
  }

  const [selectMonth, setSelectMonth] = useState(getCurrentYearMonth());
  const modalRef = useRef();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      from_date: `${currentDate.getFullYear()}-${String(
        currentDate.getMonth() + 1
      ).padStart(2, "0")}-01`,
      to_date: `${currentDate.getFullYear()}-${String(
        currentDate.getMonth() + 1
      ).padStart(2, "0")}-${new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate()}`,
    },
    onSubmit: (values, { setSubmitting }) => {
      refetch({
        input: Object.keys(values).reduce((acc, key) => {
          const value = values[key];
          if (value !== "" && value !== null) {
            acc[key] = value;
          }
          return acc;
        }, {}),
      });
    },
  });

  function getFirstAndLastDateOfMonth(yearMonth) {
    // Parse the input string to get year and month
    const [year, month] = yearMonth.split("-").map(Number);

    // Create a Date object for the first day of the month
    const firstDate = new Date(year, month - 1, 1);

    // Create a Date object for the last day of the month
    const lastDate = new Date(year, month, 0);

    // Format the dates to `YYYY-MM-DD`
    const firstDateString = `${firstDate.getFullYear()}-${(
      firstDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${firstDate.getDate().toString().padStart(2, "0")}`;
    const lastDateString = `${lastDate.getFullYear()}-${(
      lastDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${lastDate.getDate().toString().padStart(2, "0")}`;

    return {
      firstDate: firstDateString,
      lastDate: lastDateString,
    };
  }

  useEffect(() => {
    if (selectMonth) {
      const { firstDate, lastDate } = getFirstAndLastDateOfMonth(selectMonth);

      setFieldValue("from_date", firstDate);
      setFieldValue("to_date", lastDate);
    }
  }, [selectMonth]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowFilter(false);
      }
    };

    if (showFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilter]);

  return (
    <div className={`${showFilter ? "open" : ""} modal`} id="myModal">
      <div ref={modalRef} className="modal-content">
        <div className="close" onClick={() => setShowFilter(() => !showFilter)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#DE5042"
            />
          </svg>
          <div className="bene-text">Claim History</div>
        </div>
        <div className="search-fields">
          {/* <input
            type="text"
            id="from_date"
            placeholder="From Date"
            name="from_date"
            value={values.from_date}
            onChange={handleChange}
            onBlur={(e) => (e.target.type = "text")}
            onFocus={(e) => (e.target.type = "date")}
          />
          <input
            type="text"
            id="to_date"
            placeholder="To Date"
            name="to_date"
            value={values.to_date}
            onChange={handleChange}
            onBlur={(e) => (e.target.type = "text")}
            onFocus={(e) => (e.target.type = "date")}
          /> */}

          <input
            type="month"
            value={selectMonth}
            onChange={(e) => setSelectMonth(e.target.value)}
          />
        </div>
        <div
          className="search-btn"
          onClick={() => {
            handleSubmit();
            setShowFilter(false);
          }}
        >
          {/* <div className="search-btn" onClick={handleSubmit}> */}
          <span>Search</span>
        </div>
        <div className="reset-btn" onClick={resetForm}>
          <span>Reset</span>
        </div>
      </div>
    </div>
  );
}

export default ClaimHistoryFilter;
