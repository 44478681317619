import React from "react";

const TotalItem = ({ item }) => {
  return (
    <div class="eligibility_details_section !p-0">
      {/* <div class="eligibility_details_head_cont">
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="34" height="34" rx="8" fill="#4D4D53"></rect>
          <path
            d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
            fill="white"
          ></path>
          <path
            d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
            fill="white"
          ></path>
          <path
            d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
            fill="white"
          ></path>
        </svg>
        <span>Total</span>
      </div> */}
      <div class="total_cont ">
        <div class="eligibility_details_body_cont  !grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 !p-0 !shadow-none !border-none">
          <div class="eligibility_details_user_data border-b px-3 py-6">
            <div class="data_head !text-center">
              <span>Tax</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.total_resp_tax ? item?.total_resp_tax : "___"}
                {item?.total_resp_tax_currency
                  ? item?.total_resp_tax_currency
                  : "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-3 py-6">
            <div class="data_head !text-center">
              <span>Eligible</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.total_approved_value != null
                  ? item?.total_approved_value
                  : "___"}
                {" - "}
                {item?.total_approved_currency
                  ? item?.total_approved_currency
                  : "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-3 py-6">
            <div class="data_head !text-center">
              <span>Copay</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.total_copay_value != null
                  ? item?.total_copay_value
                  : "___"}
                {" - "}
                {item?.total_copay_currency
                  ? item?.total_copay_currency
                  : "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data border-b px-3 py-6">
            <div class="data_head !text-center">
              <span>Patient Share</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.total_resp_patient_share_value != null
                  ? item?.total_resp_patient_share_value
                  : "___"}
                {" - "}
                {item?.total_resp_patient_share_ccy
                  ? item?.total_resp_patient_share_ccy
                  : "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data px-3 pt-6">
            <div class="data_head !text-center">
              <span>Benefit</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.total_benefit_value != null
                  ? item?.total_benefit_value
                  : "___"}
                {" - "}
                {item?.total_benefit_currency
                  ? item?.total_benefit_currency
                  : "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data px-3 pt-6">
            <div class="data_head !text-center">
              <span>Submitted</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.total_submitted_value != null
                  ? item?.total_submitted_value
                  : "___"}
                {" - "}
                {item?.total_submitted_ccy ? item?.total_submitted_ccy : "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data px-3 pt-6">
            <div class="data_head !text-center">
              <span>Deductible</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.total_deducted_value != null
                  ? item?.total_deducted_value
                  : "___"}
                {" - "}
                {item?.total_deducted_currency
                  ? item?.total_deducted_currency
                  : "___"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalItem;
