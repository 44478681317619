import React, { useState } from "react";
import ReactJson from "react-json-view";
import Modal from "react-modal";
import { XMarkIcon } from "@heroicons/react/20/solid";

// import {} from ''
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minHeight: "75vh",
    maxWidth: "1200px",
    maxHeight: "75vh",
    // overflow:'hidden'
    overflowY: "scroll",
    zIndex: 99999,
  },
};

let subtitle;

function ReactJsonView({ showJsonView, onCloseShowJson, jsonObject }) {
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  const [collapsed, setCollapsed] = useState(true);
  const [theme, setTheme] = useState("monokai");

  const themes = [
    "rjv-default",
    "monokai",
    "colors",
    "grayscale:inverted",
    "greenscreen",
    "embers",
    "harmonic",
  ];

  const testJson = {
    employees: [
      {
        id: 1,
        first_name: "John",
        last_name: "Doe",
        position: "Manager",
        email: "johndoe@example.com",
        phone_number: "555-555-5555",
        address: {
          street: "123 Main St",
          city: "Anytown",
          state: "CA",
          zip: "12345",
        },
      },
      {
        id: 2,
        first_name: "Jane",
        last_name: "Smith",
        position: "Developer",
        email: "janesmith@example.com",
        phone_number: "555-555-5556",
        address: {
          street: "456 Elm St",
          city: "Anytown",
          state: "NY",
          zip: "67890",
        },
      },
      {
        id: 3,
        first_name: "Bob",
        last_name: "Johnson",
        position: "Designer",
        email: "bobjohnson@example.com",
        phone_number: "555-555-5557",
        address: {
          street: "789 Oak St",
          city: "Anytown",
          state: "TX",
          zip: "11111",
        },
      },
    ],
  };

  return (
    <div>
      <Modal
        isOpen={showJsonView}
        // isOpen={true}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => onCloseShowJson()}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="preauth-form">
          {/* TOP CONTAINER */}
          <div className="py-1 mb-2 px-1 flex items-center justify-between ">
            {/* left */}
            <form action="" className="inline-flex items-baseline gap-8 ">
              <div class="flex   gap-4 items-baseline">
                <div className=" ">
                  <input
                    type="checkbox"
                    id="isNewBorn"
                    name="isNewBorn"
                    onChange={() => setCollapsed(!collapsed)}
                    class="appearence-none checked:bg-cyan-600 rounded text-cyan-600 focus:ring-cyan-500"
                  />
                </div>
                <label
                  htmlFor="comments"
                  className="text-gray-900 text-sm font-medium cursor-pointer  "
                >
                  Expand
                </label>
              </div>
              <div className="flex items-baseline gap-3">
                <label
                  htmlFor="themeId"
                  className="text-gray-900 text-sm font-medium cursor-pointer  "
                >
                  Theme
                </label>
                <div>
                  <select
                    id="themeId"
                    name="country"
                    autoComplete="country-name"
                    className=" block w-full rounded-md border border-gray-300 bg-white py-2 px-30 shadow-sm focus:border-cyan-700 focus:outline-none focus:ring-cyan-500 sm:text-sm"
                    onChange={(e) => setTheme(e.target.value)}
                    value={theme}
                  >
                    {themes.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </select>
                </div>
              </div>
            </form>
            {/* RIGHT */}
            <div>
              <XMarkIcon
                className="h-5 w-5 text-gray-900 text-3xl hover:cursor-pointer "
                aria-hidden="true"
                onClick={() => onCloseShowJson()}
              />
            </div>
          </div>

          <div className=" ">
            <ReactJson
              collapsed={collapsed}
              displayDataTypes={false}
              src={jsonObject}
              theme={theme}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
  return;
}

export default ReactJsonView;
