import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

import AddBeneficiaryPersonalDataForm from "./components/AddBeneficiaryPersonalDataForm";
import beneficiary from "../../gql/beneficiary";
import PatientDetailForm from "./components/PatientDetailForm";
import { Tooltip } from "@mui/material";

function BeneficiaryForm({}) {
  const navigate = useNavigate();
  const [chiReqRecord, setChiReqRecord] = useState(null);

  return (
    <div className="superGit_container">
      <div class="benefeciary-create">
        <PatientDetailForm setChiReqRecord={setChiReqRecord} />
        <AddBeneficiaryPersonalDataForm
          item={null}
          chiReqRecord={chiReqRecord}
          setChiReqRecord={setChiReqRecord}
        />
      </div>
    </div>
  );
}

export default BeneficiaryForm;
