import React, { useEffect, useRef, useState } from "react";
import { Formik, FieldArray, Field } from "formik";
import _ from "lodash";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import ptpa from "../../../gql/ptpa";
import { useQuery } from "@apollo/client";
import BeneficiarySearchNSelect from "../../Eligibility/components/requestEligibility/BeneficiarySearchNSelect";
import { useNavigate, useLocation } from "react-router-dom";

function PreAuthFilter({
  showFilter,
  setShowFilter,

  refetch,
  setPageNo,
  setLimit,
  refetchExcelDataResponse,
  setFromDate,
  setToDate,
  setLoading,
  setRefetchFilterVariables,
  pageNo,
}) {
  const [payers, setPayers] = useState([]);
  const currentDate = new Date();
  const location = useLocation();
  const navigate = useNavigate();

  const preAuthFilterDate = JSON.parse(
    localStorage.getItem("preAuthFilterDate")
  );
  const preAuthPageNo = JSON.parse(localStorage.getItem("preAuthPageNo"));

  const [filterData, setFilterData] = useState({
    from_date: preAuthFilterDate?.from_date
      ? preAuthFilterDate?.from_date
      : `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}-01`,
    to_date: preAuthFilterDate?.to_date
      ? preAuthFilterDate?.to_date
      : `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}-${new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate()}`,
    from_date_ordered: preAuthFilterDate?.from_date_ordered
      ? preAuthFilterDate?.from_date_ordered
      : "",
    to_date_ordered: preAuthFilterDate?.to_date_ordered
      ? preAuthFilterDate?.to_date_ordered
      : "",
    payer_id: preAuthFilterDate?.payer_id ? preAuthFilterDate?.payer_id : "",
    type: preAuthFilterDate?.type ? preAuthFilterDate?.type : "",
    patient_id: preAuthFilterDate?.patient_id
      ? preAuthFilterDate?.patient_id
      : "",
    // pre_auth_id: "",
    status: preAuthFilterDate?.status ? preAuthFilterDate?.status : "",
    pre_auth_ref_no: preAuthFilterDate?.pre_auth_ref_no
      ? preAuthFilterDate?.pre_auth_ref_no
      : "",
    pre_auth_bundle_id: preAuthFilterDate?.pre_auth_bundle_id
      ? preAuthFilterDate?.pre_auth_bundle_id
      : "",
    is_followup_req: preAuthFilterDate?.is_followup_req
      ? preAuthFilterDate?.is_followup_req
      : false,
    // is_valid: preAuthFilterDate?.is_valid ? preAuthFilterDate?.is_valid : false,
    limit: 10,
    page: preAuthPageNo ? preAuthPageNo : 1,
  });

  useEffect(() => {
    if (location?.pathname === "/preauth") {
      localStorage.setItem("preAuthFilterDate", JSON.stringify(filterData));
      localStorage.setItem("preAuthPageNo", JSON.stringify(pageNo));
    }
  }, [location?.pathname, filterData, pageNo]);

  useEffect(() => {
    const preAuthFilterDate = JSON.parse(
      localStorage.getItem("preAuthFilterDate")
    );
    const preAuthPageNo = JSON.parse(localStorage.getItem("preAuthPageNo"));

    if (preAuthFilterDate && preAuthPageNo) {
      const obj = { ...preAuthFilterDate, page: preAuthPageNo };

      // console.log();
      try {
        setLoading(true);
        refetch({
          input: Object.keys(obj).reduce((acc, key) => {
            const value = obj[key];
            if (value !== "" && value !== null) {
              acc[key] = value;
            }
            return acc;
          }, {}),
        });
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const modalRef = useRef();

  const addData = async (values) => {
    let data = _.cloneDeep(values);
    setPageNo(1);
    setFromDate(values.fromDate);
    setToDate(values.toDate);
    let obj = {
      from_date: values.fromDate,
      to_date: values.toDate,
      payer_id: values.payer,
      type: values.type,
      patient_id: values.beneficiary,
      // pre_auth_id: values.preAuthorizationID,
      from_date_ordered: values?.fromDateOrdered,
      to_date_ordered: values?.toDateOrdered,
      status: values.status,
      pre_auth_ref_no: values.preAuthRefNo,
      pre_auth_bundle_id: values.bundleID,
      is_followup_req: values?.isFollowUp,
      // is_valid: values?.isValid,
      limit: 10,
      page: 1,
    };

    setFilterData(obj);

    try {
      setLoading(true);
      await refetch({
        input: Object.keys(obj).reduce((acc, key) => {
          const value = obj[key];
          if (value !== "" && value !== null) {
            acc[key] = value;
          }
          return acc;
        }, {}),
      });
    } finally {
      setLoading(false);
    }

    setRefetchFilterVariables(
      Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value !== "" && value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {})
    );

    delete obj.limit;
    delete obj.page;

    // refetchExcelDataResponse({
    //   input: Object.keys(obj).reduce((acc, key) => {
    //     const value = obj[key];
    //     if (value !== "" && value !== null) {
    //       acc[key] = value;
    //     }
    //     return acc;
    //   }, {}),
    // });
  };

  const formatPayersData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.id,
          naphipesId: item.nphies_id,
          licenseId: item.license_id,
          name_ar: item.name_ar,
          name: item.name_en,
          tpa_id: item.tpa_id,
        });
      });
    } else {
    }
    return returnData;
  };

  // const {
  //   loading: loadingPayers,
  //   error: errorPayer,
  //   data: payersData,
  //   refetch: refetchPayers,
  // } = useQuery(ptpa.GET_ALL_PAYERS);

  // React.useEffect(() => {
  //   if (payersData) {
  //     setPayers(payersData?.listPayersWithTpa?.data);
  //   }
  // }, [payersData]);

  React.useEffect(() => {
    const payersData = JSON.parse(localStorage.getItem("payersData"));

    if (payersData && payersData.length > 0) {
      setPayers(payersData);
    } else {
      navigate("/dashboard");
    }
  }, []);

  const types = [
    { id: "institutional", name: "Institutional" },
    { id: "oral", name: "Dental" },
    { id: "pharmacy", name: "Pharmacy" },
    { id: "professional", name: "Professional" },
    { id: "vision", name: "Optical" },
  ];

  const statuses = [
    { id: "approved", name: "Approved" },
    { id: "partial Approved", name: "Partial Approved" },
    { id: "rejected", name: "Rejected" },
    { id: "pending", name: "Pending" },
    { id: "pended", name: "Pended" },
  ];
  const outcome = [
    { id: "error", name: "Error" },
    { id: "queued", name: "Queued" },
    { id: "complete", name: "Complete" },
  ];

  // useEffect PreAuth
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowFilter(false);
      }
    };

    if (showFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilter]);

  return (
    <Formik
      initialValues={{
        fromDate: preAuthFilterDate?.from_date
          ? preAuthFilterDate?.from_date
          : `${currentDate.getFullYear()}-${String(
              currentDate.getMonth() + 1
            ).padStart(2, "0")}-01`,
        toDate: preAuthFilterDate?.to_date
          ? preAuthFilterDate?.to_date
          : `${currentDate.getFullYear()}-${String(
              currentDate.getMonth() + 1
            ).padStart(2, "0")}-${new Date(
              currentDate.getFullYear(),
              currentDate.getMonth() + 1,
              0
            ).getDate()}`,
        fromDateOrdered: "",
        toDateOrdered: "",
        payer: "",
        type: "",
        beneficiary: "",
        preAuthorizationID: "",
        status: "",
        preAuthRefNo: "",
        bundleID: "",
        outcome: "",
        isFollowUp: false,
        // isValid: true,
      }}
      validate={(values) => {
        const errors = {};
      }}
      onSubmit={(values, { setSubmitting }) => {
        addData(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
        /* and other goodies */
      }) => (
        <div className={`${showFilter ? "open" : ""} modal`} id="search-filter">
          <div ref={modalRef} class="modal-content">
            <div class="close" onClick={() => setShowFilter(() => !showFilter)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#DE5042"
                />
              </svg>
              <div class="bene-text">Filters</div>
            </div>
            <div class="search-fields">
              <label>Submission Date</label>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                <input
                  type="date"
                  name="fromDate"
                  value={values.fromDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="date"
                  placeholder="From Date"
                />
                <input
                  type="date"
                  name="toDate"
                  value={values.toDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="date"
                  placeholder="To Date"
                />
              </div>

              <label>Ordered Date</label>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                <input
                  type="text"
                  id="fromDateOrdered"
                  placeholder="From Order Date"
                  name="fromDateOrdered"
                  value={values.fromDateOrdered}
                  onChange={handleChange}
                  onBlur={(e) => (e.target.type = "text")}
                  onFocus={(e) => (e.target.type = "date")}
                />

                <input
                  type="text"
                  id="toDateOrdered"
                  placeholder="To Order Date"
                  name="toDateOrdered"
                  value={values.toDateOrdered}
                  onChange={handleChange}
                  onBlur={(e) => (e.target.type = "text")}
                  onFocus={(e) => (e.target.type = "date")}
                />
              </div>

              <select
                name="payer"
                onChange={handleChange}
                value={values.payer}
                id="prayer"
              >
                <option value="">Select Payer</option>
                {payers.map((category, index) => (
                  <optgroup
                    label={
                      category?.name_en
                        ? category?.name_en + ` (${category?.license_id})`
                        : ""
                    }
                    key={category.id}
                  >
                    {category.payers.map((insurance, index) => (
                      <option key={index} value={insurance.id}>
                        {insurance?.name_en} ({insurance?.license_id})
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
              <select
                name="type"
                onChange={handleChange}
                value={values.type}
                id="type"
              >
                {types.map((item) => {
                  return (
                    <option value={item.id}>
                      <p>{item.name}</p>
                    </option>
                  );
                })}
              </select>
              <BeneficiarySearchNSelect
                setFieldValue={(val) => {
                  setFieldValue("beneficiary", val.id);
                }}
              />
              {/* <input type="search" placeholder="Select a Beneficiary" /> */}
              <input
                type="text"
                placeholder="Pre Authorization ID"
                name="preAuthorizationID"
                value={values.preAuthorizationID}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <select
                name="status"
                onChange={handleChange}
                value={values.status}
                id="status"
              >
                <option value="">Select Status</option>
                {statuses.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
              <select
                name="outcome"
                onChange={handleChange}
                value={values.outcome}
                id="outcome"
              >
                <option value="">Select Outcome</option>
                {outcome.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
              <input
                type="text"
                placeholder="Pre Auth Reference No"
                name="preAuthRefNo"
                value={values.preAuthRefNo}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <input
                type="text"
                placeholder="Request Bundle ID"
                name="bundleID"
                value={values.bundleID}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="flex justify-between items-center p-2  border rounded-lg">
              <div className="text-lg text-gray-600 ml-2">Is Follow Up</div>
              <div class="toggle-button-born flex items-center">
                <input
                  type="checkbox"
                  id="toggle-born"
                  name="isFollowUp"
                  value={values.isFollowUp}
                  onChange={handleChange}
                />
                <label for="toggle-born"></label>
              </div>
            </div>

            {/* <div className="flex justify-between items-center p-2  border rounded-lg mt-2">
              <div className="text-lg text-gray-600 ml-2">Is Valid</div>
              <div class="toggle-button-born flex items-center">
                <input
                  type="checkbox"
                  id="toggle-born-1"
                  name="isValid"
                  defaultChecked={true}
                  value={values.isValid}
                  onChange={handleChange}
                />
                <label for="toggle-born-1"></label>
              </div>
            </div> */}
            <div
              class="search-btn mt-2"
              // onClick={handleSubmit}
              onClick={() => {
                handleSubmit();
                setShowFilter(false);
              }}
            >
              <span>Search</span>
            </div>
            <div class="reset-btn" onClick={resetForm}>
              <span>Reset</span>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default PreAuthFilter;
