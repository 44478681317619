import { useMutation, useQuery } from "@apollo/client";
import React, { useState, useContext, useEffect } from "react";
import beneficiary from "../../gql/beneficiary";
// import { ToastContainer, toast } from "react-toastify";
import toast from "react-hot-toast";
import { appContext } from "../../context/ContextProvider";
import { useNavigate } from "react-router-dom";

import "./styles/Physicians.css";
import Pagination from "../../components/Pagination";
import physicians from "../../gql/physicians";
import AppModal from "../../components/AppModal";
import ConformationModal from "../../components/ConformationModal";
import FilterModal from "./FilterModal";
import { Button, Tooltip } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import { TbEditCircle } from "react-icons/tb";
import { LuTrash2 } from "react-icons/lu";

function PhysiciansList() {
  const navigate = useNavigate();

  const { permissions } = useContext(appContext);
  const [item, setItem] = useState(null);

  const [showDetails, setShowDetails] = useState(false);

  const [showFilter, setShowFilter] = useState(false);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const [delEntry, { loading: delLoading }] = useMutation(
    physicians.DELETE_PHYSICIAN,
    {
      update(proxy, result) {
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  const onSuccessfulRequestResponse = (data) => {
    if (data?.deletePhysician) {
      toast.success(data?.deletePhysician?.message);
      refetch();
    } else {
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error.message}`);
  };

  const { loading, error, data, refetch } = useQuery(
    physicians.LIST_ALL_PHYSICIANS,
    {
      variables: { limit: limit, page: pageNo },
    }
  );

  useEffect(() => {
    if (error?.graphQLErrors[0]?.message) {
      toast.error(error?.graphQLErrors[0]?.message);
    }
  }, [error]);

  const onPageChange = (currentPage) => {
    setPageNo(currentPage);
    refetch({ limit: limit, page: currentPage });
  };

  const onClickDelete = (item) => {
    setCurrentItem(item);
    setShowDeleteAlert(true);
  };

  const deleteEntry = () => {
    delEntry({ variables: { input: { id: currentItem.id } } });
    setShowDeleteAlert(false);
  };

  const onClickEdit = (el) => {
    navigate("/physicians/edit/form/" + el.id);
  };

  const tableHeaders = [
    { name: "SQ #" },
    { name: "Name" },
    { name: "license" },
    { name: "Document ID" },
    { name: "Speciality" },
    { name: "Role" },
    { name: "Actions" },
  ];

  return (
    // superGit_container
    <div className="physicians_container">
      {/* benefeciaries-page */}
      <div className="physicians_page">
        {/* beneficiaries-content block md:flex justify-between */}
        <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
          <div className="">
            <Tooltip
              placement="right"
              title="Keep track of your physicians accounts"
              className="my-auto !mt-2"
            >
              <span className="font-semibold text-black/70 text-lg ">
                PHYSICIANS
                <span className="italic text-lg ml-1">
                  ({data?.listPhysicians?.total || 0}{" "}
                  <span className="text-xs font-bold italic">Entries</span>)
                </span>
              </span>
            </Tooltip>
          </div>

          <div class=" flex gap-3 flex-col md:flex-row">
            <Button
              variant="contained"
              size="small"
              onClick={() => navigate("/physicians/form")}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<AddIcon />}
            >
              Create Physician
            </Button>
            {/* <Button
              variant="outlined"
              size="small"
              onClick={() => setShowFilter(() => !showFilter)}
              className="!border-primary !text-primary hover:!bg-primary/10"
              startIcon={<FilterAltOutlinedIcon />}
            >
              Apply Filter
            </Button> */}
          </div>
        </div>
      </div>

      {/* search-filter */}
      <div className="">
        {/* <div className="search">
          <input type="search" name="search" id="search" placeholder="Search" />
        </div> */}

        <FilterModal
          refetch={refetch}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setPageNo={setPageNo}
          setLimit={setLimit}
          // refetchExcelDataResponse={refetchExcelDataResponse}
        />
      </div>

      <div className="super-git-table">
        <table className="table-super-git">
          <thead className="!p-0">
            <tr className="!p-0">
              {tableHeaders.map((el, index) => {
                return (
                  <th className="!p-2 !text-sm" key={"tableHeaders_" + index}>
                    {el.name}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {data?.listPhysicians?.data?.map((el, index) => {
              return (
                <tr key={"beneficiariesData_" + index}>
                  {/* 1 */}
                  <td className="!p-1 !text-sm">
                    {pageNo * limit + index + 1 - limit}
                  </td>
                  <td className="!p-1 !text-sm capitalize text-start">
                    {el?.name?.length > 100 ? (
                      <Tooltip title={el?.name}>
                        <span>{el?.name.slice(0, 100)}...</span>
                      </Tooltip>
                    ) : (
                      el?.name
                    )}
                  </td>
                  <td className="!p-1 !text-sm">{el?.license}</td>
                  <td className="!p-1 !text-sm">{el?.document_id}</td>
                  <td className="!p-1 !text-sm">{el?.speciality}</td>
                  <td className="!p-1 !text-sm">{el?.role}</td>
                  <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                    <Tooltip title="Edit">
                      <div
                        onClick={() => onClickEdit(el)}
                        className="text-xl cursor-pointer"
                      >
                        <TbEditCircle />
                      </div>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <div
                        onClick={() => {
                          onClickDelete(el);
                        }}
                        className="text-xl cursor-pointer"
                      >
                        <LuTrash2 />
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          currentPage={pageNo}
          totalPages={data?.listPhysicians?.pages}
          onPageChange={onPageChange}
        />
      </div>
      <ConformationModal
        open={showDeleteAlert}
        setOpen={setShowDeleteAlert}
        onDelete={deleteEntry}
      />

      <AppModal isOpen={loading || delLoading} />
    </div>
  );
}

export default PhysiciansList;

{
  /* <div className="benefeciaries-page">
  <div className="beneficiaries-content block md:flex justify-between ">
    <div className="benefeciaries-title">
      <span>Physicians</span>
      <div className="track-beneficiaries">
        <span>Keep track of your physicians accounts</span>
      </div>
    </div>
    <div className="create-benefeciaries mt-3 md:mt-0 search-filter">

      <DownloadExcel data={excelData} fileName={"Beneficiaries"} />   <-<---comment This one

      <div
        className="create-btn"
        onClick={() => navigate("/physicians/form")}
      >
        <span>Create Physician</span>
      </div>

      <div className="apply-filter">
        <div
          className="btn"
          onClick={() => setShowFilter(() => !showFilter)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M5.83751 4.99992H14.1708L9.99584 10.2499L5.83751 4.99992ZM3.54584 4.67492C5.22918 6.83325 8.33751 10.8333 8.33751 10.8333V15.8333C8.33751 16.2916 8.71251 16.6666 9.17084 16.6666H10.8375C11.2958 16.6666 11.6708 16.2916 11.6708 15.8333V10.8333C11.6708 10.8333 14.7708 6.83325 16.4542 4.67492C16.8792 4.12492 16.4875 3.33325 15.7958 3.33325H4.20418C3.51251 3.33325 3.12084 4.12492 3.54584 4.67492Z"
              fill="black"
            />
          </svg>
          Apply Filter
        </div>
      </div>
    </div>
  </div>
</div> */
}

{
  /* <div className="super-git-table">
<table className="table-super-git">
  <thead>
    <tr>
      {tableHeaders.map((el, index) => {
        return (
          <th className="" key={"tableHeaders_" + index}>
            {el.name}
          </th>
        );
      })}
    </tr>
  </thead>
  <tbody>
    {data?.listPhysicians?.data?.map((el, index) => {
      return (
        <tr key={"beneficiariesData_" + index}>
          <td>{pageNo * limit + index + 1 - limit}</td>
          <td>{el.name}</td>
          <td>{el?.license}</td>

          <td>{el.document_id}</td>  ---comment This one

          <td>{el?.speciality}</td>
          <td>{el?.role}</td>
          <td className="flex gap-3">
            <span className="edit" onClick={() => onClickEdit(el)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19L2.99878 17.25ZM5.91878 19H4.99878V18.08L14.0588 9.02L14.9788 9.94L5.91878 19ZM20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3C17.3988 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63Z"
                  fill="#4D4D53"
                />
              </svg>
            </span>

            <span
              class="view"
              className="cursor-pointer"
              onClick={() => {
                onClickDelete(el);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24"
                height="24"
                viewBox="0 0 128 128"
              >
                <path d="M 49 1 C 47.34 1 46 2.34 46 4 C 46 5.66 47.34 7 49 7 L 79 7 C 80.66 7 82 5.66 82 4 C 82 2.34 80.66 1 79 1 L 49 1 z M 24 15 C 16.83 15 11 20.83 11 28 C 11 35.17 16.83 41 24 41 L 101 41 L 101 104 C 101 113.37 93.37 121 84 121 L 44 121 C 34.63 121 27 113.37 27 104 L 27 52 C 27 50.34 25.66 49 24 49 C 22.34 49 21 50.34 21 52 L 21 104 C 21 116.68 31.32 127 44 127 L 84 127 C 96.68 127 107 116.68 107 104 L 107 40.640625 C 112.72 39.280625 117 34.14 117 28 C 117 20.83 111.17 15 104 15 L 24 15 z M 24 21 L 104 21 C 107.86 21 111 24.14 111 28 C 111 31.86 107.86 35 104 35 L 24 35 C 20.14 35 17 31.86 17 28 C 17 24.14 20.14 21 24 21 z M 50 55 C 48.34 55 47 56.34 47 58 L 47 104 C 47 105.66 48.34 107 50 107 C 51.66 107 53 105.66 53 104 L 53 58 C 53 56.34 51.66 55 50 55 z M 78 55 C 76.34 55 75 56.34 75 58 L 75 104 C 75 105.66 76.34 107 78 107 C 79.66 107 81 105.66 81 104 L 81 58 C 81 56.34 79.66 55 78 55 z"></path>
              </svg>
            </span>
          </td>
        </tr>
      );
    })}
  </tbody>
</table>
</div> */
}
