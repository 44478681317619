import { Button, IconButton, Dialog, Tooltip, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { styled } from "@mui/material/styles";
import preAuth from "../../gql/preAuth";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ConvertPreAuthToClaimModal({
  showConvertToClaimModal,
  setShowConvertToClaimModal,
  selectedItem,
}) {
  const navigate = useNavigate();
  const {
    loading: loadingDetails,
    error: detailsError,
    data: detailsData,
    refetch: refetchDetails,
  } = useQuery(preAuth.GET_PREAUTH_TRANSACTION_DETAILS, {
    variables: {
      transactionId: selectedItem?.transaction_id,
    },
    skip: !selectedItem?.transaction_id,
  });

  const [convertPreAuthToClaim, { data, loading, error }] = useMutation(
    preAuth.PRE_AUTH_TO_CLAIM,
    {
      update(proxy, result) {
        onSuccessfulCreateRequestResponse(result.data);
      },
      onError(error) {
        onCreateResponseError(error.graphQLErrors[0]);
      },
    }
  );

  const onSuccessfulCreateRequestResponse = (data) => {
    if (data?.preAuthToClaim) {
      toast.success(data?.preAuthToClaim?.message);
      formik.resetForm();
      setShowConvertToClaimModal(false);
      navigate("/claims/submit");
    } else {
    }
  };

  const onCreateResponseError = (error) => {
    toast.error(`${error?.message || "Error"}`);
  };

  const formik = useFormik({
    initialValues: {
      episode_no: "",
      // pre_auth_id: "",
      items: [],
    },

    validationSchema: () => {
      return Yup.object().shape({
        episode_no: Yup.string().required("Episode No required"),
        transaction_id: Yup.string().required("Pre Auth Id required"),
        items: Yup.array().of(
          Yup.object().shape({
            invoice_no: Yup.string().required("Invoice No required"),
            patient_share: Yup.number().required("Patient Share required"),
            payer_share: Yup.number().required("Payer Share required"),
            payer_share_with_tax: Yup.number().required(
              "Payer Share With Tax required"
            ),
            discount: Yup.number().required("Discount required"),
          })
        ),
      });
    },

    onSubmit: (values) => {
      convertPreAuthToClaim({
        variables: {
          input: values,
        },
      });
    },
  });

  useEffect(() => {
    if (selectedItem) {
      formik.setFieldValue("transaction_id", selectedItem?.transaction_id);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (detailsData && detailsData.getPreAuthTransaction) {
      const extractedItems =
        detailsData?.getPreAuthTransaction?.data?.items?.map((item) => ({
          id: item.id,
          patient_share: item?.patient_share || 0,
          invoice_no: "",
          payer_share: item?.payer_share || 0,
          payer_share_with_tax: item?.tax_amount + item?.payer_share || 0,
          discount: item?.discount_amount || 0,
        }));

      formik.setValues({
        ...formik.values,
        items: extractedItems,
      });
    }
  }, [detailsData]);

  return (
    <div>
      <BootstrapDialog
        onClose={() => setShowConvertToClaimModal(false)}
        aria-labelledby="customized-dialog-title"
        open={showConvertToClaimModal}
        PaperProps={{
          className:
            "w-full !max-w-[950px] sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl ",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Convert PreAuth To Claim
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setShowConvertToClaimModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="benefeciary-create">
            <div className="personal-data flex flex-col gap-2  !shadow-none !border-none">
              <div className="personal-fields grid grid-cols-1 lg:grid-cols-2 gap-2">
                <div className="flex flex-col gap-1">
                  <label htmlFor="episode_no">Episode No.</label>
                  <input
                    placeholder="Enter Episode No."
                    name="episode_no"
                    value={formik.values?.episode_no}
                    onChange={formik.handleChange}
                    id="episode_no"
                    className="episode_no"
                  />

                  {formik.errors.episode_no && formik.touched.episode_no ? (
                    <p className=" max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.episode_no}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="text-center font-bold text-lg">Items</div>

            <div className="my-2 mx-3">
              <div className="grid grid-cols-1 lg:grid-cols-2  gap-2 !p-0 !shadow-none !border-none">
                {formik?.values?.items?.map((item, index) => (
                  <div
                    className="shadow-md rounded-lg p-2 gap-2 flex flex-col border border-black/20"
                    key={item.id}
                  >
                    <div className="flex justify-between">
                      <dt
                        className={`text-sm font-semibold  my-auto ${
                          formik.errors?.items?.[index]?.id
                            ? "text-red-500"
                            : "text-gray-600"
                        }`}
                      >
                        Id
                      </dt>
                      <dd
                        className={`text-sm  text-ellipsis overflow-hidden  text-end ${
                          formik.errors?.items?.[index]?.id
                            ? "text-red-500"
                            : "text-gray-900"
                        } `}
                      >
                        {item?.id || "___"}
                      </dd>
                    </div>

                    <div className="flex justify-between">
                      <dt
                        className={`text-sm font-semibold text-gray-600 mt-auto ${
                          formik.errors?.items?.[index]?.invoice_no
                            ? "text-red-500"
                            : "text-gray-600"
                        } `}
                      >
                        Invoice No
                      </dt>
                      <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden  text-end">
                        <TextField
                          id={
                            formik.errors?.items?.[index]?.invoice_no
                              ? "standard-error"
                              : "standard-basic"
                          }
                          error={
                            formik.errors?.items?.[index]?.invoice_no
                              ? true
                              : false
                          }
                          //   helperText={formik.errors?.items?.[index]?.invoice_no}
                          label="Enter Invoice No."
                          variant="standard"
                          size="small"
                          className="invoice-text-field"
                          value={formik.values?.items[index]?.invoice_no}
                          onChange={(event) => {
                            const { value } = event.target;
                            formik.setFieldValue(
                              `items[${index}].invoice_no`,
                              value
                            );
                          }}
                        />
                      </dd>
                    </div>

                    <div className="flex justify-between">
                      <dt
                        className={`text-sm font-semibold  mt-auto ${
                          formik.errors?.items?.[index]?.patient_share
                            ? "text-red-500"
                            : "text-gray-600"
                        } `}
                      >
                        Patient Share
                      </dt>
                      <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden  text-end">
                        <TextField
                          id={
                            formik.errors?.items?.[index]?.patient_share
                              ? "standard-error"
                              : "standard-basic"
                          }
                          error={
                            formik.errors?.items?.[index]?.patient_share
                              ? true
                              : false
                          }
                          label="Enter Patient Share"
                          variant="standard"
                          size="small"
                          className="invoice-text-field"
                          value={formik.values?.items[index]?.patient_share}
                          onChange={(event) => {
                            const { value } = event.target;
                            formik.setFieldValue(
                              `items[${index}].patient_share`,
                              value
                            );
                          }}
                        />
                      </dd>
                    </div>

                    <div className="flex justify-between">
                      <dt
                        className={`text-sm font-semibold mt-auto  ${
                          formik.errors?.items?.[index]?.payer_share
                            ? "text-red-500"
                            : "text-gray-600"
                        }`}
                      >
                        Payer Share
                      </dt>
                      <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden  text-end">
                        <TextField
                          id={
                            formik.errors?.items?.[index]?.payer_share
                              ? "standard-error"
                              : "standard-basic"
                          }
                          error={
                            formik.errors?.items?.[index]?.payer_share
                              ? true
                              : false
                          }
                          label="Enter Payer Share"
                          variant="standard"
                          size="small"
                          className="invoice-text-field"
                          value={formik.values?.items[index]?.payer_share}
                          onChange={(event) => {
                            const { value } = event.target;
                            formik.setFieldValue(
                              `items[${index}].payer_share`,
                              value
                            );
                          }}
                        />
                      </dd>
                    </div>

                    <div className="flex justify-between">
                      <dt
                        className={`text-sm font-semibold mt-auto ${
                          formik.errors?.items?.[index]?.payer_share_with_tax
                            ? "text-red-500"
                            : "text-gray-600"
                        }`}
                      >
                        Payer Share With Tax
                      </dt>
                      <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden  text-end">
                        <TextField
                          id={
                            formik.errors?.items?.[index]?.payer_share_with_tax
                              ? "standard-error"
                              : "standard-basic"
                          }
                          error={
                            formik.errors?.items?.[index]?.payer_share_with_tax
                              ? true
                              : false
                          }
                          label="Enter Payer Share With Tax"
                          variant="standard"
                          size="small"
                          className="invoice-text-field"
                          value={
                            formik.values?.items[index]?.payer_share_with_tax
                          }
                          onChange={(event) => {
                            const { value } = event.target;
                            formik.setFieldValue(
                              `items[${index}].payer_share_with_tax`,
                              value
                            );
                          }}
                        />
                      </dd>
                    </div>

                    <div className="flex justify-between">
                      <dt
                        className={`text-sm font-semibold mt-auto ${
                          formik.errors?.items?.[index]?.payer_share_with_tax
                            ? "text-red-500"
                            : "text-gray-600"
                        }`}
                      >
                        Discount
                      </dt>
                      <dd className=" text-sm text-gray-900 text-ellipsis overflow-hidden  text-end">
                        <TextField
                          id={
                            formik.errors?.items?.[index]?.discount
                              ? "standard-error"
                              : "standard-basic"
                          }
                          error={
                            formik.errors?.items?.[index]?.discount
                              ? true
                              : false
                          }
                          label="Enter Discount"
                          variant="standard"
                          size="small"
                          className="invoice-text-field"
                          value={formik.values?.items[index]?.discount}
                          onChange={(event) => {
                            const { value } = event.target;
                            formik.setFieldValue(
                              `items[${index}].discount`,
                              value
                            );
                          }}
                        />
                      </dd>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            size="small"
            onClick={() => {
              formik.handleSubmit();
            }}
            className="!text-primary hover:!bg-primary/20"
          >
            {"Claim Ready For Submission"}
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/* <AppModal isOpen={loading} /> */}
    </div>
  );
}
