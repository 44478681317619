import React from "react";

function VisionPrescription({ item }) {
  if (item?.vision_prescription_data) {
    return (
      <>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-8">
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Date Written</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {item?.vision_prescription_data?.date_written}
            </dd>
          </div>
        </dl>
        <h2 className="font-bold text-xl text-amber-600 my-1">Vision data</h2>
        <hr />
        {item?.vision_prescription_data?.lens_specifications?.map(
          (el, index) => {
            return (
              <>
                <div className="my-2">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-8">
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        Product
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {el?.product}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        Color
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {el?.color}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        Brand
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {el?.brand}
                      </dd>
                    </div>
                  </dl>
                </div>
                <hr />
                <h2 className="font-bold text-md text-amber-600 my-2">
                  Right :{" "}
                </h2>
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-8">
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Sphere
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.right?.sphere}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Cylinder
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.right?.cylinder}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">Axis</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.right?.axis}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">Add</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.right?.add}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">Power</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.right?.power}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Back Curve
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.right?.back_curve}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Diameter
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.right?.diameter}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">Prism</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.right?.prism?.amount}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Duration
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.right?.duration?.value}
                    </dd>
                  </div>
                </dl>
                <hr />
                <h2 className="font-bold text-md text-amber-600 my-2">
                  Left :{" "}
                </h2>
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-8">
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Sphere
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.left?.sphere}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Cylinder
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.left?.cylinder}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">Axis</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.left?.axis}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">Add</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.left?.add}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">Power</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.left?.power}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Back Curve
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.left?.back_curve}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Diameter
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.left?.diameter}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">Prism</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.left?.prism?.amount}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Duration
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {el?.left?.duration?.value}
                    </dd>
                  </div>
                </dl>
                <hr />
              </>
            );
          }
        )}
      </>
    );
  } else {
    return <h3>No Vision Prescription</h3>;
  }
}

export default VisionPrescription;
