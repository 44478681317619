import Modal from "react-modal";
import DragNdrop from "./DragNDrop";
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import ItemCodesSearchNSelect from "../PreAuth/components/request/ItemCodesSearchNSelect";
import priceList from "../../gql/priceList";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import { useEffect } from "react";
import AppModal from "../../components/AppModal";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AddPriceListServiceModal({
  showPriceListServiceModal,
  setShowPriceListServiceModal,
  payersData,
  item,
  refetchSpecificPriceList,
  pageNo,
  limit,
}) {
  const { payerId, effectiveDate } = useParams();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      padding: "0",
    },
  };

  const types = [
    { id: "medical-devices", name: "Medical Devices" },
    { id: "medication-codes", name: "Medication Codes" },
    { id: "transportation-srca", name: "Transportation SRCA" },
    { id: "imaging", name: "Imaging" },
    { id: "services", name: "Services" },
    { id: "laboratory", name: "Laboratory" },
    { id: "oral-health-op", name: "Oral Health OP" },
    { id: "procedures", name: "Procedures" },
  ];

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  const onSuccessfulCreateRequestResponse = (data) => {
    if (data?.createPriceList) {
      toast.success(data?.createPriceList?.message);
      resetForm();
      refetchSpecificPriceList({
        input: {
          payer_id: payerId,
          effective_date: effectiveDate,
          limit: limit,
          page: pageNo,
        },
      });
      setShowPriceListServiceModal(false);
    }
  };

  const onSuccessfulUpdateRequestResponse = (data) => {
    if (data?.updatePriceList) {
      toast.success(data?.updatePriceList?.message);
      resetForm();
      refetchSpecificPriceList({
        input: {
          payer_id: payerId,
          effective_date: effectiveDate,
          limit: limit,
          page: pageNo,
        },
      });
      setShowPriceListServiceModal(false);
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error?.message}`);
  };

  const [specificPriceList, { data, loading, error }] = useMutation(
    priceList.CREATE_SPECIFIC_PRICE_LIST,
    {
      update(proxy, result) {
        onSuccessfulCreateRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  const [updatePriceList, {}] = useMutation(priceList.UPDATE_PRICE_LIST, {
    update(proxy, result) {
      onSuccessfulUpdateRequestResponse(result.data);
    },
    onError(error) {
      onResponseError(error.graphQLErrors[0]);
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
    isValid,
    setValues,
  } = useFormik({
    initialValues: {
      service_type: "",
      unit_price: "",
      factor: "",
      non_standard_code: "",
      non_standard_desc: "",
      service_code: "",
      service_desc: "",
      payer_id: payerId,
      effective_date: effectiveDate,
      id: 0,
    },

    validationSchema: () => {
      return Yup.object().shape({
        service_type: Yup.string().required("Type required"),
        unit_price: Yup.number().required("Unit Price required"),
        factor: Yup.number().required("Factor required"),
        non_standard_code: Yup.string().required("Non Standard Code required"),
        non_standard_desc: Yup.string().required(
          "Non Standard Description required"
        ),
        service_code: Yup.string().required("Standard code is required"),
        service_desc: Yup.string().required(
          "Standard code description is required"
        ),
      });
    },

    onSubmit: (values) => {
      if (item) {
        delete values?.payer_id;
        delete values?.effective_date;
        updatePriceList({ variables: { input: values } });
      } else {
        delete values?.id;
        specificPriceList({ variables: { input: values } });
      }
    },
  });

  useEffect(() => {
    if (item) {
      setValues({
        service_type: item.service_type || "",
        unit_price: item.unit_price || "",
        factor: item.factor || "",
        non_standard_code: item.non_standard_code || "",
        non_standard_desc: item.non_standard_desc || "",
        service_code: item.service_code || "",
        service_desc: item.service_desc || "",
        payer_id: item.payer_id || payerId,
        effective_date: item.effective_date || effectiveDate,
        id: item?.id || 0,
      });
    }
  }, [item]);

  return (
    <div>
      {/* <Modal
        isOpen={showPriceListServiceModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setShowPriceListServiceModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar className="" style={{ background: "#ffab2c" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setShowPriceListServiceModal(false);
                resetForm();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Upload Price List
            </Typography>
            <Button
              autoFocus
              size="small"
              color="inherit"
              onClick={() => {
                setShowPriceListServiceModal(false);
                resetForm();
              }}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <div className="benefeciary-create m-2 mt-4">
          <div className="personal-data flex flex-col gap-4  !shadow-none !border-none">
            <div className="personal-fields grid grid-cols-1 lg:grid-cols-2 gap-3">
              <div className="field flex flex-col gap-1">
                <label htmlFor="Select-Type">Select Type*</label>
                <select
                  name="service_type"
                  onChange={handleChange}
                  value={values.service_type}
                  id="Select-Type"
                >
                  <option value="">Select Type*</option>
                  {types.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.service_type && touched.service_type ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.service_type}
                  </p>
                ) : null}
              </div>

              <div className="field flex flex-col gap-1">
                <label htmlFor="Select-Type">
                  Search for Code* ({values?.service_code})
                </label>

                <ItemCodesSearchNSelect
                  typeName={values.service_type}
                  setFieldValue={(val) => {
                    setFieldValue(`service_code`, val.code);
                    setFieldValue(`service_desc`, val.description);
                  }}
                />
                {errors.service_code && touched.service_code ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.service_code}
                  </p>
                ) : null}
                {errors.service_desc && touched.service_desc ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.service_desc}
                  </p>
                ) : null}
              </div>

              <div className="field flex flex-col gap-1">
                <label htmlFor="Select-Type">Non Standard Code*</label>
                <input
                  service_type="text"
                  name="non_standard_code"
                  value={values.non_standard_code}
                  onChange={handleChange}
                  placeholder="Non Standard Code"
                />
                {errors.non_standard_code && touched.non_standard_code ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.non_standard_code}
                  </p>
                ) : null}
              </div>
              <div className="field flex flex-col gap-1">
                <label htmlFor="Select-Type">Non Standard Description*</label>
                <input
                  service_type="search"
                  name="non_standard_desc"
                  value={values.non_standard_desc}
                  onChange={handleChange}
                  placeholder="Non Standard Description"
                />
                {errors.non_standard_desc && touched.non_standard_desc ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.non_standard_desc}
                  </p>
                ) : null}
              </div>

              <div className="field flex flex-col gap-1">
                <label htmlFor="service_end_date">Unit Price (SAR)*</label>
                <input
                  placeholder="Enter Unit Price"
                  service_type="number"
                  name="unit_price"
                  value={values.unit_price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="unit_price"
                />
                {errors.unit_price && touched.unit_price ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.unit_price}
                  </p>
                ) : null}
              </div>

              <div className="field flex flex-col gap-1">
                <label htmlFor="service_end_date">Factor*</label>
                <input
                  service_type="number"
                  name="factor"
                  value={values.factor}
                  onChange={handleChange}
                  placeholder="Factor"
                />
                {errors.factor && touched.factor ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.factor}
                  </p>
                ) : null}
              </div>
            </div>

            <div>
              <div className="save-benefeciary-btn" onClick={handleSubmit}>
                <span>Upload </span>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      <BootstrapDialog
        onClose={() => setShowPriceListServiceModal(false)}
        aria-labelledby="customized-dialog-title"
        open={showPriceListServiceModal}
        PaperProps={{
          className:
            "w-full !max-w-[750px] sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-[750px] !z-40",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Upload Price List Services
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setShowPriceListServiceModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="benefeciary-create !m-0 !p-0">
            <div className="personal-data flex flex-col gap-4  !shadow-none !border-none">
              <div className="personal-fields grid grid-cols-1 lg:grid-cols-2 gap-3">
                <div className="field flex flex-col gap-1">
                  <label htmlFor="Select-Type">Select Type*</label>
                  <select
                    name="service_type"
                    onChange={handleChange}
                    value={values.service_type}
                    id="Select-Type"
                  >
                    <option value="">Select Type*</option>
                    {types.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          <p>{item.name}</p>
                        </option>
                      );
                    })}
                  </select>
                  {errors.service_type && touched.service_type ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {errors.service_type}
                    </p>
                  ) : null}
                </div>

                <div className="field flex flex-col gap-1">
                  <label htmlFor="Select-Type">
                    Search for Code* ({values?.service_code})
                  </label>

                  <ItemCodesSearchNSelect
                    typeName={values.service_type}
                    setFieldValue={(val) => {
                      setFieldValue(`service_code`, val.code);
                      setFieldValue(`service_desc`, val.description);
                    }}
                  />
                  {errors.service_code && touched.service_code ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {errors.service_code}
                    </p>
                  ) : null}
                  {errors.service_desc && touched.service_desc ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {errors.service_desc}
                    </p>
                  ) : null}
                </div>

                <div className="field flex flex-col gap-1">
                  <label htmlFor="Select-Type">Non Standard Code*</label>
                  <input
                    service_type="text"
                    name="non_standard_code"
                    value={values.non_standard_code}
                    onChange={handleChange}
                    placeholder="Non Standard Code"
                  />
                  {errors.non_standard_code && touched.non_standard_code ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {errors.non_standard_code}
                    </p>
                  ) : null}
                </div>
                <div className="field flex flex-col gap-1">
                  <label htmlFor="Select-Type">Non Standard Description*</label>
                  <input
                    service_type="search"
                    name="non_standard_desc"
                    value={values.non_standard_desc}
                    onChange={handleChange}
                    placeholder="Non Standard Description"
                  />
                  {errors.non_standard_desc && touched.non_standard_desc ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {errors.non_standard_desc}
                    </p>
                  ) : null}
                </div>

                <div className="field flex flex-col gap-1">
                  <label htmlFor="service_end_date">Unit Price (SAR)*</label>
                  <input
                    placeholder="Enter Unit Price"
                    service_type="number"
                    name="unit_price"
                    value={values.unit_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="unit_price"
                  />
                  {errors.unit_price && touched.unit_price ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {errors.unit_price}
                    </p>
                  ) : null}
                </div>

                <div className="field flex flex-col gap-1">
                  <label htmlFor="service_end_date">Factor*</label>
                  <input
                    service_type="number"
                    name="factor"
                    value={values.factor}
                    onChange={handleChange}
                    placeholder="Factor"
                  />
                  {errors.factor && touched.factor ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {errors.factor}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            size="small"
            onClick={() => {
              setShowPriceListServiceModal(false);
              handleSubmit();
            }}
            className="!text-primary hover:!bg-primary/20"
          >
            {loading ? "..." : "Submit"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

{
  /* <div className="flex flex-col gap-1">
                <label htmlFor="effectiveDate">Effective Date</label>
                <input
                  placeholder="Enter Effective Date"
                  service_type="date"
                  name="effectiveDate"
                  value={values.effectiveDate}
                  onChange={handleChange}
                  onFocus={(e) => (e.target.service_type = "date")}
                  onBlur={(e) => (e.target.service_type = "text")}
                  id="effectiveDate"
                  className="effectiveDate"
                />

                {errors.effectiveDate && touched.effectiveDate ? (
                  <p className=" max-w-2xl text-sm leading-6 text-red-500">
                    {errors.effectiveDate}
                  </p>
                ) : null}
              </div> */
}
