import React, { useEffect, useState } from "react";
import { Formik, useFormikContext } from "formik";
import { Switch } from "@headlessui/react";
import _ from "lodash";
import { useQuery, gql, useMutation } from "@apollo/client";
// import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import BeneficiarySearchNSelect from "../../../Eligibility/components/requestEligibility/BeneficiarySearchNSelect";
import insurancePlansGQL from "../../../../gql/insurancePlans";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function BeneficiaryInfo({ onChange, refId }) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const navigate = useNavigate();
  const [insurancePlans, setInsurancePlans] = useState([]);

  const formatInsurancePlansData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.id,
          member_card_id: item.member_card_id,
          policy_number: item.policy_number,
          expiry_date: item.expiry_date,
          isPrimary: item.isPrimary,
          payerId: item?.payer?.id,
          relation_with_subscriber: item.relation_with_subscriber,
          coverage_type: item.coverage_type,
          patient_share: item.patient_share,
          maxLimit: item.maxLimit,
          payerNphiesId: item.payerNphiesId,
          patientId: item.patientId,
          name: item?.payer?.name_en,
          license_id: item?.payer?.license_id,
        });
      });
    } else {
    }
    return returnData;
  };

  // const {
  //   loading: loadingPatients,
  //   error: errorPatient,
  //   data: iPlansData,
  //   refetch,
  // } = useQuery(insurancePlansGQL.GET_ALL_INSURANCE_PLANS, {
  //   variables: { limit: 100, page: 1 },
  // });

  // React.useEffect(() => {
  //   if (iPlansData) {
  //     setInsurancePlans(
  //       formatInsurancePlansData(iPlansData.getAllInsurancePlans.data)
  //     );
  //   }
  // }, [iPlansData]);

  const addData = (values) => {
    // setShowEligibilityModal(false);
    let data = _.cloneDeep(values);
    // checkEligibility({ variables: { input: data } });
  };

  const formikRef = React.useRef();
  React.useImperativeHandle(refId, () => ({
    Submit: async () => {
      await formikRef.current.submitForm();
    },
  }));

  const setInsuranceValue = (data, setFieldValue) => {
    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      if (element.isPrimary) {
        setFieldValue("insurancePlanId", element.id);
        setFieldValue("payerId", element.payerId);
        setFieldValue("payerName", element.name);
        setFieldValue("insurancePlanIndex", i);
        break;
      } else {
      }
    }
  };

  useEffect(() => {
    if (insurancePlans && insurancePlans.length > 0) {
      setFieldValue("insurancePlanIndex", 0);
      setFieldValue("insurancePlanId", insurancePlans[0].id);
      setFieldValue("payerId", insurancePlans[0]?.payerId);
      setFieldValue("payerName", insurancePlans[0].name);
    } else {
      setFieldValue("insurancePlanId", "");
      setFieldValue("payerId", "");
      setFieldValue("payerName", "");
    }
  }, [insurancePlans]);

  return (
    <>
      <div class="pre_auth_benef_info !grid !grid-cols-1 md:!grid-cols-2 !gap-5 !mb-0 !p-0 !shadow-none !border-0 !rounded-none">
        <div className="my-auto">
          <div class="benef_info_head">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="34" height="34" rx="8" fill="#F4F6F8" />
              <path
                d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                fill="#F5793B"
              />
              <path
                d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                fill="#F5793B"
              />
              <path
                d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                fill="#F5793B"
              />
            </svg>
            <span>Beneficiary Info</span>
          </div>
          <div class="pre_auth_checkbox !mt-3 !mb-0">
            <div class="toggle-button-ref">
              <input
                type="checkbox"
                name="isNewBorn"
                onChange={() => setFieldValue("isNewBorn", !values.isNewBorn)}
                id="newborn"
                checked={values.isNewBorn}
              />
              <label for="newborn"></label>
              <span>New Born?</span>
            </div>
            <div class="toggle-button-ref">
              <input
                type="checkbox"
                id="toggle-ref"
                name="isReferral"
                checked={values.isReferral}
                onChange={() => setFieldValue("isReferral", !values.isReferral)}
              />
              <label for="toggle-ref"></label>
              <span>Referral</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <div>
            <label for="myInput">Beneficiary({values.patientName}):</label>
            <BeneficiarySearchNSelect
              setFieldValue={(val) => {
                if (values.isNewBorn) {
                  setFieldValue("insurancePlanIndex", "");
                } else {
                  let formattedIPlans = formatInsurancePlansData(
                    val.insurancePlans
                  );
                  setInsurancePlans(formattedIPlans);

                  setInsuranceValue(formattedIPlans, setFieldValue);
                }
                setFieldValue("patientId", val.id);
                setFieldValue("patientName", val.name);
              }}
            />
            {errors.patientId && touched.patientId ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {errors.patientId}
              </p>
            ) : null}
          </div>

          <div>
            {values.isNewBorn && (
              <div>
                <label for="myInput">
                  Subscriber({values.subscriberName}):
                </label>
                <BeneficiarySearchNSelect
                  setFieldValue={(val) => {
                    if (values.isNewBorn) {
                      let formattedIPlans = formatInsurancePlansData(
                        val.insurancePlans
                      );
                      setInsurancePlans(formattedIPlans);

                      setInsuranceValue(formattedIPlans, setFieldValue);
                    } else {
                    }
                    setFieldValue("subscriber", val.id);
                    setFieldValue("subscriberName", val.name);
                  }}
                />
              </div>
            )}
          </div>

          <div>
            {values.patientId && (
              <div className="sm:col-span-3 mt-2">
                <label
                  htmlFor="insurancePlanIndex"
                  className="block text-md font-medium text-gray-700"
                >
                  Insurance Plans{" "}
                  <span className="text-sm text-red-500"> *</span>{" "}
                  <span className="text-sm text-gray-500">
                    ({values.payerName ? values.payerName : ""})
                  </span>
                </label>
                {!values.isFollowUpReq && (
                  <>
                    <select
                      id="insurancePlanIndex"
                      name="insurancePlanIndex"
                      onChange={(e) => {
                        let iPlanIndex = e.target.value;
                        setFieldValue("insurancePlanIndex", iPlanIndex);

                        if (iPlanIndex != "") {
                          setFieldValue(
                            "insurancePlanId",
                            insurancePlans[iPlanIndex].id
                          );

                          setFieldValue(
                            "payerId",
                            insurancePlans[iPlanIndex]?.payerId
                          );
                          setFieldValue(
                            "payerName",
                            insurancePlans[iPlanIndex].name
                          );
                        } else {
                          setFieldValue("insurancePlanId", "");
                          setFieldValue("payerId", "");
                          setFieldValue("payerName", "");
                        }
                      }}
                      value={values.insurancePlanIndex}
                      autoComplete="type-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    >
                      <option value="">Please Select</option>
                      {insurancePlans.map((item, index) => {
                        return (
                          <option value={index}>
                            <p>
                              {" "}
                              {item?.name} ({item?.member_card_id})
                            </p>
                          </option>
                        );
                      })}
                    </select>
                    {errors.insurancePlanId && touched.insurancePlanId ? (
                      <p className="max-w-2xl text-sm leading-6 text-red-500">
                        {errors.insurancePlanId}
                      </p>
                    ) : null}
                  </>
                )}
              </div>
            )}
          </div>
          {/* <div class="pre_auth_input">
            <div>
              <label for="myInput">Beneficiary({values.patientName}):</label>
              <BeneficiarySearchNSelect
                setFieldValue={(val) => {
                  if (values.isNewBorn) {
                    setFieldValue("insurancePlanIndex", "");
                  } else {
                    let formattedIPlans = formatInsurancePlansData(
                      val.insurancePlans
                    );
                    setInsurancePlans(formattedIPlans);

                    setInsuranceValue(formattedIPlans, setFieldValue);
                  }
                  setFieldValue("patientId", val.id);
                  setFieldValue("patientName", val.name);
                }}
              />
              {errors.patientId && touched.patientId ? (
                <p className="max-w-2xl text-sm leading-6 text-red-500">
                  {errors.patientId}
                </p>
              ) : null}
            </div>
            {values.isNewBorn && (
              <div>
                <label for="myInput">
                  Subscriber({values.subscriberName}):
                </label>
                <BeneficiarySearchNSelect
                  setFieldValue={(val) => {
                    if (values.isNewBorn) {
                      let formattedIPlans = formatInsurancePlansData(
                        val.insurancePlans
                      );
                      setInsurancePlans(formattedIPlans);

                      setInsuranceValue(formattedIPlans, setFieldValue);
                    } else {
                    }
                    setFieldValue("subscriber", val.id);
                    setFieldValue("subscriberName", val.name);
                  }}
                />
              </div>
            )} */}
          {/* <input type="text" placeholder={`Select Beneficiary (${values.patientName ? values.patientName : ""})`} /> */}
          {/* <input type="text" placeholder={`Select Subscriber (${values.subscriberName ? values.subscriberName : ""})`} /> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default BeneficiaryInfo;
