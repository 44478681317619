import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import MainFlow from "./MainFlow";
import AuthFlow from "./AuthFlow";
import { appContext } from "../context/ContextProvider";

function AppFlow(props) {
  const { user, setUser, token, setToken, setPermissions } =
    useContext(appContext);
  const [loading, setLoading] = useState(true);

  const checkToken = () => {
    let tokenValid = localStorage.getItem("token");
    let userPermissions = localStorage.getItem("permissions");
    let loggedInUser = localStorage.getItem("user");

    if (tokenValid) {
      setToken(JSON.parse(tokenValid));
      setPermissions(JSON.parse(userPermissions));
      setUser(JSON.parse(loggedInUser));
    } else {
    }
    setLoading(false);
  };

  useEffect(() => {
    checkToken();
  }, []);

  if (loading) {
    return <p></p>;
  }

  return <>{token ? <MainFlow /> : <AuthFlow />}</>;
  return <MainFlow />;
}

export default AppFlow;
