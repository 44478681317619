import React, { useState } from "react";
import { Button, Divider } from "@mui/material";
import { Tooltip } from "@mui/material";
import { FaUsers } from "react-icons/fa";
import AddIcon from "@mui/icons-material/Add";
import { MdDelete } from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { number } from "yup";
import { IoWarningOutline } from "react-icons/io5";
import { useQuery, useMutation } from "@apollo/client";
import claims from "../../../gql/claims";
import ClaimBulkStatsFilter from "./ClaimBulkStatsFilter";
import "../../Beneficiary/styles/BeneficiaryList.css";
import ConformationModal from "../../../components/ConformationModal";
import AppModal from "../../../components/AppModal";
import toast from "react-hot-toast";

export default function ClaimsSubmitStats() {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmModalBulkSubmission, setShowConfirmModalBulkSubmission] =
    useState(false);
  const [
    showConfirmModalStopBulkSubmission,
    setShowConfirmModalStopBulkSubmission,
  ] = useState(false);

  const currentDate = new Date();
  const currentYearMonth = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, "0")}`;

  function getFirstAndLastDateOfMonth(yearMonth) {
    const [year, month] = yearMonth.split("-").map(Number);
    const firstDate = new Date(year, month - 1, 1);
    const lastDate = new Date(year, month, 0);

    const firstDateString = `${firstDate.getFullYear()}-${(
      firstDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${firstDate.getDate().toString().padStart(2, "0")}`;
    const lastDateString = `${lastDate.getFullYear()}-${(
      lastDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${lastDate.getDate().toString().padStart(2, "0")}`;

    return {
      firstDate: firstDateString,
      lastDate: lastDateString,
    };
  }

  const initialDates = getFirstAndLastDateOfMonth(currentYearMonth);
  const [fromDate, setFromDate] = useState(initialDates.firstDate);
  const [toDate, setToDate] = useState(initialDates.lastDate);
  const [showFilter, setShowFilter] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const {
    loading: dataLoading,
    error,
    data,
    refetch,
  } = useQuery(claims.CLAIMS_BULK_SUBMISSION_STATS, {
    variables: {
      input: {
        from_date: fromDate,
        to_date: toDate,
      },
    },
  });

  const getLastNMonths = (n) => {
    const months = [];
    for (let i = 0; i < n; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      months.push(date);
    }
    return months;
  };


  const [clickedIndex, setClickedIndex] = useState(null);

  const handleMonthClick = (date, index) => {
    setClickedIndex(index);
    const yearMonth = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}`;
    const { firstDate, lastDate } = getFirstAndLastDateOfMonth(yearMonth);

    setFromDate(firstDate);
    setToDate(lastDate);

    refetch({
      input: {
        from_date: firstDate,
        to_date: lastDate,
      },
    });
  };

  const monthButtons = getLastNMonths(13).map((date, index) => (
    // <Button
    //   key={index}
    //   variant="outlined"
    //   size="small"
    //   onClick={() => handleMonthClick(date)}
    //   className="!border-primary !text-primary hover:!bg-primary/10 mb-2"
    // >
    //   {date.toLocaleString("default", { month: "short", year: "numeric" })}
    // </Button>
    <Button
    key={index}
    variant={clickedIndex === index ? "contained" : "outlined"}
    size="small"
    onClick={() => handleMonthClick(date, index)}
    className={clickedIndex === index ? "" : "!border-primary !text-primary hover:!bg-primary/10"}
    style={clickedIndex === index ? { backgroundImage: "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)" } : {}}
  >
    {date.toLocaleString("default", { month: "short", year: "numeric" })}
  </Button>
  ));

  // DELETE
  const [bulkDelete, setBulkDelete] = useState({ payerId: null, month: null });

  const [delEntry, { loading: delLoading }] = useMutation(
    claims.BULK_DELETE_SUBMISSION,
    {
      update(proxy, result) {
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  const onSuccessfulRequestResponse = (data) => {
    setShowConfirmModal(false);
    if (data?.bulkDeleteReadyForSubmission) {
      refetch({
        input: {
          from_date: fromDate,
          to_date: toDate,
        },
      });
      setBulkDelete({ payerId: null, month: null });
      toast.success(data?.bulkDeleteReadyForSubmission?.message);
    } else {
    }
  };

  const onResponseError = (error) => {
    setShowConfirmModal(false);
    setBulkDelete({ payerId: null, month: null });
    toast.error(`${error?.message}`);
  };

  const deleteBulkClaim = () => {
    // console.log(payerId, month);
    if (bulkDelete?.month && bulkDelete?.payerId) {
      delEntry({
        variables: {
          input: {
            month: bulkDelete?.month,
            payer_id: bulkDelete?.payerId,
          },
        },
      });
    } else {
      toast.error("Something went wrong in bulk delete!");
    }
  };

  // SUBMIT
  const [bulkSubmit, setBulkSubmit] = useState({ payerId: null, month: null });

  const [submitEntry, { loading: submitLoading }] = useMutation(
    claims.BULK_CLAIMS_SUBMISSION,
    {
      update(proxy, result) {
        onSuccessfulRequestResponsee(result.data);
      },
      onError(error) {
        onResponseErrorr(error.graphQLErrors[0]);
      },
    }
  );

  const onSuccessfulRequestResponsee = (data) => {
    setShowConfirmModalBulkSubmission(false);
    if (data?.submitBatchClaims) {
      refetch({
        input: {
          from_date: fromDate,
          to_date: toDate,
        },
      });
      setBulkSubmit({ payerId: null, month: null });
      toast.success(data?.submitBatchClaims?.message);
    } else {
    }
  };

  const onResponseErrorr = (error) => {
    setShowConfirmModalBulkSubmission(false);
    setBulkSubmit({ payerId: null, month: null });
    toast.error(`${error?.message}`);
  };

  const submitBulkClaim = () => {
    if (bulkSubmit?.month && bulkSubmit?.payerId) {
      submitEntry({
        variables: {
          input: {
            month: bulkSubmit?.month,
            payer_id: bulkSubmit?.payerId,
          },
        },
      });
    } else {
      toast.error("Something went wrong in bulk submission!");
    }
  };

  // STOP
  const [bulkSubmitStop, setBulkSubmitStop] = useState({
    payerId: null,
    month: null,
  });

  const [submitStopEntry, { loading: submitStopLoading }] = useMutation(
    claims.BULK_STOP_SUBMISSION,
    {
      update(proxy, result) {
        onSuccessfulRequestResponseee(result.data);
      },
      onError(error) {
        onResponseErrorrr(error.graphQLErrors[0]);
      },
    }
  );

  const onSuccessfulRequestResponseee = (data) => {
    setShowConfirmModalStopBulkSubmission(false);
    if (data?.stopBatchClaimsQueue) {
      refetch({
        input: {
          from_date: fromDate,
          to_date: toDate,
        },
      });
      setBulkSubmitStop({ payerId: null, month: null });
      toast.success(data?.stopBatchClaimsQueue?.message);
    } else {
    }
  };

  const onResponseErrorrr = (error) => {
    setShowConfirmModalStopBulkSubmission(false);
    setBulkSubmitStop({ payerId: null, month: null });
    toast.error(`${error?.message}`);
  };

  const submitStopBulkClaim = () => {
    if (bulkSubmitStop?.month && bulkSubmitStop?.payerId) {
      submitStopEntry({
        variables: {
          input: {
            month: bulkSubmitStop?.month,
            payer_id: bulkSubmitStop?.payerId,
          },
        },
      });
    } else {
      toast.error("Something went wrong in stop bulk submission!");
    }
  };

  return (
    <>
      <div className="claimsSubmission_container">
        <div className="claimsSubmission_page">
          <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
            <div className="">
              <Tooltip
                placement="right"
                title="Keep track of your claims submissions"
                className="my-auto !mt-2"
              >
                <span className="font-semibold text-black/70 text-lg ">
                  CLAIMS BULK SUBMISSION
                  <span className="italic text-lg ml-1">
                    ({data?.claimSubmissionStats?.data?.length || 0}{" "}
                    <span className="text-xs font-bold italic">Entries</span>)
                  </span>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 mb-4 ">{monthButtons}</div>
        {/* <Divider /> */}

        {/* Cards */}
        {data?.claimSubmissionStats?.data &&
          data?.claimSubmissionStats?.data?.map((stats) => {
            const monthDate = new Date(stats.month);
            const monthName = monthDate.toLocaleString("default", {
              month: "long",
            });
            const year = monthDate.getFullYear();

            return (
              <div key={stats.month} className="mt-6">
                <h3 className="py-2 font-bold text-xl border-y-2">
                  {monthName}, {year}
                </h3>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 my-6">
                  {stats.claims.map((claim) => (
                    <div
                      key={claim.payer_id}
                      className="card shadow-md px-3.5 py-3 rounded-md "
                    >
                      <h2 className="font-bold text-lg mb-2 px-8 text-gray-800 !text-center">
                        {claim?.payer_name}
                      </h2>
                      <Divider />
                      <p className="text-gray-700 text-sm my-2 flex justify-between">
                        <span className="font-semibold">Total Claims:</span>{" "}
                        {claim?.total_claims || "___"}
                      </p>
                      <p className="text-gray-700 text-sm mb-2 flex justify-between">
                        <span className="font-semibold">
                          Total Discount Amount:
                        </span>{" "}
                        {claim?.total_discount_amount || "___"}
                      </p>
                      <p className="text-gray-700 text-sm mb-2 flex justify-between">
                        <span className="font-semibold">Total Net Amount:</span>{" "}
                        {claim?.total_net_amount || "___"}
                      </p>
                      <p className="text-gray-700 text-sm mb-2 flex justify-between">
                        <span className="font-semibold">Total Quantity:</span>{" "}
                        {claim?.total_quantity || "___"}
                      </p>
                      <p className="text-gray-700 text-sm mb-2 flex justify-between">
                        <span className="font-semibold">Total Tax Amount:</span>{" "}
                        {claim?.total_tax_amount || "___"}
                      </p>
                      <p className="text-gray-700 text-sm flex justify-between">
                        <span className="font-semibold">Total Unit Price:</span>{" "}
                        {claim?.total_unit_price || "___"}
                      </p>

                      <div class="flex gap-3 flex-col md:flex-row justify-center pt-4 ">
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setBulkSubmit({
                              payerId: claim?.payer_id,
                              month: stats?.month,
                            });
                            setShowConfirmModalBulkSubmission(true);
                          }}
                          style={{
                            backgroundImage:
                              "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                          }}
                          startIcon={<AddIcon />}
                        >
                          Submit Bulk
                        </Button>

                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setBulkSubmitStop({
                              payerId: claim?.payer_id,
                              month: stats?.month,
                            });
                            setShowConfirmModalStopBulkSubmission(true);
                          }}
                          style={{
                            backgroundImage:
                              "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                          }}
                          startIcon={<CloseIcon />}
                        >
                          Stop
                        </Button>

                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setBulkDelete({
                              payerId: claim?.payer_id,
                              month: stats?.month,
                            });
                            setShowConfirmModal(true);
                          }}
                          className="!border-primary !text-primary hover:!bg-primary/10"
                          startIcon={<MdDelete />}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </div>

      {/* dialog */}
      <ConformationModal
        open={showConfirmModal}
        setOpen={setShowConfirmModal}
        onDelete={() => deleteBulkClaim()}
        text={"Delete"}
      />

      <ConformationModal
        open={showConfirmModalBulkSubmission}
        setOpen={setShowConfirmModalBulkSubmission}
        onDelete={() => submitBulkClaim()}
        text={"Submit Bulk"}
      />

      <ConformationModal
        open={showConfirmModalStopBulkSubmission}
        setOpen={setShowConfirmModalStopBulkSubmission}
        onDelete={() => submitStopBulkClaim()}
        text={"Stop"}
      />

      <ClaimBulkStatsFilter
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        refetch={refetch}
      />
      {/* <AppModal isOpen={loading || delLoading} /> */}
    </>
  );
}
