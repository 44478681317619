import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import BeneficiarySearchNSelect from "../../Eligibility/components/requestEligibility/BeneficiarySearchNSelect";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SubscriberDetails({ handleChange, values, setFieldValue, item }) {
  const [insurancePlans, setInsurancePlans] = useState([]);

  const formatInsurancePlansData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.id,
          memberCardId: item.member_card_id,
          policyNumber: item.policy_number,
          expiryDate: item.expiry_date,
          isPrimary: item.is_primary,
          payerId: item.payer_id,
          relationWithSubscriber: item.relation_with_subscriber,
          coverageType: item.coverage_type,
          patientShare: item.patient_share,
          maxLimit: item.max_limit,
          // payerNphiesId: item.payerNphiesId,
          patientId: item.patient_id,
          name: item?.payer?.name_en,
        });
      });
    } else {
    }
    return returnData;
  };

  const subscriberRelations = [
    { id: "Child", name: "Child" },
    { id: "Parent", name: "Parent" },
    { id: "Spouse", name: "Spouse" },
    { id: "Common Law Spouse", name: "Common Law Spouse" },
    { id: "Self", name: "Self" },
    { id: "Injured Party", name: "Injured Party" },
  ];

  console.log(insurancePlans);

  return (
    <div class="new-born">
      <div class="newborn-title">
        <div class="newborn-svg">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="34" height="34" rx="8" fill="#F4F6F8" />
            <path
              d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
              fill="#F5793B"
            />
            <path
              d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
              fill="#F5793B"
            />
          </svg>
        </div>
        <div class="new-born-head">
          <span>New Born</span>
        </div>
      </div>
      <div class="newborn-fields">
        <BeneficiarySearchNSelect
          setFieldValue={(val) => {
            setInsurancePlans(formatInsurancePlansData(val.insurancePlans));
            setFieldValue("subscriber_id", val.id);
          }}
        />
        <select
          id="relationship-subscriber"
          name="subscriber_relationship"
          onChange={handleChange}
          value={values.subscriber_relationship}
          autoComplete="type-name"
        >
          <option value="">Select Subscriber RelationShip</option>
          {subscriberRelations.map((item) => {
            return <option value={item.id}>{item.name}</option>;
          })}
        </select>

        <select
          id="insurance-plan"
          name="subscriber_insurance_plan"
          onChange={handleChange}
          value={values.subscriber_insurance_plan}
          autoComplete="type-name"
        >
          <option value="">Select Subscriber InsurancePlan</option>
          {insurancePlans.map((item) => {
            return <option value={item.id}>{item.name}</option>;
          })}
        </select>
      </div>
    </div>
  );
}

export default SubscriberDetails;
