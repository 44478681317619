import React, { useState } from "react";
import { CheckIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { useQuery } from "@apollo/client";
import preAuth from "../../../../gql/preAuth";
import { Tooltip } from "@mui/material";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DiagnosisSearchNSelect({ setFieldValue, values }) {
  const [query, setQuery] = useState("");
  const [selectedPractioner, setSelectedPractioner] = useState(null);

  const [people, setPeople] = useState([]);

  const formatData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.code_id,
          name: item.ascii_description,
          CodeId: item.code_id,
          AsciiDescription: item.ascii_description,
        });
      });
    } else {
    }
    return returnData;
  };

  const {
    loading: loadingDiagnosis,
    error: errorDiagnosis,
    data: diagnosisData,
    refetch,
  } = useQuery(preAuth.GET_SEARCHED_DIAGNOSIS, {
    variables: { q: "abcdefgh*%^%" },
  });

  React.useEffect(() => {
    if (diagnosisData) {
      setPeople(formatData(diagnosisData?.listIcdCodes10?.data));
    }
  }, [diagnosisData]);

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) => {
          let searched = person.name
            .toLowerCase()
            .includes(query.toLowerCase());
          if (!searched) {
            searched = person.id.toLowerCase().includes(query.toLowerCase());
          }
          return searched;
        });

  const onSearch = (inputText) => {
    setQuery(inputText);
    if (inputText.length > 2) {
      refetch({ q: inputText });
    } else {
    }
  };

  return (
    <Combobox
      as="div"
      value={selectedPractioner}
      onChange={(val) => {
        setSelectedPractioner(val);
        setFieldValue(val);
      }}
    >
      {/* <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
            Select Beneficiary
          </Combobox.Label> */}
      <div className="relative">
        {/* {values?.code || values?.codeText ? (
          <div className="text-sm font-light">
            ({values?.codeText + " - " + values?.code})
          </div>
        ) : null} */}

        {values?.code || values?.codeText ? (
          <Tooltip
            title={
              values?.codeText ? values.codeText + " - " + values?.code : ""
            }
          >
            <div className="text-sm font-light">
              {values?.codeText
                ? values.codeText.slice(0, 25) + "..." + " - " + values?.code
                : ""}
            </div>
          </Tooltip>
        ) : null}

        <Combobox.Input
          className=""
          onChange={(event) => onSearch(event.target.value)}
          // displayValue={(person) => `${person?.name} - ${person?.id}`}
          displayValue={(person) => person?.name}
          placeholder={"Search for Code"}
        />
        {/* <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button> */}
        {filteredPeople.length > 0 && (
          <Combobox.Options className="w-auto absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-amber-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {person.name} - {person.id}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-amber-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export default DiagnosisSearchNSelect;
