import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { MdDownload, MdOutlineRemoveRedEye } from "react-icons/md";
import ReactJsonView from "../Eligibility/components/ReactJsonView";
import claims from "../../gql/claims";
import ReactJsonTree from "../Eligibility/components/ReactJsonTree";
import { Tooltip } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClaimJsonViewModal({
  open,
  setOpen,
  handleClose,
  selectedItem,
}) {
  const [item, setItem] = useState(null);
  const [showJsonView, setShowJsonView] = useState(false);
  const [jsonObject, setJsonObject] = useState(false);

  const [jsonDialogOpen, setJsonDialogOpen] = useState(false);

  const handleJsonDialogOpen = () => {
    setJsonDialogOpen(true);
  };

  const handleJsonDialogClose = () => {
    setJsonDialogOpen(false);
  };

  const showJson = (item) => {
    setJsonObject(item);
    setShowJsonView(true);
    setJsonDialogOpen(true);
  };

  const onCloseShowJson = () => {
    setShowJsonView(false);
  };

  const { loading, error, data, refetch } = useQuery(
    claims.GET_CLAIMS_TRAN_DETAILS_LOGS,
    {
      variables: {
        transactionId: selectedItem?.transaction_id,
      },
      skip: !selectedItem?.transaction_id,
    }
  );

  useEffect(() => {
    if (data) {
      setItem(data?.getClaimTransaction?.data);
    }
  }, [data]);

  const formatDate = (backendDateTime) => {
    // Create a new Date object using the backend datetime value
    const date = new Date(backendDateTime);

    // Extract day, month, and year components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(date.getFullYear());

    // Format the components as "dd-mm-yyyy"
    return `${day}-${month}-${year}`;
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToJson = (item) => {
    downloadFile({
      data: JSON.stringify(JSON.parse(item.log)),
      fileName: `${item.type}.json`,
      fileType: "text/json",
    });
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          jsonDialogOpen ? setJsonDialogOpen(false) : handleClose();
        }}
        TransitionComponent={Transition}
        style={{ background: "#ffab2c", zIndex: 100 }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar className="" style={{ background: "#ffab2c" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                jsonDialogOpen ? setJsonDialogOpen(false) : handleClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Claims JSON View
            </Typography>
            <Button
              autoFocus
              color="inherit"
              size="small"
              onClick={() => {
                jsonDialogOpen ? setJsonDialogOpen(false) : handleClose();
              }}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            {jsonDialogOpen ? (
              <div>
                <ReactJsonTree data={jsonObject} />
              </div>
            ) : (
              <div className="super-git-table pt-4 !pb-12 px-8 mt-3">
                <table className="table-super-git">
                  <thead className="!p-0">
                    <tr className="!p-0">
                      <th className="!p-3">SQ #</th>
                      <th className="!p-3">Transaction ID</th>
                      <th className="!p-3">Transaction Types</th>
                      <th className="!p-3">Date</th>
                      <th className="!p-3">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {item?.logs?.map((item, index) => {
                      return (
                        <tr className="!p-0" key={`requests_${index}`}>
                          <td className="!p-2">{index + 1}</td>
                          <td className="!p-2">{item?.transaction_id}</td>
                          <td className="!p-2">{item.type}</td>
                          <td className="!p-2">{formatDate(item.timestamp)}</td>

                          <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-0">
                            <Tooltip title="View">
                              <div
                                onClick={() => showJson(JSON.parse(item.log))}
                                className="text-2xl cursor-pointer"
                              >
                                <MdOutlineRemoveRedEye />
                              </div>
                            </Tooltip>
                            <Tooltip title="Download">
                              <div
                                onClick={() => exportToJson(item)}
                                className="text-2xl cursor-pointer"
                              >
                                <MdDownload />
                              </div>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </Dialog>
      {/* <ReactJsonView
        showJsonView={showJsonView}
        onCloseShowJson={onCloseShowJson}
        jsonObject={jsonObject}
      /> */}
    </div>
  );
}

// {/* <div className="super-git-table mt-12 px-8 ">
//                   <table className="table-super-git border-b ">
//                     <thead>
//                       <tr className=" ">
//                         <th className=" ">SQ #</th>
//                         <th className=" ">Transaction ID</th>
//                         <th className=" ">Transaction Types</th>
//                         <th className="">Date</th>
//                         <th className="">Action</th>
//                       </tr>
//                     </thead>

//                     <tbody>
//                       {item?.logs?.map((item, index) => {
//                         return (
//                           <tr key={`requests_${index}`}>
//                             <td>{index + 1}</td>
//                             <td>{item?.transaction_id}</td>
//                             <td>{item.type}</td>
//                             <td>{formatDate(item.timestamp)}</td>
//                             <td className="flex gap-3 justify-center">
//                               <span
//                                 className="hover:cursor-pointer text-amber-700 text-2xl"
//                                 onClick={() => showJson(JSON.parse(item.log))}
//                               >
//                                 <MdOutlineRemoveRedEye />
//                               </span>
//                               <span
//                                 className="hover:cursor-pointer text-amber-700 text-2xl"
//                                 onClick={() => exportToJson(item)}
//                               >
//                                 <MdDownload />
//                               </span>
//                             </td>
//                           </tr>
//                         );
//                       })}
//                     </tbody>
//                   </table>
//                 </div> */}
