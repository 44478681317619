import React, { useEffect, useRef, useState } from "react";
import { Formik, FieldArray, Field } from "formik";
import _ from "lodash";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import ptpa from "../../../gql/ptpa";
import { useQuery } from "@apollo/client";
import BeneficiarySearchNSelect from "../../Eligibility/components/requestEligibility/BeneficiarySearchNSelect";
import { useNavigate } from "react-router-dom";

function PreAuthFilter({
  refetch,
  setPageNo,
  setLimit,
  refetchExcelDataResponse,
  setFromDate,
  setToDate,
  setRefetchFilterVariables,
  showFilter,
  setShowFilter,
}) {
  const [payers, setPayers] = useState([]);
  const navigate = useNavigate();

  const modalRef = useRef();

  const currentDate = new Date();

  const addData = (values) => {
    let data = _.cloneDeep(values);
    setPageNo(1);
    setFromDate(values.fromDate);
    setToDate(values.toDate);
    let obj = {
      from_date: values.fromDate,
      to_date: values.toDate,
      payer_id: values.payer,
      provider_name_en: values.providerNameEn,
      confirmation_status: values.status,
      limit: 10,
      page: 1,
    };

    setRefetchFilterVariables({ ...obj });
    refetch({
      input: Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value !== "" && value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {}),
    });

    delete obj.limit;
    delete obj.page;

    // refetchExcelDataResponse({
    //   input: Object.keys(obj).reduce((acc, key) => {
    //     const value = obj[key];
    //     if (value !== "" && value !== null) {
    //       acc[key] = value;
    //     }
    //     return acc;
    //   }, {}),
    // });
  };

  const formatPayersData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.id,
          naphipesId: item.nphies_id,
          licenseId: item.license_id,
          name_ar: item.name_ar,
          name: item.name_en,
          tpa_id: item.tpa_id,
        });
      });
    } else {
    }
    return returnData;
  };

  // const {
  //   loading: loadingPayers,
  //   error: errorPayer,
  //   data: payersData,
  //   refetch: refetchPayers,
  // } = useQuery(ptpa.GET_ALL_PAYERS);

  // React.useEffect(() => {
  //   if (payersData) {
  //     setPayers(payersData?.listPayersWithTpa?.data);
  //   }
  // }, [payersData]);

  React.useEffect(() => {
    const payersData = JSON.parse(localStorage.getItem("payersData"));

    if (payersData && payersData.length > 0) {
      setPayers(payersData);
    } else {
      navigate("/dashboard");
    }
  }, []);

  const types = [
    { id: "institutional", name: "Institutional" },
    { id: "oral", name: "Dental" },
    { id: "pharmacy", name: "Pharmacy" },
    { id: "professional", name: "Professional" },
    { id: "optical", name: "Optical" },
  ];

  const statuses = [
    { id: "pending", name: "Pending" },
    { id: "cleared", name: "Cleared" },
  ];

  // useEffect
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (modalRef.current && !modalRef.current.contains(event.target)) {
  //       setShowEligibilityModal(false);
  //     }
  //   };

  //   if (showEligibilityModal) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [showEligibilityModal, setShowEligibilityModal]);
  useEffect(() => {
    // Function to handle click outside of the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside of the modal, so close it
        setShowFilter(false);
      }
    };

    // Attach the event listener when the modal is shown
    if (showFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove the event listener when the modal is hidden
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilter]);

  return (
    <Formik
      initialValues={{
        fromDate: `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}-01`,
        toDate: `${currentDate.getFullYear()}-${String(
          currentDate.getMonth() + 1
        ).padStart(2, "0")}-${new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate()}`,
        payer: "",
        providerNameEn: "",
        status: "",
      }}
      validate={(values) => {
        const errors = {};
      }}
      onSubmit={(values, { setSubmitting }) => {
        addData(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
        /* and other goodies */
      }) => (
        <div className={`${showFilter ? "open" : ""} modal`} id="search-filter">
          <div ref={modalRef} class="modal-content">
            <div class="close" onClick={() => setShowFilter(() => !showFilter)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#DE5042"
                />
              </svg>
              <div class="bene-text">Filters</div>
            </div>
            <div class="search-fields">
              <input
                type="date"
                name="fromDate"
                value={values.fromDate}
                onChange={handleChange}
                onBlur={handleBlur}
                id="date"
                placeholder="From Date"
              />
              <input
                type="date"
                name="toDate"
                value={values.toDate}
                onChange={handleChange}
                onBlur={handleBlur}
                id="date"
                placeholder="To Date"
              />
              <select
                name="payer"
                onChange={handleChange}
                value={values.payer}
                id="payer"
              >
                <option value="">Select Payer</option>
                {payers.map((category, index) => (
                  <optgroup
                    label={
                      category?.name_en
                        ? category?.name_en + ` (${category?.license_id})`
                        : ""
                    }
                    key={category.id}
                  >
                    {category.payers.map((insurance, index) => (
                      <option key={index} value={insurance.id}>
                        {insurance?.name_en} ({insurance?.license_id})
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
              <input
                type="text"
                name="providerNameEn"
                value={values.providerNameEn}
                onChange={handleChange}
                onBlur={handleBlur}
                id="providerNameEn"
                placeholder="Enter Provider Name"
              />
              <select
                name="status"
                onChange={handleChange}
                value={values.status}
                id="status"
              >
                <option value="">Select Status</option>
                {statuses.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </div>

            <div
              className="search-btn"
              onClick={() => {
                handleSubmit();
                setShowFilter(false);
              }}
            >
              {/* <div class="search-btn" onClick={handleSubmit}> */}
              <span>Search</span>
            </div>
            <div class="reset-btn" onClick={resetForm}>
              <span>Reset</span>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
  return (
    <Formik
      initialValues={{
        fromDate: currentDate.toISOString().substring(0, 10),
        toDate: currentDate.toISOString().substring(0, 10),
        payer: "",
        providerNameEn: "",
        status: "",
      }}
      validate={(values) => {
        const errors = {};
      }}
      onSubmit={(values, { setSubmitting }) => {
        addData(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
        /* and other goodies */
      }) => (
        <form className="space-y-6" action="#" method="POST">
          <div className="bg-white px-4 shadow sm:rounded-lg pb-6 mb-3">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="mt-3 md:col-span-3 md:mt-0">
                <div className="grid grid-cols-6 gap-6 mt-4">
                  <div className="sm:col-span-2 col-span-6">
                    <label
                      htmlFor="fromDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      From Date
                    </label>
                    <input
                      placeholder="Enter From Date"
                      type="date"
                      name="fromDate"
                      value={values.fromDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="fromDate"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    />
                  </div>
                  <div className="sm:col-span-2 col-span-6">
                    <label
                      htmlFor="toDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      To Date
                    </label>
                    <input
                      placeholder="Enter To Date"
                      type="date"
                      name="toDate"
                      value={values.toDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="toDate"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    />
                  </div>
                  <div className="sm:col-span-2 col-span-6">
                    <label
                      htmlFor="payer"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Payer
                    </label>
                    <select
                      id="payer"
                      name="payer"
                      onChange={handleChange}
                      value={values.payer}
                      autoComplete="type-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    >
                      <option value="">Please Select</option>
                      {payers.map((item) => {
                        return <option value={item.name}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="sm:col-span-2 col-span-6">
                    <label
                      htmlFor="providerNameEn"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Enter Provider Name
                    </label>
                    <input
                      placeholder="Enter Provider Name"
                      type="text"
                      name="providerNameEn"
                      value={values.providerNameEn}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="providerNameEn"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    />
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <label
                      htmlFor="status"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Status
                    </label>
                    <select
                      id="status"
                      name="status"
                      onChange={handleChange}
                      value={values.status}
                      autoComplete="type-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                    >
                      <option value="">Please Select</option>
                      {statuses.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={resetForm}
                    type="button"
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    Reset
                  </button>
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default PreAuthFilter;
