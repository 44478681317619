import React from "react";

function AccidentInfo({ item }) {
  return (
    <>
      {item.is_accident ? (
        <div class="pre_auth_communication_page">
          <div class="pre_auth_encounter_section">
            <div class="eligibility_details_head">
              <div class="pre_auth_encounter_head_cont">
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="34" height="34" rx="8" fill="#4D4D53" />
                  <path
                    d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                    fill="white"
                  />
                  <path
                    d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                    fill="white"
                  />
                  <path
                    d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                    fill="white"
                  />
                </svg>
                <span>Accident Info.</span>
              </div>
              <div class="pre_auth_encounter_body_cont">
                <div class="pre_auth_encounter_body_cont_inner row_1">
                  <div class="pre_auth_encounter_user_data width_247">
                    <div class="data_head">
                      <span>Type</span>
                    </div>
                    <div class="data_cont">
                      <span>{item?.accident_info?.type}</span>
                    </div>
                  </div>
                  <div class="pre_auth_encounter_user_data width_254">
                    <div class="data_head">
                      <span>City</span>
                    </div>
                    <div class="data_cont">
                      <span>{item?.accident_info?.city}</span>
                    </div>
                  </div>
                  <div class="pre_auth_encounter_user_data width_239">
                    <div class="data_head">
                      <span>Street Address</span>
                    </div>
                    <div class="data_cont">
                      <span>{item?.accident_info?.street_name}</span>
                    </div>
                  </div>
                  <div class="pre_auth_encounter_user_data width_161">
                    <div class="data_head">
                      <span>State</span>
                    </div>
                    <div class="data_cont">
                      <span>{item?.accident_info?.state}</span>
                    </div>
                  </div>
                </div>
                <div class="pre_auth_encounter_body_cont_inner row_2">
                  <div class="pre_auth_encounter_user_data width_247">
                    <div class="data_head">
                      <span>Country</span>
                    </div>
                    <div class="data_cont">
                      <span>{item?.accident_info?.country}</span>
                    </div>
                  </div>
                  <div class="pre_auth_encounter_user_data width_254">
                    <div class="data_head">
                      <span>Date</span>
                    </div>
                    <div class="data_cont">
                      <span>{item?.accident_info?.date}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h3>No Accidental Case</h3>
      )}
    </>
  );
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      {/* <div className="flex justify-between px-4 py-2 sm:px-6">
          <div className="pt-2 pb-2">
        <h4 className="font-bold text-xl text-cyan-600">Total</h4>
         </div>
          </div> */}
      <div className="border-t border-gray-200 px-4 py-2 sm:px-6">
        {/* DOWN WORK */}
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Type</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 sName="text-xl text-gray-600">{item?.accident_info?.type}</h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">City</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 sName="text-xl text-gray-600">{item?.accident_info?.city}</h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Street Address
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 sName="text-xl text-gray-600">
                {item?.accident_info?.street_name}
              </h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">State</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 sName="text-xl text-gray-600">
                {item?.accident_info?.state}
              </h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Country</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 sName="text-xl text-gray-600">
                {item?.accident_info?.country}
              </h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Date</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 sName="text-xl text-gray-600">{item?.accident_info?.date}</h2>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default AccidentInfo;
