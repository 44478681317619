import { Button, IconButton, Dialog } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AppModal from "../../../components/AppModal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
// import claims from "../../../gql/claims";
import { useQuery, useMutation } from "@apollo/client";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import SaveIcon from "@mui/icons-material/Save";
import ptpa from "../../../gql/ptpa";
// import LoadingButton from "@mui/lab/LoadingButton";
import toast from "react-hot-toast";
import { appContext } from "../../../context/ContextProvider";
import gqlClaims from "../../../gql/claims";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ClaimsSubmitModal({
  open,
  handleClose,
  selectedValues,
  setSelectedValues,
  refetch,
  data,
  setSubmitClaimData,
  setTotalPages,
  setTotalSubmits,
}) {
  const [loading, setLoading] = useState(false);

  const [payers, setPayers] = useState([]);

  const { setShowLoader, setShowErrorAlert } = useContext(appContext);
  const [errorData, setErrorData] = useState([]);

  const currentDate = new Date();

  //   console.log(selectedValues);

  const [fromDate, setFromDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-01`
  );
  const [toDate, setToDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate()}`
  );

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  // const {
  //   loading: dataLoading,
  //   error,
  //   data,
  //   refetch,
  // } = useQuery(gqlClaims.GET_PAYER_CLAIMS, {
  //   variables: {
  //     input: {
  //       from_date: fromDate,
  //       to_date: toDate,
  //       status: "Ready for submission",
  //       limit: limit,
  //       page: pageNo,
  //     },
  //   },
  // });

  const [
    submitClaims,
    { data: addData, loading: addLoading, error: addError },
  ] = useMutation(gqlClaims.SUBMIT_CLAIMS, {
    update(proxy, result) {
      onSuccessfulRequestResponse(result.data);
    },
    onError(error) {
      onResponseError(error.graphQLErrors[0]);
    },
  });

  const onSuccessfulRequestResponse = (data) => {
    if (!data.submitClaims.nphies_err) {
      toast.success("Successfully Requested!");
      setSelectedValues([]);
      window.location.reload();
      refetch({
        input: {
          from_date: fromDate,
          to_date: toDate,
          status: "Ready for submission",
          limit: limit,
          page: pageNo,
        },
      }).then((res) => {
        if (res?.data?.listPayerClaims?.data) {
          setSubmitClaimData(res?.data?.listPayerClaims?.data);
          setTotalPages(res?.data?.listPayerClaims?.pages);
          setTotalSubmits(res?.data?.listPayerClaims?.total);
        }
      });
    } else {
      let tempErrors = [
        {
          Code: "DT-01293",
          ValueString: "Bundle.entry[1].resource.supportingInfo[0].timingDate",
          Display:
            "The provided value does not match the specified element data type",
          __typename: "SimpleEligibilityNphiesErr",
        },
      ];
      setErrorData(data?.submitClaims?.nphies_err);
      setShowErrorAlert(true);
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error.message}`);
    setSelectedValues([]);
    window.location.reload();
    refetch({
      input: {
        from_date: fromDate,
        to_date: toDate,
        status: "Ready for submission",
        limit: limit,
        page: pageNo,
      },
    }).then((res) => {
      if (res?.data?.listPayerClaims?.data) {
        setSubmitClaimData(res?.data?.listPayerClaims?.data);
        setTotalPages(res?.data?.listPayerClaims?.pages);
        setTotalSubmits(res?.data?.listPayerClaims?.total);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      is_batch: false,
      batch_start_date: "",
      batch_end_date: "",
    },
    validationSchema: Yup.object({
      is_batch: Yup.boolean().required("Is Batch Required"),

      batch_start_date: Yup.string().test(
        "is-batch",
        "Batch Start Date Required",
        function (value) {
          if (this.parent.is_batch) {
            return !!value;
          }
          return true;
        }
      ),

      batch_end_date: Yup.string().test(
        "is-batch",
        "Batch End Date Required",
        function (value) {
          if (this.parent.is_batch) {
            return !!value;
          }
          return true;
        }
      ),
    }),

    onSubmit: async (values) => {
      if (data?.listPayerClaims?.data.length > 0 && selectedValues.length > 0) {
        toast.loading("Requesting!");
        submitClaims({
          variables: {
            // input: replaceEmptyStringWithNull(formatInputData(postData)), //temp
            input: { id: selectedValues, ...values },
          },
        }).then((res) => {
          console.log(res);
          // refetch({
          //   input: {
          //     from_date: fromDate,
          //     to_date: toDate,
          //     status: "Ready for submission",
          //     limit: limit,
          //     page: pageNo,
          //   },
          // });
        });
      }
      formik.resetForm();
    },
  });

  const downloadExcelFile = (data, fileName) => {
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(fileData, `${fileName}.xlsx`);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          className:
            "w-full sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl !z-40",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Submit Claim
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div class="pre_auth_container !p-0 ">
            <div className="pre_auth_req_page !p-0">
              <div className="personal-data !shadow-none !border-none !p-0 !mb-0">
                {/* lg:grid-cols-2 */}
                <div className="personal-fields gap-4 grid grid-cols-2 ">
                  <div class="pre_auth_checkbox my-auto col-span-2">
                    <div class="toggle-button-ref flex gap-6">
                      <input
                        type="checkbox"
                        name="is_batch"
                        onChange={() =>
                          formik.setFieldValue(
                            "is_batch",
                            !formik.values.is_batch
                          )
                        }
                        id="is_batch"
                        checked={formik.values.is_batch}
                      />
                      <label htmlFor="is_batch"></label>
                      <span>Is Batch</span>
                    </div>
                  </div>

                  {formik?.values?.is_batch && (
                    <div className="flex flex-col gap-1">
                      <label htmlFor="batch_start_date">
                        Batch Start Date*
                      </label>
                      <input
                        placeholder="Batch Start Date*"
                        type="date"
                        name="batch_start_date"
                        value={formik.values.batch_start_date}
                        onChange={formik.handleChange}
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        disabled={!formik.values.is_batch}
                        id="batch_start_date"
                        autoComplete="off"
                        className="dob"
                      />
                      {formik.errors.batch_start_date &&
                      formik.touched.batch_start_date ? (
                        <p className=" max-w-2xl text-sm leading-6 text-red-500">
                          {formik.errors.batch_start_date}
                        </p>
                      ) : null}
                    </div>
                  )}

                  {formik?.values?.is_batch && (
                    <div className="flex flex-col gap-1">
                      <label htmlFor="batch_end_date">Batch End Date*</label>
                      <input
                        placeholder="Batch End Date*"
                        type="date"
                        name="batch_end_date"
                        value={formik.values.batch_end_date}
                        onChange={formik.handleChange}
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        disabled={!formik.values.is_batch}
                        id="batch_end_date"
                        autoComplete="off"
                        className="dob"
                      />
                      {formik.errors.batch_end_date &&
                      formik.touched.batch_end_date ? (
                        <p className=" max-w-2xl text-sm leading-6 text-red-500">
                          {formik.errors.batch_end_date}
                        </p>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            size="small"
            onClick={() => {
              handleClose();
              formik.handleSubmit();
            }}
            className="!text-primary hover:!bg-primary/20"
          >
            {loading ? "..." : "Submit"}
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <AppModal isOpen={loading || addLoading} />
    </div>
  );
}
