import { gql } from "@apollo/client";

const ELIGIBILITY_CHECK = gql`
  mutation verificationRequest($input: VerificationInput!) {
    verificationRequest(input: $input) {
      message
      nphies_err {
        code
        value_string
        display
      }
      nphies_status
      status
    }
  }
`;

const GET_ELIGIBILITY_TRANSACTIONS = gql`
  query listVerificationTransactions($input: TransactionsFilterInput!) {
    listVerificationTransactions(input: $input) {
      message
      status
      data {
        patient_name
        verification_id
        purpose
        payer_name
        transaction_date
        site_eligibility
        status
        transaction_id
      }
      pages
      total
    }
  }
`;

const GET_TRANSACTION_DETAILS = gql`
  query getVerificationTransaction($transactionId: String!) {
    getVerificationTransaction(transaction_id: $transactionId) {
      message
      status
      data {
        verification_id
        transaction_id
        site_eligibility
        patient_name
        document_id
        payer_name
        tpa_name
        outcome
        disposition
        service_date
        purpose
        is_referral
        verification_resp_ident_value
        verification_resp_ident_url
        is_new_born
        errors {
          error_message
          error_code
        }
        coverages {
          in_force
          benefit_date
          member_id
          policy_number
          policy_holder
          type
          status
          relation_with_subscriber
          subscriber_member_id
          network
          subrogation
          site_eligibility
          class_name
          class_value
          class_type
          class_group_type
          class_group_name
          class_group_value
          class_plan_name
          class_plan_value
          class_plan_type
          cost_to_ben_copay_type
          cost_to_ben_copay_code
          cost_to_ben_copay_value
          cost_to_ben_max_copay_type
          cost_to_ben_max_copay_value
          items {
            category_name
            description
            network
            unit
            term
            benefits
          }
        }
        logs {
          id
          transaction_id
          timestamp
          type
          log
        }
      }
    }
  }
`;

const GET_TRANSACTIONS_IN_EXCEL = gql`
  query listVerificationTransactionsExcel(
    $input: TransactionsExcelFilterInput!
  ) {
    listVerificationTransactionsExcel(input: $input) {
      message
      status
      data
    }
  }
`;

export default {
  ELIGIBILITY_CHECK,
  GET_ELIGIBILITY_TRANSACTIONS,
  GET_TRANSACTION_DETAILS,
  GET_TRANSACTIONS_IN_EXCEL,
};
