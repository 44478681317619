import React, { useState, useContext, useRef, useEffect } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import Modal from "react-modal";
import { Formik, useFormik } from "formik";
import { Switch } from "@headlessui/react";
import * as Yup from "yup";
import _ from "lodash";
import { useQuery, gql, useMutation } from "@apollo/client";
// import { ToastContainer, toast } from "react-toastify";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import eligibility from "../../../../gql/eligibility";
import insurancePlansGQL from "../../../../gql/insurancePlans";
import ptpa from "../../../../gql/ptpa";
import BeneficiarySearchNSelect from "../requestEligibility/BeneficiarySearchNSelect";
import { appContext } from "../../../../context/ContextProvider";
import AppErrorAlert from "../../../../components/AppErrorAlert";

import "../../styles/eligibility.css";
import AppModal from "../../../../components/AppModal";
import payerData from "../../../../assets/payerData.json";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

let subtitle;

function RequestEligibilityModal({
  showEligibilityModal,
  setShowEligibilityModal,
  errorData,
  setErrorData,
  refetch,
  fromDate,
  toDate,
}) {
  const modalRef = useRef();

  const currentDate = new Date();

  const { showErrorAlert, setShowErrorAlert } = useContext(appContext);

  const navigate = useNavigate();

  const [enabled, setEnabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  // useEffect
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowEligibilityModal(false);
      }
    };

    if (showEligibilityModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEligibilityModal, setShowEligibilityModal]);

  // console.log("useEffect: ", modalRef);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  const [insurancePlans, setInsurancePlans] = useState([]);
  const [payers, setPayers] = useState([]);

  const formatInsurancePlansData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.id,
          memberCardId: item.member_card_id,
          policyNumber: item.policy_number,
          expiryDate: item.expiry_date,
          isPrimary: item.is_primary,
          payerId: item.payer_id,
          relationWithSubscriber: item.relation_with_subscriber,
          coverageType: item.coverage_type,
          patientShare: item.patient_share,
          maxLimit: item.max_limit,
          // payerNphiesId: item.payer_nphies_id,
          patientId: item.patient_id,
          name: item?.payer?.name_en,
          license_id: item?.payer?.license_id,
        });
      });
    } else {
    }
    return returnData;
  };

  // const {
  //   loading: loadingPayers,
  //   error: errorPayer,
  //   data: payersData,
  //   refetch: refetchPayers,
  // } = useQuery(ptpa.GET_ALL_PAYERS);

  React.useEffect(() => {
    const payersData = JSON.parse(localStorage.getItem("payersData"));

    if (payersData && payersData.length > 0) {
      setPayers(payersData);
    } else {
      navigate("/dashboard");
    }
  }, []);

  const [checkEligibility, { data, loading, error }] = useMutation(
    eligibility.ELIGIBILITY_CHECK,
    {
      update(proxy, result) {
        if (result.data.verificationRequest.nphies_status === "error") {
          result.data.verificationRequest.nphies_err.map((err) => {
            toast.error(err.display);
          });
          setShowLoader(false);
        } else {
          toast.success("Data send to payer successfully!");
          onSuccessfulRequestResponse(result.data);
        }
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  // const { loading, error, data } = useQuery(usersGQL.GET_USER);
  const onSuccessfulRequestResponse = (data) => {
    if (!data.verificationRequest.nphies_err) {
      setShowLoader(false);
      refetch({
        limit: 10,
        page: 1,
        fromDate: fromDate,
        toDate: toDate,
      });
    } else {
      setShowLoader(false);
      setErrorData(data.verificationRequest.nphies_err);
      setShowErrorAlert(true);
    }
  };

  const onResponseError = (error) => {
    setShowLoader(false);
    refetch({
      limit: 10,
      page: 1,
      fromDate: fromDate,
      toDate: toDate,
    });
    toast.error(`${error?.message}`);
  };

  const stringToBoolean = (stringVal) => {
    if (String(stringVal).toLowerCase() == "true") {
      return true;
    } else {
      return false;
    }
  };

  const getPurpose = (values) => {
    let discovery = "discovery";
    let benefits = "benefits";
    let validations = "validation";

    let newArray;

    if (values.discovery && values.benefits && values.validations) {
      newArray = [discovery, benefits, validations];
    } else if (values.discovery && values.benefits) {
      newArray = [discovery, benefits];
    } else if (values.discovery && values.validations) {
      newArray = [discovery, validations];
    } else if (values.benefits && values.validations) {
      newArray = [benefits, validations];
    } else if (values.discovery) {
      newArray = [discovery];
    } else if (values.benefits) {
      newArray = [benefits];
    } else if (values.validations) {
      newArray = [validations];
    }

    return newArray;
  };

  const formatInputData = (postData) => {
    return {
      start_date: postData.startDate,
      end_date: postData.endDate,
      payer_id: `${postData.payerId}`,
      patient_id: postData.patientId,
      insurance_plan_id: `${postData.insurancePlan.id}`,
      purpose: postData.purpose,
      is_new_born: postData.isNewBorn,
      is_referral: postData.isReferral,
    };
  };

  const addData = (values) => {
    console.log("values ::: ", values);
    setShowEligibilityModal(false);
    let data = _.cloneDeep(values);
    setShowLoader(true);
    checkEligibility({ variables: { input: formatInputData(data) } });
  };

  const customValidate = (values) => {
    if (values.patientId != "" && values.payerId != "") {
      return false;
    } else {
      return true;
    }
  };

  const setPurposeValues = (valChecked, currentVal, purposeArray) => {
    if (valChecked) {
      // Check if "benefits" exists in the array
      if (!purposeArray.includes(currentVal)) {
        // Add currentVal to the array
        purposeArray.push(currentVal);
      }
    } else {
      // Check if currentVal exists in the array
      const index = purposeArray.indexOf(currentVal);
      if (index !== -1) {
        // Remove currentVal from the array
        purposeArray.splice(index, 1);
      }
    }

    return purposeArray;
  };

  const sortedInsurancePlans = insurancePlans.slice().sort((a, b) => {
    // Sort in descending order, so that true comes first
    return b.is_primary - a.is_primary;
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    isValid,
    validateForm,
    /* and other goodies */
  } = useFormik({
    initialValues: {
      isNewBorn: false,
      isReferral: false,
      patientId: "",
      subscriber: "",
      benefeciaryName: "",
      startDate: currentDate.toISOString().substring(0, 10),
      endDate: currentDate.toISOString().substring(0, 10),
      purpose: ["benefits", "validation"],
      benefits: true,
      validations: true,
      discovery: false,
      insurancePlan: "",
      insurancePlanIndex: "",
      payerId: "",
    },

    validationSchema: () => {
      return Yup.object().shape({
        patientId: Yup.string().required("Patient ID is required"),
        payerId: values.discovery
          ? Yup.string().required("Payer ID is required")
          : Yup.string(),
        insurancePlan: values.discovery
          ? Yup.mixed()
          : Yup.mixed().required("Insurance is required"),
      });
    },
    onSubmit: (values, { setSubmitting }) => {
      addData(values);
    },
  });

  return (
    <div
      className={`${showEligibilityModal ? "open" : ""} modal request-eligible`}
      id="request-eligible"
    >
      <div ref={modalRef} class="modal-content">
        <div
          class="close"
          onClick={() => setShowEligibilityModal(() => !showEligibilityModal)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#DE5042"
            />
          </svg>
          <div class="bene-text"></div>
        </div>
        <div class="referral">
          <div class="referral-title">Referral</div>
          <div class="toggle-button-ref">
            <input
              type="checkbox"
              id="toggle-ref"
              name="isReferral"
              value={values.isReferral}
              onChange={() => setFieldValue("isReferral", !values.isReferral)}
            />
            <label for="toggle-ref"></label>
          </div>
        </div>
        <div class="newBorn">
          <div class="newBorn-title">New Born?</div>
          <div class="toggle-button-born">
            <input
              type="checkbox"
              id="toggle-born"
              name="isNewBorn"
              value={values.isNewBorn}
              onChange={handleChange}
            />
            <label for="toggle-born"></label>
          </div>
        </div>
        {values.isNewBorn && (
          <>
            <div class="select-subs">
              <div class="subs-title">Select Subscriber</div>
            </div>
            <div class="search">
              <BeneficiarySearchNSelect
                setFieldValue={(val) => {
                  if (values.isNewBorn) {
                    setInsurancePlans(
                      formatInsurancePlansData(val.insurancePlans)
                    );
                  } else {
                  }
                  setFieldValue("subscriber", val.id);
                  setFieldValue("insurancePlan", "");
                  setFieldValue("insurancePlanIndex", "");
                  setFieldValue("payerId", "");
                }}
              />
            </div>
          </>
        )}
        <div class="input-container">
          {/* <input type="text" id="myInput" placeholder="Name" /> */}
          <BeneficiarySearchNSelect
            setFieldValue={(val) => {
              console.log(val);
              if (values.isNewBorn) {
              } else {
                setInsurancePlans(formatInsurancePlansData(val.insurancePlans));
              }
              setFieldValue("patientId", val.id);
              setFieldValue("benefeciaryName", val.name);
              setFieldValue(
                "insurancePlan",
                val?.insurancePlans && val?.insurancePlans?.length > 0
                  ? formatInsurancePlansData(val.insurancePlans)[0]
                  : ""
              );
              setFieldValue("insurancePlanIndex", 0);
              setFieldValue(
                "payerId",
                val?.insurancePlans && val?.insurancePlans?.length > 0
                  ? formatInsurancePlansData(val.insurancePlans)[0]?.payerId
                  : ""
              );
              validateForm(); // Trigger validation
            }}
          />
          {errors.patientId && touched.patientId ? (
            <p className="max-w-2xl text-sm leading-6 text-red-500">
              {errors.patientId}
            </p>
          ) : null}
          {/* <label for="myInput">Beneficiary</label> */}
        </div>
        <div class="service_to_date">
          <input
            type="date"
            name="startDate"
            value={values.startDate}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Select Date"
            id="startDate"
          />
          <input
            type="date"
            name="endDate"
            value={values.endDate}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Select Date"
            id="endDate"
          />
        </div>
        <div class="purpose">
          <div class="purpose-title">
            Purpose
            <span class="required">
              <span class="a11y"></span>
            </span>
          </div>
          <div class="purpose-checks">
            <input
              type="checkbox"
              name="benefits"
              value={values.benefits}
              checked={values.benefits}
              onChange={(e) => {
                setFieldValue(
                  "purpose",
                  setPurposeValues(!values.benefits, "benefits", values.purpose)
                );

                setFieldValue("benefits", !values.benefits);
              }}
              onBlur={handleBlur}
              id="benefits"
            />
            <label for="Benefits">Benefits</label>
            <span></span>
            <input
              type="checkbox"
              name="validations"
              value={values.validations}
              checked={values.validations}
              onChange={(e) => {
                setFieldValue(
                  "purpose",
                  setPurposeValues(
                    !values.validations,
                    "validation",
                    values.purpose
                  )
                );

                setFieldValue("validations", !values.validations);
              }}
              onBlur={handleBlur}
              id="validations"
            />
            <label for="Validations">Validations</label>
          </div>
        </div>
        <div class="discovery">
          <div class="discovery-title">Select Discovery</div>
          <div class="discovery-checks">
            <input
              type="checkbox"
              name="discovery"
              value={values.discovery}
              checked={values.discovery}
              onChange={(e) => {
                setFieldValue(
                  "purpose",
                  setPurposeValues(
                    !values.discovery,
                    "discovery",
                    values.purpose
                  )
                );

                setFieldValue("discovery", !values.discovery);
              }}
              onBlur={handleBlur}
              id="discovery"
            />
            <label for="discovery">Discovery</label>
          </div>
        </div>
        <div class="insurance-plan">
          <div class="ins-plan-title">
            {values.discovery ? "Payers" : "Insurance Plan"}
            <span class="required">
              <span class="a11y"></span>
            </span>
          </div>
          <select
            name="insurancePlanIndex"
            onChange={(e) => {
              let iPlanIndex = e.target.value;

              if (iPlanIndex != "") {
                setFieldValue(
                  "insurancePlan",
                  values.discovery ? iPlanIndex : insurancePlans[iPlanIndex]
                );
                setFieldValue(
                  "payerId",
                  values.discovery
                    ? iPlanIndex
                    : insurancePlans[iPlanIndex].payerId
                );
              } else {
                setFieldValue("insurancePlan", "");
                setFieldValue("payerId", "");
              }
              setFieldValue("insurancePlanIndex", iPlanIndex);
              validateForm(); // Trigger validation
            }}
            value={values.insurancePlanIndex}
            id="insurance-plan"
          >
            <option value={null}>Select One</option>
            {values.discovery
              ? payers.map((category, index) => (
                  <optgroup
                    label={
                      category?.name_en
                        ? category?.name_en + ` (${category?.license_id})`
                        : ""
                    }
                    key={category.id}
                  >
                    {category.payers.map((insurance, index) => (
                      <option key={index} value={insurance.id}>
                        {insurance?.name_en} ({insurance?.license_id})
                      </option>
                    ))}
                  </optgroup>
                ))
              : insurancePlans.map((item, index) => {
                  return (
                    <option key={index} value={index} selected={item}>
                      {item?.name} ({item?.license_id})
                    </option>
                  );
                })}
          </select>
          {errors.insurancePlan && touched.insurancePlan ? (
            <p className="max-w-2xl text-sm leading-6 text-red-500">
              {errors.insurancePlan}
            </p>
          ) : null}
          {errors.payerId && touched.payerId ? (
            <p className="max-w-2xl text-sm leading-6 text-red-500">
              {errors.payerId}
            </p>
          ) : null}
        </div>
        <div class="request-eligible-btns">
          <div
            class="cancel-btn"
            onClick={() => setShowEligibilityModal(false)}
          >
            <span>Cancel</span>
          </div>
          <div class="request-btn" onClick={handleSubmit}>
            <span>Request</span>
          </div>
        </div>
      </div>
      <AppModal isOpen={showLoader || loading} />
    </div>
  );
}

export default RequestEligibilityModal;
