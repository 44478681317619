import { gql } from "@apollo/client";

const REQUEST_AUTHORIZATION = gql`
  mutation preAuthRequest($input: PreAuthInput!) {
    preAuthRequest(input: $input) {
      message
      transaction_id
      nphies_err {
        code
        value_string
        display
      }
      nphies_status
      status
    }
  }
`;

const GET_CODES = gql`
  query GetCodes($fileName: String!) {
    getItemsCodeDescription(fileName: $fileName) {
      Status
      Message
      Data {
        code
        description
        receivedDate
      }
    }
  }
`;

const GET_SEARCHED_DIAGNOSIS = gql`
  query GetSearchedDiagnosis($q: String!) {
    listIcdCodes10(search: $q) {
      status
      message
      data {
        code_id
        ascii_description
      }
    }
  }
`;

const GET_SEARCHED_ITEM_CODES = gql`
  query GetSearchedItemCodes($q: String!, $fileName: String!) {
    getItemsCodeDescription(fileName: $fileName, search: $q) {
      status
      message
      data {
        code
        description
        receivedDate
      }
    }
  }
`;

const GET_PRESCRIPTION = gql`
  query GetPrescription($q: String!) {
    getPharmacyItems(search: $q) {
      Status
      Message
      Data {
        tradeName
        descriptionCode
        gtinNumber
      }
    }
  }
`;

const GET_PREAUTH_TRANSACTIONS = gql`
  query listPreAuthTransactions($input: PreAuthTransactionsFilterInput!) {
    listPreAuthTransactions(input: $input) {
      message
      status
      data {
        patient_name
        date_ordered
        response_date
        pre_auth_ref_no
        type
        payer_name
        outcome
        transaction_id
        status
        is_followup_req
        followup_ref_transaction_id
        communication_count
      }
      pages
      total
    }
  }
`;

const GET_PREAUTH_EXCEL_DATA = gql`
  query listPreAuthTransactionsExcel(
    $input: PreAuthTransactionsExcelFilterInput!
  ) {
    listPreAuthTransactionsExcel(input: $input) {
      message
      status
      data
    }
  }
`;

const GET_PREAUTH_TRANSACTION_DETAILS_SI = gql`
  query getPreAuthTransaction($transactionId: String!) {
    getPreAuthTransaction(transaction_id: $transactionId) {
      message
      status
      data {
        supporting_info {
          sequence_no
          category
          code
          code_text
          start_date
          end_date
          value
          unit
          image_value
          image_title
          image_content_type
          image_creation_date
          reason
        }
      }
    }
  }
`;

const GET_PREAUTH_TRANSACTION_DETAILS_WITHOUT_SI = gql`
  query getPreAuthTransaction($transactionId: String!) {
    getPreAuthTransaction(transaction_id: $transactionId) {
      message
      status
      data {
        authorization_id
        transaction_id
        request_bundle_id
        response_bundle_id
        transaction_date
        date_ordered
        outcome
        status
        type
        subtype
        subscriber_id
        payer_name
        tpa_name
        errors {
          error_message
          error_code
        }
        pre_auth_ref_no
        pre_auth_ref_value
        pre_auth_ref_url
        pre_auth_period
        related_pre_auth_req_identifier
        offline_verification_id
        offline_verification_date
        verification_resp_ident_value
        verification_resp_ident_url
        is_followup_req
        followup_ref_transaction_id
        disposition
        is_referral
        is_new_born
        related_pre_auth_ref_no
        calcellation_status
        priority
        calcellation_status_reason
        total_approved_value
        total_approved_currency
        total_benefit_value
        total_benefit_currency
        total_copay_value
        total_copay_currency
        total_deducted_value
        total_deducted_currency
        total_approved_quantity
        total_resp_tax
        total_resp_tax_currency
        total_submitted_value
        total_submitted_ccy
        total_resp_patient_share_value
        total_resp_patient_share_ccy
        patient_document_id
        patient_name
        patient_gender
        patient_dob
        patient_contact_no
        patient_email
        payer_id
        insurance_plan_id
        accident_info {
          city
          country
          date
          state
          street_name
          type
        }
        is_accident
        encounter {
          status
          service_type
          class
          priority
          period_start
          period_end
          admin_source
          readmission
          discharge_disposition
        }
        is_vision_prescription
        vision_prescription_data {
          date_written
          prescriber_id
          lens_specifications {
            product
            color
            brand
            right {
              sphere
              cylinder
              axis
              add
            }
            left {
              sphere
              cylinder
              axis
              add
              prism {
                base
                amount
              }
              power
              back_curve
              diameter
              duration {
                time
                value
              }
            }
            notes
          }
        }
        insurance_plan {
          id
          member_card_id
          policy_number
          expiry_date
          relation_with_subscriber
          coverage_type
          payer {
            id
            name_en
            name_ar
            tpa_id
          }
        }
        items {
          id
          sequence_no
          type
          standard_code
          standard_description
          net
          tax_amount
          non_standard_code
          non_standard_description
          service_start_date
          service_end_date
          is_package
          body_site
          sub_site
          diagnosis
          supporting_info
          care_teams
          quantity
          unit_price
          discount_amount
          tax_amount
          patient_share
          payer_share
          factor
          approved_value
          approved_currency
          benefit_value
          benefit_currency
          copay_value
          copay_currency
          deducted_value
          deducted_currency
          approved_quantity
          resp_tax
          resp_tax_currency
          submitted_value
          submitted_currency
          resp_patient_share_value
          resp_patient_share_ccy
          status
          reject_reason_code
          reject_reason_display
          medication_selection_reason
          prescribed_medication
        }
        care_teams {
          sequence_no
          id
          name
          practitioner_role
          physician_id
          care_team_role
          qualification
          speciality
        }
        diagnosis {
          sequence_no
          type
          code
          description
          on_admission
        }
        patient {
          id
          full_name
          document_id
          dob
          contact_number
          gender
          email
        }
      }
    }
  }
`;

const GET_PREAUTH_TRANSACTION_DETAILS = gql`
  query getPreAuthTransaction($transactionId: String!) {
    getPreAuthTransaction(transaction_id: $transactionId) {
      message
      status
      data {
        authorization_id
        transaction_id
        request_bundle_id
        response_bundle_id
        transaction_date
        date_ordered
        outcome
        status
        type
        subtype
        subscriber_id
        payer_name
        tpa_name
        errors {
          error_message
          error_code
        }
        pre_auth_ref_no
        pre_auth_ref_value
        pre_auth_ref_url
        pre_auth_period
        related_pre_auth_req_identifier
        offline_verification_id
        offline_verification_date
        verification_resp_ident_value
        verification_resp_ident_url
        is_followup_req
        followup_ref_transaction_id
        disposition
        is_referral
        is_new_born
        related_pre_auth_ref_no
        calcellation_status
        priority
        calcellation_status_reason
        total_approved_value
        total_approved_currency
        total_benefit_value
        total_benefit_currency
        total_copay_value
        total_copay_currency
        total_deducted_value
        total_deducted_currency
        total_approved_quantity
        total_resp_tax
        total_resp_tax_currency
        total_submitted_value
        total_submitted_ccy
        total_resp_patient_share_value
        total_resp_patient_share_ccy
        patient_document_id
        patient_name
        patient_gender
        patient_dob
        patient_contact_no
        patient_email
        payer_id
        insurance_plan_id
        accident_info {
          city
          country
          date
          state
          street_name
          type
        }
        is_accident
        encounter {
          status
          service_type
          class
          priority
          period_start
          period_end
          admin_source
          readmission
          discharge_disposition
        }
        is_vision_prescription
        vision_prescription_data {
          date_written
          prescriber_id
          lens_specifications {
            product
            color
            brand
            right {
              sphere
              cylinder
              axis
              add
            }
            left {
              sphere
              cylinder
              axis
              add
              prism {
                base
                amount
              }
              power
              back_curve
              diameter
              duration {
                time
                value
              }
            }
            notes
          }
        }
        insurance_plan {
          id
          member_card_id
          policy_number
          expiry_date
          relation_with_subscriber
          coverage_type
          payer {
            id
            name_en
            name_ar
            tpa_id
          }
        }
        items {
          id
          sequence_no
          type
          standard_code
          standard_description
          net
          tax_amount
          non_standard_code
          non_standard_description
          service_start_date
          service_end_date
          is_package
          body_site
          sub_site
          diagnosis
          supporting_info
          care_teams
          quantity
          unit_price
          discount_amount
          tax_amount
          patient_share
          payer_share
          factor
          approved_value
          approved_currency
          benefit_value
          benefit_currency
          copay_value
          copay_currency
          deducted_value
          deducted_currency
          approved_quantity
          resp_tax
          resp_tax_currency
          submitted_value
          submitted_currency
          resp_patient_share_value
          resp_patient_share_ccy
          status
          reject_reason_code
          reject_reason_display
          medication_selection_reason
          prescribed_medication
        }
        care_teams {
          sequence_no
          id
          name
          practitioner_role
          physician_id
          care_team_role
          qualification
          speciality
        }
        supporting_info {
          sequence_no
          category
          code
          code_text
          start_date
          end_date
          value
          unit
          image_value
          image_title
          image_content_type
          image_creation_date
          reason
        }
        diagnosis {
          sequence_no
          type
          code
          description
          on_admission
        }
        patient {
          id
          full_name
          document_id
          dob
          contact_number
          gender
          email
        }
      }
    }
  }
`;
const GET_PREAUTH_TRANSACTION_LOGS = gql`
  query getPreAuthTransaction($transactionId: String!) {
    getPreAuthTransaction(transaction_id: $transactionId) {
      message
      status
      data {
        logs {
          id
          transaction_id
          timestamp
          type
          log
        }
      }
    }
  }
`;

const ADD_COMMUNICATION = gql`
  mutation communicationRequest($input: CommunicationInput!) {
    communicationRequest(input: $input) {
      message
      nphies_err {
        code
        value_string
        display
      }
      nphies_status
      status
      data {
        id
        timestamp
        transaction_id
        bundle_id
        status
        payload {
          payload_comment
          payload_attachment {
            payload_attachment_content_type
            payload_attachment_data
            payload_attachment_title
            payload_attachment_creation_date
          }
          item_sequence
        }
        type
        creator
      }
    }
  }
`;

const GET_TRANSACTION_COMMUNICATIONS = gql`
  query listCommunicationsByTransactionId($input: CommunicationFilterInput!) {
    listCommunicationsByTransactionId(input: $input) {
      message
      status
      data {
        id
        transaction_id
        bundle_id
        poll_bundle_id
        ref_communication_req_id
        status
        category
        priority
        type
        creator
        timestamp
        payload {
          payload_comment
          payload_attachment {
            payload_attachment_content_type
            payload_attachment_data
            payload_attachment_title
            payload_attachment_creation_date
          }
          item_sequence
        }
      }
    }
  }
`;

const CANCEL_REQUEST = gql`
  mutation cancelRequest($input: TransactionInput!) {
    cancelRequest(input: $input) {
      message
      nphies_err {
        code
        value_string
        display
      }
      nphies_status
      status
    }
  }
`;

const PRE_AUTH_POLL_REQ = gql`
  query {
    pollRequest(request_name: "preAuth") {
      status
      message
      data
    }
  }
`;

const PRE_AUTH_COMMUNICATION_POLL = gql`
  query {
    pollRequest(request_name: "communication") {
      status
      message
      data
    }
  }
`;

const PRE_AUTH_TO_CLAIM = gql`
  mutation preAuthToClaim($input: PreAuthToClaimInput!) {
    preAuthToClaim(input: $input) {
      message
      status
    }
  }
`;

const PRE_AUTH_BULK_POLL_REQ = gql`
  query {
    bulkPollRequest(request_name: "preAuth", num: 1) {
      status
      message
      data
    }
  }
`;

export default {
  REQUEST_AUTHORIZATION,
  GET_CODES,
  GET_SEARCHED_DIAGNOSIS,
  GET_SEARCHED_ITEM_CODES,
  GET_PRESCRIPTION,
  GET_PREAUTH_TRANSACTIONS,
  GET_PREAUTH_EXCEL_DATA,
  GET_PREAUTH_TRANSACTION_DETAILS,
  ADD_COMMUNICATION,
  GET_TRANSACTION_COMMUNICATIONS,
  CANCEL_REQUEST,
  PRE_AUTH_POLL_REQ,
  GET_PREAUTH_TRANSACTION_LOGS,
  PRE_AUTH_TO_CLAIM,
  PRE_AUTH_COMMUNICATION_POLL,
  PRE_AUTH_BULK_POLL_REQ,
  GET_PREAUTH_TRANSACTION_DETAILS_WITHOUT_SI,
  GET_PREAUTH_TRANSACTION_DETAILS_SI,
};
