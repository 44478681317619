import { Chip } from "@mui/material";
import React from "react";
function ResponseDetailsComponent({ item }) {
  function formatDateTime(data) {
    const date = new Date(data);
    // Get date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding padding if needed
    const day = String(date.getDate()).padStart(2, "0"); // Adding padding if needed

    // Get time components
    const hours = String(date.getHours()).padStart(2, "0"); // Adding padding if needed
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Adding padding if needed
    const seconds = String(date.getSeconds()).padStart(2, "0"); // Adding padding if needed

    // Construct the date and time string in the format "YYYY-MM-DD HH:MM:SS"
    const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return dateTimeString;
  }
  const getColor = (status) => {
    if (status === "rejected") {
      return "error";
    } else if (status === "approved") {
      return "success";
    } else if (status === "pending") {
      return "info";
    } else if (status === "queued") {
      return "warning";
    } else if (status === "complete") {
      return "success";
    } else if (status === "error") {
      return "error";
    } else if (status === "Abnormal Request") {
      return "error";
    } else if (status === "not-required") {
      return "warning";
    } else {
      return "secondary";
    }
  };
  return (
    <div class="eligibility_details_section">
      {item?.errors?.map((el, index) => {
        return (
          <div className="sm:col-span-6 mb-2">
            <div class="p-4 text-sm text-red-800 rounded-lg bg-red-50 dark:text-red-400">
              <span class="font-medium">Error {el?.error_code} : </span>
              {el?.error_message}
            </div>
          </div>
        );
      })}

      <div class="eligibility_details_body_cont !grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 !p-0 !border-none !shadow-none">
        <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Req. Bundle Id</span>
          </div>
          <div class="data_cont !text-center">
            <span className="!text-base !font-semibold">
              {item?.request_bundle_id || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Beneficiary Name</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold ">
              {item?.patient_name || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Document</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold ">
              {item?.patient_document_id || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data  border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Insurer</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.payer_name || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>TPA Name</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.tpa_name || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Is Referral</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.is_referral ? "Yes" : "No" || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Eligibility Response Identifier (Value)</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.verification_resp_ident_value || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Eligibility Response Identifier (Url)</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.verification_resp_ident_url || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Is New Born ?</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.is_new_born ? "Yes" : "No" || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Transaction Date</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {formatDateTime(item?.transaction_date) || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Date Ordered</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {formatDateTime(item?.date_ordered) || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Type</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.type || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Sub Type</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.subtype || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Insurer</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.payer_name || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Pre Auth Ref. No.</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.offline_pre_auth_no || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Pre Auth Ref Date</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.offline_pre_auth_date || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Pre Auth Period</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.pre_auth_period || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Offline Eligibility Id</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.offline_verification_id || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Offline Eligibility Date</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.offline_verification_date || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Disposition</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.disposition || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Pre Auth Res. Value</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.pre_auth_resp_value || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Pre Auth Res. Url</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.pre_auth_resp_url || "___"}
            </span>
          </div>
        </div>

        <div class="eligibility_details_user_data border-b  px-1.5 pt-1.5 ">
          <div class="data_head !text-center">
            <span>Cancellation Status</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.calcellation_status || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b  px-1.5 pt-1.5 ">
          <div class="data_head !text-center">
            <span>Cancellation Status Reason</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.calcellation_status_reason || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 pt-1.5 ">
          <div class="data_head !text-center">
            <span>Related Pre Auth Ref. No.</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.related_pre_auth_ref_no || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 pt-1.5 ">
          <div class="data_head !text-center">
            <span>Episode No.</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.episode_no || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 pt-1.5 ">
          <div class="data_head !text-center">
            <span>Is Follow Up Req</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.is_followup_req ? "Yes" : "No"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data border-b px-1.5 pt-1.5 ">
          <div class="data_head !text-center">
            <span>Ref Transaction Id</span>
          </div>
          <div class="data_cont !text-center">
            <span class="data_cont !text-base !font-semibold">
              {item?.followup_ref_transaction_id || "___"}
            </span>
          </div>
        </div>
        <div class="eligibility_details_user_data  px-1.5 py-1.5">
          <div class="data_head !text-center">
            <span>Outcome</span>
          </div>
          <div class=" !text-white  !text-center">
            <p class=" !text-white !text-base !font-semibold">
              {item?.outcome ? (
                <Chip
                  label={item?.outcome}
                  color={getColor(item?.outcome)}
                  size="small"
                  className="uppercase text-xs "
                />
              ) : (
                "___"
              )}
            </p>
          </div>
        </div>
        <div class="eligibility_details_user_data  px-1.5 py-1.5 ">
          <div class="data_head !text-center">
            <span>Status</span>
          </div>
          <div class=" !text-white  !text-center">
            <p class=" !text-white !text-base !font-semibold">
              {item?.status ? (
                <Chip
                  label={item?.status}
                  color={getColor(item?.status)}
                  size="small"
                  className="uppercase text-xs !text-white"
                />
              ) : (
                "___"
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponseDetailsComponent;
