import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import Login from "../pages/auth/Login";
// import Register from "../auth/pages/Register";
// import EmailVerification from "../auth/pages/EmailVerification";
// import ForgotPassword from "../auth/pages/ForgotPassword";

function AuthRouter(props) {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {/* <Route path="/register" element={<Register />} />
      <Route path="/email-verification" element={<EmailVerification />} />
      <Route path="/forgot-password" element={<ForgotPassword />} /> */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default AuthRouter;
