import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdClear } from "react-icons/md";
import "../PriceList/style/dragndropstyle.css";

const DragNdrop = forwardRef(({ width, height, formik, onRemoveFile }, ref) => {
  const [files, setFiles] = useState("");

  const handleRemoveFile = () => {
    setFiles("");
    formik.setFieldValue("file", null);
    if (onRemoveFile) {
      onRemoveFile();
    }
  };

  useImperativeHandle(ref, () => ({
    reset: () => setFiles(""),
  }));

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      const newFiles = selectedFiles;
      setFiles(newFiles);
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files[0];
    if (droppedFiles) {
      const newFiles = droppedFiles;
      setFiles(newFiles);
    }
  };

  // const handleRemoveFile = () => {
  //   setFiles("");
  //   formik.setFieldValue("file", null);
  // };

  useEffect(() => {
    formik.setFieldValue("file", files);
  }, [files]);

  return (
    <section className="drag-drop" style={{ width: width, height: height }}>
      <div
        className={`document-uploader ${
          files.length > 0 ? "upload-box active" : "upload-box"
        }`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <>
          <div className="upload-info">
            <AiOutlineCloudUpload />
            <div>
              <p>Drag and drop your files here</p>
              <p>Limit 16MB per file. Supported file: .XLSX</p>
            </div>
          </div>
          <input
            type="file"
            hidden
            id="browse"
            onChange={handleFileChange}
            accept=".xlsx"
          />
          <label htmlFor="browse" className="browse-btn">
            Browse files
          </label>
        </>

        {files && (
          <div className="file-list">
            <div className="file-list__container">
              <div className="file-item">
                <div className="file-info">
                  <p>{files.name}</p>
                </div>
                <div className="file-actions">
                  <MdClear onClick={() => handleRemoveFile()} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
});

export default DragNdrop;
