import {
  Button,
  IconButton,
  Dialog,
  LinearProgress,
  DialogContentText,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useRef } from "react";
import * as XLSX from "xlsx";
import { useState } from "react";
import { useEffect } from "react";
import priceList from "../../gql/priceList";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import AppModal from "../../components/AppModal";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { styled } from "@mui/material/styles";
import DragNdrop from "./DragNdrop";
import axios from "axios";
import { IoWarningOutline } from "react-icons/io5";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function UploadClaimModal({
  showUploadClaimModal,
  setShowUploadClaimModal,
  getUploadClaimHistory,
}) {
  const [loadingFile, setLoadingFile] = useState(false);

  // yyy
  const [uploadError, setUploadError] = useState(false);
  const [errors, setErrors] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const [files, setFiles] = useState(null);

  // const handleRemoveFile = () => {
  //   setFiles(null);
  //   formik.setFieldValue("file", null);
  // };

  const resetDragNdropRef = useRef(null);

  const handleRemoveFile = () => {
    formik.setFieldValue("file", null);
    if (resetDragNdropRef.current) {
      resetDragNdropRef.current.reset();
    }
  };

  const formik = useFormik({
    initialValues: {
      file: null,
    },

    validationSchema: () => {
      return Yup.object().shape({
        file: Yup.mixed()
          .required("Excel file required")
          .test(
            "fileFormat",
            "Invalid file format. Please upload an Excel file.",
            (value) => {
              if (value instanceof File) {
                return (
                  value.type === "application/vnd.ms-excel" ||
                  value.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                );
              }
              return false;
            }
          )
          .test(
            "fileSize",
            "File size is too large. Maximum size is 16MB.",
            (value) => {
              if (value instanceof File) {
                return value.size <= 16 * 1024 * 1024; // 16MB in bytes
              }
              return false;
            }
          ),
      });
    },

    onSubmit: async (values) => {
      // Retrieve the token from local storage
      const token = localStorage.getItem("token");
      const tokenWithoutQuotes = token.replace(/"/g, "");

      if (!token) {
        console.error("Token not found in local storage.");
        return;
      }

      // Create a FormData object
      const formData = new FormData();
      formData.append("file", values.file);

      // Make the API call using Axios
      // try {
      //   setLoadingFile(true);
      //   const response = await axios.post(
      //     "https://api.super-git.com/api/claim/excel",
      //     formData,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${tokenWithoutQuotes}`,
      //         "Content-Type": "multipart/form-data",
      //       },
      //     }
      //   );
      //   setLoadingFile(false);
      //   toast.success(response.data?.message);
      //   formik.resetForm();
      //   setShowUploadClaimModal(false);
      //   getUploadClaimHistory();
      //   // Handle success response
      // } catch (error) {
      //   setLoadingFile(false);
      //   console.log();
      //   toast.error(error?.response?.data?.error);
      //   // Handle error response
      // }

      // yyy
      try {
        setLoadingFile(true);
        const response = await axios.post(
          "https://api.super-git.com/api/claim/excel",
          formData,
          {
            headers: {
              Authorization: `Bearer ${tokenWithoutQuotes}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setLoadingFile(false);
        setUploadError(false);
        setErrors(null);
        setShowErrors(false);
        toast.success(response.data?.message);
        formik.resetForm();
        setShowUploadClaimModal(false);
        getUploadClaimHistory();
      } catch (error) {
        setLoadingFile(false);
        setUploadError(true);
        setErrors(error?.response?.data?.error);
        setShowErrors(false); // Don't show errors immediately
        console.log(error);
        toast.error("Upload failed. Click 'Get Errors' to see details.");
      }
    },
  });

  return (
    <>
      <div>
        <BootstrapDialog
          onClose={() => setShowUploadClaimModal(false)}
          aria-labelledby="customized-dialog-title"
          open={showUploadClaimModal}
          PaperProps={{
            className:
              "w-full !max-w-[750px] sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl ",
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Upload Claim
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setShowUploadClaimModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* <DialogContent dividers>
          <div className="benefeciary-create">
            <div className="personal-data flex flex-col gap-4  !shadow-none !border-none">
              {loadingFile && (
                <LinearProgress
                  sx={{
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#ffab2c",
                    },
                  }}
                />
              )}

              <div className="section">
                <DragNdrop formik={formik} width="100%" height="100%" />

                {formik.errors.file && formik.touched.file ? (
                  <p className=" max-w-2xl text-sm leading-6 text-red-500">
                    {formik.errors.file}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </DialogContent> */}

          {/* yyy */}
          <DialogContent dividers>
            <div className="benefeciary-create">
              <div className="personal-data flex flex-col gap-4  !shadow-none !border-none">
                {loadingFile && (
                  <LinearProgress
                    sx={{
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#ffab2c",
                      },
                    }}
                  />
                )}

                <div className="section">
                  {/* <DragNdrop
                    formik={formik}
                    width="100%"
                    height="100%"
                    onRemoveFile={handleRemoveFile}
                  /> */}

                  <DragNdrop
                    formik={formik}
                    width="100%"
                    height="100%"
                    onRemoveFile={handleRemoveFile}
                    ref={resetDragNdropRef}
                  />

                  {formik.errors.file && formik.touched.file ? (
                    <p className=" max-w-2xl text-sm leading-6 text-red-500">
                      {formik.errors.file}
                    </p>
                  ) : null}

                  {showErrors && errors && (
                    <div className="mt-4 p-4 bg-red-100 rounded-md">
                      <h4 className="text-red-700 font-semibold mb-2">
                        Errors:
                      </h4>
                      <p className="text-red-600">{errors}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>

          {/* <DialogActions>
          <Button
            autoFocus
            size="small"
            disabled={loadingFile}
            onClick={() => {
              formik.handleSubmit();
            }}
            className="!text-primary hover:!bg-primary/20"
          >
            {loadingFile ? "..." : "Upload"}
          </Button>
        </DialogActions> */}

          {/* yyy */}
          <DialogActions>
            {!uploadError ? (
              <Button
                autoFocus
                size="small"
                disabled={loadingFile}
                onClick={() => {
                  formik.handleSubmit();
                }}
                className="!text-primary hover:!bg-primary/20"
              >
                {loadingFile ? "..." : "Upload"}
              </Button>
            ) : (
              <>
                <Button
                  size="small"
                  onClick={() => {
                    // Show errors when the button is clicked
                    setShowErrors(true);
                  }}
                  className="!text-primary hover:!bg-primary/20"
                >
                  Get Errors
                </Button>
                <Button
                  size="small"
                  // onClick={() => {
                  //   setUploadError(false);
                  //   setErrors(null);
                  //   setShowErrors(false);
                  //   formik.resetForm();
                  // }}
                  onClick={handleClickOpen}
                  className="!text-red-500 hover:!bg-primary/20"
                >
                  Delete
                </Button>
              </>
            )}
          </DialogActions>
        </BootstrapDialog>

        {/* <AppModal isOpen={ loadingFile} /> */}
      </div>

      {/* dialog confirmation */}
      <div>
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              className="!text-red-500 !px-3 !py-1"
              id="alert-dialog-title"
            >
              {"Alert"}
            </DialogTitle>
            {/* <Divider /> */}
            <DialogContent className="!px-4 !py-5">
              <DialogContentText
                className="!text-black/70 flex"
                id="alert-dialog-description"
              >
                <IoWarningOutline className="!my-auto !text-primary text-2xl pr-1.5" />
                Do you really want to delete uploaded file?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className="!text-primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className="!text-red-500 !m-0"
                // onClick={handleClose}
                onClick={() => {
                  setUploadError(false);
                  setErrors(null);
                  setShowErrors(false);
                  formik.resetForm();
                  handleRemoveFile();
                  handleClose();
                }}
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </div>
    </>
  );
}
