import React from "react";
import { useState } from "react";
import ReactJsonView from "./ReactJsonView";

const JsonTable = ({ item }) => {
  const [showJsonView, setShowJsonView] = useState(false);
  const [jsonObject, setJsonObject] = useState(false);

  const showJson = (item) => {
    setJsonObject(item);
    setShowJsonView(true);
  };

  const onCloseShowJson = () => {
    setShowJsonView(false);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToJson = (item) => {
    downloadFile({
      data: JSON.stringify(JSON.parse(item.log)),
      fileName: `${item.type}.json`,
      fileType: "text/json",
    });
  };

  const requests = [
    {
      id: 1,
      transactionType: "eligibility-request",
      date: "06/02/23 01:23:22 PM",
      jsonResponse: {
        employees: [
          {
            id: 1,
            first_name: "John",
            last_name: "Doe",
            position: "Manager",
            email: "johndoe@example.com",
            phone_number: "555-555-5555",
            address: {
              street: "123 Main St",
              city: "Anytown",
              state: "CA",
              zip: "12345",
            },
          },
          {
            id: 2,
            first_name: "Jane",
            last_name: "Smith",
            position: "Developer",
            email: "janesmith@example.com",
            phone_number: "555-555-5556",
            address: {
              street: "456 Elm St",
              city: "Anytown",
              state: "NY",
              zip: "67890",
            },
          },
          {
            id: 3,
            first_name: "Bob",
            last_name: "Johnson",
            position: "Designer",
            email: "bobjohnson@example.com",
            phone_number: "555-555-5557",
            address: {
              street: "789 Oak St",
              city: "Anytown",
              state: "TX",
              zip: "11111",
            },
          },
        ],
      },
    },
    {
      id: 2,
      transactionType: "eligibility response",
      date: "06/02/23 01:23:22 PM",
      jsonResponse: {
        employees: [
          {
            id: 1,
            first_name: "John",
            last_name: "Doe 1",
            position: "Manager",
            email: "johndoe@example.com",
            phone_number: "555-555-5555",
            address: {
              street: "123 Main St",
              city: "Anytown",
              state: "CA",
              zip: "12345",
            },
          },
          {
            id: 2,
            first_name: "Jane",
            last_name: "Smith 1",
            position: "Developer",
            email: "janesmith@example.com",
            phone_number: "555-555-5556",
            address: {
              street: "456 Elm St",
              city: "Anytown",
              state: "NY",
              zip: "67890",
            },
          },
          {
            id: 3,
            first_name: "Bob",
            last_name: "Johnson 1",
            position: "Designer",
            email: "bobjohnson@example.com",
            phone_number: "555-555-5557",
            address: {
              street: "789 Oak St",
              city: "Anytown",
              state: "TX",
              zip: "11111",
            },
          },
        ],
      },
    },
  ];

  const formatDate = (backendDateTime) => {
    // Create a new Date object using the backend datetime value
    const date = new Date(backendDateTime);

    // Extract day, month, and year components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(date.getFullYear());

    // Format the components as "dd-mm-yyyy"
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <div className="table-responsive  ">
        <table className="table  table-hover table-auto ">
          <thead>
            <tr className=" ">
              <th className="py-2  ">Transaction Types</th>
              <th className="py-2 col-span-2 ">Date</th>
              <th className="py-2 "></th>
            </tr>
          </thead>
          <tbody>
            {item?.logs?.map((item, index) => {
              return (
                <tr key={`requests_${index}`}>
                  <td>{item.type}</td>
                  <td>{formatDate(item.timestamp)}</td>
                  <td className="flex gap-2">
                    <span
                      className="hover:cursor-pointer text-amber-700"
                      onClick={() => showJson(JSON.parse(item.log))}
                    >
                      View
                    </span>
                    <span
                      className="hover:cursor-pointer text-amber-700"
                      onClick={() => exportToJson(item)}
                    >
                      Download
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ReactJsonView
        showJsonView={showJsonView}
        onCloseShowJson={onCloseShowJson}
        jsonObject={jsonObject}
      />
    </>
  );
};

export default JsonTable;
