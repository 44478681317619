import React, { useState } from "react";
import { Formik, useFormikContext } from "formik";
import { Switch } from "@headlessui/react";
import _ from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function IsAccident({ onChange, refId }) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const accidentTypes = [
    { id: 1, name: "Motor Vehicle Accident" },
    { id: 2, name: "School Accident" },
    { id: 3, name: "Sporting Accident" },
    { id: 4, name: "Workplace Accident" },
  ];
  const countries = [
    { id: 1, name: "Afganistan" },
    { id: 2, name: "Saudia Arabia" },
    { id: 3, name: "Pakistan" },
    { id: 4, name: "UAE" },
    { id: 5, name: "India" },
  ];

  const subTypes = [{ id: 1, name: "OutPatient" }];

  const addData = (values) => {
    // setShowEligibilityModal(false);
    let data = _.cloneDeep(values);
    console.log("data :: ", data);
    // checkEligibility({ variables: { input: data } });
  };

  const formikRef = React.useRef();
  React.useImperativeHandle(refId, () => ({
    Submit: async () => {
      await formikRef.current.submitForm();
    },
  }));

  function watchForm(data) {
    if (onChange) {
      onChange({
        values: data,
      });
    }
  }

  return (
    <div class="personal-data !p-3 !mb-3 !bg-gray-100">
      <div class="personal-title !mb-3">
        <div class="inventory-svg">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="34" height="34" rx="8" fill="#F4F6F8"></rect>
            <path
              d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
              fill="#F5793B"
            ></path>
            <path
              d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
              fill="#F5793B"
            ></path>
          </svg>
        </div>
        <div class="personal-head">
          <span>Accident</span>
          <div className="flex space-x-3 md:mt-0 md:ml-4 mr-2">
            <Switch
              checked={values.is_accident}
              onChange={() => setFieldValue("is_accident", !values.is_accident)}
              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2"
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className="pointer-events-none absolute h-full w-full rounded-md bg-white"
              />
              <span
                aria-hidden="true"
                className={classNames(
                  values.is_accident ? "bg-amber-600" : "bg-gray-200",
                  "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                )}
              />
              <span
                aria-hidden="true"
                className={classNames(
                  values.is_accident ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
      {values.is_accident && (
        <div class="personal-fields grid grid-cols-1 lg:grid-cols-2 gap-2">
          <select
            name="accidentType"
            onChange={handleChange}
            value={values.accidentType}
            id="accident-type"
          >
            <option value={""}>Select Accident Type</option>
            {accidentTypes.map((item) => {
              return (
                <option value={item.name}>
                  <p>{item.name}</p>
                </option>
              );
            })}
          </select>
          <input
            type="text"
            name="street"
            value={values.street}
            onChange={handleChange}
            id=""
            placeholder="Street Name"
          />
          <input
            type="text"
            name="city"
            value={values.city}
            onChange={handleChange}
            id=""
            placeholder="Enter City Name"
          />
          <input
            type="text"
            name="state"
            value={values.state}
            onChange={handleChange}
            id=""
            placeholder="Enter State/Province"
          />
          <select
            name="country"
            onChange={handleChange}
            value={values.country}
            id="country"
          >
            <option value={""}>Select Country</option>
            {countries.map((item) => {
              return (
                <option value={item.name}>
                  <p>{item.name}</p>
                </option>
              );
            })}
          </select>
          <input
            type="date"
            name="date"
            value={values.date}
            onChange={handleChange}
            id="date"
            placeholder="Date"
          />
        </div>
      )}
    </div>
  );
}

export default IsAccident;
