import React, { useEffect } from "react";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import AddBeneficiaryPersonalDataForm from "./components/AddBeneficiaryPersonalDataForm";
import beneficiary from "../../gql/beneficiary";
import PatientDetailForm from "./components/PatientDetailForm";
import AppModal from "../../components/AppModal";
import toast from "react-hot-toast";
function EditBeneficiaryForm(props) {
  const location = useLocation();
  const item = location.state?.item ? location.state.item : null;

  const { loading, error, data, refetch } = useQuery(
    beneficiary.GET_PATIENT_BY_ID,
    {
      variables: { id: item?.id },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (error?.graphQLErrors[0]?.message) {
      toast.error(error?.graphQLErrors[0]?.message);
    }
  }, [error]);

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="superGit_container">
      <div class="benefeciary-create">
        <div class="benefeciary-top">
          <div class="back" onClick={handleGoBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                fill="#4D4D53"
              />
            </svg>
          </div>
          <div>
            <div class="benefeciary-title">
              <span>Edit Beneficiary</span>
            </div>
            <div class="benefeciary-content">
              <span>Edit beneficiary account details</span>
            </div>
          </div>
        </div>
        <PatientDetailForm />
        <AddBeneficiaryPersonalDataForm item={data?.getPatient?.data} />
      </div>
      <AppModal isOpen={loading} />
    </div>
  );
  return <></>;
}

export default EditBeneficiaryForm;
