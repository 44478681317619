import { Formik, useFormikContext } from "formik";
import { Switch } from "@headlessui/react";
import _ from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function EncounterForm({ onChange, refId }) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const status = [
    { id: "Planned", name: "Planned" },
    { id: "Arrived", name: "Arrived" },
    { id: "Triaged", name: "Triaged" },
    { id: "In Progress", name: "In Progress" },
    { id: "On Leave", name: "On Leave" },
    { id: "Finished", name: "Finished" },
    { id: "Cancelled", name: "Cancelled" },
    { id: "Entered in Error", name: "Entered in Error" },
    { id: "Unknown", name: "Unknown" },
  ];

  const classes = [
    { id: "Ambulatory", name: "Ambulatory" },
    { id: "Emergency", name: "Emergency" },
    { id: "Home Health", name: "Home Health" },
    { id: "Inpatient Encounter", name: "Inpatient Encounter" },
    { id: "Short Stay", name: "Short Stay" },
  ];

  const serviceTypes = [
    { id: "237 - Acute Inpatient Serv", name: "237 - Acute Inpatient Serv" },
    { id: "576 - Rehabilitation", name: "576 - Rehabilitation" },
    { id: "356 - General Maintenance", name: "356 - General Maintenance" },
    { id: "621 - Complex Maintenance", name: "621 - Complex Maintenance" },
    { id: "179 - Palliative Medicine", name: "179 - Palliative Medicine" },
  ];

  const adminSources = [
    { id: "IA - Immediate Admission", name: "IA - Immediate Admission" },
    {
      id: "EER - Admission from hospital ER",
      name: "EER - Admission from hospital ER",
    },
    {
      id: "EOP - Emergency Admission from hospital outpatient",
      name: "EOP - Emergency Admission from hospital outpatient",
    },
    {
      id: "EGPHC - Emergency Admission by referral from government primary healthcare center",
      name: "EGPHC - Emergency Admission by referral from government primary healthcare center",
    },
    {
      id: "EGGH - Emergency Admission by referral from general government hospital",
      name: "EGGH - Emergency Admission by referral from general government hospital",
    },
    {
      id: "EPPHC - Emergency Admission by referral from private primary healthcare center",
      name: "EPPHC - Emergency Admission by referral from private primary healthcare center",
    },
    {
      id: "EPH - Emergency Admission by referral from private hospital",
      name: "EPH - Emergency Admission by referral from private hospital",
    },
    {
      id: "EIC - Emergency Admission by insurance company",
      name: "EIC - Emergency Admission by insurance company",
    },
    {
      id: "EWGS - Elective waiting list admission government free Scheme",
      name: "EWGS - Elective waiting list admission government free Scheme",
    },
    {
      id: "EWSS - Elective waiting list admission self-payment Scheme",
      name: "EWSS - Elective waiting list admission self-payment Scheme",
    },
    {
      id: "EWIS - Elective waiting list admission insurance coverage Scheme",
      name: "EWIS - Elective waiting list admission insurance coverage Scheme",
    },
    {
      id: "EMBA - Emergency Maternity Birth Admission",
      name: "EMBA - Emergency Maternity Birth Admission",
    },
    {
      id: "PMBA - Planned Maternity Birth Admission",
      name: "PMBA - Planned Maternity Birth Admission",
    },
    { id: "Others - Others", name: "Others - Others" },
  ];

  const dischargeDispositions = [
    { id: "Home", name: "Home" },
    { id: "Alternative home", name: "Alternative home" },
    { id: "Other healthcare facility", name: "Other healthcare facility" },
    { id: "Hospice", name: "Hospice" },
    { id: "Long-term care", name: "Long-term care" },
    { id: "Left against advice", name: "Left against advice" },
    { id: "Expired", name: "Expired" },
    { id: "Psychiatric hospital", name: "Psychiatric hospital" },
    { id: "Rehabilitation", name: "Rehabilitation" },
    { id: "Skilled nursing facility", name: "Skilled nursing facility" },
    { id: "Other", name: "Other" },
  ];

  const reAdmission = [{ id: "re-admission", name: "re-admission" }];

  const priorities = [
    { id: "EL", name: "El- Elective" },
    { id: "EM", name: "EM - Emergency" },
  ];

  return (
    <div class="personal-data !p-3 !mb-3 !bg-gray-100">
      <div class="personal-title !mb-3">
        <div class="inventory-svg">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="34" height="34" rx="8" fill="#F4F6F8"></rect>
            <path
              d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
              fill="#F5793B"
            ></path>
            <path
              d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
              fill="#F5793B"
            ></path>
          </svg>
        </div>
        <div class="personal-head">
          <span>Encounter</span>
          <div className="flex space-x-3 md:mt-0 md:ml-4 mr-2">
            <Switch
              checked={values.isEncounter}
              onChange={() => setFieldValue("isEncounter", !values.isEncounter)}
              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2"
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className="pointer-events-none absolute h-full w-full rounded-md bg-white"
              />
              <span
                aria-hidden="true"
                className={classNames(
                  values.isEncounter ? "bg-amber-600" : "bg-gray-200",
                  "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                )}
              />
              <span
                aria-hidden="true"
                className={classNames(
                  values.isEncounter ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
      {values.isEncounter && (
        <>
          <div class="personal-fields grid grid-cols-1 lg:grid-cols-2 gap-2">
            <select
              name="encounterStatus"
              onChange={handleChange}
              value={values.encounterStatus}
              id="status"
            >
              <option value={null}>Status</option>
              {status.map((item) => {
                return (
                  <option value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
            <select
              name="encounterClass"
              onChange={handleChange}
              value={values.encounterClass}
              id="class"
            >
              <option value="">Class</option>
              {classes.map((item) => {
                return (
                  <option value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
            <select
              name="serviceType"
              onChange={handleChange}
              value={values.serviceType}
              id="service-type"
            >
              <option value="">Service-type</option>
              {serviceTypes.map((item) => {
                return (
                  <option value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
            <select
              name="encounterPriority"
              onChange={handleChange}
              value={values.encounterPriority}
              id="priority"
            >
              <option value="">Priority *</option>
              {priorities.map((item) => {
                return (
                  <option value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
            <input
              type="date"
              name="periodStart"
              value={values.periodStart}
              onChange={handleChange}
              id="date"
              placeholder="Period Start Date *"
            />
            <input
              type="date"
              name="periodEnd"
              value={values.periodEnd}
              onChange={handleChange}
              id="date"
              placeholder="Period End Date *"
            />
          </div>
          <div class="encounter-fields">
            <span>Encounter</span>
          </div>
          <div class="personal-fields grid grid-cols-1 lg:grid-cols-2 gap-2">
            <select
              name="adminSource"
              onChange={handleChange}
              value={values.adminSource}
              id="adminsource"
            >
              <option value="">Admin Source</option>
              {adminSources.map((item) => {
                return (
                  <option value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
            <select
              name="readmission"
              onChange={handleChange}
              value={values.readmission}
              id="readmission"
            >
              <option value="">Readmission</option>
              {reAdmission.map((item) => {
                return (
                  <option value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
            <select
              name="dischargeDisposition"
              onChange={handleChange}
              value={values.dischargeDisposition}
              id="discharge-disposition"
            >
              <option value="">Discharge Disposition</option>
              {dischargeDispositions.map((item) => {
                return (
                  <option value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
          </div>
        </>
      )}
    </div>
  );
}

export default EncounterForm;
