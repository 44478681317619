import React from "react";

function Coverages({ item }) {
  function getDateDifferenceFormatted(dateRangeString) {
    if (dateRangeString) {
      // Split the date range string into start and end date strings
      const [startDateString, endDateString] = dateRangeString.split(" - ");

      // Convert the start and end date strings to Date objects
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);

      // Format the dates as strings
      const formattedStartDate = startDate.toLocaleDateString();
      const formattedEndDate = endDate.toLocaleDateString();

      return `${formattedStartDate} - ${formattedEndDate}`;
    } else {
      return "___";
    }
  }

  return (
    <>
      <div class="eligibility_details_section">
        <div class="eligibility_details_head">
          <div class="eligibility_details_body_cont !grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 !p-0 !border-none !shadow-none">
            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>In Force</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.in_force || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Benefit Date</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {getDateDifferenceFormatted(item?.benefit_date)}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Member ID</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.member_id || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Policy Number</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.policy_number || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Policy Holder</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.policy_holder || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Type</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.type || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Status</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.status || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Relation With Subscriber</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.relation_with_subscriber || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data   px-3 py-6">
              <div class="data_head !text-center">
                <span>Network</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.network || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data   px-3 py-6">
              <div class="data_head !text-center">
                <span>Site Eligibility</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.site_eligibility || "___"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="eligibility_details_section">
        <div class="eligibility_details_head">
          <div class="eligibility_details_head_cont">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="34" height="34" rx="8" fill="#4D4D53" />
              <path
                d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                fill="white"
              />
              <path
                d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                fill="white"
              />
              <path
                d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                fill="white"
              />
            </svg>
            <span>Classes</span>
          </div>
          <div class="eligibility_details_body_cont">
            <div class="eligibility_details_body_cont_inner !grid !grid-cols-3 row_1">
              <div class="eligibility_details_user_data">
                <div class="data_head">
                  <span>Type</span>
                </div>
                <div class="data_cont">
                  <span>{item?.class_type}</span>
                </div>
              </div>
              <div class="eligibility_details_user_data">
                <div class="data_head">
                  <span>Name</span>
                </div>
                <div class="data_cont">
                  <span>{item?.class_name}</span>
                </div>
              </div>
              <div class="eligibility_details_user_data">
                <div class="data_head">
                  <span> Value</span>
                </div>
                <div class="data_cont">
                  <span>{item?.class_value}</span>
                </div>
              </div>
            </div>

            <div class="eligibility_details_body_cont_inner !grid !grid-cols-3 row_2">
              <div class="eligibility_details_user_data">
                <div class="data_head">
                  <span>Type</span>
                </div>
                <div class="data_cont">
                  <span>{item?.class_group_type}</span>
                </div>
              </div>
              <div class="eligibility_details_user_data">
                <div class="data_head">
                  <span>Name</span>
                </div>
                <div class="data_cont">
                  <span>{item?.class_group_name}</span>
                </div>
              </div>
              <div class="eligibility_details_user_data">
                <div class="data_head">
                  <span>Value</span>
                </div>
                <div class="data_cont">
                  <span>{item?.class_group_value}</span>
                </div>
              </div>
            </div>

            <div class="eligibility_details_body_cont_inner !grid !grid-cols-3 row_3">
              <div class="eligibility_details_user_data">
                <div class="data_head">
                  <span>Type</span>
                </div>
                <div class="data_cont">
                  <span>{item?.class_plan_type}</span>
                </div>
              </div>
              <div class="eligibility_details_user_data">
                <div class="data_head">
                  <span>Name</span>
                </div>
                <div class="data_cont">
                  <span>{item?.class_plan_name}</span>
                </div>
              </div>
              <div class="eligibility_details_user_data">
                <div class="data_head">
                  <span>Value</span>
                </div>
                <div class="data_cont">
                  <span>{item?.class_plan_value}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Coverages;
