import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { MdDownload, MdOutlineRemoveRedEye } from "react-icons/md";
import ReactJsonView from "../Eligibility/components/ReactJsonView";
import claims from "../../gql/claims";
import ReactJsonTree from "../Eligibility/components/ReactJsonTree";
import { Tooltip } from "@mui/material";
import axios from "axios";
import Pagination from "../../components/Pagination";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClaimUploadErrorModal({
  open,
  setOpen,
  handleClose,
  selectedItem,
}) {
  const [item, setItem] = useState(null);
  const [showJsonView, setShowJsonView] = useState(false);
  const [jsonObject, setJsonObject] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);
const itemsPerPage = 5; // You can adjust this value as needed

  const [loadingFile, setLoadingFile] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  // console.log(selectedItem);
  const [jsonDialogOpen, setJsonDialogOpen] = useState(false);

//   const getUploadErrors = async (data) => {
//     const token = localStorage.getItem("token");
//     const tokenWithoutQuotes = token ? token.replace(/"/g, "") : "";

//     if (!token) {
//       console.error("Token not found in local storage.");
//       return;
//     }

//     try {
//       setLoadingFile(true);
//       const response = await axios.get(
//         `https://api.super-git.com/api/claim/excel/errors?excel_id=${selectedItem.ReferenceNo}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${tokenWithoutQuotes}`,
//           },
//         }
//       );
//       setLoadingFile(false);
//       setUploadData(response?.data?.data);
//     } catch (error) {
//       setLoadingFile(false);
//       toast.error(error?.response?.data?.error);
//     }
//   };

//   useEffect(() => {
//     if (selectedItem && selectedItem.ReferenceNo) {
//       getUploadErrors({ pages: 1, count: 5 });
//     }
//   }, [selectedItem]);

const getUploadErrors = async (page) => {
    const token = localStorage.getItem("token");
    const tokenWithoutQuotes = token ? token.replace(/"/g, "") : "";
  
    if (!token) {
      console.error("Token not found in local storage.");
      return;
    }
  
    try {
      setLoadingFile(true);
      const response = await axios.get(
        `https://api.super-git.com/api/claim/excel/errors?excel_id=${selectedItem.ReferenceNo}&page=${page}&limit=${itemsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${tokenWithoutQuotes}`,
          },
        }
      );
      setLoadingFile(false);
      setUploadData(response?.data?.data);
      setTotalPages(Math.ceil(response?.data?.count / itemsPerPage));
    } catch (error) {
      setLoadingFile(false);
      toast.error(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (selectedItem && selectedItem.ReferenceNo) {
      getUploadErrors(currentPage);
    }
  }, [selectedItem, currentPage]);

  const handlePageChange = (newPage) => {
    console.log(newPage);
    setCurrentPage(newPage);
  };


  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          jsonDialogOpen ? setJsonDialogOpen(false) : handleClose();
        }}
        TransitionComponent={Transition}
        style={{ background: "#ffab2c", zIndex: 100 }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar className="" style={{ background: "#ffab2c" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                jsonDialogOpen ? setJsonDialogOpen(false) : handleClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Claim Upload Errors
            </Typography>
            <Button
              autoFocus
              color="inherit"
              size="small"
              onClick={() => {
                jsonDialogOpen ? setJsonDialogOpen(false) : handleClose();
              }}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        {loadingFile ? (
          <div>Loading...</div>
        ) : (
        <>
          {jsonDialogOpen ? (
            <div>
              <ReactJsonTree data={jsonObject} />
            </div>
          ) : (
            <div className="super-git-table pt-4 !pb-12 px-8 mt-3">
              <table className="table-super-git">
                <thead className="!p-0">
                  <tr className="!p-0">
                    <th className="!p-3">SQ #</th>
                    <th className="!p-3">Sheet Name</th>
                    <th className="!p-3">Column Name</th>
                    <th className="!p-3">Row Number</th>
                    <th className="!p-3">Error</th>
                  </tr>
                </thead>

                <tbody>
                  {uploadData.map((item, index) => {
                    return (
                      <tr className="!p-0" key={`requests_${index}`}>
                        <td className="!p-2">{index + 1}</td>
                        <td className="!p-2">{item?.SheetName}</td>
                        <td className="!p-2">{item.ColumnName}</td>
                        <td className="!p-2">{item.RowNumber}</td>
                        <td className="!p-2">{item.Error}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
            </div>
          )}
        </>
       )}
      </Dialog>
    </div>
  );
}
