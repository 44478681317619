import { gql } from "@apollo/client";

const GET_DASHBOARD_STATS = gql`
  query getDashboardStats($filters: DashboardStatsFilter) {
    getDashboardStats(filters: $filters) {
      Status
      Message
      Data {
        ActiveProviders
        NonActiveProviders
        Payers
        Patients
        ActiveUsers
        InactiveUsers
        ActiveEligibility
        NotActiveEligibility
        AcceptedClaims
        PartialClaims
        RejectedClaims
        PendingClaims
        AcceptedPreAuth
        PartialPreAuth
        RejectedPreAuth
        PendingPreAuth
      }
    }
  }
`;

const DASHBOARD_STATS = gql`
  query getDashboardStats($filters: DashboardStatsFilterInput) {
    getDashboardStats(filters: $filters) {
      status
      message
      data {
        patients
        total_claims
        rejected_claims
        accepted_claims
        partial_claims
        pending_claims
        cancelled_claims
        errored_claims
        total_verification
        active_verification
        not_active_verification
        total_pre_auth
        rejected_pre_auth
        accepted_pre_auth
        partial_pre_auth
        pending_pre_auth
        errored_pre_auth
      }
    }
  }
`;

export default {
  GET_DASHBOARD_STATS,
  DASHBOARD_STATS,
};
