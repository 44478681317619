import React, { useState, useContext, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik"; 
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import claims from "../../../gql/claims";
import Select from 'react-select';
import AppModal from "../../../components/AppModal";

const BulkCancellationSchema = Yup.object().shape({
  batch_no: Yup.string().required("Batch Number is required"),
  status: Yup.array()
  .of(Yup.string().required("Each status must be a string"))
  .required("Status is required")
  .min(1, "At least one status is required"),
  limit: Yup.number()
    .integer("Limit must be an integer")
    .positive("Limit must be a positive number")
    .required("Limit is required"),
});

export default function BulkCancellation() {
  const [showLoader, setShowLoader] = useState(false);
  const [submitEntry, { loading: submitLoading }] = useMutation(
    claims.BULK_CANCELLATION,
    {
      update(proxy, result) {
        setShowLoader(false);
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        setShowLoader(false);
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  const onSuccessfulRequestResponse = (data) => {
    if (data?.bulkCancelRequest) {
      toast.success(data?.bulkCancelRequest?.message);
    } else {
      toast.error("An error occurred while cancelling the claims.");
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error?.message}`);
  };


const statusOptions = [
  { value: "pended", label: "Pended" },
  { value: "rejected", label: "Rejected" },
  { value: "approved", label: "Approved" }, 
  { value: "partial Approved", label: "Partial Approved" },
  { value: "pending", label: "Pending" }
];


  const handleBulkCancellation = async (values, { resetForm }) => {
    setShowLoader(true);
    try {
      await submitEntry({
        variables: {
          input: {
            batch_no: values.batch_no,
            limit: parseInt(values.limit),
            status: values.status,  
          },
        },
      });
      resetForm();
    } catch (error) {
      console.error("Error submitting bulk cancellation:", error);
    }
  };

  return (
    <Formik
    initialValues={{ batch_no: "", limit: "", status: [] }}
    validationSchema={BulkCancellationSchema}
    onSubmit={handleBulkCancellation}
  >
    {({ errors, touched, setFieldValue }) => (
      <Form>
        <div className="w-1/2">
          <div className="details-fields grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4 mb-4 mt-8">
            <div className="flex gap-1 flex-col">
              <label className="text-sm">Batch Number</label>
              <Field
                placeholder="Enter Batch number"
                type="text"
                name="batch_no"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              {errors.batch_no && touched.batch_no ? (
                <p className="max-w-2xl text-sm leading-6 text-red-500">
                  {errors.batch_no}
                </p>
              ) : null}
            </div>

            <div className="flex gap-1 flex-col">
              <label className="text-sm">Limit (recommended 100)</label>
              <Field
                placeholder="Enter Limit"
                type="text"
                name="limit"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              {errors.limit && touched.limit ? (
                <p className="max-w-2xl text-sm leading-6 text-red-500">
                  {errors.limit}
                </p>
              ) : null}
            </div>

            <div className="flex gap-1 flex-col">
              <label className="text-sm">Select Muliple Statuses</label>
              <Select
                isMulti
                name="status"
                options={statusOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) => {
                  setFieldValue(
                    "status",
                    selectedOptions.map((option) => option.value)
                  );
                }}
              />
              {errors.status && touched.status ? (
                <p className="max-w-2xl text-sm leading-6 text-red-500">
                  {errors.status}
                </p>
              ) : null}
            </div>
          </div>

          <div className="flex items-end mb-0.5 justify-end">
            <Button
              variant="contained"
              type="submit"
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<CloseIcon />}
              size="small"
            >
              {submitLoading ? "Submitting..." : "Submit"}
            </Button>
          </div>
          <AppModal isOpen={showLoader || submitLoading} />
        </div>
      </Form>
    )}
  </Formik>
  );
}
