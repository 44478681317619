import { Chip, Tooltip } from "@mui/material";
import React from "react";

function Details({ item }) {
  const getColor = (status) => {
    if (status === "rejected") {
      return "error";
    } else if (status === "approved") {
      return "success";
    } else if (status === "pending") {
      return "info";
    } else if (status === "queued") {
      return "warning";
    } else if (status === "complete") {
      return "success";
    } else if (status === "error") {
      return "error";
    } else if (status === "Abnormal Request") {
      return "error";
    } else if (status === "not-required") {
      return "warning";
    } else {
      return "secondary";
    }
  };
  return (
    <div class="eligibility_details_section">
      <div class="eligibility_details_head">
        {item?.errors?.map((el, index) => {
          return (
            <div className="sm:col-span-6">
              <div class="my-2 p-4 text-sm text-red-800 rounded-lg bg-red-50 dark:text-red-400">
                <span class="font-medium">Error {el?.error_code} : </span>
                {el?.error_message}
              </div>
            </div>
          );
        })}

        <div class="eligibility_details_body_cont !grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 !p-0 !border-none !shadow-none">
          <div class="eligibility_details_user_data  border-b px-3 py-6">
            <div class="data_head !text-center">
              <span>Site Eligibility</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.site_eligibility || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data  border-b px-3 py-6">
            <div class="data_head !text-center">
              <span>Beneficiary Name</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.patient_name || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data  border-b px-3 py-6">
            <div class="data_head !text-center">
              <span>Document</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.document_id || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data  border-b px-3 py-6">
            <div class="data_head !text-center">
              <span>Insurer</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.payer_name || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data  border-b px-3 py-6">
            <div class="data_head !text-center">
              <span>TPA Name</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.tpa_name || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data  border-b px-3 py-6">
            <div class="data_head !text-center">
              <span>Service Date</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.service_date || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data  border-b px-3 py-6">
            <div class="data_head !text-center">
              <span>Purpose</span>
            </div>
            <div class="data_cont !text-center">
              <span className="!text-base !font-semibold">
                {item?.purpose || "___"}
              </span>
            </div>
          </div>

          <div class="eligibility_details_user_data border-b px-3 pt-6 ">
            <div class="data_head !text-center">
              <span>Is Referral</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.is_referral ? "Yes" : "No"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data px-3 pt-6 ">
            <div class="data_head !text-center">
              <span>Is New Born</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.is_new_born ? "Yes" : "No"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data px-3 pt-6 ">
            <div class="data_head !text-center">
              <span>Eligibility Response Identifier (Value)</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.verification_resp_ident_value || "___"}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data px-3 pt-6 ">
            <div class="data_head !text-center">
              <span>Eligibility Response Identifier (Url)</span>
            </div>
            <div class="data_cont !text-center">
              <span class="data_cont !text-base !font-semibold">
                {item?.verification_resp_ident_url?.length > 30 ? (
                  <Tooltip title={item?.verification_resp_ident_url}>
                    <span>
                      {item?.verification_resp_ident_url.slice(0, 30)}...
                    </span>
                  </Tooltip>
                ) : (
                  item?.standard_description || "___"
                )}
              </span>
            </div>
          </div>
          <div class="eligibility_details_user_data  px-3 py-6">
            <div class="data_head !text-center">
              <span>Outcome</span>
            </div>
            <div class=" !text-white  !text-center">
              <p class=" !text-white !text-base !font-semibold">
                {item?.outcome ? (
                  <Chip
                    label={item?.outcome}
                    color={getColor(item?.outcome)}
                    size="small"
                    className="uppercase text-xs "
                  />
                ) : (
                  "___"
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
