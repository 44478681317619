import React from "react";
import "../../styles/pre-auth.css";

const Encounter = ({ item }) => {
  return (
    <div class="pre_auth_communication_page">
      <div class="pre_auth_encounter_section">
        <div class="eligibility_details_head">
          <div class="pre_auth_encounter_head_cont">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="34" height="34" rx="8" fill="#4D4D53" />
              <path
                d="M24 9H19.82C19.4 7.84 18.3 7 17 7C15.7 7 14.6 7.84 14.18 9H10C8.9 9 8 9.9 8 11V25C8 26.1 8.9 27 10 27H24C25.1 27 26 26.1 26 25V11C26 9.9 25.1 9 24 9ZM17 8.75C17.41 8.75 17.75 9.09 17.75 9.5C17.75 9.91 17.41 10.25 17 10.25C16.59 10.25 16.25 9.91 16.25 9.5C16.25 9.09 16.59 8.75 17 8.75ZM24 25H10V11H24V25Z"
                fill="white"
              />
              <path
                d="M20.08 17.03L17.96 14.91L12 20.86V23H14.1L20.08 17.03Z"
                fill="white"
              />
              <path
                d="M21.85 15.27C22.05 15.07 22.05 14.76 21.85 14.56L20.44 13.15C20.24 12.95 19.93 12.95 19.73 13.15L18.67 14.21L20.79 16.33L21.85 15.27Z"
                fill="white"
              />
            </svg>
            <span>Encounter</span>
          </div>
          <div class="pre_auth_encounter_body_cont">
            <div class="pre_auth_encounter_body_cont_inner row_1">
              <div class="pre_auth_encounter_user_data width_247">
                <div class="data_head">
                  <span>Status</span>
                </div>
                <div class="data_cont">
                  <span>{item?.status ? item?.status : "-"} </span>
                </div>
              </div>
              <div class="pre_auth_encounter_user_data width_254">
                <div class="data_head">
                  <span>Service Type</span>
                </div>
                <div class="data_cont">
                  <span>{item?.service_type ? item?.service_type : ""} </span>
                </div>
              </div>
              <div class="pre_auth_encounter_user_data width_239">
                <div class="data_head">
                  <span>Class</span>
                </div>
                <div class="data_cont">
                  <span>{item?.class ? item?.class : ""} </span>
                </div>
              </div>
              <div class="pre_auth_encounter_user_data width_161">
                <div class="data_head">
                  <span>Priority</span>
                </div>
                <div class="data_cont">
                  <span>{item?.priority ? item?.priority : ""} </span>
                </div>
              </div>
            </div>
            <div class="pre_auth_encounter_body_cont_inner row_2">
              <div class="pre_auth_encounter_user_data width_247">
                <div class="data_head">
                  <span>PeriodStart</span>
                </div>
                <div class="data_cont">
                  <span>{item?.period_start ? item?.period_start : ""} </span>
                </div>
              </div>
              <div class="pre_auth_encounter_user_data width_254">
                <div class="data_head">
                  <span>PeriodEnd</span>
                </div>
                <div class="data_cont">
                  <span>{item?.period_end ? item?.period_end : ""} </span>
                </div>
              </div>
              <div class="pre_auth_encounter_user_data width_239">
                <div class="data_head">
                  <span>AdminSource</span>
                </div>
                <div class="data_cont">
                  <span>{item?.admin_source ? item?.admin_source : ""} </span>
                </div>
              </div>
              <div class="pre_auth_encounter_user_data width_161">
                <div class="data_head">
                  <span>Readmission</span>
                </div>
                <div class="data_cont">
                  <span>{item?.readmission ? item?.readmission : ""} </span>
                </div>
              </div>
            </div>
            <div class="pre_auth_encounter_body_cont_inner row_3">
              <div class="pre_auth_encounter_user_data width_247">
                <div class="data_head">
                  <span>DischargeDisposition</span>
                </div>
                <div class="data_cont">
                  <span>
                    {item?.discharge_disposition
                      ? item.discharge_disposition
                      : ""}{" "}
                  </span>
                </div>
              </div>
              {/* <div class="pre_auth_encounter_user_data width_254">
              <div class="data_head">
                <span>Eligibility Purpose Identifier (Value)</span>
              </div>
              <div class="data_cont">
                <span>9fa1cbea-9dfa-4c4e-b7cd-cd74825ba2aa</span>
              </div>
            </div> */}
              {/* <div class="pre_auth_encounter_user_data width_239">
              <div class="data_head">
                <span>Eligibility Purpose Identifier (Url)</span>
              </div>
              <div class="data_cont">
                <span>
                  http://pseudo-payer.com.sa/coverageeligibilityresponse
                </span>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      {/* <div className="flex justify-between px-4 py-2 sm:px-6">
          <div className="pt-2 pb-2">
        <h4 className="font-bold text-xl text-cyan-600">Total</h4>
         </div>
          </div> */}
      <div className="border-t border-gray-200 px-4 py-2 sm:px-6">
        {/* DOWN WORK */}
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Status</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 sName="text-xl text-gray-600">
                {item?.status ? item?.status : "-"}{" "}
              </h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">ServiceType</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 className="text-xl text-gray-600">
                {item?.service_type ? item?.service_type : ""}{" "}
              </h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Class</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 className="text-xl text-gray-600">
                {item?.class ? item?.class : ""}{" "}
              </h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Priority</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 className="text-xl text-gray-600">
                {item?.priority ? item?.priority : ""}{" "}
              </h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">PeriodStart</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 className="text-xl text-gray-600">
                {item?.period_start ? item?.period_start : ""}{" "}
              </h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">PeriodEnd</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 className="text-xl text-gray-600">
                {item?.period_end ? item?.period_end : ""}{" "}
              </h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">AdminSource</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 className="text-xl text-gray-600">
                {item?.admin_source ? item?.admin_source : ""}{" "}
              </h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Readmission</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 className="text-xl text-gray-600">
                {item?.readmission ? item?.readmission : ""}{" "}
              </h2>
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              DischargeDisposition
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              <h2 className="text-xl text-gray-600">
                {item?.discharge_disposition ? item.discharge_disposition : ""}{" "}
              </h2>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default Encounter;
