import React from "react";

function Coverages({ item }) {
  const tableHeaders = [
    {
      name: "Type",
    },
    {
      name: "Name",
    },
    {
      name: "Value",
    },
  ];

  const costBeneficiariesData = [
    {
      type: "plan",
      name: "",
      value: "22001814",
    },
    {
      type: "class",
      name: "1",
      value: "individual",
    },
    {
      type: "class",
      name: "A-1st Class",
      value: "A",
    },
  ];
  return (
    <div className="row p-2">
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card bg-white shadow">
          <div className="card-body rounded-md">
            <h4 className="font-bold text-xl text-cyan-600 py-3">Coverages</h4>
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">in_force</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.in_force}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Benefit Date</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.benefit_date}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Member ID</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.member_id}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                Policy Number
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.policy_number}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                   Type
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.type}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                Status
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.status}
                </dd>
              </div>
              {/* MY WORK */}
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Relation With Subscriber</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.relation_with_subscriber}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Subscriber Member ID</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.subscriber_member_id}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  Network
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.network}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                site_eligibility
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {item?.site_eligibility}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coverages;
