import React, { useState } from "react";
import ReactJsonView from "./ReactJsonView";

function ResponseDetailsItemsAccordian({ item }) {
  const [showJsonView, setShowJsonView] = useState(false);
  const [jsonObject, setJsonObject] = useState(false);
  const showJson = (item) => {
    setJsonObject(item);
    setShowJsonView(true);
  };

  const onCloseShowJson = () => {
    setShowJsonView(false);
  };

  // This is the LAST ACCORDIAN DATA
  const tableHeaderTwo = [
    {
      name: "Item Name",
    },
    {
      name: "Description",
    },
    {
      name: "Network",
    },
    {
      name: "Unit",
    },
    {
      name: "Term",
    },
    {
      name: "Benefits",
    },
  ];
  const tableDataTwo = [
    {
      itemName: "Acquired Heart Diseases",
      description: "Out Mininum Network Clinic",
      network: "In",
      unit: "Indiviual",
      term: "Annual",
    },
    {
      itemName: "Acquired Heart Diseases 2",
      Description: "Out Mininum Network Clinic",
      network: "In",
      unit: "Indiviual",
      term: "Annual",
    },
    {
      itemName: "Acquired Heart Diseases 3",
      Description: "Out Mininum Network Clinic",
      network: "In",
      unit: "Indiviual",
      term: "Annual",
    },
    {
      itemName: "Acquired Heart Diseases 4",
      Description: "Out Mininum Network Clinic",
      network: "In",
      unit: "Indiviual",
      term: "Annual",
    },
  ];
  const accordianItems = [
    {
      id: 1,
      name: "Acquired Valvular Hearth Diseases",
    },
    {
      id: 2,
      name: "Autism Managment",
    },
    {
      id: 3,
      name: "Circumcision of Male newborn",
    },
  ];
  // This is the LAST ACCORDIAN DATA

  return (
    <div className="row p-2">
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card bg-white shadow">
          <div className="card-body rounded-md">
            <h4 className="card-title">Items</h4>
            <div class="accordion " id="accordionExample">
              <div class="accordion-item bg-white">
                <h2 class="accordion-header mb-0 ptpa-dropdown-icons">
                  <button
                    class="accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#tpaHeading`}
                    aria-expanded="false"
                    aria-controls={`tpaHeading`}
                  >
                    {item?.Coverages?.Type}
                  </button>
                </h2>
                <div
                  id={`tpaHeading`}
                  class="accordion-collapse collapse"
                  aria-labelledby={`tpaHeadingLabel`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="table-responsive">
                    <table className="table table-hover table-fixed">
                      <thead>
                        <tr>
                          {tableHeaderTwo.map((el, index) => {
                            return (
                              <th
                                className={`py-2 w-1/${tableDataTwo.length}`}
                                key={"tableHeaders_" + index}
                              >
                                {el.name}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {item?.Coverages?.Items?.map((ele, index) => {
                          return (
                            <tr key={"hospitalsData_" + index}>
                              <td className="whitespace-normal break-all pr-2">
                                {ele.CategoryName}
                              </td>
                              <td className="whitespace-normal break-all pr-2">
                                {ele.Description}
                              </td>
                              <td className="whitespace-normal break-all pr-2">
                                {ele.Network}
                              </td>
                              <td className="whitespace-normal break-all pr-2">
                                {ele.Unit}
                              </td>
                              <td className="whitespace-normal break-all pr-2">
                                {ele.Term}
                              </td>
                              <td>
                                <div className="dropdown">
                                  <div
                                    id="dropdownMenuIconButton1"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <i className="ti-arrow-circle-down"></i>
                                    {/* <i className="mdi mdi-arrow-down-drop-circle"></i> */}
                                  </div>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuIconButton1"
                                  >
                                    <h6
                                      className="dropdown-header"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => showJson(ele.Benefits)}
                                    >
                                      Show Benefits
                                    </h6>
                                    <h6
                                      className="dropdown-header"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      Edit
                                    </h6>
                                    <h6
                                      className="dropdown-header"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      Delete
                                    </h6>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ReactJsonView
        showJsonView={showJsonView}
        onCloseShowJson={onCloseShowJson}
        jsonObject={jsonObject}
      /> */}
    </div>
  );
}

export default ResponseDetailsItemsAccordian;
