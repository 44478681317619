import { useState, useContext } from "react";
import { appContext } from "../context/ContextProvider";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import TopNavBar from "../components/TopNavBar";
import MobileNavBar from "../components/MobileNavBar";
import Router from "../router/Router";
import { useLocation, useParams } from "react-router-dom";
// import { ToastContainer } from "react-toastify";

import Header from "../components/header/Header";
import BeneficiariesTable from "../pages/Beneficiary/BeneficiariesTable";
import Footer from "../components/Footer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

//
function MainFlow() {
  const { sidebarOpen, setSidebarOpen } = useContext(appContext);

  const location = useLocation();

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <div>
          <Header />
          <div className="pt-4 !pb-12 px-8 ">
            <Router />
          </div>
        </div>
        <footer
          style={{
            backgroundColor: "#fcfdff", // Change background color to white
            color: "#333",
            padding: "10px",
            textAlign: "center",
            position: "absolute",
            bottom: 0,
            width: "100%",
            marginTop: "20px",
            boxShadow: "0px 4px 20px 4px rgba(1, 70, 151, 0.06)", // Add a shadow
          }}
          className=""
        >
          <div className="block md:flex justify-between mx-4">
            <p className="text-sm">
              &copy; 2024 Super Git. All rights reserved.
            </p>
            <p className="bg-gray-100 py-1.5 px-3 text-gray-600 text-xs font-semibold rounded-full">
              Super Git v2.1.4
            </p>
          </div>
        </footer>
      </div>
    </>
  );

  return (
    <div className="min-h-full">
      <div className="bg-cyan-600 pb-32">
        <Disclosure
          as="nav"
          className="border-b border-cyan-300 border-opacity-25 bg-cyan-600 lg:border-none"
        >
          {({ sidebarOpen }) => (
            <>
              <TopNavBar />

              <MobileNavBar />
            </>
          )}
        </Disclosure>
        <header className="py-10">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-white">
              {toTitleCase(location.pathname.split("/")[1])}
            </h1>
          </div>
        </header>
      </div>

      <main className="-mt-32">
        {/* <ToastContainer /> */}
        <div className="mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <Router />
        </div>
      </main>
    </div>
  );
}

export default MainFlow;
