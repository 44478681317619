import React from "react";

function AccordianBenefitDetails({ item }) {
  return (
    <div class="eligibility_details_body !grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
      <div class="eligibility_details_user_data   px-3 py-6">
        <div class="data_head !text-center">
          <span>Allowed Money</span>
        </div>
        <div class="data_cont !text-center">
          <span className="!text-base !font-semibold">
            {item?.allowedMoney?.value || "___"} -{" "}
            {item?.allowedMoney?.currency || "___"}
          </span>
        </div>
      </div>
      <div class="eligibility_details_user_data  px-3 py-6">
        <div class="data_head !text-center">
          <span>Code</span>
        </div>
        <div class="data_cont !text-center">
          <span className="!text-base !font-semibold">
            {item?.type?.coding?.[0]?.code ?? "___"}
          </span>
        </div>
      </div>
      <div class="eligibility_details_user_data  px-3 py-6">
        <div class="data_head !text-center">
          <span>Used Money</span>
        </div>
        <div class="data_cont !text-center">
          <span className="!text-base !font-semibold">
            {item?.usedMoney?.value || "___"} -{" "}
            {item?.usedMoney?.currency || "___"}
          </span>
        </div>
      </div>
      {/* <div class="eligibility_details_user_data width_247">
        <div class="data_head">
          <span>Allowed Money</span>
        </div>
        <div class="data_cont">
          <span>
            {item?.allowedMoney?.value ?? ""} -{" "}
            {item?.allowedMoney?.currency ?? ""}
          </span>
        </div>
      </div>
      <div class="eligibility_details_user_data width_254">
        <div class="data_head">
          <span>Code</span>
        </div>
        <div class="data_cont">
          <span>{item?.type?.coding?.[0]?.code ?? ""}</span>
        </div>
      </div>
      <div class="eligibility_details_user_data width_239">
        <div class="data_head">
          <span>Used Money</span>
        </div>
        <div class="data_cont">
          <span>
            {" "}
            {item?.usedMoney?.value ?? ""} - {item?.usedMoney?.currency ?? ""}
          </span>
        </div>
      </div> */}
    </div>
  );
}

export default AccordianBenefitDetails;
