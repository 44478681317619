import React, { useEffect, useState } from "react";
import { Formik, FieldArray, Field, useFormik } from "formik";
// import { ToastContainer, toast } from "react-toastify";
import toast from "react-hot-toast";
import _, { set } from "lodash";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import beneficiary from "../../../gql/beneficiary";
import InsurancePlans from "./InsurancePlans";
import { Switch } from "@headlessui/react";
import SubscriberDetails from "./SubscriberDetails";
import countries from "../../../components/CountriesList";
import AppModal from "../../../components/AppModal";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import eligibility from "../../../gql/eligibility";
import AddIcon from "@mui/icons-material/Add";
import DoneAllIcon from "@mui/icons-material/DoneAll";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AddBeneficiaryPersonalDataForm({
  item,
  chiReqRecord,
  setChiReqRecord,
}) {
  const [addAddress, setAddAddress] = useState(false);
  const [isFormReset, setIsFormReset] = useState(false);
  const [submitEligibility, setSubmitEligibility] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const currentDate = new Date();

  const [fromDate, setFromDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-01`
  );
  const [toDate, setToDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate()}`
  );

  const navigate = useNavigate();

  const [
    checkEligibility,
    { data: eligibilityData, loading: loadingData, error: errorData },
  ] = useMutation(eligibility.ELIGIBILITY_CHECK, {
    update(proxy, result) {
      toast.success("Data send to payer successfully!");
      onSuccessfulEligibilityRequestResponse(result.data);
      // if (!result?.data.verificationRequest?.nphies_err) {
      //   result.data.verificationRequest.nphies_err.map((err) => {
      //     toast.error(err.display);
      //   });
      //   setShowLoader(false);
      // } else {
      //   toast.success("Data send to payer successfully!");
      //   onSuccessfulEligibilityRequestResponse(result.data);
      // }
    },
    onError(error) {
      onEligibilityResponseError(error.graphQLErrors[0]);
    },
  });

  // const { loading, error, data } = useQuery(usersGQL.GET_USER);
  const onSuccessfulEligibilityRequestResponse = (data) => {
    if (!data.verificationRequest.nphies_err) {
      setShowLoader(false);
      navigate("/eligibility");
      // refetch({
      //   limit: 10,
      //   page: 1,
      //   fromDate: fromDate,
      //   toDate: toDate,
      // });
    } else {
      setShowLoader(false);
      // setErrorData(data.verificationRequest.nphies_err);
      // setShowErrorAlert(true);
    }
  };

  const onEligibilityResponseError = (error) => {
    setShowLoader(false);
    // refetch({
    //   limit: 10,
    //   page: 1,
    //   fromDate: fromDate,
    //   toDate: toDate,
    // });
    toast.error(`${error?.message}`);
  };

  const [patientId, setPatientId] = useState(item ? item.id : null);
  const gender = [
    { id: "male", name: "Male" },
    { id: "female", name: "Female" },
    { id: "unknown", name: "Unknown" },
    // { id: "other", name: "Undetermined" },
  ];

  const xdocumentTypes = [
    { id: "iqama", name: "Iqama" },
    { id: "Resident Card", name: "Resident Card" },
    { id: "Passport", name: "Passport" },
    { id: "Visa", name: "Visa" },
    { id: "National Card", name: "National Card" },
    { id: "Medical Record Number", name: "Medical Record Number" },
  ];

  const documentTypes = [
    { id: "VP", name: "VISA" },
    { id: "PPN", name: "Passport" },
    { id: "PRC", name: "Permanent Resident Card Number" },
    { id: "MR", name: "Medical record number" },
    { id: "NI", name: "National Card" },
  ];

  const nationalities = [
    { id: "Saudi Arabia", name: "Saudi Arabia" },
    { id: "Afghanistan", name: "Afghanistan" },
    { id: "Albania", name: "Albania" },
    { id: "Aland Islands", name: "Aland Islands" },
  ];

  const residencyTypes = [
    { id: "iqama", name: "Iqama" },
    { id: "visa", name: "Visitor" },
    { id: "nationalId", name: "Citizen or Resident" },
  ];

  const maritalStatuses = [
    { id: "Divorced", name: "Divorced" },
    { id: "Legally Separated", name: "Legally Separated" },
    { id: "Married", name: "Married" },
    { id: "Unmarried", name: "Unmarried" },
    { id: "Widowed", name: "Widowed" },
  ];

  const bloodGroups = [
    { id: "O+", name: "O+" },
    { id: "O-", name: "O-" },
    { id: "A+", name: "A+" },
    { id: "A-", name: "A-" },
    { id: "B+", name: "B+" },
    { id: "B-", name: "B-" },
  ];

  const visaType = [
    { id: 6, name_ar: "مرور", name_en: "Transit" },
    {
      id: 10,
      name_ar: "سياحية او سياحية - هيا",
      name_en: "Tourism or Tourist - Haya",
    },
    { id: 14, name_ar: "توصيل بضائع", name_en: "Cargo Delivery" },
    { id: 23, name_ar: "زيارة تجارية", name_en: "Business Visit" },
    { id: 24, name_ar: "زيارة عائلية", name_en: "Family Visit" },
    { id: 21, name_ar: "رجال اعمال", name_en: "Businessmen" },
    { id: 22, name_ar: "زيارة عمل", name_en: "Work Visit" },
    { id: 27, name_ar: "زيارة شخصية", name_en: "Personal Visit" },
    { id: 40, name_ar: "مرافق", name_en: "Accompanying" },
    { id: 30, name_ar: "زيارة فعالية", name_en: "Event Visit" },
  ];

  const tableHeaders = [
    {
      name: "House No./ Apartment",
    },
    {
      name: "Street Name",
    },
    {
      name: "City",
    },
    {
      name: "State/Province",
    },
    {
      name: "Country",
    },
    {
      name: "Postal Code",
    },
  ];

  const onSuccessfulRequestResponse = (data) => {
    if (data.registerPatient) {
      toast.success("Successfully Added!");
      formik.resetForm();
      setChiReqRecord(null);
      setIsFormReset(true);
      setPatientId(data.registerPatient.data.id);
      // !submitEligibility && navigate("/eligibility");
    } else {
    }
  };

  const onResponseError = (error) => {
    toast.error(`${error?.message}`);
  };

  const [createPatient, { data, loading, error }] = useMutation(
    beneficiary.CREATE_PATIENT,
    {
      update(proxy, result) {
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  const [editBeneficiary, { updatedData, updatedLoading, updatedError }] =
    useMutation(beneficiary.UPDATE_BENEFICIARY, {
      update(proxy, result) {
        onSuccessfulEditResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    });

  const onSuccessfulEditResponse = (data) => {
    if (data?.updatePatient) {
      toast.success(data?.updatePatient?.message || "Update Successfully!");
      formik.resetForm();
      setChiReqRecord(null);
      setIsFormReset(true);
      navigate("/eligibility");
      // refetch();
    } else {
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors[0]?.message) {
      toast.error(error?.graphQLErrors[0]?.message);
    }
    if (updatedError?.graphQLErrors[0]?.message) {
      toast.error(updatedError?.graphQLErrors[0]?.message);
    }
  }, [error, updatedError]);

  const formatInputData = (postData) => {
    return {
      start_date: currentDate.toISOString().substring(0, 10),
      end_date: currentDate.toISOString().substring(0, 10),
      payer_id: `${
        postData?.insurance_plans?.length > 0 &&
        postData?.insurance_plans[0]?.payer_id
      }`,
      patient_id: postData?.id,
      insurance_plan_id: `${
        postData?.insurance_plans?.length > 0 &&
        postData?.insurance_plans[0]?.id
      }`,
      purpose: ["benefits", "validation"],
      is_new_born: false,
      is_referral: false,
    };
  };

  const addData = (values) => {
    let data = _.cloneDeep(values);

    if (item) {
      data["id"] = item.id;

      delete data.insurance_plans;
      // delete data.subscriber_relationship;
      delete data.beneficiary_file_id;

      editBeneficiary({ variables: { input: data } }).then((res) => {
        if (submitEligibility) {
          toast.loading("Checking Eligibility");
          checkEligibility({
            variables: {
              input: formatInputData(values),
            },
          });
        }
      });
    } else {
      delete data.beneficiary_file_id;
      // delete data.subscriber_relationship;
      createPatient({ variables: { input: data } }).then((res) => {
        if (submitEligibility) {
          toast.loading("Checking Eligibility");
          checkEligibility({
            variables: {
              input: formatInputData(res?.data?.registerPatient?.data),
            },
          });
        }
      });
    }
  };

  const inValidBtn = (values) => {
    if (!values.is_new_born && values.insurance_plans.length < 1) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (chiReqRecord) {
      formik.resetForm();
      formik.setFieldValue("full_name", chiReqRecord?.Insurance[0]?.Name || "");
      formik.setFieldValue(
        "document_id",
        chiReqRecord?.Insurance[0]?.IdentityNumber || ""
      );

      formik.setFieldValue(
        "gender",
        chiReqRecord?.Insurance[0]?.Gender == 1
          ? "male"
          : chiReqRecord?.Insurance[0]?.Gender == 2
          ? "female"
          : chiReqRecord?.Insurance[0]?.Gender == 3
          ? "unknown"
          : "other" || ""
      );
    }
  }, [chiReqRecord]);

  const formik = useFormik({
    initialValues: {
      first_name: item?.first_name
        ? item.first_name
        : chiReqRecord
        ? chiReqRecord?.Insurance[0]?.Name
        : "",
      middle_name: item?.middle_name ? item.middle_name : "",
      last_name: item?.last_name ? item.last_name : "",
      family_name: item?.family_name ? item.family_name : "",
      full_name: item?.full_name
        ? item.full_name
        : chiReqRecord
        ? chiReqRecord?.Insurance[0]?.Name
        : "",
      file_no: item?.file_no ? item.file_no : "",
      dob: item?.dob ? item.dob : currentDate.toISOString().substring(0, 10),
      gender: item?.gender
        ? item.gender
        : chiReqRecord
        ? chiReqRecord?.Insurance[0]?.Gender == 1
          ? "male"
          : chiReqRecord?.Insurance[0]?.Gender == 2
          ? "female"
          : chiReqRecord?.Insurance[0]?.Gender == 3
          ? "unknown"
          : "other"
        : "",
      document_type: item?.document_type ? item.document_type : "",
      document_id: item?.document_id
        ? item.document_id
        : chiReqRecord
        ? chiReqRecord?.Insurance[0]?.IdentityNumber
        : "",
      nphies_id: item?.nphies_id ? item.nphies_id : "",
      eHealth_id: item?.eHealth_id ? item.eHealth_id : "",
      nationality: item?.nationality ? item.nationality : "",
      residency_type: item?.residency_type ? item.residency_type : "",
      contact_number: item?.contact_number ? item.contact_number : "",
      martial_status: item?.martial_status ? item.martial_status : "",
      blood_group: item?.blood_group ? item.blood_group : "",
      preferred_language: item?.preferred_language
        ? item.preferred_language
        : "",
      emergency_number: item?.emergency_number ? item.emergency_number : "",
      email: item?.email ? item.email : "",
      // providers_id: item?.providers_id ? item.providers_id : "",
      is_new_born: item?.is_new_born ? item.is_new_born : false,
      street_line: item?.street_line ? item.street_line : "",
      address_line: item?.address_line ? item.address_line : "",
      city: item?.city ? item.city : "",
      state: item?.state ? item.state : "",
      country: item?.country ? item.country : "",
      postal_code: item?.postal_code ? item.postal_code : "",
      subscriber_id: item?.subscriber_id ? item.subscriber_id : "",
      subscriber_relationship: item?.subscriber_relationship
        ? item.subscriber_relationship
        : "",
      subscriber_insurance_plan: item?.subscriber_insurance_plan
        ? item.subscriber_insurance_plan
        : "",
      insurance_plans: item?.insurance_plans ? item.insurance_plans : [],
      passport_no: item?.passport_no ? item.passport_no : "",
      visa_no: item?.visa_no ? item.visa_no : "",
      visa_title: item?.visa_title ? item.visa_title : "",
      visa_type: item?.visa_type ? item.visa_type : "",
      border_no: item?.border_no ? item.border_no : "",
      insurance_duration: item?.insurance_duration
        ? item.insurance_duration
        : "",
      id: item?.id ? item.id : "",
    },
    enableReinitialize: true,

    validationSchema: () => {
      return Yup.object().shape({
        // first_name: Yup.string().required("first_name is required"),
        full_name: Yup.string().required("Full Name is required"),
        file_no: Yup.string(),
        dob: Yup.date()
          .nullable()
          .required("Dob is required")
          .max(new Date(), "Dob cannot be in the future")
          .typeError("Please enter a valid date"),
        gender: Yup.string().required("Gender is required"),
        document_type: Yup.string().required("Document Type is required"),
        document_id: Yup.string().required("Document id is required"),
        insurance_plans: Yup.array()
          .of(
            Yup.object().shape({
              member_card_id: Yup.string().required(
                "Member Card ID is required"
              ),
              policy_number: Yup.string().required("Policy number is required"),
              expiry_date: Yup.date().required("Expiry date is required"),
              issue_date: Yup.string().required("Issue Date is required"),
              is_primary: Yup.boolean().required("Is primary is required"),
              payer_id: Yup.string().required("Payer ID is required"),
              max_limit: Yup.number().required("Max Limit is required"),
              relation_with_subscriber: Yup.string().required(
                "Relation with subscriber is required"
              ),
            })
          )
          .required("At least one insurance plan is required")
          .min(1, "At least one insurance plan is required"),
        // nphies_id: Yup.string().required("nphies_id is required"),
        // eHealth_id: Yup.string().required("eHealth_id is required"),
        // nationality: Yup.string().required("nationality is required"),
        residency_type: Yup.string().required("Residency type is required"),
        // contact_number: Yup.string().required("contact_number is required"),
        // martial_status: Yup.string().required("martial_status is required"),
        // emergency_number: Yup.string().required("emergency_number is required"),
        // email: Yup.string().email("Invalid email").required("email is required"),
        // street_line: Yup.string().required("street_line is required"),
        // address_line: Yup.string().required("address_line is required"),
        // city: Yup.string().required("city is required"),
        // state: Yup.string().required("state is required"),
        // country: Yup.string().required("country is required"),
        passport_no: Yup.number(),
        visa_no: Yup.number(),
        visa_title: Yup.string(),
        visa_type: Yup.number(),
        border_no: Yup.number(),
        insurance_duration: Yup.string(),
      });
    },

    onSubmit: (values, { setSubmitting }) => {
      addData(values);
    },
  });

  useEffect(() => {
    if (formik.values?.document_id.length > 0) {
      if (formik.values?.document_id[0] == 1) {
        formik.setFieldValue("document_type", "NI");
        formik.setFieldValue("residency_type", "nationalId");
      } else if (formik.values?.document_id[0] == 2) {
        formik.setFieldValue("document_type", "PRC");
        formik.setFieldValue("residency_type", "iqama");
      } else if (formik.values?.document_id[0] == 4) {
        formik.setFieldValue("document_type", "VP");
        formik.setFieldValue("residency_type", "visa");
      }
    }
  }, [formik.values?.document_id]);

  const addInsurancePlan = () => {
    const initialPlain = {
      member_card_id: "",
      policy_number: "",
      expiry_date: "",
      is_primary: true,
      payer_id: "",
      relation_with_subscriber: "self",
      coverage_type: "EHCPOL",
      patient_share: "0.00",
      max_limit: 0,
      network: "",
      issue_date: "",
      sponsor_no: "",
      policy_class: "",
      policy_holder: "",
      insurance_type: "",
      insurance_status: "",
      insurance_duration: "",
    };

    formik.setFieldValue("insurance_plans", [
      ...formik?.values?.insurance_plans,
      initialPlain,
    ]);
  };

  return (
    <>
      <div class="personal-data !p-2 !mb-3">
        <div class="personal-title !mb-2">
          <div class="inventory-svg">
            <svg
              width="26"
              height="26"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="34" height="34" rx="8" fill="#F4F6F8" />
              <path
                d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
                fill="#F5793B"
              />
              <path
                d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
                fill="#F5793B"
              />
            </svg>
          </div>
          <div class="personal-head">
            <span>Personal Data</span>
          </div>
        </div>
        <div class="personal-fields grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  gap-2">
          {/* <div className="">
            <input
              placeholder="Enter first name*"
              type="text"
              name="first_name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="first_name"
              autoComplete="email"
              className="w-full"
            />
            {formik.errors.first_name && formik.touched.first_name ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.first_name}
              </p>
            ) : null}
          </div> */}
          {/* <div>
            <input
              placeholder="Enter second name"
              type="text"
              name="middle_name"
              value={formik.values.middle_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="middle_name"
              autoComplete="email"
              className="w-full"
            />
          </div> */}

          {/* <div>
            <input
              placeholder="Enter Third name"
              type="text"
              name="last_name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="last_name"
              autoComplete="email"
              className="w-full"
            />
          </div> */}

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Full Name*</label>
            <input
              placeholder="Enter Full name"
              type="text"
              name="full_name"
              value={formik.values.full_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="full_name"
              autoComplete="email"
              required
              className="w-full"
            />
            {formik.errors.full_name && formik.touched.full_name ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.full_name}
              </p>
            ) : null}
          </div>

          {/* <div>
            <input
              placeholder="Enter Family name"
              type="text"
              name="family_name"
              value={formik.values.family_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="family_name"
              autoComplete="email"
              className="w-full"
            />
          </div> */}

          <div className="flex gap-1 flex-col">
            <label className="text-sm">File No</label>
            <input
              placeholder="Enter File No"
              type="text"
              name="file_no"
              value={formik.values.file_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="file_no"
            />
            {formik.errors.file_no && formik.touched.file_no ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.file_no}
              </p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Date of Birth*</label>
            <input
              placeholder="Enter Date of Birth"
              type="date"
              name="dob"
              value={formik.values.dob}
              onChange={formik.handleChange}
              id="dobDate"
              autoComplete="off"
              className="dob"
            />

            {formik.errors.dob && formik.touched.dob ? (
              <p className=" max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.dob}
              </p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Gender*</label>
            <select
              id="select-gender"
              name="gender"
              onChange={formik.handleChange}
              value={formik.values.gender}
              autoComplete="type-name"
              required
            >
              <option value={""}>Select Gender</option>
              {gender.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {formik.errors.gender && formik.touched.gender ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.gender}
              </p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Document Type*</label>
            <select
              id="doc-type"
              name="document_type"
              onChange={formik.handleChange}
              value={formik.values.document_type}
              autoComplete="type-name"
              required
            >
              <option value="">Select Document Type</option>
              {documentTypes.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
            {formik.errors.document_type && formik.touched.document_type ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.document_type}
              </p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Document ID*</label>
            <input
              placeholder="Enter Document ID"
              type="text"
              name="document_id"
              value={formik.values.document_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="document_id"
              required
            />
            {formik.errors.document_id && formik.touched.document_id ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.document_id}
              </p>
            ) : null}
          </div>

          {/* <div>
            <input
              placeholder="Enter Nphies ID"
              type="text"
              name="nphies_id"
              value={formik.values.nphies_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="nphies_id"
            />
            {formik.errors.nphies_id && formik.touched.nphies_id ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.nphies_id}
              </p>
            ) : null}
          </div> */}

          {/* <div>
            <input
              placeholder="Enter Health ID"
              type="text"
              name="eHealth_id"
              value={formik.values.eHealth_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="eHealth_id"
            />
            {formik.errors.eHealth_id && formik.touched.eHealth_id ? (
              <p className=" max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.eHealth_id}
              </p>
            ) : null}
          </div> */}

          {/* <div>
            <input
              type="text"
              name="nationality"
              onChange={formik.handleChange}
              value={formik.values.nationality}
              placeholder="Enter Nationality"
              id="nationality"
            />
            {formik.errors.nationality && formik.touched.nationality ? (
              <p className=" max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.nationality}
              </p>
            ) : null}
          </div> */}

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Residency Type*</label>
            <select
              id="residence-type"
              name="residency_type"
              onChange={formik.handleChange}
              value={formik.values.residency_type}
              autoComplete="type-name"
            >
              <option value={""}>Select Residency Type</option>
              {residencyTypes.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
            {formik.errors.residency_type && formik.touched.residency_type ? (
              <p className=" max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.residency_type}
              </p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Contact Number</label>
            <input
              placeholder="Enter contact number"
              type="text"
              name="contact_number"
              value={formik.values.contact_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="contact_number"
              autoComplete="email"
            />
            {formik.errors.contact_number && formik.touched.contact_number ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.contact_number}
              </p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Passport Number</label>
            <input
              placeholder="Enter Passport Number"
              type="number"
              name="passport_no"
              value={formik.values.passport_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="passport_no"
            />
            {formik.errors.passport_no && formik.touched.passport_no ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.passport_no}
              </p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Visa Title</label>
            <input
              placeholder="Enter Visa Title"
              type="text"
              name="visa_title"
              value={formik.values.visa_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="visa_title"
            />
            {formik.errors.visa_title && formik.touched.visa_title ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.visa_title}
              </p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Visa Number</label>
            <input
              placeholder="Enter Visa Number"
              type="number"
              name="visa_no"
              value={formik.values.visa_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="visa_no"
            />
            {formik.errors.visa_no && formik.touched.visa_no ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.visa_no}
              </p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Visa Type</label>
            <select
              id="visa_type"
              name="visa_type"
              onChange={formik.handleChange}
              value={formik.values.visa_type}
              autoComplete="visa_type"
            >
              <option value="">Select Visa Type</option>
              {visaType.map((item) => {
                return <option value={item.id}>{item.name_en}</option>;
              })}
            </select>
            {formik.errors.visa_type && formik.touched.visa_type ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.visa_type}
              </p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Border Number</label>
            <input
              placeholder="Enter Border Number"
              type="number"
              name="border_no"
              value={formik.values.border_no}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="border_no"
            />
            {formik.errors.border_no && formik.touched.border_no ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.border_no}
              </p>
            ) : null}
          </div>

          <div className="flex gap-1 flex-col">
            <label className="text-sm">Insurance Duration</label>
            <input
              placeholder="Enter Insurance Duration"
              type="text"
              name="insurance_duration"
              value={formik.values.insurance_duration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="border_no"
            />
            {formik.errors.insurance_duration &&
            formik.touched.insurance_duration ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.insurance_duration}
              </p>
            ) : null}
          </div>

          {/* <div>
            <select
              id="martial-status"
              name="martial_status"
              onChange={formik.handleChange}
              value={formik.values.martial_status}
              autoComplete="type-name"
            >
              <option value={""}>Select Marital Status</option>
              {maritalStatuses.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
            {formik.errors.martial_status && formik.touched.martial_status ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.martial_status}
              </p>
            ) : null}
          </div> */}

          {/* <div>
            <select
              id="blood-group"
              name="blood_group"
              onChange={formik.handleChange}
              value={formik.values.blood_group}
              autoComplete="type-name"
            >
              <option value="">Select Blood Group</option>
              {bloodGroups.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
          </div> */}

          {/* <div>
            <input
              placeholder="Emergency Phone number"
              type="text"
              name="emergency_number"
              value={formik.values.emergency_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="emergency_number"
              autoComplete="email"
            />
            {formik.errors.emergency_number &&
            formik.touched.emergency_number ? (
              <p className=" max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.emergency_number}
              </p>
            ) : null}
          </div> */}

          {/* <div>
            <input
              placeholder="Enter Email*"
              type="text"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="email"
              autoComplete="email"
            />
            {formik.errors.email && formik.touched.email ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.email}
              </p>
            ) : null}
          </div> */}
        </div>
      </div>
      {/* <div class="address">
        <div class="address-title">
          <div class="address-svg">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="34" height="34" rx="8" fill="#F4F6F8" />
              <path
                d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
                fill="#F5793B"
              />
              <path
                d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
                fill="#F5793B"
              />
            </svg>
          </div>
          <div class="address-head">
            <span>Address</span>
          </div>
        </div>
        <div class="address-fields grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-3">
          <div>
            <input
              placeholder="Enter House No./ Apartment"
              type="text"
              name="address_line"
              value={formik.values.address_line}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="address_line"
              autoComplete="address_line"
            />
            {formik.errors.address_line && formik.touched.address_line ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.address_line}
              </p>
            ) : null}
          </div>
          <div>
            <input
              placeholder="Enter Street"
              type="text"
              name="street_line"
              value={formik.values.street_line}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="street_line"
              autoComplete="street_line"
            />
            {formik.errors.street_line && formik.touched.street_line ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.street_line}
              </p>
            ) : null}
          </div>
          <div>
            <input
              placeholder="Enter City Name"
              type="text"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="city"
              autoComplete="city"
            />
            {formik.errors.city && formik.touched.city ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.city}
              </p>
            ) : null}
          </div>
          <div>
            <input
              placeholder="Enter State/Province"
              type="text"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="state"
              autoComplete="state"
            />
            {formik.errors.state && formik.touched.state ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.state}
              </p>
            ) : null}
          </div>

          <div>
            <select
              id="select-country"
              name="country"
              onChange={formik.handleChange}
              value={formik.values.country}
              autoComplete="type-name"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value={""}>Select Country</option>
              {countries.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
            {formik.errors.country && formik.touched.country ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {formik.errors.country}
              </p>
            ) : null}
          </div>
          <input
            placeholder="Enter Postal Code"
            type="text"
            name="postal_code"
            value={formik.values.postal_code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="postal_code"
            autoComplete="postal_code"
          />
        </div>
      </div> */}
      {formik.values.is_new_born ? (
        <SubscriberDetails
          handleChange={formik.handleChange}
          values={formik.values}
          setFieldValue={formik.setFieldValue}
          item={item}
        />
      ) : (
        <>
          <InsurancePlans
            chiReqRecord={chiReqRecord}
            item={item}
            personalDetailValues={formik.values}
            personalDetailsAreValid={formik.isValid}
            formik={formik}
            patientId={patientId}
            setFieldValue={formik.setFieldValue}
            isFormReset={isFormReset}
            setIsFormReset={setIsFormReset}
          />
          {formik.errors.insurance_plans &&
          typeof formik.errors.insurance_plans === "string" &&
          formik.touched.insurance_plans ? (
            <p className="max-w-2xl text-sm leading-6 text-red-500">
              {formik.errors.insurance_plans}
            </p>
          ) : null}
        </>
      )}

      <div className="flex justify-between">
        <div className="flex gap-1 mt-2">
          <div class="toggle-button-ref">
            <input
              type="checkbox"
              id="toggle-ref"
              name="is_new_born"
              value={formik.values.is_new_born}
              onChange={() =>
                formik.setFieldValue("is_new_born", !formik.values.is_new_born)
              }
            />
            <label for="toggle-ref"></label>
          </div>
          <div>
            <span className="text-lg font-medium text-gray-600">
              Is New Born?
            </span>
          </div>
        </div>

        <div className="">
          <Button
            variant="contained"
            onClick={addInsurancePlan}
            style={{
              backgroundImage:
                "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
            }}
            startIcon={<AddIcon />}
            className=""
            type="button"
            size="small"
          >
            New Insurance Plan
          </Button>
        </div>
      </div>

      {/* {errors && Object.keys(errors).length > 0 && (
            <div>
              <h3>Validation Errors:</h3>
              <ul>
                {Object.values(errors).map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )} */}
      {/* <div class="save-benefeciary-btn" onClick={formik.handleSubmit}>
        <span>{item ? "Update" : "Save"} Beneficiary</span>
      </div> */}
      <div className=" flex gap-3 justify-center">
        <Button
          variant="contained"
          onClick={formik.handleSubmit}
          style={{
            backgroundImage: "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
          }}
          startIcon={<CheckIcon />}
          size="small"
        >
          {item ? "Update" : "Save"} Beneficiary
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            setSubmitEligibility(true);
            formik.handleSubmit();
          }}
          style={{
            backgroundImage: "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
          }}
          startIcon={<DoneAllIcon />}
          size="small"
        >
          {item ? "Update" : "Save"} Beneficiary & Check Eligibility
        </Button>
      </div>

      <AppModal
        isOpen={loading || updatedLoading || showLoader || loadingData}
      />
    </>
  );
}

export default AddBeneficiaryPersonalDataForm;
