import { Fragment, useState, memo, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

// newly added
import { MultiSelect } from "react-multi-select-component";

const options = [
  { label: "Grapes 🍇", value: "grapes" },
  { label: "Mango 🥭", value: "mango" },
  { label: "Strawberry 🍓", value: "strawberry", disabled: true },
];
//
const MultiSelectDropDown = memo(
  ({ setSelectedValue, multiValues: valuesArr, initialSelectedValues }) => {
    const [selected, setSelected] = useState([]);
    const [query, setQuery] = useState("");

    useEffect(() => {
      if (initialSelectedValues.length > 0) {
        setSelected(initialSelectedValues);
      } else {
        setSelected([]);
      }
    }, [initialSelectedValues]);

    // useEffect(() => {
    //   if (valuesArr.length > 0) {
    //     setSelected(valuesArr);
    //   }
    // }, [valuesArr]);

    const filteredPeople =
      query === ""
        ? valuesArr
        : valuesArr.filter((valObj) =>
            valObj.name
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""))
          );

    const onChangeVal = (val) => {
      setSelected(val);
      setSelectedValue(val);
    };
    return (
      // <Combobox value={selected} onChange={onChangeVal} multiple>
      //   <div className="relative mt-1">
      //     <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border-gray-300 shadow-sm focus:border-cyan-500 sm:text-sm">
      //       <Combobox.Input
      //         className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm py-2 pl-3 pr-10 leading-5 text-gray-900"
      //         displayValue={(valuesArr) =>
      //           valuesArr.map((valObj) => valObj.name).join(", ")
      //         }
      //       />
      //       <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
      //         <ChevronUpDownIcon
      //           className="h-5 w-5 text-gray-400"
      //           aria-hidden="true"
      //         />
      //       </Combobox.Button>
      //     </div>
      //     <Transition
      //       as={Fragment}
      //       leave="transition ease-in duration-100"
      //       leaveFrom="opacity-100"
      //       leaveTo="opacity-0"
      //       afterLeave={() => setQuery("")}
      //     >
      //       <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
      //         {filteredPeople.length === 0 && query !== "" ? (
      //           <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
      //             Nothing found.
      //           </div>
      //         ) : (
      //           filteredPeople.map((valObj) => (
      //             <Combobox.Option
      //               key={valObj.id}
      //               className={({ active }) =>
      //                 `relative cursor-default select-none py-2 pl-10 pr-4 ${
      //                   active ? "bg-cyan-600 text-white" : "text-gray-900"
      //                 }`
      //               }
      //               value={valObj}
      //             >
      //               {({ selected, active }) => (
      //                 <>
      //                   <span
      //                     className={`block truncate ${
      //                       selected ? "font-medium" : "font-normal"
      //                     }`}
      //                   >
      //                     {valObj.name}
      //                   </span>
      //                   {selected ? (
      //                     <span
      //                       className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
      //                         active ? "text-white" : "text-cyan-600"
      //                       }`}
      //                     >
      //                       <CheckIcon className="h-5 w-5" aria-hidden="true" />
      //                     </span>
      //                   ) : null}
      //                 </>
      //               )}
      //             </Combobox.Option>
      //           ))
      //         )}
      //       </Combobox.Options>
      //     </Transition>
      //   </div>
      // </Combobox>
      <div>
        <MultiSelect
          options={valuesArr}
          value={selected}
          onChange={onChangeVal}
          labelledBy="Select"
        />
      </div>
    );
  }
);

export default MultiSelectDropDown;
