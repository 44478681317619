import { gql } from "@apollo/client";

const ADD_PROVIDER_MUTATION = gql`
  mutation providersRegistrationMutation($input: Providers!) {
    providersRegistrationMutation(input: $input) {
      Message
      Status
      Data {
        ID
        Address
        NaphieId
        NameAr
        NameEn
        Group
        Type
        Wave
        CCHI
        VAT
        Url
        StartDate
        EndDate
        PaymentReminderDate
        PaymentDeadlineDate
        CreatedAt
        UpdatedAt
        CreatedBy
      }
    }
  }
`;
const GET_ALL_PROVIDERS = gql`
  query GetAllProviders($limit: Int!, $page: Int!) {
    getAllProviders(limit: $limit, page: $page) {
      Status
      Message
      Data {
        ID
        Address
        NaphieId
        NameAr
        NameEn
        Group
        Type
        Wave
        NaphieId
        CCHI
        VAT
        Url
        StartDate
        EndDate
        PaymentReminderDate
        PaymentDeadlineDate
      }
      Pages
    }
  }
`;

const DELETE_PROVIDER = gql`
  mutation deleteProviders($input: DeleteProviders!) {
    deleteProviders(input: $input) {
      Message
      Status
      Data {
        ID
        Address
        NaphieId
        NameAr
        NameEn
        Group
        Type
        Wave
        NaphieId
        CCHI
        StatusId
        VAT
        StartDate
        EndDate
        PaymentReminderDate
        PaymentDeadlineDate
        CreatedAt
      }
    }
  }
`;

const PROVIDER_UPDATE = gql`
  mutation providersUpdate($input: UpdateProviders!) {
    providersUpdate(input: $input) {
      Message
      Status
      Data {
        ID
        Address
        NaphieId
        NameAr
        NameEn
        Group
        Type
        Wave
        CCHI
        VAT
        StartDate
        EndDate
        PaymentReminderDate
        PaymentDeadlineDate
        CreatedAt
        UpdatedAt
        CreatedBy
      }
    }
  }
`;

const ADD_BRANCH = gql`
  mutation providersBranchRegistrationMutation($input: ProvidersBranch!) {
    providersBranchRegistrationMutation(input: $input) {
      Message
      Status
      Data {
        ProvidersId
        NameAr
        NameEn
        Location
        LocationLicense
        CityId
        CreatedAt
      }
    }
  }
`;

const GET_ALL_PROVIDERS_WITHOUT_PAGINATION = gql`
  query {
    getAllProviders {
      Status
      Message
      Data {
        ID
        Address
        NaphieId
        NameAr
        NameEn
        Group
        Type
        Wave
        NaphieId
        CCHI
        VAT
        StartDate
        EndDate
        PaymentReminderDate
        PaymentDeadlineDate
      }
    }
  }
`;

export default {
  ADD_PROVIDER_MUTATION,
  GET_ALL_PROVIDERS,
  DELETE_PROVIDER,
  PROVIDER_UPDATE,
  ADD_BRANCH,
  GET_ALL_PROVIDERS_WITHOUT_PAGINATION,
};
