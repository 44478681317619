import React from "react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";

function Pagination({ currentPage, totalPages, onPageChange }) {
  const pages = [];
  const lastPages = [];

  for (let i = currentPage - 1; i <= totalPages; i++) {
    if (i == currentPage + 3) {
      break;
    } else if (i == 0) {
      i = 1;
      pages.push(1);
    } else {
      pages.push(i);
    }
  }

  // for (let i = totalPages; i >= 4; i--) {
  //   if (i == totalPages - 3) {
  //     break;
  //   } else {
  //     lastPages.unshift(i);
  //   }
  // }

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        {currentPage > 1 && (
          <a
            href="#"
            className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            onClick={() => onPageChange(currentPage - 1)}
          >
            <ArrowLongLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </a>
        )}
      </div>

      <div className="hidden md:-mt-px md:flex">
        {currentPage > 2 && (
          <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
            ...
          </span>
        )}
        {pages.map((page, i) => (
          <a
            key={i}
            href="#"
            className={`${
              page != currentPage
                ? "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                : "inline-flex items-center border-t-2 border-amber-500 px-4 pt-4 text-sm font-medium text-amber-600"
            }`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </a>
        ))}
        {totalPages - currentPage >= 3 && (
          <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
            ...
          </span>
        )}
        {/* {lastPages.map((page) => (
          <a
            href="#"
            className={`${
              page != currentPage
                ? "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                : "inline-flex items-center border-t-2 border-amber-500 px-4 pt-4 text-sm font-medium text-amber-600"
            }`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </a>
        ))} */}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        {currentPage < totalPages && (
          <a
            href="#"
            className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next
            <ArrowLongRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </a>
        )}
      </div>
    </nav>
  );
}

export default Pagination;
