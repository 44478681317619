import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import _ from "lodash";
import { useQuery } from "@apollo/client";

import gqlProviders from "../../../../gql/providers";
import { useLocation } from "react-router";

function FilterModal({
  showFilter,
  setShowFilter,
  refetch,
  setPageNo,
  setLimit,
  // refetchExcelDataResponse,
  pageNo,
}) {
  const [providers, setProviders] = useState([]);
  const location = useLocation();
  const beneficiariesPageNo = JSON.parse(
    localStorage.getItem("beneficiariesPageNo")
  );

  useEffect(() => {
    if (location?.pathname === "/beneficiary") {
      localStorage.setItem(
        "beneficiariesPageNo",
        JSON.stringify(pageNo ? pageNo : 1)
      );
    }
  }, [location?.pathname, pageNo]);

  const addData = (values) => {
    let data = _.cloneDeep(values);
    setPageNo(1);
    let obj = {
      full_name: values.name,
      file_no: values.fileID,
      providers_id: values.providerId,
      document_id: values.documentID,
      contact_number: values.contact,
      limit: 10,
      page: 1,
    };

    // console.log("obj :: ", obj);

    refetch({
      filters: Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        if (value !== "" && value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {}),
    });

    delete obj.limit;
    delete obj.page;

    // refetchExcelDataResponse({
    //   input: Object.keys(obj).reduce((acc, key) => {
    //     const value = obj[key];
    //     if (value !== "" && value !== null) {
    //       acc[key] = value;
    //     }
    //     return acc;
    //   }, {}),
    // });
  };

  const formatProvidersData = (data) => {
    let returnData = [];
    if (data?.length > 0) {
      data.map((item) => {
        returnData.push({
          id: item.ID,
          name: item.NameEn,
        });
      });
    }
    return returnData;
  };

  // const {
  //   loading: loadingProviders,
  //   error: errorProvider,
  //   data: providersData,
  //   refetch: refetchProviders,
  // } = useQuery(gqlProviders.GET_ALL_PROVIDERS_WITHOUT_PAGINATION);

  // React.useEffect(() => {
  //   if (providersData) {
  //     setProviders(formatProvidersData(providersData?.getAllProviders?.Data));
  //   }
  // }, [providersData]);

  const modalRef = useRef(null);

  useEffect(() => {
    // Function to handle click outside of the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside of the modal, so close it
        setShowFilter(false);
      }
    };

    // Attach the event listener when the modal is shown
    if (showFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove the event listener when the modal is hidden
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilter]);

  return (
    <div className={`${showFilter ? "open" : ""} modal`} id="myModal">
      <Formik
        initialValues={{
          name: "",
          documentID: "",
          contact: "",
          fileID: "",
          memberCardID: "",
          providerId: "",
        }}
        validate={(values) => {
          const errors = {};
        }}
        onSubmit={(values, { setSubmitting }) => {
          addData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm,
          /* and other goodies */
        }) => (
          <div ref={modalRef} className="modal-content">
            <div
              className="close"
              onClick={() => setShowFilter(() => !showFilter)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#DE5042"
                />
              </svg>
              <div className="bene-text">Beneficiaries</div>
            </div>
            <div className="search-fields">
              <input
                placeholder="Name"
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                id="name"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <input
                placeholder="Document ID"
                type="text"
                name="documentID"
                value={values.documentID}
                onChange={handleChange}
                onBlur={handleBlur}
                id="documentID"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <input
                placeholder="Contact"
                type="text"
                name="contact"
                value={values.contact}
                onChange={handleChange}
                onBlur={handleBlur}
                id="contact"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <input
                type="text"
                placeholder="File ID"
                name="fileID"
                value={values.fileID}
                onChange={handleChange}
                onBlur={handleBlur}
                id="fileID"
              />
              {/* <select
                id="Type"
                name="providerId"
                onChange={handleChange}
                value={values.providerId}
                autoComplete="type-name"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Select Provider</option>
                {providers.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select> */}
            </div>

            <div
              className="search-btn"
              onClick={() => {
                handleSubmit();
                setShowFilter(false);
              }}
            >
              {/* <div className="search-btn" onClick={handleSubmit}> */}
              <span>Search</span>
            </div>

            <div className="reset-btn" onClick={resetForm}>
              <span>Reset</span>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default FilterModal;
