import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useQuery, gql, useMutation } from "@apollo/client";
// import { ToastContainer, toast } from "react-toastify";
import toast from "react-hot-toast";
import { Formik, useFormikContext } from "formik";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";

import { appContext } from "../../../context/ContextProvider";
import ClaimsAddDiagnoseModal from "./ClaimsAddDiagnoseModal";
import ClaimsSupportingInfo from "./ClaimsSupportingInfo";
import ClaimsActivityModal from "./ClaimsActivityModal";
import SupportingInfoForm from "../../PreAuth/components/SupportingInfoForm";
import ClaimsInfo from "./request/ClaimsInfo";
import BeneficiaryInfo from "../../PreAuth/components/request/BeneficiaryInfo";
import CareTeam from "../../PreAuth/components/request/CareTeam";
import Diagnosis from "../../PreAuth/components/request/Diagnosis";
import IsAccident from "../../PreAuth/components/request/IsAccident";
import ItemsList from "./request/ItemsList";
import cliamsGQL from "../../../gql/claims";
import AppErrorAlert from "../../../components/AppErrorAlert";
import { useContext } from "react";
import VisionPrescription from "../../PreAuth/components/request/VisionPrescription";
import supportingInfoElements from "./request/SupportingInfoElement";
import EncounterForm from "../../PreAuth/components/request/EncounterForm";
import AppLoader from "../../../components/AppLoader";
import ClaimsResponseModal from "./ClaimsResponseModal";
import { claimsValidationSchema } from "../../PreAuth/components/request/ValidationSchema";

import "../styles/pre-auth.css";
import "../../Beneficiary/styles/BeneficiaryList.css";
import AppModal from "../../../components/AppModal";
import { Button } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

function ClaimsForm(props) {
  const navigate = useNavigate();

  const location = useLocation();
  // const followUpItem = location.state?.item ? location.state.item : null;
  const currentDate = new Date();
  const { id, followUp } = useParams();
  const [item, setItem] = useState(null);
  const [isFollowUp, setIsFollowUp] = useState(false);
  const { showErrorAlert, setShowErrorAlert } = useContext(appContext);
  const [errorData, setErrorData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showClaimsResponseModal, setShowClaimsResponseModal] = useState(false);
  const [currentTransactionIdItem, setCurrentTransactionIdItem] =
    useState(null);

  const {
    loading: loadingDetails,
    error: detailsError,
    data: detailsData,
    refetch: refetchDetails,
  } = useQuery(cliamsGQL.GET_CLAIMS_TRAN_DETAILS, {
    variables: {
      transactionId: id,
    },
    fetchPolicy: "no-cache",
    skip: !id,
  });

  useEffect(() => {
    if (id) {
      refetchDetails();
    }
  }, []);

  useEffect(() => {
    if (detailsData?.getClaimTransaction?.data) {
      // setTimeout(() => {
      setItem(detailsData?.getClaimTransaction?.data);
      if (followUp == "follow-up") {
        setIsFollowUp(true);
      } else {
        setIsFollowUp(false);
      }
      // }, 500);
    }
  }, [detailsData]);

  // useEffect(() => {
  //   if (followUpItem) {
  //     setIsFollowUp(true);
  //     setItem(followUpItem);
  //   } else {
  //     setIsFollowUp(false);
  //     setItem(null);
  //   }
  // }, [followUpItem]);

  const [requestClaims, { data, loading, error }] = useMutation(
    cliamsGQL.CREATE_CLAIM,
    {
      update(proxy, result) {
        onSuccessfulRequestResponse(result.data);
      },
      onError(error) {
        onResponseError(error.graphQLErrors[0]);
      },
    }
  );

  useEffect(() => {
    if (error?.graphQLErrors[0]?.message) {
      toast.error(error?.graphQLErrors[0]?.message);
    }
    if (detailsError?.graphQLErrors[0]?.message) {
      toast.error(detailsError?.graphQLErrors[0]?.message);
    }
  }, [error, detailsError]);

  const onSuccessfulRequestResponse = (data) => {
    setShowLoader(false);
    if (data.createClaim.transaction_id) {
      toast.success("Successfully Requested!");
      setCurrentTransactionIdItem({
        transaction_id: data.createClaim.transaction_id,
      });
      setShowClaimsResponseModal(true);
    } else if (data?.createClaim?.nphies_err) {
      setErrorData(data.createClaim.nphies_err);
      setShowErrorAlert(true);
    } else {
      toast.success(`Successfully Created`);
    }

    navigate("/claims/submit");
  };

  const onResponseError = (error) => {
    setShowLoader(false);

    if (error) {
      toast.error(`${error.message}`);
    }
    // else {
    //   toast.success(`Error!`);
    // }
  };

  const stringToBoolean = (stringVal) => {
    if (String(stringVal).toLowerCase() == "true") {
      return true;
    } else {
      return false;
    }
  };

  const formatInputData = (postData) => {
    return {
      is_edit_request: postData?.is_edit_request,
      // prv_transaction_id: postData?.transaction_id,
      // is_communication_req: false,
      prv_transaction_id: postData?.prv_transaction_id,
      ref_transaction_id: postData?.refTransactionId,
      is_followup_req: postData?.isFollowUpReq,
      // payer_id: postData?.payerId,
      patient_id: postData.patientId,
      insurance_plan_id: postData.insurancePlanId,
      is_new_born: postData.isNewBorn,
      is_referral: postData.isReferral,
      date_ordered: postData.dateOrdered,
      priority: postData.priority,
      type: postData.claimType,
      subtype: postData.subType,
      offline_verification_id:
        postData.offlineEligibilityId != ""
          ? postData.offlineEligibilityId
          : null,
      offline_verification_date:
        postData.offlineEligibilityDate != ""
          ? postData.offlineEligibilityDate
          : null,
      verification_resp_ident_value:
        postData.eligibilityResponseIdentifierValue != ""
          ? postData.eligibilityResponseIdentifierValue
          : null,

      verification_resp_ident_url:
        postData.eligibilityResponseIdentifierUrl != ""
          ? postData.eligibilityResponseIdentifierUrl
          : null,

      // referrring_provider: postData.referringProvider,
      physicians_id: [
        postData.careTeamsArr.length > 0
          ? postData.careTeamsArr[0].practitioner
          : 0,
      ],
      //extra claim fields than Pre auth
      offline_pre_auth_no:
        postData.preAuthRefNo != "" ? postData.preAuthRefNo : null,
      // pre_auth_ref_date:
      //   postData.preAuthRefDate != "" ? postData.preAuthRefDate : null,
      offline_pre_auth_date:
        postData.offlinePreAuthDate != "" ? postData.offlinePreAuthDate : null,
      pre_auth_resp_value: postData.preAuthRespValue
        ? postData.preAuthRespValue
        : null,
      // related_pre_auth_ref_no: postData.relatedPreAuthRefNo
      //   ? postData.relatedPreAuthRefNo
      //   : null,
      pre_auth_resp_url:
        postData.preAuthRespUrl != "" ? postData.preAuthRespUrl : null,
      episode_no: postData.episodeNo != "" ? postData.episodeNo : null,
      //end extra claim fields than Pre auth
      diagnosis: postData.diagnosisArr.map((item) => {
        return {
          type: item?.type,
          on_admission: stringToBoolean(item?.on_admission),
          code: item?.codeId,
          description: item?.codeDescription,
        };
      }),
      supporting_info: postData.supportingInfos.map((item) => {
        return {
          category: "category" in item ? item?.category : null,
          code: "code" in item ? item?.code : null,
          code_text: "codeText" in item ? item?.codeText : null,
          start_date: "startDate" in item ? item?.start_date : null,
          end_date: "endDate" in item ? item?.end_date : null,
          value: "value" in item ? item?.value : null,
          unit:
            "valueUnit" in item && item?.valueUnit != ""
              ? item?.valueUnit
              : null,
          reason: "reason" in item ? item?.reason : null,
          image_content_type: "fileName" in item ? item?.fileType : null,
          image_title: "fileName" in item ? item?.fileName : null,
          image_creation_date: "attachment" in item ? new Date() : null,
          image_value: "attachment" in item ? item?.attachment : null,
        };
      }),
      is_accident: postData.is_accident,
      accident_info: {
        type: postData.accidentType,
        street_name: postData.street,
        city: postData.city,
        state: postData.state,
        country: postData.country,
        date: postData.date,
      },
      items: postData.items.map((item) => {
        return {
          type: item?.type,
          standard_code: item?.standardCode,
          standard_description: item?.standardCodeDescription,
          non_standard_code:
            item?.non_standard_code != "" ? item?.non_standard_code : null,
          non_standard_description:
            item?.non_standard_description != ""
              ? item?.non_standard_description
              : null,
          medication_selection_reason:
            item?.medicationSelectionReason != ""
              ? item?.medicationSelectionReason
              : null,
          prescribed_medication:
            item?.prescribedMedication != ""
              ? item?.prescribedMedication
              : null,
          body_site: item?.body_site != "" ? item?.body_site : null,
          sub_site: item?.sub_site,
          service_start_date: item?.service_start_date,
          service_end_date: item?.service_end_date,
          supporting_info: item?.supporting_info
            ? item?.supporting_info.map((value) => parseInt(value, 10))
            : null,
          diagnosis: item?.diagnosis
            ? item?.diagnosis.map((value) => parseInt(value, 10))
            : null,
          care_teams: item?.care_teams
            ? item?.care_teams.map((value) => parseInt(value, 10))
            : null,
          is_package: stringToBoolean(item?.is_package),
          quantity: parseInt(item?.quantity ? item?.quantity : "0"),
          code: item?.prescribedMedication ? item?.prescribedMedication : "", //pending
          unit_price: parseFloat(item?.unit_price ? item?.unit_price : "0"),
          tax_amount: parseFloat(item?.tax_amount ? item?.tax_amount : 0),
          factor: parseFloat(item?.factor ? item?.factor : 0),
          net: parseFloat(item?.net ? item?.net : 0),
          discount_amount: parseFloat(
            item?.discount_amount ? item?.discount_amount : 0
          ),
          patient_share: parseFloat(
            item?.patient_share ? item?.patient_share : 0
          ),
          patient_share_amount: parseFloat(
            item?.patientShareAmount ? item?.patientShareAmount : 0
          ),
          payer_share: parseFloat(item?.payer_share ? item?.payer_share : 0),
          invoice_no: item?.invoice_no,
        };
      }),
      vision_prescription: postData.isVisionPrescription
        ? {
            date_written: postData.visionPrescriptionDateWritten,
            prescriber_id: postData.prescriber_id,
            lens_specifications: postData.lens_specifications.map((item) => {
              return {
                product: item?.product,
                color: item?.color,
                brand: item?.brand,
                notes: item?.notes != "" ? item?.notes : null,
                right: {
                  eye_name: "right",
                  sphere:
                    item?.rightSphere != ""
                      ? parseFloat(item?.rightSphere)
                      : null,
                  cylinder:
                    item?.rightCylinder != ""
                      ? parseFloat(item?.rightCylinder)
                      : null,
                  axis: item?.rightAxis != "" ? item?.rightAxis : null,
                  add: item?.rightAdd != "" ? parseFloat(item?.rightAdd) : null,
                  prism: {
                    base:
                      item?.rightPrismUnit != "" ? item?.rightPrismUnit : null,
                    amount:
                      item?.rightPrismVal != ""
                        ? parseFloat(item?.rightPrismVal)
                        : null,
                  },
                  power:
                    item?.rightPowerContact != ""
                      ? parseFloat(item?.rightPowerContact)
                      : null,
                  back_curve:
                    item?.rightBackCurveContact != ""
                      ? parseFloat(item?.rightBackCurveContact)
                      : null,
                  diameter:
                    item?.rightDiameterContact != ""
                      ? parseFloat(item?.rightDiameterContact)
                      : null,
                  duration: {
                    time:
                      item?.rightUnitContact != ""
                        ? item?.rightUnitContact
                        : null,
                    value:
                      item?.rightDurationContact != ""
                        ? item?.rightDurationContact
                        : null,
                  },
                },
                left: {
                  eye_name: "left",
                  sphere:
                    item?.leftSphere != ""
                      ? parseFloat(item?.leftSphere)
                      : null,
                  cylinder:
                    item?.leftCylinder != ""
                      ? parseFloat(item?.leftCylinder)
                      : null,
                  axis: item?.leftAxis != "" ? item?.leftAxis : null,
                  add: item?.leftAdd != "" ? parseFloat(item?.leftAdd) : null,
                  prism: {
                    base:
                      item?.leftPrismUnit != "" ? item?.leftPrismUnit : null,
                    amount:
                      item?.leftPrismVal != ""
                        ? parseFloat(item?.leftPrismVal)
                        : null,
                  },
                  power:
                    item?.leftPowerContact != ""
                      ? parseFloat(item?.leftPowerContact)
                      : null,
                  back_curve:
                    item?.leftBackCurveContact != ""
                      ? parseFloat(item?.leftBackCurveContact)
                      : null,
                  diameter:
                    item?.leftDiameterContact != ""
                      ? parseFloat(item?.leftDiameterContact)
                      : null,
                  duration: {
                    time:
                      item?.leftUnitContact != ""
                        ? item?.leftUnitContact
                        : null,
                    value:
                      item?.leftDurationContact != ""
                        ? item?.leftDurationContact
                        : null,
                  },
                },
              };
            }),
          }
        : null,
      is_vision_prescription: postData.isVisionPrescription,
      encounter: postData.isEncounter
        ? {
            class: postData.encounterClass,
            status: postData.encounterStatus,
            service_type: postData.serviceType,
            priority: postData.encounterPriority,
            period_start: postData.periodStart,
            period_end: postData.periodEnd,
            admin_source: postData.adminSource,
            readmission: postData.readmission,
            discharge_disposition: postData.dischargeDisposition,
          }
        : null,
    };
  };

  function snakeToCamel(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => snakeToCamel(item));
    }

    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = key.replace(/_([a-z])/g, (match, letter) =>
        letter.toUpperCase()
      );
      acc[camelKey] = snakeToCamel(obj[key]);
      return acc;
    }, {});
  }

  const setSupportingInfo = (object) => {
    let obj = object; //  snakeToCamel(object)
    let x = obj;
    supportingInfoElements.map((item, key) => {
      if (item?.object.category == obj?.category) {
        const obj1Keys = Object.keys(item?.object);
        const obj2Keys = Object.keys(obj);

        const keysToRemove = obj2Keys.filter((key) => !obj1Keys.includes(key));

        keysToRemove.forEach((key) => delete obj[key]);

        x = obj;
      }
    });

    return x;
  };

  const initialValues = {
    is_edit_request: item && !isFollowUp ? true : false,
    isFollowUpReq: isFollowUp,
    prv_transaction_id: item && !isFollowUp && id ? id : null,
    refTransactionId:
      isFollowUp && item?.transaction_id ? item?.transaction_id : null,
    //beneficiary info
    isNewBorn: item?.is_new_born ? item?.is_new_born : false,
    isReferral: item?.is_referral ? item?.is_referral : false,
    patientId: item?.patient?.id ? item?.patient?.id : null,
    patientName: item?.patient?.full_name ? item?.patient?.full_name : null,
    subscriberName: item?.patient?.full_name ? item?.patient?.full_name : null,
    insurancePlanId: item?.insurance_plan_id ? item?.insurance_plan_id : null,
    subscriber: item?.subscriber_id ? item?.subscriber_id : null,
    payerId: item?.payer_id ? item?.payer_id : null,
    payerName: item?.payer_name ? item?.payer_name : null,
    insurancePlanIndex: "",
    //preauth info
    dateOrdered: item?.date_ordered
      ? new Date(item?.date_ordered).toISOString().substring(0, 10)
      : currentDate.toISOString().substring(0, 10),
    claimType: item?.type ? item?.type : "",
    subType: item?.subtype ? item?.subtype : "",
    eligibilityResponseIdentifierValue: item?.verification_resp_ident_value
      ? item?.verification_resp_ident_value
      : "",
    eligibilityResponseIdentifierUrl: item?.verification_resp_ident_url
      ? item?.verification_resp_ident_url
      : "",
    offlineEligibilityId: item?.offline_verification_id
      ? item?.offline_verification_id
      : "",
    offlineEligibilityDate: item?.offline_verification_date
      ? item?.offline_verification_date
      : "",
    priority: item?.priority ? item?.priority : "stat",
    payeeType: "Provider",
    payee: "",
    relatedPreAuthRefNo: item?.related_pre_auth_ref_no
      ? item?.related_pre_auth_ref_no
      : "",
    referringProvider: "",
    //claim updated info
    preAuthRefNo: item?.offline_pre_auth_no ? item?.offline_pre_auth_no : "",
    // preAuthRefDate: item?.pre_auth_ref_date ? item?.pre_auth_ref_date : "",
    offlinePreAuthDate: item?.offline_pre_auth_date
      ? item?.offline_pre_auth_date
      : "",
    preAuthRespValue: item?.pre_auth_resp_value
      ? item?.pre_auth_resp_value
      : "",
    preAuthRespUrl: item?.pre_auth_resp_url ? item?.pre_auth_resp_url : "",
    episodeNo: item?.episode_no ? item?.episode_no : "",
    //end claim updated info
    //care Teams
    careTeamsArr: item?.care_teams
      ? item?.care_teams.map((el, index) => {
          return {
            practitioner: el.physician_id,
            practitionerName: el.name,
            practitioner_role: el.practitioner_role,
            care_team_role: el.care_team_role,
            qualification: el.speciality,
          };
        })
      : [],
    //diagnosis
    diagnosisArr: item?.diagnosis
      ? item?.diagnosis.map((el, index) => {
          return {
            id: index + 1,
            codeDescription: el.description,
            type: el.type,
            on_admission: el?.on_admission || false,
            codeId: el.code,
          };
        })
      : [],
    //supporting Info
    supportingInfos: item?.supporting_info
      ? item?.supporting_info.map((el, index) => {
          return setSupportingInfo({
            ...el,
            valueUnit: el?.unit,
            fileName: el?.image_title,
            fileType: el?.image_content_type,
            attachment: el?.image_value,
            codeText: el?.code_text,
            code: el?.code,
          });
        })
      : [],
    //accident info
    accident_type: "",
    street: "",
    city: "",
    state: "",
    country: "",
    date: "",
    is_accident: false,
    //items
    items: item?.items
      ? item?.items.map((el, index) => {
          return {
            type: el.type,
            standardCode: el.standard_code,
            standardCodeDescription: el.standard_description,
            non_standard_code: el.non_standard_code,
            non_standard_description: el.non_standard_description,
            body_site: el.body_site,
            sub_site: el.sub_site,
            service_start_date: el.service_start_date,
            service_end_date: el.service_end_date,
            //not getting this info in response
            supporting_info: el.supporting_info,
            diagnosis: el.diagnosis,
            care_teams: el.care_teams,
            //
            is_package: el.is_package,
            quantity: el.quantity,
            unit_price: el.unit_price,
            tax_amount: el.tax_amount,
            factor: el.factor,
            net: el.net,
            discount_amount: el.discount_amount,
            patient_share: el.patient_share,
            //not getting this info in response
            patientShareAmount: el?.patient_share_amount,
            payer_share: el?.payer_share,
            medicationSelectionReason: el.medication_selection_reason,
            prescribedMedication: el.prescribed_medication,
            //
            invoice_no: el.invoice_no,
          };
        })
      : [],
    //vision prescription
    visionPrescriptionDateWritten: item?.vision_prescription_data?.date_written
      ? item?.vision_prescription_data?.date_written
      : null,
    lens_specifications: item?.vision_prescription_data?.lens_specifications
      ? item?.vision_prescription_data?.lens_specifications?.map(
          (item, index) => {
            return {
              product: item?.product ? item?.product : "",
              color: item?.color ? item?.color : "",
              brand: item?.brand ? item?.brand : "",
              notes: item?.notes ? item?.notes : "",
              rightSphere: item?.right?.sphere ? item?.right?.sphere : "",
              rightCylinder: item?.right?.cylinder ? item?.right?.cylinder : "",
              rightAxis: item?.right?.axis ? item?.right?.axis : "",
              rightAdd: item?.right?.add ? item?.right?.add : "",
              rightPrismUnit: item?.right?.prism?.base
                ? item?.right?.prism?.base
                : "",
              rightPrismVal: item?.right?.prism?.amount
                ? item?.right?.prism?.amount
                : "",
              rightPowerContact: item?.right?.power ? item?.right?.power : "",
              rightBackCurveContact: item?.right?.back_curve
                ? item?.right?.back_curve
                : "",
              rightDiameterContact: item?.right?.diameter
                ? item?.right?.diameter
                : "",
              rightUnitContact: item?.right?.duration?.time
                ? item?.right?.duration?.time
                : "",
              rightDurationContact: item?.right?.duration?.value
                ? item?.right?.duration?.value
                : "",
              leftSphere: item?.left?.sphere ? item?.left?.sphere : "",
              leftCylinder: item?.left?.cylinder ? item?.left?.cylinder : "",
              leftAxis: item?.left?.axis ? item?.left?.axis : "",
              leftAdd: item?.left?.add ? item?.left?.add : "",
              leftPrismUnit: item?.left?.prism?.base
                ? item?.left?.prism?.base
                : "",
              leftPrismVal: item?.left?.prism?.amount
                ? item?.left?.prism?.amount
                : "",
              leftPowerContact: item?.left?.power ? item?.left?.power : "",
              leftBackCurveContact: item?.left?.back_curve
                ? item?.left?.back_curve
                : "",
              leftDiameterContact: item?.left?.diameter
                ? item?.left?.diameter
                : "",
              leftUnitContact: item?.left?.duration?.time
                ? item?.left?.duration?.time
                : "",
              leftDurationContact: item?.left?.duration?.value
                ? item?.left?.duration?.value
                : "",
            };
          }
        )
      : [],
    prescriber_id: item?.vision_prescription_data?.prescriber_id
      ? item?.vision_prescription_data?.prescriber_id
      : null,
    isVisionPrescription: item?.vision_prescription_data ? true : false,
    //encounter fields
    isEncounter: false,
    encounterStatus: item?.encounter?.status ? item?.encounter?.status : null,
    encounterClass: item?.encounter?.class ? item?.encounter?.class : null,
    serviceType: item?.encounter?.service_type
      ? item?.encounter?.service_type
      : null,
    encounterPriority: item?.encounter?.priority
      ? item?.encounter?.priority
      : null,
    periodStart: item?.encounter?.period_start
      ? item?.encounter?.period_start
      : null,
    periodEnd: item?.encounter?.period_end ? item?.encounter?.period_end : null,
    adminSource: item?.encounter?.admin_source
      ? item?.encounter?.admin_source
      : null,
    readmission: item?.encounter?.readmission
      ? item?.encounter?.readmission
      : null,
    dischargeDisposition: item?.encounter?.discharge_disposition
      ? item?.encounter?.discharge_disposition
      : null,
  };

  const addData = (values) => {
    // setShowLoader(true);

    requestClaims({
      variables: {
        // input: replaceEmptyStringWithNull(formatInputData(postData)), //temp
        input: formatInputData(values),
      },
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};
        }}
        onSubmit={(values, { setSubmitting }) => {
          addData(values);
        }}
        validationSchema={claimsValidationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => {
          return (
            <>
              <div class="pre_auth_req_page">
                <div class="pre_auth_details_top !p-3 !mb-3 !grid !grid-cols-1 md:!grid-cols-2 lg:!grid-cols-3">
                  <div className="">
                    <div class="back" onClick={() => navigate(-1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                          fill="#4D4D53"
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <div class="pre_auth_details_title">
                        <span>Claims</span>
                      </div>
                      <div class="pre_auth_details_content">
                        <span>Claim Req. Form</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <BeneficiaryInfo />
                  </div>
                </div>

                <ClaimsInfo />

                <div className="!grid !grid-cols-1 lg:!grid-cols-2 gap-2">
                  <CareTeam />
                  <Diagnosis />
                </div>

                <SupportingInfoForm />

                <ItemsList />

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                  <IsAccident />
                  <EncounterForm />
                </div>
                {values.claimType == "vision" && <VisionPrescription />}
                <div>
                  <div className="flex justify-center">
                    <Button
                      variant="contained"
                      size="small"
                      type="button"
                      style={{
                        backgroundImage:
                          "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                      }}
                      onClick={handleSubmit}
                      startIcon={<CheckOutlinedIcon />}
                    >
                      {item
                        ? isFollowUp
                          ? "Follow Up Claim Request"
                          : "Update Claim Request"
                        : "Request Claim"}
                    </Button>
                    {/* <button
                      type="button"
                      className="req-auth-btn"
                      onClick={handleSubmit}
                    >
                      {item
                        ? isFollowUp
                          ? "Follow Up Claim Request"
                          : "Update Claim Request"
                        : "Request Claim"}
                    </button> */}
                  </div>
                </div>
              </div>
              <AppErrorAlert data={errorData} />
              <AppLoader
                showLoader={showLoader}
                setShowLoader={setShowLoader}
              />
              {/* <PreAuthResponseModal
              showPreAuthResponseModal={showPreAuthResponseModal}
              setShowPreAuthResponseModal={setShowPreAuthResponseModal}
              currentItem={currentTransactionIdItem}
            /> */}
            </>
          );
        }}
      </Formik>
      <AppModal isOpen={loading || loadingDetails} />
    </>
  );
}

export default ClaimsForm;
