import React, { useState, useEffect, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import {
  FunnelIcon,
  XCircleIcon,
  ShieldCheckIcon,
} from "@heroicons/react/20/solid";
import { useMutation, useQuery } from "@apollo/client";
// import { toast } from "react-toastify";
import toast from "react-hot-toast";

import Filter from "./components/ClaimsFilter";
import claimsGQL from "../../gql/claims";
import gqlPreAuth from "../../gql/preAuth";
import { appContext } from "../../context/ContextProvider";
import Pagination from "../../components/Pagination";

import "./styles/pre-auth.css";
import "../Beneficiary/styles/BeneficiaryList.css";
import AppErrorAlert from "../../components/AppErrorAlert";
import AppModal from "../../components/AppModal";
import ClaimJsonViewModal from "./ClaimJsonViewModal";
import { Alert, Badge, Chip, Tooltip } from "@mui/material";
import { MdArrowCircleUp, MdOutlineRemoveRedEye } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaArrowUpFromBracket, FaRegCircleCheck } from "react-icons/fa6";
import { TbEditCircle } from "react-icons/tb";
import { BsFiletypeJson } from "react-icons/bs";
import ClaimReportModal from "./components/ClaimReportModal";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FileUpload, HistoryOutlined } from "@mui/icons-material";
import UploadClaimModal from "./UploadClaimModal";
import { RiMessage2Line } from "react-icons/ri";
import ConformationModal from "../../components/ConformationModal";

function ClaimsList(props) {
  const { setShowLoader, setShowErrorAlert } = useContext(appContext);
  const navigate = useNavigate();
  const currentDate = new Date();
  const [loading, setLoading] = useState(false);

  const [excelData, setExcelData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [allClaims, setAllClaims] = useState([]);
  const [claimsTotal, setClaimsTotal] = useState(0);
  const [claimsPages, setClaimsPages] = useState(0);

  const [reportOpen, setReportOpen] = useState(false);
  const handleReportOpen = () => {
    setReportOpen(true);
  };

  const handleReportClose = () => {
    setReportOpen(false);
  };

  const removeEmptyKeys = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== "") {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const claimsFilterDate = removeEmptyKeys(
    JSON.parse(localStorage.getItem("claimsFilterDate"))
  );

  const [activeTab, setActiveTab] = useState(
    claimsFilterDate?.outcome ? claimsFilterDate?.outcome : ""
  );
  const [item, setItem] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUploadClaimModal, setShowUploadClaimModal] = useState(false);

  const [fromDate, setFromDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-01`
  );
  const [toDate, setToDate] = useState(
    `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate()}`
  );

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(
    +JSON?.parse(localStorage?.getItem("claimsPageNo")) || 1
  );

  const [refetchFilterVariables, setRefetchFilterVariables] = useState({
    limit: limit,
    from_date: fromDate,
    to_date: toDate,
  });

  // Function to remove empty keys

  const {
    loading: dataLoading,
    error,
    data,
    refetch,
  } = useQuery(claimsGQL.GET_CLAIMS_BY_FILTER, {
    variables: {
      input: {
        from_date: fromDate,
        to_date: toDate,
        limit: 10,
        page: pageNo,
        ...(activeTab && { outcome: activeTab }),
        ...claimsFilterDate,
      },
    },
  });

  useEffect(() => {
    if (data?.listClaimTransactions?.data?.length > 0) {
      setAllClaims(data?.listClaimTransactions?.data);
      setClaimsPages(data?.listClaimTransactions?.pages);
      setClaimsTotal(data?.listClaimTransactions?.total);
    } else {
      setAllClaims([]);
    }
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors[0]?.message) {
      toast.error(error?.graphQLErrors[0]?.message);
    }
  }, [error]);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSeelectedItem] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const {
  //   loading: excelDataLoading,
  //   error: excelDataError,
  //   data: excelDataResponse,
  //   refetch: refetchExcelDataResponse,
  // } = useQuery(claimsGQL.GET_PREAUTH_EXCEL_DATA, {
  //   variables: {
  //     input: {
  //       from_date: currentDate.toISOString().substring(0, 10),
  //       to_date: currentDate.toISOString().substring(0, 10),
  //     },
  //   },
  // });

  // useEffect(() => {
  //   if (excelDataResponse) {
  //     setExcelData(
  //       JSON.parse(excelDataResponse?.listPreAuthTransactionsExcel?.data)
  //     );
  //   }
  // }, [excelDataResponse]);

  const tableHeaders = [
    {
      name: "SQ #",
    },
    {
      name: "Benefeciary Name",
    },
    {
      name: "Payer",
    },
    // {
    //   name: "Request Bundle ID",
    // },
    // {
    //   name: "Response Bundle ID",
    // },
    // {
    //   name: "Claim Reference",
    // },
    {
      name: "Batch ID",
    },
    {
      name: "Date Ordered",
    },
    {
      name: "Type",
    },
    {
      name: "Episode No.",
    },
    {
      name: "Communication",
    },
    {
      name: "Outcome",
    },
    {
      name: "Status",
    },

    {
      name: "Action",
    },
  ];

  const onDataRowClicked = (event, item) => {
    // if (
    //   event.target.tagName !== "H6" &&
    //   event.target.tagName !== "I" &&
    //   event.target.tagName !== "BUTTON" &&
    //   !!event.target.closest("td")
    // ) {
    //   setItem(item);
    //   setShowPreAuthResponseModal(true);
    // }
    navigate("/claims/details", { state: { currentItem: item } });
  };

  const onPageChange = async (currentPage) => {
    const claimsFilterDate = JSON.parse(
      localStorage.getItem("claimsFilterDate")
    );
    claimsFilterDate.outcome = activeTab;

    setPageNo(currentPage);

    // Function to remove empty keys
    const removeEmptyKeys = (obj) => {
      const newObj = {};
      for (const key in obj) {
        if (obj[key] !== "") {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    };

    // Remove empty keys from claimsFilterDate
    const filteredClaimsFilterDate = removeEmptyKeys(claimsFilterDate);

    let filterInput = {
      ...filteredClaimsFilterDate,
      page: currentPage,
      limit: limit,
    };

    try {
      setLoading(true);
      await refetch({
        input: filterInput,
      }).then(({ data }) => {
        setAllClaims(data?.listClaimTransactions?.data);
        setClaimsPages(data?.listClaimTransactions?.pages);
        setClaimsTotal(data?.listClaimTransactions?.total);
      });
    } finally {
      setLoading(false);
    }
  };

  //check Status
  const [
    checkReq,
    { data: checkData, loading: checkLoading, error: checkError },
  ] = useMutation(claimsGQL.CHECK_STATUS, {
    update(proxy, result) {
      onSuccessfulCheckRequestResponse(result.data);
    },
    onError(error) {
      onCancelResponseError(error.graphQLErrors[0]);
    },
  });

  const onSuccessfulCheckRequestResponse = (data) => {
    if (!data.checkStatusRequest.nphies_err) {
      toast.success("Successfully Requested!");
    } else if (data.checkStatusRequest.nphies_err) {
      setErrorData(data.checkStatusRequest.nphies_err);
      setShowErrorAlert(true);
    } else {
      toast.error(`Unknown Error`);
    }
  };

  const checkClicked = (item) => {
    toast.loading("Requesting!");
    checkReq({
      variables: {
        input: {
          transaction_id: item.transaction_id,
          request_name: "claims",
        },
      },
    });
  };
  //end status check

  //cancel request
  const [
    cancelReq,
    { data: cancelData, loading: cancelLoading, error: cancelError },
  ] = useMutation(gqlPreAuth.CANCEL_REQUEST, {
    update(proxy, result) {
      onSuccessfulCancelRequestResponse(result.data);
    },
    onError(error) {
      onCancelResponseError(error.graphQLErrors[0]);
    },
  });

  const onSuccessfulCancelRequestResponse = (data) => {
    if (data.cancelRequest) {
      toast.success("Successfully Cancelled!");
    } else {
    }
  };

  const onCancelResponseError = (error) => {
    toast.error(`${error.message}`);
  };

  const cancelClicked = async () => {
    cancelReq({
      variables: {
        input: {
          transaction_id: selectedItem?.transaction_id,
          request_name: "claims",
        },
      },
    }).then(async (res) => {
      const claimsFilterDate = JSON.parse(
        localStorage.getItem("claimsFilterDate")
      );

      let filterInput = {
        ...claimsFilterDate,
        page: pageNo,
        limit: limit,
        outcome: activeTab,
      };

      // Remove empty keys from claimsFilterDate
      const filteredClaimsFilterDate = removeEmptyKeys(filterInput);

      try {
        setLoading(true);
        await refetch({
          input: filteredClaimsFilterDate,
        }).then(({ data }) => {
          setAllClaims(data?.listClaimTransactions?.data);
          setClaimsPages(data?.listClaimTransactions?.pages);
          setClaimsTotal(data?.listClaimTransactions?.total);
        });
      } finally {
        setLoading(false);
      }
    });

    setSeelectedItem(null);
    setShowConfirmModal(false);
  };
  //end Cancel Request

  function OutcomeComponent({ outcome }) {
    if (outcome == "queued") {
      return <span className="queued">Queued</span>;
    } else if (outcome == "complete") {
      return <span className="outcome-complete">Complete</span>;
    } else if (outcome == "error") {
      return <span className="outcome-error">Error</span>;
    } else if (outcome == "Abnormal Request") {
      return <span className="outcome-error">Abnormal Request</span>;
    }
    // else if (outcome == "error") {
    //   return <label className="badge badge-danger">Error</label>;
    // } else if (outcome == "cancelled") {
    //   return <label className="badge badge-danger">Cancelled</label>;
    // } else if (outcome == "rejected") {
    //   return <label className="badge badge-danger">Rejected</label>;
    // }
    else {
      return <label className="badge badge-danger">Unknown</label>;
    }
  }

  function StatusComponent({ outcome }) {
    if (outcome == "queued") {
      return "Pending";
    } else if (outcome == "complete") {
      return "Approved";
    } else if (outcome == "error") {
      return "Error";
    } else if (outcome == "cancelled") {
      return "Cancelled";
    } else if (outcome == "rejected") {
      return "Rejected";
    } else {
      return "Unknown";
    }
  }

  const {
    loading: pollLoading,
    error: pollError,
    data: pollData,
    refetch: refetchPoll,
  } = useQuery(claimsGQL.CLAIMS_POLL_REQ, {
    skip: true, // Skip initial fetching
  });

  const pollClicked = () => {
    toast.loading("Requesting!");
    refetchPoll().then(async (res) => {
      toast.success(res?.data?.pollRequest?.message || "Request Successfully!");

      const claimsFilterDate = JSON.parse(
        localStorage.getItem("claimsFilterDate")
      );

      let filterInput = {
        ...claimsFilterDate,
        page: pageNo,
        limit: limit,
        outcome: activeTab,
      };

      // Remove empty keys from claimsFilterDate
      const filteredClaimsFilterDate = removeEmptyKeys(filterInput);

      try {
        setLoading(true);
        await refetch({
          input: filteredClaimsFilterDate,
        }).then(({ data }) => {
          setAllClaims(data?.listClaimTransactions?.data);
          setClaimsPages(data?.listClaimTransactions?.pages);
          setClaimsTotal(data?.listClaimTransactions?.total);
        });
      } finally {
        setLoading(false);
      }
    });
  };

  const handleTabClick = async (tabName) => {
    setActiveTab(tabName);
    const claimsFilterDate = JSON.parse(
      localStorage.getItem("claimsFilterDate")
    );
    setPageNo(1);

    let filterInput = {
      ...claimsFilterDate,
      page: pageNo,
      limit: limit,
      outcome: tabName,
    };

    // Remove empty keys from claimsFilterDate
    const filteredClaimsFilterDate = removeEmptyKeys(filterInput);

    try {
      setLoading(true);
      await refetch({
        input: filteredClaimsFilterDate,
      }).then(({ data }) => {
        setAllClaims(data?.listClaimTransactions?.data);
        setClaimsPages(data?.listClaimTransactions?.pages);
        setClaimsTotal(data?.listClaimTransactions?.total);
      });
    } finally {
      setLoading(false);
    }
  };

  const getColor = (status) => {
    if (status === "rejected") {
      return "error";
    } else if (status === "approved") {
      return "success";
    } else if (status === "pending") {
      return "info";
    } else if (status === "pended") {
      return "secondary";
    } else if (status === "queued") {
      return "warning";
    } else if (status === "complete") {
      return "success";
    } else if (status === "error") {
      return "error";
    } else if (status === "Abnormal Request") {
      return "error";
    } else if (status === "Exception Error") {
      return "error";
    } else if (status === "Validation Error") {
      return "error";
    } else if (status === "not-required") {
      return "warning";
    } else {
      return "secondary";
    }
  };

  // dropdown menu

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="pre_auth_container !p-0">
        <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
          <div className="">
            <Tooltip
              placement="right"
              title="Keep track of your Claims Transactions"
              className="my-auto !mt-2"
            >
              <span className="font-semibold text-black/70 text-xl ">
                CLAIMS TRANSACTION
                <span className="italic text-lg ml-1">
                  ({claimsTotal || 0}{" "}
                  <span className="text-xs font-bold italic">Entries</span>)
                </span>
              </span>
            </Tooltip>
          </div>
          <div className="flex gap-3 flex-col lg:flex-row">
            {/* <Button
              variant="outlined"
              onClick={() => setReportOpen(true)}
              size="small"

              className="!border-primary !text-primary hover:!bg-primary/10"
              startIcon={<FileDownloadOutlinedIcon />}
            >
              Download Report
            </Button> */}

            {/* <Button
              variant="contained"
              onClick={() => pollClicked()}
              size="small"
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<ImportExportIcon />}
            >
              Poll
            </Button> */}

            {/* <div>
              <Button
                id="demo-customized-button"
                aria-controls={openMenu ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                size="small"
                endIcon={<KeyboardArrowDownIcon />}
                className="!shadow-lg"
                style={{
                  backgroundImage:
                    "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                }}
              >
                Claim Options
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    navigate("/claims/submit");
                  }}
                  disableRipple
                >
                  <AddIcon />
                  Submissions
                </MenuItem>
           
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    setReportOpen(true);
                  }}
                  disableRipple
                >
                  <FileDownloadOutlinedIcon />
                  Download Report
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    navigate("/claims/history");
                  }}
                  disableRipple
                >
                  <HistoryOutlined />
                  Claim History
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    navigate("/claims/uploads");
                  }}
                  disableRipple
                >
                  <HistoryOutlined />
                  Upload Claims
                </MenuItem>
              </StyledMenu>
            </div> */}

            <Button
              variant="contained"
              onClick={() => setReportOpen(true)}
              size="small"
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<FileDownloadOutlinedIcon />}
            >
              Download Report
            </Button>

            <Button
              variant="contained"
              size="small"
              onClick={() => navigate("/claims/form")}
              style={{
                backgroundImage:
                  "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
              }}
              startIcon={<AddIcon />}
            >
              Request Claim
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setShowFilter(() => !showFilter)}
              className="!border-primary !text-primary hover:!bg-primary/10"
              startIcon={<FilterAltOutlinedIcon />}
            >
              Apply Filter
            </Button>
          </div>
        </div>

        <Filter
          refetch={refetch}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setPageNo={setPageNo}
          setLimit={setLimit}
          pageNo={pageNo}
          limit={limit}
          // refetchExcelDataResponse={refetchExcelDataResponse}
          setFromDate={setFromDate}
          setToDate={setToDate}
          setLoading={setLoading}
          setRefetchFilterVariables={setRefetchFilterVariables}
          activeTab={activeTab}
          setAllClaims={setAllClaims}
          setClaimsTotal={setClaimsTotal}
          setClaimsPages={setClaimsPages}
        />
        {/* </div> */}

        <div className="my-2">
          {/* <ul className="hidden text-sm font-medium text-center text-gray-500 rounded shadow-md sm:flex border border-gray-100">
            <li className="w-full focus-within:z-10">
              <a
                href="#"
                className={`inline-block w-full p-4 rounded-l-md ${
                  activeTab === ""
                    ? "gradient-background text-white "
                    : "bg-white text-gray-800 border-r border-gray-300"
                }`}
                onClick={() => handleTabClick("")}
              >
                All Claims
              </a>
            </li>
            <li className="w-full focus-within:z-10">
              <a
                href="#"
                className={`inline-block w-full p-4 ${
                  activeTab === "queued"
                    ? "gradient-background text-white "
                    : "bg-white text-gray-800 border-r border-gray-200"
                }`}
                onClick={() => handleTabClick("queued")}
              >
                Queued
              </a>
            </li>
            <li className="w-full focus-within:z-10">
              <a
                href="#"
                className={`inline-block w-full p-4 ${
                  activeTab === "error"
                    ? "gradient-background text-white"
                    : "bg-white text-gray-800 border-r border-gray-200"
                }`}
                onClick={() => handleTabClick("error")}
              >
                Error
              </a>
            </li>
            <li className="w-full focus-within:z-10">
              <a
                href="#"
                className={`inline-block w-full p-4 ${
                  activeTab === "Abnormal Request"
                    ? "gradient-background text-white"
                    : "bg-white text-gray-800 border-r border-gray-200"
                }`}
                onClick={() => handleTabClick("Abnormal Request")}
              >
                Abnormal Request
              </a>
            </li>

            <li className="w-full focus-within:z-10">
              <a
                href="#"
                className={`inline-block w-full p-4 ${
                  activeTab === "Exception Error"
                    ? "gradient-background text-white"
                    : "bg-white text-gray-800 border-r border-gray-200"
                }`}
                onClick={() => handleTabClick("Exception Error")}
              >
                Exception Error
              </a>
            </li>
            <li className="w-full focus-within:z-10">
              <a
                href="#"
                className={`inline-block w-full p-4 rounded-r-md ${
                  activeTab === "complete"
                    ? "gradient-background text-white"
                    : "bg-white text-gray-800 border-r border-gray-200"
                }`}
                onClick={() => handleTabClick("complete")}
              >
                Complete
              </a>
            </li>
          </ul> */}

          <ul className="grid grid-flow-col text-center text-gray-500 bg-gray-100 rounded p-1 shadow-inner">
            <li>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === ""
                    ? "gradient-background rounded shadow text-white"
                    : ""
                }`}
                onClick={() => handleTabClick("")}
              >
                All Claims
              </a>
            </li>
            <li>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === "queued"
                    ? "gradient-background rounded shadow text-white"
                    : ""
                }`}
                onClick={() => handleTabClick("queued")}
              >
                Queued
              </a>
            </li>
            <li>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === "error"
                    ? "gradient-background rounded shadow text-white"
                    : ""
                }`}
                onClick={() => handleTabClick("error")}
              >
                Error
              </a>
            </li>
            <li>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === "Abnormal Request"
                    ? "gradient-background rounded shadow text-white"
                    : ""
                }`}
                onClick={() => handleTabClick("Abnormal Request")}
              >
                Abnormal Request
              </a>
            </li>
            <li>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === "Exception Error"
                    ? "gradient-background rounded shadow text-white"
                    : ""
                }`}
                onClick={() => handleTabClick("Exception Error")}
              >
                Exception Error
              </a>
            </li>

            <li>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === "Validation Error"
                    ? "gradient-background rounded shadow text-white"
                    : ""
                }`}
                onClick={() => handleTabClick("Validation Error")}
              >
                Validation Error
              </a>
            </li>

            <li>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === "complete"
                    ? "gradient-background rounded shadow text-white"
                    : ""
                }`}
                onClick={() => handleTabClick("complete")}
              >
                Complete
              </a>
            </li>
          </ul>
        </div>

        {allClaims ? (
          allClaims?.map((item, index) => (
            <div
              key={"claimsData_" + index}
              onClick={() => setSeelectedItem(item)}
            >
              <div className="eligibility_details_section !my-2 !p-0 !border !border-gray-100 !shadow-md ">
                <div className="eligibility_details_body_cont !grid grid-cols-1 sm:grid-cols-2  md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-12 !p-0 !border-none ">
                  <div className="eligibility_details_user_data py-2  col-span-1">
                    <div className=" data_head !text-xs !text-center">
                      <span>Payer</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm  ">
                        {item?.payer_name || "___"}
                      </span>
                    </div>
                  </div>
                  <div className="eligibility_details_user_data py-2 col-span-1  ">
                    <div className=" data_head !text-xs !text-center">
                      <span>Beneficiary Name</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm   ">
                        {item?.patient_name || "___"}
                      </span>
                    </div>
                  </div>

                  {/* <div className="eligibility_details_user_data py-2  col-span-2">
                    <div className=" data_head !text-xs !text-center">
                      <span>Request Bundle ID</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm ">
                        {item?.request_bundle_id || "___"}
                      </span>
                    </div>
                  </div> */}

                  <div className="eligibility_details_user_data py-2 col-span-1">
                    <div className=" data_head !text-xs !text-center">
                      <span>Type</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm ">
                        {item?.type || "___"}
                      </span>
                    </div>
                  </div>

                  <div className="eligibility_details_user_data py-2 col-span-1 ">
                    <div className=" data_head !text-xs !text-center">
                      <span>Claim Reference</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm ">
                        {item?.tp_ref_no || "___"}
                      </span>
                    </div>
                  </div>
                  <div className="eligibility_details_user_data py-2 col-span-1 ">
                    <div className=" data_head !text-xs !text-center">
                      <span>Batch ID</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm ">
                        {item?.batch_id || "___"}
                      </span>
                    </div>
                  </div>
                  <div className="eligibility_details_user_data py-2 col-span-1">
                    <div className=" data_head !text-xs !text-center">
                      <span>Date Ordered</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm ">
                        {item?.date_ordered || "___"}
                      </span>
                    </div>
                  </div>

                  <div className="eligibility_details_user_data py-2 col-span-1 ">
                    <div className=" data_head !text-xs !text-center">
                      <span>Response Date</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm ">
                        {item?.response_date?.length > 16
                          ? item?.response_date
                              .substring(0, 16)
                              .replace("T", " ")
                          : item?.response_date}
                      </span>
                    </div>
                  </div>

                  {/* <div className="eligibility_details_user_data py-2 col-span-2 ">
                    <div className=" data_head !text-xs !text-center">
                      <span>Response Bundle ID</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm ">
                        {item?.response_bundle_id || "___"}
                      </span>
                    </div>
                  </div> */}

                  <div className="eligibility_details_user_data py-2 col-span-1">
                    <div className=" data_head !text-xs !text-center">
                      <span>Episode No.</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm ">
                        {item?.episode_no || "___"}
                      </span>
                    </div>
                  </div>

                  <div className="eligibility_details_user_data py-2 col-span-1">
                    <div className=" data_head !text-xs !text-center">
                      <span>Is Follow UP</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm ">
                        {item?.is_followup_req
                          ? (
                              <Tooltip
                                title={`${item?.followup_ref_transaction_id}`}
                              >
                                <div
                                  className="cursor-pointer text-3xl flex justify-center"
                                  onClick={() =>
                                    navigate(
                                      "/claims/details/" +
                                        item?.followup_ref_transaction_id
                                    )
                                  }
                                >
                                  <MdArrowCircleUp />
                                </div>
                              </Tooltip>
                            ) || "___"
                          : "No" || "___"}
                      </span>
                    </div>
                  </div>

                  {/* <div className="eligibility_details_user_data py-2 col-span-1">
                    <div className=" data_head !text-xs !text-center">
                      <span>Communication</span>
                    </div>
                    <div className="data_cont !text-center">
                      <span className="data_cont !text-sm ">
                        <Tooltip
                          title={`${
                            item?.communication_count || 0
                          } Communications`}
                        >
                          <div className="text-2xl cursor-pointer flex justify-center">
                            <Badge
                              color="secondary"
                              badgeContent={item?.communication_count || 0}
                              showZero
                            >
                              <RiMessage2Line />
                            </Badge>
                          </div>
                        </Tooltip>
                      </span>
                    </div>
                  </div> */}

                  <div className="eligibility_details_user_data py-2  col-span-1">
                    <div className=" data_head !text-xs !text-center">
                      <span>Outcome</span>
                    </div>
                    <div className=" !text-center">
                      <p className=" !text-sm ">
                        {item?.outcome ? (
                          <Chip
                            label={item?.outcome}
                            color={getColor(item?.outcome)}
                            size="small"
                            className="uppercase !text-xs "
                          />
                        ) : (
                          "___"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="eligibility_details_user_data py-2  col-span-1">
                    <div className=" data_head !text-xs !text-center">
                      <span>Status</span>
                    </div>
                    <div className=" !text-center">
                      <p className=" !text-sm ">
                        {item?.status ? (
                          <Chip
                            label={item?.status}
                            color={getColor(item?.status)}
                            size="small"
                            className="uppercase !text-xs !text-white"
                          />
                        ) : (
                          "___"
                        )}
                      </p>
                      {!item?.is_valid ? (
                        <div className="!p-1.5 shadow-md rounded-md text-xs font-semibold bg-red-50">
                          Naphies Validations
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="eligibility_details_user_data py-2  col-span-1">
                    <div className=" data_head !text-xs !text-center">
                      <span>Action</span>
                    </div>
                    <div className=" !text-center">
                      <span className=" !text-sm ">
                        <div className=" flex gap-2 mx-2 justify-center">
                          <Tooltip
                            title={`${
                              item?.communication_count || 0
                            } Communications`}
                          >
                            <div
                              className="text-2xl cursor-pointer "
                              onClick={(event) => onDataRowClicked(event, item)}
                            >
                              <Badge
                                color="secondary"
                                badgeContent={item?.communication_count || 0}
                                showZero
                              >
                                <RiMessage2Line />
                              </Badge>
                            </div>
                          </Tooltip>
                          <Tooltip title="View">
                            <div
                              onClick={(event) => onDataRowClicked(event, item)}
                              className="text-2xl cursor-pointer"
                            >
                              <MdOutlineRemoveRedEye />
                            </div>
                          </Tooltip>

                          {item?.outcome === "error" ||
                          item?.outcome === "Exception Error" ||
                          item?.outcome === "Validation Error" ||
                          // item?.outcome === "queued" ||
                          item?.outcome === "Abnormal Request" ? (
                            <Tooltip title="Edit">
                              <div
                                onClick={() =>
                                  navigate(
                                    "/claims/edit/form/" + item.transaction_id
                                  )
                                }
                                className="text-2xl cursor-pointer"
                              >
                                <TbEditCircle />
                              </div>
                            </Tooltip>
                          ) : null}

                          <Tooltip title="Check">
                            <div
                              onClick={() => checkClicked(item)}
                              className="text-xl cursor-pointer"
                            >
                              <FaRegCircleCheck />
                            </div>
                          </Tooltip>
                        </div>
                        <div className="flex gap-2 mx-2 justify-center ">
                          {item?.outcome === "error" ||
                          item?.outcome === "Abnormal Request" ? null : (
                            <Tooltip title="Cancel">
                              <div
                                onClick={() => {
                                  setSeelectedItem(item);
                                  setShowConfirmModal(true);
                                }}
                                className="text-2xl"
                              >
                                <IoMdCloseCircleOutline />
                              </div>
                            </Tooltip>
                          )}
                          {
                            // item?.is_followup_req &&
                            item?.status === "rejected" ? (
                              <Tooltip title="Follow Up">
                                <div
                                  onClick={() =>
                                    navigate(
                                      `/claims/edit/form/${
                                        item?.transaction_id
                                      }/${"follow-up"}`
                                    )
                                  }
                                  className="text-xl cursor-pointer"
                                >
                                  <FaArrowUpFromBracket />
                                </div>
                              </Tooltip>
                            ) : // : !item?.is_followup_req ? (
                            //   <Tooltip title="Follow Up">
                            //     <div
                            //       onClick={() =>
                            //         navigate(
                            //           `/claims/edit/form/${
                            //             item?.transaction_id
                            //           }/${"follow-up"}`
                            //         )
                            //       }
                            //       className="text-xl cursor-pointer"
                            //     >
                            //       <FaArrowUpFromBracket />
                            //     </div>
                            //   </Tooltip>
                            // )

                            null
                          }

                          <Tooltip title="JSON View">
                            <div
                              onClick={handleClickOpen}
                              className="text-2xl cursor-pointer"
                            >
                              <BsFiletypeJson />
                            </div>
                          </Tooltip>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="">
                  {item?.disposition ? (
                    <Alert className="!py-0 !px-3" severity="error">
                      <span className="!font-semibold">Disposition: </span>{" "}
                      {item?.disposition}
                    </Alert>
                  ) : null}
                  {item?.errors && item?.errors?.length > 0 ? (
                    <Alert className="!py-0 !px-3" severity="error">
                      <span className="!font-semibold">Errors: </span>{" "}
                      {item?.errors
                        .map(
                          (error) =>
                            `${error?.error_code || "___"} - ${
                              error?.error_message || "___"
                            }`
                        )
                        .join(", ")}
                    </Alert>
                  ) : null}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center py-3 shadow-md border border-gray-100">
            <h2 className="text-center text-lg">No Data Found</h2>
          </div>
        )}

        <div>
          <Pagination
            currentPage={pageNo}
            totalPages={claimsPages}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      <AppErrorAlert data={errorData} />

      <ConformationModal
        open={showConfirmModal}
        setOpen={setShowConfirmModal}
        onDelete={() => cancelClicked()}
        text={"Cancel"}
      />

      <ClaimJsonViewModal
        setOpen={setOpen}
        open={open}
        handleClose={handleClose}
        selectedItem={selectedItem}
      />

      <ClaimReportModal
        open={reportOpen}
        setOpen={setReportOpen}
        handleClose={handleReportClose}
        handleClickOpen={handleReportOpen}
      />

      <UploadClaimModal
        showUploadClaimModal={showUploadClaimModal}
        setShowUploadClaimModal={setShowUploadClaimModal}
      />
      <AppModal isOpen={loading || dataLoading} />
    </>
  );
}

export default ClaimsList;

//  {/* <div className="pre_auth_page">
//     <div className="pre_auth_content block lg:flex justify-between">
//       <div className="pre_auth_title">
//         <span>Claims Transactions</span>
//         <div className="track_pre_auth">
//           <span>Keep track of your Claims Transactions</span>
//         </div>
//       </div>
//       <div className="create_pre_auth mt-3 lg:mt-0 search-filter">
//         <DownloadExcel
//           data={excelData}
//           fileName={"PreAuthorizationTransactions"}
//         />
//         <div className="create-btn" onClick={() => setReportOpen(true)}>
//           <span>Report</span>
//         </div>
//         <div className="create-btn" onClick={() => pollClicked()}>
//           <span>Poll</span>
//         </div>
//         <div
//           className="create-btn"
//           onClick={() => navigate("/claims/submit")}
//         >
//           <span>Submissions</span>
//         </div>
//         <div className="create-btn" onClick={() => navigate("/claims/form")}>
//           <span>Request Claim</span>
//         </div>
//         <div className="apply-filter">
//           <div
//             className="btn"
//             onClick={() => setShowFilter(() => !showFilter)}
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="20"
//               height="20"
//               viewBox="0 0 20 20"
//               fill="none"
//             >
//               <path
//                 d="M5.83751 4.99992H14.1708L9.99584 10.2499L5.83751 4.99992ZM3.54584 4.67492C5.22918 6.83325 8.33751 10.8333 8.33751 10.8333V15.8333C8.33751 16.2916 8.71251 16.6666 9.17084 16.6666H10.8375C11.2958 16.6666 11.6708 16.2916 11.6708 15.8333V10.8333C11.6708 10.8333 14.7708 6.83325 16.4542 4.67492C16.8792 4.12492 16.4875 3.33325 15.7958 3.33325H4.20418C3.51251 3.33325 3.12084 4.12492 3.54584 4.67492Z"
//                 fill="black"
//               />
//             </svg>
//             Apply Filter
//           </div>
//         </div>
//       </div>
//     </div>
//   </div> */}
//   {/* <div className="search-filter"> */}
//   {/* <div className="search">
//       <input
//         type="search"
//         name="search"
//         id="search"
//         placeholder="Search"
//       />
//     </div> */}

//       {/* <div className="super-git-table">
//   <table className="table-super-git">
//     <thead>
//       <tr>
//         {tableHeaders.map((el, index) => {
//           return (
//             <th className="" key={"tableHeaders_" + index}>
//               {el.name}
//             </th>
//           );
//         })}
//       </tr>
//     </thead>

//     <tbody>
//       {data?.listClaimTransactions?.data ? (
//         data?.listClaimTransactions?.data?.map((el, index) => {
//           return (
//             <tr
//               key={"beneficiariesData_" + index}
//               onClick={() => setSeelectedItem(el)}
//             >
//               <td>{pageNo * limit + index + 1 - limit}</td>
//               <td>{el?.patient_name}</td>
//               <td>{el?.payer_name}</td>
//               <td>{el?.request_bundle_id}</td>
//               <td>{el?.response_bundle_id}</td>
//               <td>{el?.tp_ref_no}</td>
//               <td>{el?.batch_id}</td>
//               <td>{el?.date_ordered}</td>
//               <td>{el?.type}</td>
//               <td>{el?.episode_no}</td>
//               <td style={{ minWidth: "200px" }}>
//                 <OutcomeComponent outcome={el.outcome} />
//               </td>
//               <td>{el.status}</td>

//               <td className="user-action flex gap-2 mx-2 justify-end">
//                 <Tooltip title="View">
//                   <div
//                     onClick={(event) => onDataRowClicked(event, el)}
//                     className="text-2xl"
//                   >
//                     <MdOutlineRemoveRedEye />
//                   </div>
//                 </Tooltip>

//                 {el?.outcome === "error" ||
//                 el?.outcome === "Abnormal Request" ? (
//                   <Tooltip title="Edit">
//                     <div
//                       onClick={() =>
//                         navigate(
//                           "/claims/edit/form/" + el.transaction_id
//                         )
//                       }
//                       className="text-2xl"
//                     >
//                       <TbEditCircle />
//                     </div>
//                   </Tooltip>
//                 ) : null}

//                 <Tooltip title="Cancel">
//                   <div
//                     onClick={() => cancelClicked(el)}
//                     className="text-2xl"
//                   >
//                     <IoMdCloseCircleOutline />
//                   </div>
//                 </Tooltip>

//                 <Tooltip title="Check">
//                   <div
//                     onClick={() => checkClicked(el)}
//                     className="text-xl"
//                   >
//                     <FaRegCircleCheck />
//                   </div>
//                 </Tooltip>

//                 <Tooltip title="JSON View">
//                   <div onClick={handleClickOpen} className="text-2xl">
//                     <BsFiletypeJson />
//                   </div>
//                 </Tooltip>
//               </td>
//             </tr>
//           );
//         })
//       ) : (
//         <tr>
//           <td colspan="6">
//             <div className="flex justify-center">
//               <h2 className="text-center text-lg">No Data Found</h2>
//             </div>
//           </td>
//         </tr>
//       )}
//     </tbody>
//   </table>
// </div> */}
