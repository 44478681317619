import React from "react";

function AccordianItemDescription({ item }) {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Item name</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.category_name}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Description</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.description}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Network</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.network}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Unit</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.unit}</dd>
      </div>
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Term</dt>
        <dd className="mt-1 text-sm text-gray-900">{item?.term}</dd>
      </div>
    </dl>
  );
}

export default AccordianItemDescription;
