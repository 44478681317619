import React from "react";

function Classes({ item }) {
  function getDateDifferenceFormatted(dateRangeString) {
    if (dateRangeString) {
      // Split the date range string into start and end date strings
      const [startDateString, endDateString] = dateRangeString.split(" - ");

      // Convert the start and end date strings to Date objects
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);

      // Format the dates as strings
      const formattedStartDate = startDate.toLocaleDateString();
      const formattedEndDate = endDate.toLocaleDateString();

      return `${formattedStartDate} - ${formattedEndDate}`;
    } else {
      return "___";
    }
  }

  return (
    <>
      <div class="eligibility_details_section">
        <div class="eligibility_details_head">
          <div class="eligibility_details_body_cont !grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3  !p-0 !border-none !shadow-none">
            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Type</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.class_type || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Name</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {getDateDifferenceFormatted(item?.class_name)}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Value</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.class_value || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Type</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.class_group_type || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Name</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.class_group_name || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data  border-b px-3 py-6">
              <div class="data_head !text-center">
                <span>Value</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.class_group_value || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data px-3 py-6">
              <div class="data_head !text-center">
                <span>Type</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.class_plan_type || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data   px-3 py-6">
              <div class="data_head !text-center">
                <span>Name</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.class_plan_name || "___"}
                </span>
              </div>
            </div>

            <div class="eligibility_details_user_data   px-3 py-6">
              <div class="data_head !text-center">
                <span>Value</span>
              </div>
              <div class="data_cont !text-center">
                <span className="!text-base !font-semibold">
                  {item?.class_plan_value || "___"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Classes;
