import React, { useContext } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import AppFlow from "../src/navigation/AppFlow";
import { appContext } from "./context/ContextProvider";

function ApolloAppProvider(props) {
  const { token, setToken } = useContext(appContext);

  const httpLink = createHttpLink({
    uri: "https://api.super-git.com/query",
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const errorLink = onError(({ networkError }) => {
    if (networkError && networkError.statusCode === 403) {
      // Handle the forbidden (403) response here
      console.log(":: Forbidden request!");
      localStorage.clear();
      setToken(null);
      // You can call a function or perform any custom logic you need
    }
  });

  const client = new ApolloClient({
    link: errorLink.concat(authLink).concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <AppFlow />
    </ApolloProvider>
  );
}

export default ApolloAppProvider;
