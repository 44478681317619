import React, { useState } from "react";
import Modal from "react-modal";
import { Formik } from "formik";

import { Switch } from "@headlessui/react";
import _ from "lodash";
import DatePicker from "react-datepicker";
import ResponseDetailsComponent from "./ResponseDetailsComponent";
import ItemClassesAndBenefeciaries from "./ItemClassesAndBenefeciaries";
import ReactJsonView from "./ReactJsonView";
import ResponseDetailsItemsAccordian from "./ResponseDetailsItemsAccordian";
import JsonTable from "./JsonTable";
import { XMarkIcon } from "@heroicons/react/20/solid";
import eligibility from "../../../gql/eligibility";
import { useQuery } from "@apollo/client";
import Coverages from "./response/Coverages";

// import { AiOutlineClose } from "react-icons/hi";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minHeight: "90%",
    maxWidth: "90%",
    maxHeight: "75vh",
    overflowY: "scroll",
    padding: "0px !important",
  },
};

let subtitle;

function EligibilityResponseModal({
  showEligibilityResponseModal,
  setShowEligibilityResponseModal,
  item,
}) {
  const currentDate = new Date();

  const [enabled, setEnabled] = useState(false);
  const [showItems, setShowItems] = useState(true);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  const { loading, error, data, refetch } = useQuery(
    eligibility.GET_TRANSACTION_DETAILS,
    {
      variables: {
        transactionId: item.transaction_id,
      },
      skip: true, // Skip initial fetching
    }
  );

  const [currentTab, setCurrentTab] = useState("Details");

  const tabs = [
    { name: "Details", href: "#", current: true },
    { name: "JSON", href: "#", current: false },
  ];

  const types = [
    { id: 1, name: "HCP" },
    { id: 2, name: "Public HCP" },
  ];

  const addData = (values) => {
    setShowEligibilityResponseModal(false);
    let data = _.cloneDeep(values);
  };

  const tableHeaders = [
    {
      name: "In-Force",
    },
    {
      name: "Benefit Date",
    },
    {
      name: "Member ID",
    },
    {
      name: "Policy Number",
    },
    {
      name: "Type",
    },
    {
      name: "Status",
    },
    {
      name: "Relationship With Subscriber",
    },
    {
      name: "Subscriber Member ID",
    },
    {
      name: "Network",
    },
    {
      name: "Subrogation",
    },
    {
      name: "Site Eligibility",
    },
    {
      name: "Actions",
    },
  ];

  const [eligibilityResponseData, setEligibilityResponseData] = useState([
    {
      id: 1,
      in_force: "Yes",
      benefit_date: "01/01/2023 - 31/12/2023",
      member_id: 35444349,
      policy_number: 22001814,
      Type: "Extended HealthCare",
      Status: true,
      relation_with_subscriber: "self",
      subscriber_member_id: "",
      Network: "0222",
      Subrogation: false,
      site_eligibility: "eligible",
      visible: false,
    },
  ]);

  // const showItemDetails = (index) => {
  //   let temp = _.cloneDeep(eligibilityResponseData);
  //   temp[index].visible = !eligibilityResponseData[index].visible;
  //   setEligibilityResponseData(temp);
  // };

  return (
    <div className="">
      <Modal
        // isOpen={true}
        isOpen={showEligibilityResponseModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setShowEligibilityResponseModal(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* First Row */}
        <hr className="  " />
        <div className="flex justify-between items-center py-1  ">
          <div>
            <h2 className="font-bold text-xl text-cyan-600">
              Eligibility - {item?.eligibility_id}
            </h2>
          </div>
          <div className="flex align-items-baseline gap-4 ">
            <h4 className="font-bold text-xl text-cyan-600">
              Transaction - {item?.transaction_id}
            </h4>
            <XMarkIcon
              className="h-5 w-5 text-gray-900 hover:cursor-pointer "
              aria-hidden="true"
              onClick={() => setShowEligibilityResponseModal(false)}
            />
          </div>
        </div>
        <hr />
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        {data && (
          <>
            <div className="py-1">
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                  id="tabs"
                  name="tabs"
                  onChange={(e) => setCurrentTab(e.target.value)}
                  className="block w-full rounded-md border-gray-300 focus:border-cyan-500 focus:ring-cyan-500"
                  defaultValue={tabs.find((tab) => tab.current).name}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <nav className="flex space-x-4" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      className={classNames(
                        currentTab == tab.name
                          ? "bg-cyan-100 text-cyan-600"
                          : "text-gray-500 hover:text-gray-700",
                        "px-3 py-2 font-medium text-sm rounded-md"
                      )}
                      onClick={(e) => setCurrentTab(tab.name)}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <hr />
            {currentTab == "Details" ? (
              <div className="preauth-form ">
                <form className=" divide-gray-200">
                  <div className=" mt-2">
                    {/* SECOND ROW */}
                    <div className="">
                      <ResponseDetailsComponent
                        item={data?.getVerificationTransaction?.Data}
                      />
                    </div>
                  </div>

                  {/* Coverages */}
                  <Coverages
                    item={data?.getVerificationTransaction?.Data?.Coverages}
                  />

                  {showItems && (
                    <>
                      <div>
                        <ItemClassesAndBenefeciaries
                          item={data?.getVerificationTransaction?.Data}
                        />
                      </div>

                      <div>
                        <ResponseDetailsItemsAccordian
                          item={data?.getVerificationTransaction?.Data}
                        />
                      </div>
                    </>
                  )}
                </form>
              </div>
            ) : (
              <div className="preauth-form ">
                <div>
                  <JsonTable item={data?.getVerificationTransaction?.Data} />
                </div>
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  );
}

export default EligibilityResponseModal;
