import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";

export default function DashboardChart({ apiData }) {
  const [pieData, setPieData] = useState([
    {
      id: 1,
      value: 0,
      label: "Eligibles",
      color: "#84EDAF",
    },
    {
      id: 2,
      value: 0,
      label: "Not Actives",
      color: "#DC262680",
    },
  ]);

  useEffect(() => {
    if (apiData) {
      setPieData([
        {
          id: 1,
          value: apiData?.active_verification || 0,
          label: "Eligibles",
          color: "#84EDAF",
        },
        {
          id: 2,
          value: apiData?.not_active_verification || 0,
          label: "Not Actives",
          color: "#DC262680",
        },
      ]);
    }
  }, [apiData]);

  const sizing = {
    margin: { right: 5 },
    height: 200,
  };
  const TOTAL = pieData.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params) => {
    const percent = params.value / TOTAL;
    return `${params?.value}`;
  };

  const xLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dataset = [
    {
      month: "Jan",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
    {
      month: "Feb",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
    {
      month: "Mar",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
    {
      month: "Apr",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
    {
      month: "May",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
    {
      month: "Jun",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
    {
      month: "Jul",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
    {
      month: "Aug",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
    {
      month: "Sep",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
    {
      month: "Oct",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
    {
      month: "Nov",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
    {
      month: "Dec",
      total: 0,
      approved: 0,
      rejected: 0,
      errored: 0,
      cancelled: 0,
      queued: 0,
      partials: 0,
    },
  ];

  const pData = dataset.map((item) => item.total);
  const uData = dataset.map((item) => item.approved);
  const rData = dataset.map((item) => item.rejected);
  const eData = dataset.map((item) => item.errored);
  const cData = dataset.map((item) => item.cancelled);
  const qData = dataset.map((item) => item.queued);
  const paData = dataset.map((item) => item.partials);

  const pieParams = { height: 200, margin: { right: 5 } };
  return (
    <div className="px-2">
      <div className="flex">
        <div className="w-full">
          {pieData.length > 0 && apiData && (
            <PieChart
              series={[
                {
                  outerRadius: 90,
                  data: pieData,
                  arcLabel: getArcLabel,
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontSize: 14,
                  m: "auto",
                },
              }}
              {...sizing}
            />
          )}
        </div>

        {/* <div className="flex flex-col gap-2 justify-center">
          
          {data.map((item) => (
            <div key={item.id}>
              <span
                className="inline-block w-3 h-3 mr-1"
                style={{ backgroundColor: item.color }}
              ></span>
              {item.label}
            </div>
          ))}
        </div> */}
      </div>
      <div className="w-full">
        <LineChart
          sx={{ width: "100%" }}
          height={250}
          series={[
            {
              data: pData,
              label: "Total",
              yAxisKey: "leftAxisId",
              color: "#7CD2FC",
            },
            {
              data: uData,
              label: "Approved",
              yAxisKey: "leftAxisId",
              color: "#84EDAF",
            },
            {
              data: rData,
              label: "Rejected",
              yAxisKey: "leftAxisId",
              color: "#DC262680",
            },
            {
              data: eData,
              label: "Errored",
              yAxisKey: "leftAxisId",
              color: "#FC997480",
            },
            {
              data: cData,
              label: "Cancelled",
              yAxisKey: "leftAxisId",
              color: "#FF00FF80",
            },
            {
              data: qData,
              label: "Queued",
              yAxisKey: "leftAxisId",
              color: "#ED6C0280",
            },
            {
              data: paData,
              label: "Partials",
              yAxisKey: "leftAxisId",
              color: "#9C27B080",
            },
          ]}
          xAxis={[{ scaleType: "point", data: xLabels }]}
          yAxis={[{ id: "leftAxisId" }]}
          legend={{
            hidden: false,
            fontSize: 1,
            label: {
              fontSize: 1,
            },
            itemSize: 1,
          }}
        />

        <div className="text-gray-600 text-center font-semibold">
          Claim Yearly Chart
        </div>
      </div>
    </div>
  );
}
